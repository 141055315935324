import { useEffect, useState } from 'react'

import { getMachIdFromCookie } from '@/features/advertisement/utils/gptTargeting'
import { lap } from '@/features/listing/scripts/lap/lap.min.js'
import { getGSIApplicationId } from '@/features/listing/utils/GSITracking'
import { type VipQueryListing } from '@/types/listings'
import { sendToLogger } from '@/utils/sendToLogger'
import { isVipJustEdited, isVipJustPosted } from '@/utils/vip/vipStates'

/**
 * Custom hook to handle GSI tracking for a listing.
 *
 * @param - The listing object for which GSI tracking is to be enabled.
 * @returns An object containing the state of GSI tracking.
 *
 * @example
 * const { isGSITrackingEnabled } = useGSITracking(listing)
 *
 * @remarks
 * This hook initializes GSI tracking if the listing is provided and certain conditions are met:
 * - The listing is not just posted.
 * - The listing is not just edited.
 * - The GSI application ID is available.
 *
 */
export const useGSITracking = (listing?: VipQueryListing | null) => {
  const [isGSITrackingEnabled, setIsGSITrackingEnabled] = useState<boolean>(false)

  useEffect(() => {
    if (listing) {
      const gsiApplicationId = getGSIApplicationId(listing)
      const isEnabled = !isVipJustPosted() && !isVipJustEdited() && !!gsiApplicationId

      if (isEnabled && !isGSITrackingEnabled) {
        try {
          lap.init({
            adID: listing.id,
            machineId: getMachIdFromCookie(),
            applicationID: gsiApplicationId,
          })
          setIsGSITrackingEnabled(isEnabled)
        } catch (err) {
          sendToLogger(err, { fingerprint: ['gsi-tracking-vip'] })
        }
      }
    }
  }, [isGSITrackingEnabled, listing])

  return { isGSITrackingEnabled }
}
