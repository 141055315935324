export const addCtmTargetingToSlot = (slotId: string, tabId: number) => {
  if (typeof window !== 'undefined' && window.googletag && googletag.apiReady) {
    const slots = googletag.pubads().getSlots()

    for (const slot of slots) {
      // Need to find the correct slot to pass into the refresh fn.
      if (slot.getSlotElementId() === slotId) {
        slot.setTargeting('ctm', tabId.toString())
        break
      }
    }
  }
}
