import { type ListingPriceRating } from '@kijiji/generated/graphql-types'
import { formatPrice } from '@kijiji/money'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { PriceInsightsBadge } from '@/features/listing/components/price-insights/PriceInsightsBadge'
import {
  AveragePriceContainer,
  AveragePriceLabelLine,
  CarfaxLogoWrapper,
  PriceInsightCarfaxLogoSmall,
  PriceInsightScaleContainer,
  PriceInsightsProgressBarLabel,
  PriceInsightsProgressBarLabelContainer,
} from '@/features/listing/components/price-insights/styled'
import { PRICE_INSIGHT_COLOUR_MAP } from '@/features/listing/constants/priceInsight'
import { getPriceRelativeToMarketValuePercentage } from '@/features/listing/utils/getPriceRelativeToMarketValuePercentage'
import { ROUTE_LOCALE, useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'
import { ProgressBarPercent } from '@/ui/atoms/progress-bar-percent'
import { Spacing } from '@/ui/atoms/spacing'

export type PriceInsightsProgressBarProps = {
  averagePrice: number
  rating: ListingPriceRating
  price: number
}

const CARFAX_LOGO_SMALL = '/next-assets/images/carfax-logo-small.svg'

export const PriceInsightsProgressBar: FC<PriceInsightsProgressBarProps> = ({
  averagePrice,
  price,
  rating,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { routeLocale } = useLocale()

  const colorMap = PRICE_INSIGHT_COLOUR_MAP(colors)

  const ratingColorMap = colorMap[rating]

  const progress = getPriceRelativeToMarketValuePercentage(price, averagePrice)

  const formattedPrice = formatPrice(price, {
    isFrench: routeLocale === ROUTE_LOCALE.fr,
    suppressCents: true,
  })

  const formattedAveragePrice = formatPrice(averagePrice, {
    isFrench: routeLocale === ROUTE_LOCALE.fr,
    suppressCents: true,
  })

  const label = (
    <PriceInsightsProgressBarLabelContainer gap={spacing.mini} flexDirection="column-reverse">
      <PriceInsightsProgressBarLabel weight="bold" textAlign="center">
        {formattedPrice}
      </PriceInsightsProgressBarLabel>
      <PriceInsightsBadge insightType={rating} />
    </PriceInsightsProgressBarLabelContainer>
  )

  return (
    <PriceInsightScaleContainer flexDirection="column" alignItems="center" justifyContent="center">
      <ProgressBarPercent
        id="price-insights"
        progress={progress}
        label="price-insights"
        progressLabel={label}
        color={ratingColorMap.progressBar}
      />
      <AveragePriceContainer alignItems="center" justifyContent="flex-start">
        <CarfaxLogoWrapper alignItems="center">
          <PriceInsightCarfaxLogoSmall src={CARFAX_LOGO_SMALL} alt="Carfax" skipNextImage />
        </CarfaxLogoWrapper>
        <Spacing p={`${spacing.mini} ${spacing.defaultSmall}`}>
          <BodyText size="xSmall" color={colors.grey.light1}>
            {t('listing:price_insights.average_price.label')} <br />
            {formattedAveragePrice}
          </BodyText>
        </Spacing>
        <AveragePriceLabelLine />
      </AveragePriceContainer>
    </PriceInsightScaleContainer>
  )
}
