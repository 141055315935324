import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { hexToRgb } from '@/ui/helpers/hexToRgb'

export const SellerVipTopBannerWrapper = styled.div(
  ({ theme }) => `
  background: ${theme.colors.purple.light5};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.boxShadow['shadow-4']};
  justify-content: center;
  margin-top: ${theme.spacing.default};
  padding: ${theme.spacing.default} ${theme.spacing.husky};
  
  ${MEDIA_QUERIES(theme).medium}{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  `
)

export const SellerVipStatusContainer = styled.div(
  ({ theme }) => `
  border-bottom: .1rem solid ${theme.colors.grey.light3};
  padding-bottom: ${theme.spacing.default};
  
  ${MEDIA_QUERIES(theme).medium}{
    padding-right: ${theme.spacing.husky};
    border-right: .1rem solid ${theme.colors.grey.light3};
    border-bottom: none;
  }
`
)

export const SellerVipMetricsContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr auto);
`

export const SellerVipMetricItem = styled.li(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  padding: 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
`
)

export const SellerVipPromoteContainer = styled(Flex)(
  ({ theme }) => `
  padding-top: ${theme.spacing.default};
  
  ${MEDIA_QUERIES(theme).medium}{
    padding-left: ${theme.spacing.husky};
  }
`
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const SellerVipPromoteLearnMoreButton = styled.button`
  align-items: center;
  display: flex;

  & > svg {
    height: 2.4rem;
    width: 2.4rem;
  }
`

/** Sticky Bottom Bar */

export const SellerVipStickyContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.grey.primary};
  bottom: 0;
  display: flex;
  left:0;
  padding: ${theme.spacing.default} ${theme.spacing.default};
  position: sticky;
  width: 100%;
  z-index: ${zIndexRegistry.FLOATING_CTA};

  ${MEDIA_QUERIES(theme).medium}{
    background-color: ${hexToRgb(theme.colors.grey.primary, 0.95)};
    padding: ${theme.spacing.default} 0;
  }
`
)

export const SellerVipEditButton = styled(Button)(
  ({ theme }) => `
  align-items: center;
  display: flex;
  justify-content: center;

  & > svg {
    height: 2.4rem;
    width: auto;
  }

  ${MEDIA_QUERIES(theme).medium}{
    & > svg {
      display: none;
    }
  }
`
)
