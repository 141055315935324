import { isCarsTrucks } from '@kijiji/category'
import { useGetMileageAnalysisQuery } from '@kijiji/generated/graphql-types'
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { VipSectionTitle } from '@/components/vip/shared/vip-section-title/VipSectionTitle'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import {
  MileageAnalysisContainer,
  MileageAnalysisFooter,
  VehicleMileage,
} from '@/components/vip/vip-mileage-analysis/styled'
import { ROUTE_LOCALE } from '@/domain/locale'
import { MileageAnalysisBadge } from '@/features/listing/components/mileage-analysis/MileageAnalysisBadge'
import { MileageAnalysisModal } from '@/features/listing/components/mileage-analysis/MileageAnalysisModal'
import { MileageAnalysisScale } from '@/features/listing/components/mileage-analysis/MileageAnalysisScale'
import { getMileageRangeFromUsage } from '@/features/listing/constants/mileageAnalysis'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { TextLink } from '@/ui/atoms/text-link'

export type MileageAnalysisSectionProps = { categoryId: number; listingId: string }

export const MileageAnalysisSection: FC<MileageAnalysisSectionProps> = ({
  categoryId,
  listingId,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('listing')
  const { routeLocale } = useLocale()
  const [showModal, setShowModal] = useState(false)

  const { data } = useGetMileageAnalysisQuery({
    skip: !isCarsTrucks(categoryId),
    variables: { listingId },
  })
  if (data?.listing?.__typename !== 'AutosListing' || !data.listing.mileageAnalysis) return

  const { categories, usage, value } = data.listing.mileageAnalysis
  const activeMileageRange = getMileageRangeFromUsage(usage)

  const handleModalOpen = () => {
    trackEvent({ action: GA_EVENT.MileageAnalysisLearnMore, label: 'partner=Mileage' })
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  return (
    <VipSectionContainer data-testid="mileage-analysis">
      <Flex flexDirection="column">
        <VipSectionTitle title={t('listing:mileage_analysis.title')} />
        <MileageAnalysisContainer
          alignItems="flex-start"
          flexDirection="column"
          gap={spacing.default}
        >
          <Flex alignItems="center" gap={spacing.defaultSmall}>
            <VehicleMileage as="span">{`${formatWholeNumber(value, routeLocale === ROUTE_LOCALE.en)} km`}</VehicleMileage>
            <MileageAnalysisBadge range={activeMileageRange} />
          </Flex>
          <MileageAnalysisScale
            aboveAverageKm={categories.high}
            belowAverageKm={categories.low}
            activeRange={activeMileageRange}
          />
        </MileageAnalysisContainer>
        <MileageAnalysisFooter>
          <BodyText color={colors.grey.light1} size={{ small: 'xSmall', medium: 'small' }}>
            {t('listing:mileage_analysis.disclaimer')}
          </BodyText>
          <TextLink
            as="button"
            variant="secondary"
            hasUnderline
            onClick={handleModalOpen}
            size="small"
          >
            {t('listing:mileage_analysis.modal.button')}
          </TextLink>
        </MileageAnalysisFooter>
      </Flex>

      {showModal && <MileageAnalysisModal isOpen={showModal} handleModalClose={handleModalClose} />}
    </VipSectionContainer>
  )
}
