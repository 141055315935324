import { REAL_ESTATE_L3 } from '@kijiji/category'
import { type RealEstateListingLocation } from '@kijiji/generated/graphql-types'

import { type VipQueryListing, isRealEstateListingLocation } from '@/types/listings'

/**
 * It should return true if the listing is a real estate listing (within the for sale or for rent categories)
 * and has neighbourhood scores.
 * @param listing - The listing to check
 * @returns True if the listing is a real estate listing with neighbourhood scores, false otherwise
 */
export const shouldRenderNeighbourhoodScoresSection = (
  listing: VipQueryListing
): listing is VipQueryListing & {
  location: RealEstateListingLocation
} => {
  return !!(
    [...REAL_ESTATE_L3].includes(listing.categoryId) &&
    isRealEstateListingLocation(listing.location) && // Type guard
    listing.location.neighbourhoodInfo?.scores?.transportation &&
    Object.keys(listing.location.neighbourhoodInfo?.scores?.transportation ?? {}).length > 0
  )
}
