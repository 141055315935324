import {
  LogoType,
  useGetGoogleReviewsQuery,
  useGetProfileQuery,
} from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { Review } from '@/features/profile/components/review/Review'
import { ReviewList } from '@/features/profile/components/styled'
import { generateNumericIdentifierFromName } from '@/features/profile/utils/generateNumericIdentifierFromName'
import { getNumericUserId } from '@/features/profile/utils/getNumericUserId'
import { Skeleton } from '@/ui/atoms/skeleton'
import { Spacing } from '@/ui/atoms/spacing'
import { isDealerAccount } from '@/utils/user'

export type UserReviewsProps = {
  userId: string
}

/**
 * At the moment this component displays the first 3-5 dealer Google reviews only.
 * In the future we may want to expand the component to display the first couple of Kijiji
 * user reviews.
 */
export const UserReviews: FC<UserReviewsProps> = ({ userId }) => {
  const { spacing } = useTheme()
  const { t } = useTranslation('common')

  const numericUserId = getNumericUserId(userId)

  const { data: profileData } = useGetProfileQuery({
    fetchPolicy: 'cache-first',
    variables: {
      profileId: userId,
      size: LogoType.ViewAd,
    },
  })

  const isDealer = isDealerAccount(profileData?.profile.userType)

  const { data: googleReviewData, loading: loadingDealerReviews } = useGetGoogleReviewsQuery({
    skip: !isDealer,
    fetchPolicy: 'cache-first',
    variables: { dealerId: numericUserId.toString() },
  })

  /**
   * It should show a max of 3 items on desktop and 2 on mobile
   */
  const reviews = googleReviewData?.reviewsFromGoogle?.reviews?.slice(0, 3)

  if (!reviews && !loadingDealerReviews) {
    return null
  }

  return (
    <Spacing mTop={spacing.xLarge} mBottom={spacing.default} data-testid="user-reviews">
      <ReviewList flexDirection="column" gap={spacing.default}>
        {loadingDealerReviews ? (
          <Skeleton count={3} height="12.8rem" id="user-reviews" />
        ) : (
          reviews?.map((review) => (
            <Review
              avatarUrl={review.author.profilePhotoUrl ?? undefined}
              collapsedText={t('common:read.more')}
              date={review.time}
              expandedText={t('common:show.less')}
              key={review.time}
              rating={review.rating}
              text={review.text ?? undefined}
              userName={review.author.name}
              userId={generateNumericIdentifierFromName(review.author.name)}
            />
          ))
        )}
      </ReviewList>
    </Spacing>
  )
}
