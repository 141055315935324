import { type VehicleReport } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC, Fragment } from 'react'
import { useTheme } from 'styled-components'

import { VehicleHistoryViewReportContent } from '@/components/vip/vip-vehicle-history/styled'
import { getVehicleReportBadges } from '@/components/vip/vip-vehicle-history/utils'
import { CAR_HISTORY_BADGES } from '@/constants/listings'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'

type VehicleHistoryViewBodyProps = {
  reportBadgeKeys?: VehicleReport['badgeKeys']
}

export const VehicleHistoryViewBody: FC<VehicleHistoryViewBodyProps> = ({ reportBadgeKeys }) => {
  const { colors } = useTheme()
  const { routeLocale } = useLocale()
  const { t } = useTranslation(TRANSLATION_KEYS.VIP)

  const { lowKilometresIcon, noAccidentsIcon, oneOwnerIcon, topConditionIcon } =
    getVehicleReportBadges(t, routeLocale)

  const getActiveBadges = (badges?: VehicleReport['badgeKeys']) => {
    if (!badges) return []

    return badges.map((item) => {
      switch (item) {
        case CAR_HISTORY_BADGES.ACCIDENT_FREE:
          return noAccidentsIcon
        case CAR_HISTORY_BADGES.ONE_OWNER:
          return oneOwnerIcon
        case CAR_HISTORY_BADGES.LOW_KILOMETERS:
          return lowKilometresIcon
        case CAR_HISTORY_BADGES.TOP_CONDITION:
          return topConditionIcon
        default:
          return null
      }
    })
  }

  const badges = getActiveBadges(reportBadgeKeys)

  return (
    <>
      <VehicleHistoryViewReportContent data-testid="vehicle-history-view-content">
        {badges.map((item, index) => {
          return <Fragment key={`vehicle-history-badge-${index}`}>{item}</Fragment>
        })}
      </VehicleHistoryViewReportContent>

      <BodyText color={colors.grey.primary} size="medium">
        {t('vehicle_history.view.description_long')}
      </BodyText>
    </>
  )
}
