import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import {
  getAdditionalOptionsToDisplay,
  getDealerUpdatesAttributesToDisplay,
} from '@/features/attributes/utils/getDisplayableAttributes'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { RoundedChip } from '@/ui/atoms/rounded-chip'
import { Spacing } from '@/ui/atoms/spacing'

export type AdditionalOptionsChipsProps = {
  /** It will be used to define a unique key for the additional options list */
  sectionId: string
  categoryId: number
  attributes?: ListingAttributeV2[]
}

export const AdditionalOptionsChips: FC<AdditionalOptionsChipsProps> = ({
  attributes,
  categoryId,
  sectionId,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { colors, spacing } = useTheme()

  const additionalOptions = getAdditionalOptionsToDisplay(t, attributes)
  const dealerUpdates = getDealerUpdatesAttributesToDisplay(t, categoryId, attributes)

  const sellerOffersList = [...additionalOptions, ...dealerUpdates]
  if (!sellerOffersList.length) return null

  return (
    <Spacing pTop={spacing.large}>
      <Flex gap={spacing.defaultSmall} alignItems="center" flexWrap="wrap">
        <BodyText size="small" color={colors.grey.light1}>
          {t('listing:additional_options.seller.title')}
        </BodyText>

        {sellerOffersList.map((item) => (
          <RoundedChip
            data-testid="additional-option-chip"
            isNonInteractable
            key={`${sectionId}-${item.canonicalName}`}
          >
            {item.label}
          </RoundedChip>
        ))}
      </Flex>
    </Spacing>
  )
}
