import { useTranslation } from 'next-i18next'

import { VipAttributesFeaturedWrapper } from '@/components/vip/vip-attributes/styled'
import { AttributesFeatured } from '@/features/attributes/components/AttributesFeatured'
import { getFeaturedAttributesTitleKey } from '@/features/attributes/constants/attributesFeatured'
import { type DisplayableAttribute } from '@/features/attributes/types/listingAttributes'

type VipAttributesFeaturedProps = {
  featuredAttributes: DisplayableAttribute[]
  categoryId: number
}

export const VipAttributesFeatured = ({
  featuredAttributes,
  categoryId,
}: VipAttributesFeaturedProps) => {
  const { t } = useTranslation('vip')
  const title = t(getFeaturedAttributesTitleKey(categoryId))

  return (
    <VipAttributesFeaturedWrapper>
      <AttributesFeatured title={title} attributes={featuredAttributes} />
    </VipAttributesFeaturedWrapper>
  )
}
