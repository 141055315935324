import { useTranslation } from 'next-i18next'

import { TRANSLATION_KEYS } from '@/constants/localization'

export type VirtualTourProps = {
  url: string
}

export const VirtualTour = ({ url }: VirtualTourProps) => {
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)

  return (
    <iframe
      data-testid="virtual-tour-iframe"
      title={t('listing:virtual_tour.title')}
      style={{ width: '100%', height: '100%', border: 0 }}
      src={url}
      sandbox="allow-presentation allow-same-origin allow-scripts"
      allow="xr-spatial-tracking"
    />
  )
}
