import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'
import { Trans } from 'next-i18next'

import { CPO_VALUES } from '@/features/attributes/constants/certifiedPreOwnedValues'
import { TextLink } from '@/ui/atoms/text-link'

export const getCpoAttributeLink = (attribute: ListingAttributeV2) => {
  const value = attribute.canonicalValues?.[0] ?? attribute.values[0]
  const cpo = value ? CPO_VALUES[value] : null

  if (!cpo) return ''

  return (
    <Trans
      i18nKey="listing:attribute.label_override.cpo"
      components={[
        <TextLink
          hasUnderline
          href={cpo.link}
          key="cpo-link"
          size="medium"
          target="_blank"
          variant="secondary"
        />,
      ]}
    />
  )
}
