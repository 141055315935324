/**
 * This function takes an array of any type and returns this same type in 2 different columns
 * This dynamic typescript helps maintaining the type of the array returned as first and second column
 */
export const divideArrayInTwoColumns = <T>(array: T[]) => {
  const midPoint = Math.ceil(array.length / 2)

  const firstColumn = array.slice(0, midPoint)
  const secondColumn = array.slice(midPoint)

  return { firstColumn, secondColumn }
}
