import { useGetProfileMetricsQuery } from '@kijiji/generated/graphql-types'
import { differenceInMonths, differenceInYears } from 'date-fns'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { VerticalDivider } from '@/components/shared/vertical-divider/VerticalDivider'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { SellerMetricItem } from '@/features/seller-metrics/components/SellerMetricItem'
import { SellerMetricsContainer } from '@/features/seller-metrics/components/styled'
import {
  hasPositiveReplyRate,
  REPLY_RATE_BOUND,
} from '@/features/seller-metrics/utils/hasPositiveReplyRate'
import { Spacing } from '@/ui/atoms/spacing'

const EMPTY_METRICS_LABEL = '--'

export type VipSellerMetricsProps = { posterId?: string }

export const SellerMetrics: FC<VipSellerMetricsProps> = ({ posterId }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.PROFILE])
  const { spacing } = useTheme()

  const { data } = useGetProfileMetricsQuery({
    skip: !posterId,
    fetchPolicy: 'cache-first',
    variables: { profileId: `${posterId}` },
  })

  if (data?.profile.__typename !== 'StandardProfileV2') return null
  const { memberSince, replyRate, responsiveness } = data.profile

  const getResponsivenessLabel = () => {
    return responsiveness
      ? t(`profile.metrics.responsiveness.${responsiveness}`)
      : EMPTY_METRICS_LABEL
  }

  const getReplyRateLabel = () => {
    if (!replyRate) return EMPTY_METRICS_LABEL

    /** Check if reply rate is positive or negative */
    if (hasPositiveReplyRate(replyRate)) {
      return `${replyRate}%`
    } else {
      return `< ${REPLY_RATE_BOUND}%`
    }
  }

  const getMemberSinceConfig = (): { value: string; label: string } => {
    if (!memberSince)
      return { value: EMPTY_METRICS_LABEL, label: t('profile.metrics.member_since.label') }

    const currentDate = new Date()

    const yearsAmount = differenceInYears(currentDate, memberSince)
    if (yearsAmount) {
      return {
        value: `${yearsAmount} ${t('profile.metrics.member_since.year', { count: yearsAmount })}`,
        label: t('profile.metrics.member_since.label'),
      }
    }

    const monthsAmount = differenceInMonths(currentDate, memberSince)
    if (monthsAmount) {
      return {
        value: `${monthsAmount} ${t('profile.metrics.member_since.month')}`,
        label: t('profile.metrics.member_since.label'),
      }
    }

    return {
      value: t('profile.metrics.member_since.new'),
      label: t('profile.metrics.member_since.new.label'),
    }
  }

  const memberSinceConfig = getMemberSinceConfig()

  return (
    <Spacing p={spacing.default}>
      <SellerMetricsContainer>
        <SellerMetricItem
          value={getResponsivenessLabel()}
          label={t('profile.metrics.responsiveness.label')}
        />

        <VerticalDivider />

        <SellerMetricItem
          value={getReplyRateLabel()}
          label={t('profile.metrics.reply_rate.label')}
        />

        <VerticalDivider />

        <SellerMetricItem value={memberSinceConfig.value} label={memberSinceConfig.label} />
      </SellerMetricsContainer>
    </Spacing>
  )
}
