import { type GetReviewSummaryQuery } from '@kijiji/generated/graphql-types'

import { getProfileRatingUrl } from '@/features/profile/utils/getProfileRatingUrl'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type RatingProps } from '@/ui/molecules/rating'

type GetUserRatingPropsType = {
  /** isOwnRating and numericUserId are required to compose the profile rating URL */
  isOwnRating: boolean
  loadingUserReviews: boolean
  numericUserId: number
  reviewData?: GetReviewSummaryQuery
}

export type GetRatingReturnType = Omit<RatingProps, 'size' | 'starMeterLabel'> & {
  shouldShowRating: boolean
}

/**
 * Generates the user rating props for the <Rating> component.
 *
 * @param isOwnRating - Indicates if the rating is for the user's own profile.
 * @param loadingUserReviews - Whether the user reviews are currently loading.
 * @param numericUserId - The numeric identifier of the user.
 * @param reviewData - The review data fetched from the server.
 */
export const getUserRatingProps = ({
  isOwnRating,
  loadingUserReviews,
  numericUserId,
  reviewData,
}: GetUserRatingPropsType): GetRatingReturnType => {
  const ratingSummary = reviewData?.reviewSummary
  const hasReviews = ratingSummary && ratingSummary.totalReviews > 0
  const shouldShowRating = !!(loadingUserReviews || hasReviews)
  const score = ratingSummary?.sumScore ? ratingSummary.sumScore / ratingSummary.totalReviews : 0
  const reviewUrl = getProfileRatingUrl(numericUserId, isOwnRating)
  const reviewsLabel = ratingSummary?.totalReviews.toString() ?? '0'
  const handleReviewUrlClick = () => {
    trackEvent({ action: GA_EVENT.ViewUserRatingsClick, label: 'btn=sellerInfoBox' })
  }

  return {
    handleReviewUrlClick,
    isLoading: loadingUserReviews,
    shouldOpenInNewTab: false,
    reviewsLabel,
    reviewUrl,
    score,
    shouldShowRating,
  }
}
