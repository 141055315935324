import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import {
  type MileageRange,
  MILEAGE_RANGE_COLOUR_MAP,
} from '@/features/listing/constants/mileageAnalysis'
import { Badge, BadgeSize } from '@/ui/atoms/badge'

interface MileageAnalysisBadgeProps {
  range: MileageRange
}

export const MileageAnalysisBadge: FC<MileageAnalysisBadgeProps> = ({ range }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])
  const { colors } = useTheme()
  return (
    <Badge
      size={BadgeSize.XLARGE}
      backgroundColor={MILEAGE_RANGE_COLOUR_MAP[range]}
      borderRadius="rounded"
      color={colors.grey.primary}
      weight="medium"
      label={t(`listing:mileage_analysis.badge.${range}`)}
      isBorderless
    />
  )
}
