import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

type SellerMetricItemProps = {
  value: string
  label: string
}

export const SellerMetricItem: FC<SellerMetricItemProps> = ({ label, value }) => {
  const { colors } = useTheme()

  return (
    <Flex flexDirection="column" alignItems="center" data-testid="seller-metric-item">
      <BodyText size="medium" color={colors.grey.primary}>
        {value}
      </BodyText>
      <BodyText size="xSmall" color={colors.grey.light1}>
        {label}
      </BodyText>
    </Flex>
  )
}
