import CloseIcon from '@kijiji/icons/src/icons/Close'
import { ButtonHTMLAttributes, FC, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  CloseButton,
  Direction,
  DrawerBackdrop,
  DrawerContainer,
} from '@/ui/atoms/drawer/styled'

import { useKeyboardInteractions } from './useKeyboardInteractions'
import { usePreventBodyToScroll } from './usePreventBodyToScroll'

export type DrawerProps = {
  children: React.ReactNode
  closeButtonLabel: string
  direction?: Direction
  isOpen: boolean
  label: string
  maxHeight?: string
  maxWidth?: string
  onCancel: () => void
  portalRefId?: string
  hasBackdrop?: boolean
  isCustomCloseButton?: boolean
}

/**
 * A drawer component that pops out from the bottom, top, left and right of the page
 *
 * @param closeButtonLabel - The close button accessibility label
 * @param direction - The direction you would like the drawer to open from (i.e. left, right, top). Bottom is the default direction.
 * @param isOpen - Determines the visibility of the component
 * @param label - The drawer accessibility label
 * @param maxHeight - Optional drawer maximum height (i.e. '40rem')
 * @param maxWidth - Optional drawer maximum width (i.e. '40rem')
 * @param onCancel - Accepts a callback that's executed when the user clicks the close button
 * @param portalRefId - The drawer portal ID. Make sure to prepend your ID name with a #.
 * @param hasBackdrop - Determines if the backdrop (a slight shadow behind) is present when the drawer is open
 * @param isCustomCloseButton - If true, the drawer will not render a default close button
 */
export const Drawer: FC<DrawerProps> = ({
  children,
  closeButtonLabel,
  direction = 'bottom',
  isOpen,
  label,
  maxHeight = 'initial',
  maxWidth = 'initial',
  onCancel,
  portalRefId = '#drawerPortal',
  hasBackdrop = false,
  isCustomCloseButton = false,
  ...rest
}) => {
  const ref = useRef<HTMLElement | null>(null)
  const prevFocusedElement = useRef<HTMLElement | null>(null)
  const [mounted, setMounted] = useState(false)

  /* 
    In order to meet WCAG 2.1.1, we need to trap focus within the drawer when it's open
    https://www.wcag.com/developers/2-1-1-keyboard/
    https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/
  */
  useKeyboardInteractions({ isOpen, onCancel, portalRef: ref })
  usePreventBodyToScroll({ hasBackdrop, isOpen })

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>(portalRefId)

    // If a portal element is not found - create one and append it to the document body
    if (!ref.current) {
      const rootContainer = document.createElement('div')
      rootContainer.setAttribute('id', portalRefId)
      document.body.appendChild(rootContainer)

      ref.current = rootContainer
    }
    setMounted(true)
  }, [])

  // If the portal is open, and it contains a focusable element, focus on that element. Otherwise the entire page must be traversed before the user reaches the drawer if they're using keyboard navigation.
  useEffect(() => {
    if (isOpen && ref.current) {
      const currentFocusedElement = document.activeElement
      if (currentFocusedElement instanceof HTMLElement) {
        prevFocusedElement.current = currentFocusedElement
      }

      const focusableElement = ref.current.querySelector(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      ) as HTMLElement | null

      if (focusableElement) {
        focusableElement.focus()
      }
    } else if (!isOpen) {
      prevFocusedElement.current?.focus()
    }
  }, [isOpen])

  return mounted && ref.current
    ? createPortal(
        <>
          {hasBackdrop && (
            <DrawerBackdrop
              data-testid="drawer-backdrop"
              onClick={onCancel}
              isOpen={isOpen}
            />
          )}
          <DrawerContainer
            role="dialog"
            aria-modal={isOpen}
            aria-label={label}
            direction={direction}
            isOpen={isOpen}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            {...rest}
          >
            {children}
            {!isCustomCloseButton && (
              <DrawerCloseButton
                aria-label={closeButtonLabel}
                onClick={onCancel}
              />
            )}
          </DrawerContainer>
        </>,
        ref.current
      )
    : null
}

export const DrawerCloseButton = ({
  'aria-label': ariaLabel,
  onClick,
}: Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'aria-label'>) => (
  <CloseButton
    aria-label={ariaLabel}
    onClick={onClick}
    data-testid="drawer-close-button"
  >
    <CloseIcon aria-hidden />
  </CloseButton>
)
