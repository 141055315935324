import { CallOutlineIcon } from '@kijiji/icons/src/icons'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { InlineIconLink } from '@/components/vip/vip-seller-profile/InlineIconLink'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { type ProfilePhoneNumberProps } from '@/features/profile/components/phone-number/ProfilePhoneNumber'
import { useProfilePhoneNumber } from '@/features/profile/utils/phone-number/useProfilePhoneNumber'
import { Skeleton } from '@/ui/atoms/skeleton'

export type ProfilePhoneNumberInlineProps = ProfilePhoneNumberProps

/**
 * Inline version of the listing phone number component that reveals the phone
 * number of a listing.
 *
 * @see {@link ProfilePhoneNumberProps}
 */
export const ProfilePhoneNumberInline: FC<ProfilePhoneNumberInlineProps> = (props) => {
  const { colors } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.R2S)

  const {
    revealedPhoneNumber,
    isRevealPhoneNumberLoading,
    revealPhoneNumberError,
    triggerPhoneNumberReveal,
  } = useProfilePhoneNumber(props)

  if (isRevealPhoneNumberLoading) {
    return <Skeleton count={1} variant="inline" height="2rem" width="15rem" />
  }

  /**
   * Returns the label for the phone number link between loading, revealed, and
   * not revealed states.
   */
  const getLinkLabel = () => {
    if (revealedPhoneNumber) {
      return revealedPhoneNumber
    } else if (revealPhoneNumberError) {
      return t('common:forms.errors.generic.description')
    } else {
      return t('r2s:phone_number.reveal.long_label')
    }
  }

  const handleClick = () => {
    if (revealedPhoneNumber) return
    triggerPhoneNumberReveal()
  }

  return (
    <InlineIconLink
      Icon={CallOutlineIcon}
      href={`tel:${revealedPhoneNumber}`}
      iconColor={colors.purple.primary}
      isButton={!revealedPhoneNumber}
      label={getLinkLabel()}
      onClick={handleClick}
      target="_self"
    />
  )
}
