import { theme } from '@kijiji/theme'
import styled from 'styled-components'

import { MileageRange } from '@/features/listing/constants/mileageAnalysis'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const SCALE_STYLES_MAP = {
  [MileageRange.BelowAverage]: {
    activeColour: theme.colors.green.primary,
    borderRadius: `${theme.borderRadius.rounded} 0 0 ${theme.borderRadius.rounded}`,
  },
  [MileageRange.Average]: { activeColour: theme.colors.purple.primary, borderRadius: '0' },
  [MileageRange.AboveAverage]: {
    activeColour: theme.colors.yellow.primary,
    borderRadius: `0 ${theme.borderRadius.rounded} ${theme.borderRadius.rounded} 0`,
  },
}

export const MileageAnalysisScaleWrapper = styled(Flex)`
  width: 100%;
`

export const MileageAnalysisScaleSectionWrapper = styled(Flex)`
  flex: 1;
`

export const MileageAnalysisScaleBar = styled.div<{ isActive: boolean; range: MileageRange }>(
  ({ theme, isActive, range }) => `
    background-color:  ${
      isActive ? SCALE_STYLES_MAP[range].activeColour : theme.colors.grey.light3
    };
    border-radius: ${SCALE_STYLES_MAP[range].borderRadius};
    height: 0.6rem;
    margin: 0.9rem 0;
    width: 100%;
  `
)

export const MileageAnalysisScaleText = styled(Flex)(
  ({ theme }) => `
    flex-direction: column;

    ${MEDIA_QUERIES(theme).medium} {
      flex-direction: row;
      gap: ${theme.spacing.defaultSmall};
    }
  `
)
