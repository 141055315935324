import { type LanguageKey } from '@/domain/locale'

/**
 * Gets GMaps URL from address
 */
export const getGoogleMapsUrl = (
  languageKey: LanguageKey,
  address?: string,
  coordinates?: { latitude: number; longitude: number } | null
) => {
  const url = new URL('https://www.google.com/maps/search/')

  if (address) {
    url.searchParams.append('q', address)
  } else if (coordinates?.latitude != null && coordinates?.longitude != null) {
    url.searchParams.append('q', `${coordinates.latitude},${coordinates.longitude}`)
  } else {
    return ''
  }

  /**
   * Append language parameter
   */
  url.searchParams.append('hl', languageKey)

  return url.toString()
}
