import styled from 'styled-components'

type VerticalDividerProps = {
  color?: string
}

export const VerticalDivider = styled.div<VerticalDividerProps>(
  ({ theme, color }) => `
    background-color: ${color ?? theme.colors.grey.light3};
    display: block;
    flex-shrink: 0;
    width: 0.1rem;
  `
)
