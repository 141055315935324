import throttle from 'lodash/throttle'
import { useTranslation } from 'next-i18next'
import { type FC, type PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { HideAtOrLarger } from '@/components/shared/breakpoint'
import {
  ButtonDividerWrapper,
  FadeOutButtonWrapper,
  FadeOutOverlay,
  FadeOutWrapper,
} from '@/components/shared/fade-out-section/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { Button } from '@/ui/atoms/button'
import { isVisibleInViewport } from '@/utils/isVisibleInViewport'

export type FadeOutSectionProps = PropsWithChildren & {
  maxSectionHeightRem?: number
  sectionColor?: string
  isMobileOnly?: boolean

  /**
   * Whether to show the "show more" button.
   */
  disableShowMoreButton?: boolean

  /**
   * Callback function that is called when the section is expanded
   */
  onExpand?: () => void
}

export const FadeOutSection: FC<FadeOutSectionProps> = ({
  children,
  maxSectionHeightRem = 15.6,
  isMobileOnly,
  sectionColor,
  onExpand,
  disableShowMoreButton,
}) => {
  const { breakpoints } = useTheme()

  const { t } = useTranslation(TRANSLATION_KEYS.COMMON)

  const [hasOverflow, setHasOverflow] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const setupContainerOverflow = useCallback(() => {
    if (!contentRef.current) return

    /** Check container's height to define if "show more" button + overlay should appear  */
    const contentHeight = contentRef.current.scrollHeight
    const maxHeight = maxSectionHeightRem * 10

    setHasOverflow(contentHeight > maxHeight)
  }, [maxSectionHeightRem])

  useEffect(() => {
    /** On load */
    setupContainerOverflow()

    const handleResize = throttle(() => {
      if ((isMobileOnly && window.innerWidth < breakpoints.medium) || !isMobileOnly) {
        setupContainerOverflow()
      }
    }, 300)
    window.addEventListener('resize', handleResize)

    /** On browser resize */
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoints.medium, isMobileOnly, setupContainerOverflow])

  useEffect(() => {
    if (!contentRef.current) return
    /** Check container's height to define if "show more" button + overlay should appear  */
    const contentHeight = contentRef.current.scrollHeight
    const maxHeight = maxSectionHeightRem * 10

    if (contentHeight > maxHeight) {
      setHasOverflow(true)
    }
  }, [maxSectionHeightRem])

  const expandContent = () => {
    onExpand?.()
    setIsExpanded(true)
  }

  /**
   * This function will automatically scroll the user to the same position as they were before clicking on the "Show Less" button
   * This will avoid the user being left somewhere down the page they haven't scrolled to when this container shrinks
   */
  const scrollToSamePosition = () => {
    /** It won't scroll the user if the container is still in the viewport once the content is max in size */
    if (!contentRef.current || isVisibleInViewport(contentRef.current)) return

    const expandedContentHeight = contentRef?.current?.scrollHeight ?? 0
    const maxHeight = maxSectionHeightRem * 10

    const heightToScroll = expandedContentHeight - maxHeight

    window.scrollBy({ top: -heightToScroll })
  }

  const collapseContent = () => {
    scrollToSamePosition()

    setIsExpanded(false)
  }

  const fadeOutButtons = (
    <ButtonDividerWrapper isExpanded={isExpanded} isMobileOnly={isMobileOnly}>
      {!isExpanded && (
        <Button onClick={expandContent} variant="secondary" size="small">
          {t('common:show.more')}
        </Button>
      )}

      {isExpanded && (
        <Button onClick={collapseContent} variant="secondary" size="small">
          {t('common:show.less')}
        </Button>
      )}
    </ButtonDividerWrapper>
  )

  return (
    <FadeOutWrapper
      isMobileOnly={isMobileOnly}
      ref={contentRef}
      isExpanded={isExpanded}
      maxSectionHeight={maxSectionHeightRem}
      data-testid="fade-out-section"
    >
      {hasOverflow && !isExpanded && (
        <FadeOutOverlay
          sectionColor={sectionColor}
          isMobileOnly={isMobileOnly}
          disableShowMoreButton={disableShowMoreButton}
        />
      )}

      {children}

      {!disableShowMoreButton &&
        hasOverflow &&
        (isMobileOnly ? (
          <HideAtOrLarger breakpoint="medium">
            <FadeOutButtonWrapper>{fadeOutButtons}</FadeOutButtonWrapper>
          </HideAtOrLarger>
        ) : (
          <FadeOutButtonWrapper>{fadeOutButtons}</FadeOutButtonWrapper>
        ))}
    </FadeOutWrapper>
  )
}
