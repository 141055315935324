import { type NeighbourhoodScores as NeighbourhoodScoresType } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { NeighbourhoodScoreItem } from '@/components/vip/vip-neighbourhood-info/NeighbourhoodScoreItem'
import { ScoresContainer } from '@/components/vip/vip-neighbourhood-info/styled'
import { getOrderedTransportationScores } from '@/components/vip/vip-neighbourhood-info/utils'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { BodyText } from '@/ui/atoms/body-text'
import { Spacing } from '@/ui/atoms/spacing'

type NeighbourhoodScoresProps = {
  /**
   * The scores to display. E.g:
   * {
   *   transportation: {
   *     walk: { score: 9, description: "Walker's Paradise" },
   *     transit: { score: 8, description: "Excellent Transit" },
   *     cycle: { score: 7, description: "Very Bikeable" },
   *   },
   * }
   */
  scores: NeighbourhoodScoresType
}

/**
 * Component that displays neighbourhood transportation scores for a real estate listing
 * Shows walk score, transit score and bike score with icons and descriptions
 * @returns Returns the scores display or null if no transportation data
 */
export const NeighbourhoodScores = ({ scores = {} }: NeighbourhoodScoresProps) => {
  const { transportation } = scores
  const { colors, spacing } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.VIP)
  const transportationScores = useMemo(
    () => getOrderedTransportationScores(transportation),
    [transportation]
  )

  if (!transportationScores) return null
  return (
    <>
      <ScoresContainer forwardedAs="ul" flexDirection="column">
        {Object.entries(transportationScores).map(([key, value]) => (
          <NeighbourhoodScoreItem
            key={key}
            name={key}
            label={t(`vip:neighbourhood.scores.${key}.title`)}
            score={value.score}
            description={value.description}
          />
        ))}
      </ScoresContainer>
      <Spacing pTop={spacing.default} pBottom={spacing.husky}>
        <BodyText size="small" color={colors.grey.light1}>
          {t('vip:neighbourhood.disclaimer')}
        </BodyText>
      </Spacing>
    </>
  )
}
