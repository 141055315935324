import { type Category } from '@kijiji/generated/graphql-types'

import { type ApiLocale } from '@/domain/locale'
import prepopulatedMessages from '@/features/listing/data/reply-to-seller/prepopulatedMessages.json'

/**
 * Get a prepopulated message for a given category.
 *
 * @see {@link prepopulatedMessages} for the list of prepopulated messages.
 */
export const getPrepopulatedMessage = (
  categoryId: number,
  apiLocale: ApiLocale,
  categoryPath?: (Category | null)[]
) => {
  const categoryL1 = categoryPath?.[0]?.id

  const structuredMessage = prepopulatedMessages.find((item) => {
    const { categoryIds, excludedCategoryIds, l1CategoryIds } = item

    const shouldUseMessage =
      !excludedCategoryIds?.includes(categoryId) &&
      (categoryIds?.includes(categoryId) || (categoryL1 && l1CategoryIds?.includes(categoryL1)))

    return shouldUseMessage
  })

  return structuredMessage?.message[apiLocale]
}
