import {
  type ListingAttributeV2,
  type LivePersonContextVariable,
} from '@kijiji/generated/graphql-types'

import {
  BOOLEAN_NUMERIC_VALUES,
  BOOLEAN_TEXT_VALUES,
} from '@/features/attributes/constants/attributes'
import { CONTEXT_VARIABLE_NAME_MAP } from '@/features/liveperson/constants/contextVariables'

/**
 * Checks if the attribute value is a boolean and returns the appropriate string
 * @param canonicalValue string attribute value
 * @returns 'true' or 'false' if the value is a boolean, null otherwise
 */
const getBooleanAttribute = (canonicalValue: string) => {
  if (canonicalValue === BOOLEAN_NUMERIC_VALUES.YES || canonicalValue === BOOLEAN_TEXT_VALUES.YES)
    return 'true'

  if (canonicalValue === BOOLEAN_NUMERIC_VALUES.NO || canonicalValue === BOOLEAN_TEXT_VALUES.NO)
    return 'false'

  return null
}

/**
 * attribute values from Anvil always in English, so is this pointless?
 * Checks if the attribute has en/fr variations and returns two objects if required
 * @param canonicalName canonical name of the attribute
 * @param value value of the attribute
 * @param acc reducer accumulator for the LivePerson context variables
 * @returns array of LivePerson context variables

 */
const formatReturnValue = (
  canonicalName: string,
  value: string,
  acc: LivePersonContextVariable[]
) => {
  const names = CONTEXT_VARIABLE_NAME_MAP[canonicalName]

  if (!names) return acc

  if (names.length === 1) {
    return [...acc, { name: names[0], value }]
  }

  return [...acc, { name: names[0], value }, { name: names[1], value }]
}

/**
 * Processes attributes for LivePerson Anvil call
 * @param attributes autos attributes for processing
 * @returns array of LivePerson context variables
 */
const getAttributesForLivePerson = (attributes: ListingAttributeV2[]) => {
  return attributes.reduce((acc, attribute) => {
    // check which attribute values we have; may have 1, both, or neither
    const canonicalValue = attribute.canonicalValues?.[0]
    const value = attribute.values?.[0]

    if (canonicalValue) {
      // if there is a canonicalValue, check if it's one of the string boolean types
      const booleanValue = getBooleanAttribute(canonicalValue) // returns null if not boolean type

      if (booleanValue) {
        return formatReturnValue(attribute.canonicalName, booleanValue, acc)
      }

      // if not boolean and no value, fallback to canonical value
      if (booleanValue === null && !value) {
        return formatReturnValue(attribute.canonicalName, canonicalValue, acc)
      }
    }

    // if not boolean, prefer value over canonicalValue
    if (value) {
      return formatReturnValue(attribute.canonicalName, value, acc)
    }

    return acc
  }, [] as LivePersonContextVariable[])
}

type TransformLivePersonVipDataProps = {
  attributes: ListingAttributeV2[]
  sellerId: string
  carfaxReportUrl: string
  dealershipName: string
  address: string
  creditOptIn: boolean
  tradeInOptIn: boolean
}

export const transformLivePersonVipData = ({
  attributes,
  sellerId,
  carfaxReportUrl,
  dealershipName,
  address,
  creditOptIn,
  tradeInOptIn,
}: TransformLivePersonVipDataProps): LivePersonContextVariable[] => {
  const processedAttributes = getAttributesForLivePerson(attributes)

  // all context veriable values should be strings
  return [
    ...processedAttributes,
    { name: 'commercialUserId', value: sellerId },
    { name: 'carfaxReportUrl', value: carfaxReportUrl },
    { name: 'dealership', value: dealershipName },
    { name: 'location', value: address },
    { name: 'autoVerifyFinancing', value: creditOptIn.toString() },
    { name: 'autoVerifyTradeIn', value: tradeInOptIn.toString() },
  ]
}
