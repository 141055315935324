import { ATTRIBUTES, FULFILLMENT_VALUES } from '@/features/attributes/constants/attributes'
import { type AttributeConfig } from '@/features/attributes/constants/attributesConfig'
import { ATTRIBUTES_LABEL_OVERRIDE } from '@/features/attributes/constants/attributesLabelOverride'

/**
 * The additional options fields return values duplicated from different attributes
 * The attributes listed below are the explicit attributes related to the "additional options"
 * & the duplicated groups
 */
export const ADDITIONAL_OPTIONS_FIELDS = [
  ATTRIBUTES.CASHLESS_PAYMENT,
  ATTRIBUTES.CASH_ACCEPTED,
  ATTRIBUTES.CURBSIDE,
  ATTRIBUTES.DROP_OFF,
  ATTRIBUTES.SHIPPING,
  /**
   * The fields below returns the same values as the above, but in groups
   * different categories will return different combination of those values
   */
  ATTRIBUTES.FULFILLMENT,
  ATTRIBUTES.PAYMENT,
  ATTRIBUTES.RENTALS_VIRTUAL_OPTIONS,
]

const { REPLACE_TRUE_VALUE_WITH_TRANSLATION } = ATTRIBUTES_LABEL_OVERRIDE

export const ATTRIBUTES_ADDITIONAL_OPTIONS: AttributeConfig = {
  [ATTRIBUTES.DROP_OFF]: { labelOverride: REPLACE_TRUE_VALUE_WITH_TRANSLATION },
  [ATTRIBUTES.SHIPPING]: { labelOverride: REPLACE_TRUE_VALUE_WITH_TRANSLATION },
  [ATTRIBUTES.CURBSIDE]: { labelOverride: REPLACE_TRUE_VALUE_WITH_TRANSLATION },
  [ATTRIBUTES.FULFILLMENT]: {
    separateMultipleAttributeValues: {
      overrideCanonical: { [FULFILLMENT_VALUES.DELIVERY]: ATTRIBUTES.DROP_OFF },
    },
  },

  [ATTRIBUTES.CASH_ACCEPTED]: { labelOverride: REPLACE_TRUE_VALUE_WITH_TRANSLATION },
  [ATTRIBUTES.CASHLESS_PAYMENT]: { labelOverride: REPLACE_TRUE_VALUE_WITH_TRANSLATION },
  [ATTRIBUTES.PAYMENT]: { separateMultipleAttributeValues: {} },
}
