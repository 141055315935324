import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const VipFinancingSectionWrapper = styled.div(
  ({ theme }) => `
  align-items: center;
  background: ${theme.colors.grey.light5};
  border-radius: ${theme.borderRadius.small};
  display: flex;
  gap: ${theme.spacing.default};
  justify-content: space-between;
  padding: ${theme.spacing.default};


  & img  {
    width: 7.2rem;
  }

  ${MEDIA_QUERIES(theme).medium}{
    & img  {
      width: 9.6rem;
    }
  }
`
)

export const VipFinancingSectionMobileChevron = styled.div(
  ({ theme }) => `
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: ${theme.spacing.defaultSmall} 0 ${theme.spacing.defaultSmall} ${theme.spacing.default};
  
  ${MEDIA_QUERIES(theme).medium}{
    display: none;
  }
`
)
