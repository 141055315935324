import { LocalStorageRegistry } from '@/constants/localStorageRegistry'
import { DEFAULT_FORM_VALUES } from '@/features/payment-estimator/constants/paymentEstimatorForm'
import { type PaymentEstimatorFormValues } from '@/features/payment-estimator/types/paymentEstimator'
import { getSavedFormValues } from '@/features/payment-estimator/utils/getSavedFormValues'
/**
 * Saves the payment estimator form data to localStorage. If any field is missing, it sets
 * the default value for that field before saving.
 *
 * @param formValues - The new form values to be saved in localStorage.
 *   All values are optional; missing values will default to predefined values.
 *
 * @returns Saves the updated form values to localStorage.
 */
export const saveFormValues = (formValues: Partial<PaymentEstimatorFormValues>): void => {
  // Get existing values from localStorage
  const savedFormValues = getSavedFormValues() ?? {}

  // Merge provided values with current values, falling back to defaults
  const updatedFormValues: PaymentEstimatorFormValues = {
    ...DEFAULT_FORM_VALUES,
    ...savedFormValues,
    ...formValues,
  }

  // Save the merged values to localStorage
  localStorage.setItem(
    LocalStorageRegistry.PAYMENT_ESTIMATOR_FORM,
    JSON.stringify(updatedFormValues)
  )
}
