import { type FC, useState } from 'react'

import { ListingReportButton } from '@/features/listing/components/report/ListingReportButton'
import { ListingReportModal } from '@/features/listing/components/report/ListingReportModal'

export type ListingReportProps = {
  /**
   * Specifies if it is a MoVe listing
   */
  isMoveListing: boolean

  /**
   * The ID of the listing to report.
   */
  listingId: string

  /**
   * Specifies if it is a seller vip
   */
  isSellerVip: boolean
}

/**
 * Wrapper component for the report feature, managing modal states.
 *
 * This component is responsible for rendering the report button and the modal.
 * It manages the modal state and passes the listing ID to the modal.
 *
 * To use a custom trigger, use the `ListingReportModal` components directly.
 * ```
 * const [isModalOpen, setIsModalOpen] = useState(false)
 * <ListingReportModal listingId={listingId} isOpen={isModalOpen} />
 * // Use a custom trigger to open the modal with setIsModalOpen(true)
 * ```
 */
export const ListingReport: FC<ListingReportProps> = ({
  isMoveListing,
  isSellerVip,
  listingId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  if (isSellerVip || isMoveListing) return null

  return (
    <>
      <ListingReportButton onClick={handleOpenModal} />
      <ListingReportModal listingId={listingId} isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  )
}
