import {
  cloneElement,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'

import { Button } from '@/ui/atoms/button/Button'
import { ResponsiveProp } from '@/ui/typings/helpers'

import { DropdownListWrapper, DropdownWrapper } from './styled'

export type DropdownDirections = 'right' | 'left' | 'down-left' | 'down-right'

export type DropdownProps = PropsWithChildren & {
  /**
   * Label for the dropdown
   */
  label?: string

  /**
   * Minimum width of the dropdown
   */
  width?: string

  /**
   * Custom trigger for the dropdown
   */
  customTrigger?: ReactElement

  /**
   * Function triggered when the dropdown is clicked
   */
  onToggle?: (isOpen: boolean) => void

  /**
   * Direction of the dropdown (supports responsive prop)
   */
  direction?: ResponsiveProp<DropdownDirections>

  /**
   * Z-index of the dropdown
   */
  zIndex?: number

  // Future support for controlled open state is nice to have
}

/**
 * Dropdown component to be used with DropdownItem
 *
 * Example usage:
 * ```tsx
 * <Dropdown label="Share" direction={{ medium: 'down', xLarge: 'right' }}>
 *  <DropdownItem {...args} />
 *  <DropdownItem {...args} />
 * </Dropdown>
 * ```
 */
export const Dropdown: FC<DropdownProps> = ({
  label,
  customTrigger,
  onToggle,
  children,
  direction = 'down-right',
  width = '26rem',
  zIndex = 1,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLButtonElement>(null)

  const dropdownId = useId()

  /**
   * Toggle the dropdown
   */
  const handleToggle = () => {
    setIsOpen((prev) => !prev)
    onToggle?.(!isOpen)
  }

  /**
   * Close the dropdown
   */
  const handleClose = () => {
    setIsOpen(false)
    onToggle?.(false)
  }

  /**
   * Handle keydown event for Escape key
   */
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        handleClose()
      }
    },
    [isOpen]
  )

  /**
   * Handle blur event
   */
  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.relatedTarget as Node) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.relatedTarget as Node)
    ) {
      handleClose() // Close dropdown on blur
    }
  }

  // Add event listener for Escape key when dropdown is open
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, handleKeyDown])

  return (
    <DropdownWrapper onBlur={handleBlur} ref={dropdownRef}>
      {customTrigger ? (
        cloneElement(customTrigger, {
          onClick: handleToggle,
          ref: triggerRef,
          'aria-expanded': isOpen,
          'aria-haspopup': 'menu',
          'aria-controls': dropdownId,
        })
      ) : (
        <Button
          onClick={handleToggle}
          ref={triggerRef}
          aria-expanded={isOpen}
          aria-haspopup="menu"
          aria-controls={dropdownId}
          type="button"
        >
          {label}
        </Button>
      )}
      {isOpen && children ? (
        <DropdownListWrapper
          id={dropdownId}
          $direction={direction}
          role="menu"
          $width={width}
          $zIndex={zIndex}
          onClick={handleClose}
        >
          {children}
        </DropdownListWrapper>
      ) : null}
    </DropdownWrapper>
  )
}
