import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { GenericError } from '@/components/shared/error'
import { BaseLayout } from '@/components/shared/layouts/BaseLayout'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { TextLink } from '@/ui/atoms/text-link'

/**
 * This VIP not found page is a client fallback error page when the server failed to identify the listing didn't exist
 * and it didn't trigger redirect from the server to /404
 */
type VipErrorProps = {
  variant?: 'not_found' | 'default'
}

export const VipError: FC<VipErrorProps> = ({ variant = 'default' }) => {
  const { colors } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.VIP])

  const defaultSrpUrl = `/b-classifieds/c${ALL_CATEGORIES_ID_NUM}`

  return (
    <BaseLayout showSearchBar bodyColor={colors.white}>
      <GenericError
        errorTitle={t(`vip:error.${variant}.title`)}
        errorBody={t(`vip:error.${variant}.body`)}
      >
        {variant === 'not_found' ? (
          <LinkCustom href={defaultSrpUrl}>
            <TextLink variant="secondary">{t(`vip:error.${variant}.link`)}</TextLink>
          </LinkCustom>
        ) : null}
      </GenericError>
    </BaseLayout>
  )
}
