import { isAnyCarsVehiclesCategory, isVerticalCategory } from '@kijiji/category'
import {
  type ListingPriceRating,
  AuthModalVariant,
  useContactSellerMutation,
  useContactSellerWithAttachmentMutation,
} from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { Trans, useTranslation } from 'next-i18next'
import { useReCaptcha } from 'next-recaptcha-v3'
import { type ChangeEventHandler, type FC, type MouseEventHandler, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { HEADERS } from '@/constants/headers'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { LocalStorageRegistry } from '@/constants/localStorageRegistry'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { useSignInModal } from '@/features/auth/hooks/useSignInModal'
import { refreshSession } from '@/features/auth/utils/refreshSession'
import {
  EnhanceLeadsOptions,
  R2SEnhanceLeads,
} from '@/features/listing/components/reply-to-seller/conversations/R2SEnhanceLeads'
import { R2SJobAttachment } from '@/features/listing/components/reply-to-seller/conversations/R2SJobAttachment'
import {
  type R2SStructuredMessagesProps,
  R2SStructuredMessages,
} from '@/features/listing/components/reply-to-seller/conversations/R2SStructuredMessages'
import { R2SFormSubmitContainer } from '@/features/listing/components/reply-to-seller/conversations/styled'
import { ListingsSimilarModal } from '@/features/listing/components/similar/ListingsSimilarModal'
import { CONTACT_SELLER_RECAPTCHA_EVENT } from '@/features/listing/constants/R2S'
import { getMyConversationUrl } from '@/features/listing/utils/reply-to-seller/getMyConversationUrl'
import { getR2SSuccessMessage } from '@/features/listing/utils/reply-to-seller/getR2SSuccessMessage'
import { useFormValidation } from '@/hooks/useFormValidation'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { SystemMessage } from '@/ui/molecules/system-message'
import { TextField } from '@/ui/molecules/text-field'
import { TextAreaField } from '@/ui/molecules/textarea-field'
import { sendToLogger } from '@/utils/sendToLogger'
import { validationFnPerField } from '@/utils/validation'

export type R2SFormProps = Pick<R2SStructuredMessagesProps, 'structuredMessages'> & {
  /**
   * Listing's category Id
   * */
  categoryId: number

  /**
   * Specifies if the R2S Form form should be disabled
   */
  disableForm?: boolean

  /**
   * Initial value for the message field.
   */
  initialMessage?: string

  /**
   * Defines if guest messaging (logged out) is available for the listing
   */
  isGuestMessageEnabled: boolean

  /**
   * To differentiate between modal and in-page R2S form
   */
  isModal?: boolean

  /**
   * It should define if the seller of this listing is a professional seller
   * Dealer, KMB or Professional
   */
  isProfessionalSeller?: boolean

  /**
   * Specifies the listing id to be messaged
   */
  listingId: string

  /**
   * Callback to be executed after the form is successfully submitted
   */
  successCallbackFn?: () => void

  /**
   * Specifies if the seller has bough the Enhance Leads paid feature
   * This will append extra fields on the dealer's R2S form
   */
  hasEnhancedContactForm?: boolean | null

  /**
   * Price rating of the listing if any
   */
  priceInsightRate?: ListingPriceRating

  /**
   * Stock number of the vehicle if any
   */
  vehicleStockNumber?: string | null

  /**
   * Specifies if the listing is an Autos Dealer listing
   */
  isAutosDealerAd?: boolean

  /**
   * Whether the listing is in a jobs category
   */
  isJobsCategory?: boolean
}

enum FORM_FIELDS {
  MESSAGE = 'message',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  ENHANCED_LEADS = 'enhancedLeads',
  RESUME = 'resume',
}

// For any fields that are not strings, we need to define a custom type
type FieldOverrides = {
  [FORM_FIELDS.RESUME]: File | null
}

type R2SFormFields = {
  [K in FORM_FIELDS]: K extends keyof FieldOverrides ? FieldOverrides[K] : string
}

const DEFAULT_FORM_VALUES = {
  [FORM_FIELDS.EMAIL]: '',
  [FORM_FIELDS.ENHANCED_LEADS]: '',
  [FORM_FIELDS.MESSAGE]: '',
  [FORM_FIELDS.NAME]: '',
  [FORM_FIELDS.PHONE]: '',
  [FORM_FIELDS.RESUME]: null,
}

// If you use this, make sure you spread to avoid mutability problems
const DEFAULT_FORM_ERRORS = Object.fromEntries(
  [...Object.values(FORM_FIELDS), 'submit'].map((key) => [key, ''])
) as Record<FORM_FIELDS | 'submit', string>

export type GuestContactDetails = {
  name: string
  email: string
}

// If any of these are changed you'll need to update Anvil as well
const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5 MB
const SUPPORTED_FILE_TYPES = [
  'application/pdf', // pdf
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'image/jpeg', // jpeg, jpg
  'image/png', // png
  'application/rtf', // rtf
  'text/plain', // txt
]

/**
 * Form used by the R2S feature to send a message to a seller.
 */
export const R2SForm: FC<R2SFormProps> = ({
  categoryId,
  disableForm,
  hasEnhancedContactForm,
  initialMessage,
  isGuestMessageEnabled,
  isModal,
  isProfessionalSeller,
  listingId,
  priceInsightRate,
  structuredMessages,
  successCallbackFn,
  isAutosDealerAd,
  vehicleStockNumber,
  isJobsCategory,
}) => {
  /** It fires on focus only once */
  const hasR2SEmailBeginFired = useRef<boolean>(false)

  const { t } = useTranslation([
    TRANSLATION_KEYS.R2S,
    TRANSLATION_KEYS.ROUTES,
    TRANSLATION_KEYS.COMMON,
  ])

  const { colors, spacing } = useTheme()
  const { status, data: userData } = useSession()
  const { asPath } = useRouter()
  const { executeRecaptcha } = useReCaptcha()
  const { validateForm } = useFormValidation()
  const { openSignInModal } = useSignInModal()
  const [contactSeller] = useContactSellerMutation()
  const [contactSellerWithAttachment] = useContactSellerWithAttachmentMutation()

  const [guestContactDetails, setGuestContactDetails] = useLocalStorage<GuestContactDetails>(
    LocalStorageRegistry.R2S_GUEST_CONTACT,
    {
      name: '',
      email: '',
    }
  )

  const [fields, setFields] = useState<R2SFormFields>({
    ...DEFAULT_FORM_VALUES,
    ...(initialMessage && { [FORM_FIELDS.MESSAGE]: initialMessage }),

    // Access localStorage for saved details
    ...(guestContactDetails.email && { [FORM_FIELDS.EMAIL]: guestContactDetails.email }),
    ...(guestContactDetails.name && { [FORM_FIELDS.NAME]: guestContactDetails.name }),

    // If the user is logged in these will override the last values
    ...(userData?.user.email && { [FORM_FIELDS.EMAIL]: userData.user.email }),
    ...(userData?.user.name && { [FORM_FIELDS.NAME]: userData.user.name }),
  })

  const [errors, setErrors] = useState<Record<FORM_FIELDS | 'submit', string>>({
    ...DEFAULT_FORM_ERRORS,
  })

  const [loading, setLoading] = useState<boolean>(false)
  /** The state will contain the conversation ID created when the form is submitted */
  const [submittedChatId, setSubmittedChatId] = useState<string>()
  const [enhancedLeadsCheckedValues, setEnhancedLeadsCheckedValues] = useState<
    EnhanceLeadsOptions[]
  >([])

  const [isListingsSimilarModalOpen, setIsListingsSimilarModalOpen] = useState(false)
  const showListingsSimilarModal = isVerticalCategory(categoryId)
  const guestMessageTrackingLabel = `guest_msg=${isGuestMessageEnabled && !isUserAuthenticated(status)}`

  const handleSimilarModalClose = () => setIsListingsSimilarModalOpen(false)

  const validationObj = {
    phone: (value: string) => {
      if (!value.trim()) return null
      const validation = validationFnPerField.phone_number(value)
      if (validation) return `common:${validation}`
      return null
    },
    message: validationFnPerField.message,
    // only validate name and email when guest message fields are shown
    ...(isGuestMessageEnabled && {
      email: (value: string) => {
        // If the user has already an email, we don't need to validate it
        if (userData?.user.email) return null

        const validation = validationFnPerField.email(value)
        if (validation) return `common:${validation}`

        return null
      },
      name: (value: string) => {
        // If the user has already a name, we don't need to validate it
        if (userData?.user.name) return null

        const validation = validationFnPerField.name(value)
        if (validation) return `common:${validation}`

        return null
      },
    }),
  }

  const buildMessage = () => {
    let message = fields.message

    /** Add Enhanced Leads message if any */
    const checkedValuesLength = enhancedLeadsCheckedValues.length
    if (checkedValuesLength) {
      message += `\n${t('r2s:enhance_leads.option.base_message')}`

      enhancedLeadsCheckedValues.forEach((id, index) => {
        if (checkedValuesLength === index + 1) {
          message += `, ${t('r2s:enhance_leads.option.and')}`
        }

        message += ` ${t(`r2s:enhance_leads.option.${id}.message`)}`
      })
    }

    /** Append Ad ID and Stock Number (if any) for Autos guest messaging */
    if (isAutosDealerAd && isGuestMessageEnabled) {
      message += ` ${t('r2s:email.adId', { adId: listingId })}`

      if (vehicleStockNumber) {
        message += ` ${t('r2s:email.stockNumber', { stockNumber: vehicleStockNumber })}`
      }
    }

    /**
     * The phone number field should eventually be sent as a part of the mutation fields
     * when the BE team has support for it
     * */
    if (fields.phone) {
      message += `\n${t('r2s:phone_number.message')}: ${fields.phone}`
    }

    return message
  }

  const buildTrackingLabel = () => {
    const hasPhone = !!fields.phone.trim().length
    let trackingLabel = `phone=${hasPhone};categoryid=${categoryId};${guestMessageTrackingLabel};`

    /** Add guest message to label */
    if (hasEnhancedContactForm) {
      const isFinancingChecked = enhancedLeadsCheckedValues.includes(EnhanceLeadsOptions.FINANCING)
      const isTestDriveChecked = enhancedLeadsCheckedValues.includes(EnhanceLeadsOptions.TEST_DRIVE)
      const isTradeInChecked = enhancedLeadsCheckedValues.includes(EnhanceLeadsOptions.TRADE_IN)
      trackingLabel += `financing=${isFinancingChecked};testdrive=${isTestDriveChecked};tradein=${isTradeInChecked};`
    }

    if (priceInsightRate) {
      trackingLabel += `price_badge=${priceInsightRate.toLowerCase()};`
    }

    return trackingLabel
  }

  const handleSendMessage: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()

    const trackingLabel = buildTrackingLabel()
    trackEvent({ action: GA_EVENT.R2SEmailAttempt, label: trackingLabel })

    if (!isUserAuthenticated(status) && !isGuestMessageEnabled) {
      openSignInModal({ modalVariant: AuthModalVariant.LoginToMessage, callbackUrl: asPath })
      return
    }

    setErrors({ ...DEFAULT_FORM_ERRORS })
    const validationErrors = validateForm(validationObj, fields)

    if (Object.keys(validationErrors).length) {
      setErrors((prev) => ({ ...prev, ...validationErrors }))
      trackEvent({ action: GA_EVENT.R2SEmailFail, label: 'Validation Error' })
      return
    }

    setLoading(true)

    try {
      await refreshSession(status)

      let recaptchaToken

      if (isGuestMessageEnabled && !isUserAuthenticated(status)) {
        recaptchaToken = await executeRecaptcha(CONTACT_SELLER_RECAPTCHA_EVENT)
      }

      let conversationId: string | undefined
      if (isJobsCategory && fields.resume) {
        const { data } = await contactSellerWithAttachment({
          variables: {
            listingContactWithAttachmentId: listingId,
            message: buildMessage(),
            attachment: fields.resume,
            // No guest message fields for jobs category
          },
        })

        conversationId = data?.listingContactWithAttachment
      } else {
        const { data } = await contactSeller({
          variables: {
            listingContactId: listingId,
            message: buildMessage(),
            ...(isGuestMessageEnabled && {
              /**
               * Only send name and email when the R2S sends an email instead of initiating a chat
               * */
              name: fields.name,
              email: fields.email,
            }),
          },
          context: {
            headers: {
              ...(recaptchaToken && { [HEADERS.X_RECAPTCHA_TOKEN]: recaptchaToken }),
            },
          },
        })

        conversationId = data?.listingContact
      }

      // Store guest contact details in localStorage upon successful submission
      if (isGuestMessageEnabled && !isUserAuthenticated(status)) {
        setGuestContactDetails({
          name: fields[FORM_FIELDS.NAME],
          email: fields[FORM_FIELDS.EMAIL],
        })
      }

      setSubmittedChatId(conversationId)
      trackEvent({ action: GA_EVENT.R2SEmailSuccess, label: trackingLabel })

      if (isGuestMessageEnabled && !isUserAuthenticated(status)) {
        openSignInModal({
          modalVariant: AuthModalVariant.GuestMessage,
          callbackUrl: asPath,
          categoryId,
        })
      } else if (showListingsSimilarModal) {
        setIsListingsSimilarModalOpen(true)
      }

      successCallbackFn?.()
    } catch (err) {
      sendToLogger(err, { fingerprint: ['useContactSeller'] })
      setErrors((prev) => ({ ...prev, submit: t('r2s:message_form.error.message') }))
      trackEvent({ action: GA_EVENT.R2SEmailFail, label: `${guestMessageTrackingLabel};` })
    } finally {
      setLoading(false)
    }
  }

  const handleFormFieldChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    const value = e.target.value

    const targetId = e.target.id as FORM_FIELDS

    /** Validate targetId is defined as one of the expected form fields */
    if (Object.values(FORM_FIELDS).includes(targetId)) {
      setFields((prev) => ({ ...prev, [targetId]: value }))
    }
  }

  const handleFocus = () => {
    if (!hasR2SEmailBeginFired.current) {
      hasR2SEmailBeginFired.current = true
      trackEvent({ action: GA_EVENT.R2SEmailBegin, label: `${guestMessageTrackingLabel};` })
    }
  }

  const handleStructureMessageClick = (message: string) => {
    setFields((prev) => ({ ...prev, message }))

    /**
     * I can't use ref to focus the text-area because the input elements from the UI library
     * already define refs inside of them to control the floating label.
     *
     * The only way I can focus this element on click is directly manipulating the document
     */
    const textArea = document.getElementById(FORM_FIELDS.MESSAGE)
    if (textArea) textArea.focus()
  }

  const handleEnhancedLeadsChange = (value: EnhanceLeadsOptions) => {
    if (enhancedLeadsCheckedValues.includes(value)) {
      const newValues = enhancedLeadsCheckedValues.filter((item) => item !== value)
      setEnhancedLeadsCheckedValues(newValues)
      return
    }

    setEnhancedLeadsCheckedValues((prev) => [...prev, value])
  }

  const formatPhoneNumber = (value: string) => {
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, '')

    // Apply the formatting: 123-456-7890
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)

    if (match) {
      const [, area, prefix, line] = match

      const formatted = [area, prefix, line].filter(Boolean).join('-')
      return formatted
    }

    return value
  }

  const handleSendAnotherMessage = () => {
    /** Clear fields */
    setFields((prev) => ({
      ...prev,
      message: DEFAULT_FORM_VALUES[FORM_FIELDS.EMAIL],
      enhancedLeads: DEFAULT_FORM_VALUES[FORM_FIELDS.ENHANCED_LEADS],
      resume: null,
    }))
    setEnhancedLeadsCheckedValues([])

    /** Show form again */
    setSubmittedChatId(undefined)
  }

  const handleFileChange = (file: File | null) => {
    setErrors((prev) => ({ ...prev, [FORM_FIELDS.RESUME]: '' }))
    setFields((prev) => ({ ...prev, [FORM_FIELDS.RESUME]: null }))

    if (!file) return

    if (file.size > MAX_FILE_SIZE) {
      return setErrors((prev) => ({
        ...prev,
        [FORM_FIELDS.RESUME]: t('r2s:message_form.error.file_size'),
      }))
    } else if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
      return setErrors((prev) => ({
        ...prev,
        [FORM_FIELDS.RESUME]: t('r2s:message_form.error.file_type'),
      }))
    }

    setFields((prev) => ({ ...prev, [FORM_FIELDS.RESUME]: file }))
  }

  const successMessage = getR2SSuccessMessage(
    t,
    categoryId,
    isProfessionalSeller,
    userData?.user.email || guestContactDetails?.email || undefined
  )

  const isFormVisible = (isUserAuthenticated(status) || isGuestMessageEnabled) && !submittedChatId

  const chatUrl = getMyConversationUrl(submittedChatId)

  return (
    <>
      <R2SStructuredMessages
        structuredMessages={structuredMessages}
        handleStructureMessageClick={handleStructureMessageClick}
        isVisible={hasR2SEmailBeginFired.current}
      />
      <Flex
        as={isFormVisible ? 'form' : 'div'}
        flexDirection="column"
        gap={spacing.default}
        justifyContent="space-between"
        style={{ height: '100%' }}
        data-testid="r2s-form"
      >
        {!submittedChatId && !errors.submit && !isFormVisible ? null : (
          <Flex flexDirection="column" gap={spacing.default}>
            {submittedChatId ? (
              <SystemMessage
                description={
                  <BodyText color={colors.grey.light1}>{successMessage.description}</BodyText>
                }
                hideCloseButton
                title={successMessage.title}
                variation="success"
              />
            ) : null}

            {errors.resume || errors.submit ? (
              <SystemMessage
                description={errors.resume || errors.submit}
                hideCloseButton
                variation="error"
              />
            ) : null}

            {isFormVisible ? (
              <TextAreaField
                bottom="0"
                error={errors.message && t(errors.message)}
                id={FORM_FIELDS.MESSAGE}
                label={t('common:forms.inputs.message.label')}
                onChange={handleFormFieldChange}
                onFocus={handleFocus}
                value={fields[FORM_FIELDS.MESSAGE]}
              />
            ) : null}

            {/* (Logged out) Guest Message Fields */}
            {isFormVisible && isGuestMessageEnabled ? (
              <>
                {!userData?.user.name && (
                  <TextField
                    bottom="0"
                    error={errors.name && t(errors.name)}
                    id="name"
                    label={t('common:forms.inputs.name_only.label')}
                    onChange={handleFormFieldChange}
                    autoComplete="name"
                    value={fields[FORM_FIELDS.NAME]}
                  />
                )}

                {!userData?.user.email && (
                  <TextField
                    autoComplete="email"
                    bottom="0"
                    error={errors.email && t(errors.email)}
                    id="email"
                    label={t('common:forms.inputs.email.label')}
                    onChange={handleFormFieldChange}
                    type="email"
                    value={fields[FORM_FIELDS.EMAIL]}
                  />
                )}
              </>
            ) : null}

            {isFormVisible && isAnyCarsVehiclesCategory(categoryId) && (
              <>
                <TextField
                  autoComplete="phone"
                  bottom="0"
                  error={errors.phone && t(errors.phone)}
                  id="phone"
                  label={`${t('common:forms.inputs.phone.label')} (${t('common:forms.inputs.optional')})`}
                  onChange={(e) => {
                    e.target.value = formatPhoneNumber(e.target.value)
                    return handleFormFieldChange(e)
                  }}
                  type="tel"
                  value={fields[FORM_FIELDS.PHONE]}
                />

                {hasEnhancedContactForm && (
                  <R2SEnhanceLeads
                    handleChange={handleEnhancedLeadsChange}
                    checkedValues={enhancedLeadsCheckedValues}
                    isModal={isModal}
                  />
                )}
              </>
            )}

            {isFormVisible && isJobsCategory && (
              <R2SJobAttachment
                onFileChange={handleFileChange}
                selectedFile={fields[FORM_FIELDS.RESUME]}
                supportedFileTypes={SUPPORTED_FILE_TYPES}
              />
            )}
          </Flex>
        )}

        <R2SFormSubmitContainer flexDirection="column" gap={spacing.defaultSmall}>
          {/* Dealers flow to submit another message */}
          {!!submittedChatId && isGuestMessageEnabled ? (
            <Button onClick={handleSendAnotherMessage} isFullWidth variant="secondary">
              {t('r2s:message_form.send_other_message.label')}
            </Button>
          ) : !!submittedChatId && !isGuestMessageEnabled ? (
            // FSBO flow to take the user to their conversation
            <Button as="a" href={chatUrl} isFullWidth variant="secondary">
              {t('r2s:message_form.send_other_message.label')}
            </Button>
          ) : (
            <Button
              type="submit"
              isFullWidth
              isLoading={loading}
              onClick={handleSendMessage}
              disabled={disableForm}
            >
              {t('r2s:message_form.submit_button.label')}
            </Button>
          )}

          <BodyText size="xSmall" color={colors.grey.light1}>
            <Trans
              i18nKey="r2s:message_form.disclaimer"
              components={[
                <LinkCustom
                  hasUnderline
                  href={t('routes:external.terms_of_use.href')}
                  key="termsLink"
                  noChildren
                  rel="noreferrer"
                  size="xSmall"
                  target="_blank"
                  variant="tertiary"
                />,
                <LinkCustom
                  hasUnderline
                  href={t('routes:external.privacy_policy.href')}
                  key="privacyLink"
                  noChildren
                  rel="noreferrer"
                  size="xSmall"
                  target="_blank"
                  variant="tertiary"
                />,
              ]}
            />
          </BodyText>
        </R2SFormSubmitContainer>
      </Flex>

      {isListingsSimilarModalOpen && (
        <ListingsSimilarModal
          isOpen={isListingsSimilarModalOpen}
          handleClose={handleSimilarModalClose}
          listingId={listingId}
          successMessage={successMessage}
        />
      )}
    </>
  )
}
