import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'

export const VipLocationMapContainer = styled.div(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  flex-direction: column;
  height: 13rem;
  margin-bottom: ${theme.spacing.large};
  overflow: hidden;
  position: relative;
  width: 100%;
`
)

export const VipLocationDirectionsButton = styled(Button)(
  ({ theme }) => `
  margin-left: ${theme.spacing.default};
  margin-top: ${theme.spacing.default};
  position: absolute;
`
)

export const VipLocationExploreButton = styled(Button)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

// eslint-disable-next-line @kijiji/prefer-button-component
export const VipLocationClickableOverlay = styled.button(
  ({ theme }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.grey.primary};
  opacity: 10%;
`
)
