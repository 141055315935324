import SvgLocationOutline from '@kijiji/icons/src/icons/LocationOutline'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { EXTERNAL_LINKS_REL } from '@/constants/externalLinks'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { GenericMap } from '@/features/map/components/generic/GenericMap'
import {
  GenericMapModalFooter,
  GenericMapModalLink,
} from '@/features/map/components/generic/styled'
import { getGoogleMapsUrl } from '@/features/map/utils/getGoogleMapsUrl'
import { useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Modal } from '@/ui/molecules/modal/Modal'

export type MapModalProps = {
  address: string
  coordinates?: { latitude: number; longitude: number } | null
  handleClose: () => void
  isOpen: boolean
  /**
   * Should only be true when the entire page is not wrapped with the map provider
   * and the location feature is contained in this modal
   */
  wrapMapWithProvider?: boolean
}

export const MapModal: FC<MapModalProps> = ({
  address,
  coordinates,
  handleClose,
  isOpen,
  wrapMapWithProvider,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.COMMON])
  const { languageKey } = useLocale()
  const { colors, spacing } = useTheme()

  if (!coordinates) return null

  const googleMapsUrl = getGoogleMapsUrl(languageKey, address, coordinates)

  return (
    <Modal
      closeButtonLabel={t('common:modals.close.button.label')}
      hasCloseButton
      height={{ small: '100vh', medium: '90vh' }}
      id="vip-map-directions"
      isOpen={isOpen}
      label={t('common:map.modal.label')}
      margin={{ small: '0', medium: '5vh auto' }}
      onCancel={handleClose}
      padding="0"
      width={{ small: '100vw', medium: '90vw' }}
    >
      <Flex alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <GenericMap coordinates={coordinates} wrapMapWithProvider={wrapMapWithProvider} />
      </Flex>

      <GenericMapModalFooter>
        <Flex alignItems="center" gap={spacing.mini}>
          <SvgLocationOutline aria-hidden size="xsm" color={colors.white} />
          <BodyText size="small" color={colors.white}>
            {address}
          </BodyText>
        </Flex>

        <GenericMapModalLink target="_blank" rel={EXTERNAL_LINKS_REL} href={googleMapsUrl}>
          {t('common:map.link.label')}
        </GenericMapModalLink>
      </GenericMapModalFooter>
    </Modal>
  )
}
