export const trimAndAddEllipsis = ({
  text,
  maxChars,
}: {
  text: string
  maxChars: number
}) => {
  const lastCharIsWhiteSpace = text.at(maxChars - 1) === ' '
  return text
    .slice(0, lastCharIsWhiteSpace ? maxChars - 1 : maxChars)
    .concat('...')
}
