import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { MileageAnalysisBadge } from '@/features/listing/components/mileage-analysis/MileageAnalysisBadge'
import { MileageRange } from '@/features/listing/constants/mileageAnalysis'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { ListItem } from '@/ui/atoms/list-item'
import { Spacing } from '@/ui/atoms/spacing'
import { List } from '@/ui/molecules/list'
import { Modal, ModalBody, ModalHeader } from '@/ui/molecules/modal/Modal'

export type MileageAnalysisModalProps = {
  handleModalClose: () => void
  isOpen: boolean
}

export const MileageAnalysisModal: FC<MileageAnalysisModalProps> = ({
  handleModalClose,
  isOpen,
}) => {
  const theme = useTheme()
  const { t } = useTranslation('listing')

  return (
    <Modal
      label={t('listing:mileage_analysis.modal.title')}
      id="mileage-analysis-modal"
      data-testid="mileage-analysis-modal"
      isOpen={isOpen}
      hasCloseButton
      onCancel={handleModalClose}
      width={{ small: '100vw', medium: '68rem' }}
    >
      <ModalHeader>
        <Spacing mBottom={theme.spacing.defaultSmall}>
          <HeadlineText as="h3" size="large">
            {t('listing:mileage_analysis.modal.title')}
          </HeadlineText>
        </Spacing>
      </ModalHeader>
      <ModalBody>
        <Flex flexDirection="column" gap={theme.spacing.default}>
          <Spacing mBottom={theme.spacing.default}>
            <BodyText>{t('listing:mileage_analysis.modal.description')}</BodyText>
          </Spacing>
          <Spacing mBottom={theme.spacing.defaultSmall}>
            <HeadlineText as="h4" size="medium">
              {t('listing:mileage_analysis.modal.badge_header')}
            </HeadlineText>
          </Spacing>
          <MileageAnalysisBadge range={MileageRange.BelowAverage} />
          <Spacing mBottom={theme.spacing.defaultSmall}>
            <BodyText>{t('listing:mileage_analysis.modal.below_average_definition')}</BodyText>
          </Spacing>
          <MileageAnalysisBadge range={MileageRange.Average} />
          <Spacing mBottom={theme.spacing.defaultSmall}>
            <BodyText>{t('listing:mileage_analysis.modal.average_definition')}</BodyText>
          </Spacing>
          <MileageAnalysisBadge range={MileageRange.AboveAverage} />
          <Spacing mBottom={theme.spacing.default}>
            <BodyText>{t('listing:mileage_analysis.modal.above_average_definition')}</BodyText>
          </Spacing>
          <HeadlineText as="h4" size="medium">
            {t('listing:mileage_analysis.modal.km_per_year_header')}
          </HeadlineText>
          <BodyText>{t('listing:mileage_analysis.modal.km_per_year_description')}</BodyText>
          <List>
            <ListItem variant="bullet" bulletColor={theme.colors.grey.primary}>
              <BodyText as="span" weight="medium">
                (I)
              </BodyText>{' '}
              <BodyText as="span">
                {t('listing:mileage_analysis.modal.km_per_year_bullet1')}
              </BodyText>
            </ListItem>
            <ListItem variant="bullet" bulletColor={theme.colors.grey.primary}>
              <BodyText as="span">
                {t('listing:mileage_analysis.modal.km_per_year_bullet2a')}
              </BodyText>{' '}
              <BodyText as="span" weight="medium">
                (II)
              </BodyText>{' '}
              <BodyText as="span">
                {t('listing:mileage_analysis.modal.km_per_year_bullet2b')}
              </BodyText>
            </ListItem>
          </List>
        </Flex>
      </ModalBody>
    </Modal>
  )
}
