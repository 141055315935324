import { isAnyCarsVehiclesCategory } from '@kijiji/category'
import { LogoType } from '@kijiji/generated/graphql-types'
import WebsiteGlobeIcon from '@kijiji/icons/src/icons/WebsiteGlobe'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { VIP_LOCATION_VARIANT, VipLocation } from '@/components/vip/shared/vip-location/VipLocation'
import { InlineIconLink } from '@/components/vip/vip-seller-profile/InlineIconLink'
import { VipSideProfileForSaleBy } from '@/components/vip/vip-seller-profile/VipSideProfileForSaleBy'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { SellerOverviewCard } from '@/features/profile/components/SellerOverviewCard'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryListing } from '@/types/listings'
import { Flex } from '@/ui/atoms/flex'

export type VipSideProfileSellerInformationProps = {
  handleViewWebsiteClick?: () => void
  isListingFromDealer: boolean
  listing: VipQueryListing
  websiteUrl?: string | null
}

export const VipSideProfileSellerInformation: FC<VipSideProfileSellerInformationProps> = ({
  handleViewWebsiteClick,
  isListingFromDealer,
  listing,
  websiteUrl,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.PROFILE)
  const { colors, spacing } = useTheme()

  const isAutosDealer = isListingFromDealer && isAnyCarsVehiclesCategory(listing.categoryId)

  return (
    <ShowAtOrLarger breakpoint="large">
      <Flex flexDirection="column" gap={spacing.default}>
        <SellerOverviewCard
          googleReviewsGaEvent={GA_EVENT.GoogleReviewClickR2S}
          hideAvatar={isAutosDealer}
          hideForSaleBy
          listing={listing}
          logoType={LogoType.ViewAd}
          showJoinedSinceMetric
          userId={listing.posterInfo.posterId}
        />

        <Flex flexDirection="column" gap={spacing.defaultSmall}>
          {/* Profile type for specific categories with professional sellers */}
          <VipSideProfileForSaleBy
            attributes={listing.attributes?.all}
            categoryId={listing.categoryId}
          />

          <VipLocation location={listing.location} variant={VIP_LOCATION_VARIANT.LINK} />

          {/* Website URL */}
          {websiteUrl ? (
            <InlineIconLink
              Icon={WebsiteGlobeIcon}
              href={websiteUrl}
              isExternalLink
              label={t('profile:profile.metrics.website')}
              onClick={handleViewWebsiteClick}
              iconColor={colors.purple.primary}
            />
          ) : null}
        </Flex>
      </Flex>
    </ShowAtOrLarger>
  )
}
