import { StyledGptImageGalleryOverlay } from '@/components-page/vip/advertisement/styled'
import { VIP_GPT_ID } from '@/components-page/vip/advertisement/vipAdSlots'
import { LEGACY_MIN_TABLET_VLI } from '@/constants/pageSettings'
import { triggerManualAdRefresh } from '@/features/advertisement/utils/triggerManualAdRefresh'
import { useBreakpointChange } from '@/hooks/useBreakpointChange'

export const GptImageGalleryOverlay = () => {
  useBreakpointChange([LEGACY_MIN_TABLET_VLI], () => {
    triggerManualAdRefresh(VIP_GPT_ID.IMAGE_GALLERY_OVERLAY)
  })

  return (
    <StyledGptImageGalleryOverlay
      id={VIP_GPT_ID.IMAGE_GALLERY_OVERLAY}
      data-testid={VIP_GPT_ID.IMAGE_GALLERY_OVERLAY}
    />
  )
}
