import { CATEGORIES } from '@kijiji/category'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { isOntarioLocation } from '@/domain/location/isOntarioLocation'
import { Spacing } from '@/ui/atoms/spacing'
import { SystemMessage } from '@/ui/molecules/system-message'

export type VipJobsDisclaimerProps = {
  /**
   * Specifies listing's categoryId
   */
  categoryId: number

  /**
   * Specifies listing's locationId
   */
  locationId: number
}

export const VipJobsDisclaimer: FC<VipJobsDisclaimerProps> = ({ categoryId, locationId }) => {
  const { spacing } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.VIP)

  const isElectricianOrConstructionCategory = (categoryId: number) =>
    categoryId === CATEGORIES.ELECTRICIAN_CATEGORY_ID ||
    categoryId === CATEGORIES.JOBS_CONSTRUCTION_TRADES_CATEGORY_ID

  /*
   * This disclaimer should appear if the listing is in Ontario,
   * AND the listing is on an Electrician or Construction Trades category
   */
  if (!isOntarioLocation(locationId) || !isElectricianOrConstructionCategory(categoryId)) {
    return null
  }

  return (
    <Spacing pBottom={spacing.defaultSmall}>
      <SystemMessage variation="tip" title={t('vip:listing.disclaimer.esa')} hideCloseButton />
    </Spacing>
  )
}
