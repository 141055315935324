import { formatPrice } from '@kijiji/money'
import { type FormikHandlers, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { type ChangeEvent, type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import {
  PaymentEstimateWrapper,
  PriceEstimateContainer,
  VerticalDivider,
} from '@/features/payment-estimator/components/styled'
import { DEFAULT_FORM_VALUES } from '@/features/payment-estimator/constants/paymentEstimatorForm'
import { type PaymentEstimatorFormValues } from '@/features/payment-estimator/types/paymentEstimator'
import { calculateMonthlyPayment } from '@/features/payment-estimator/utils/calculateMonthlyPayment'
import { convertFormValuesToCents } from '@/features/payment-estimator/utils/convertFormValuesToCents'
import { convertFormValuesToDollars } from '@/features/payment-estimator/utils/convertFormValuesToDollars'
import { getLoanTermOptions } from '@/features/payment-estimator/utils/getLoanTermOptions'
import { saveFormValues } from '@/features/payment-estimator/utils/saveFormValues'
import { useFormValidation } from '@/hooks/useFormValidation'
import { ROUTE_LOCALE, useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal, ModalBody, ModalFooter } from '@/ui/molecules/modal/Modal'
import { type SelectItem, Select } from '@/ui/molecules/select'
import { TextField } from '@/ui/molecules/text-field'
import { validationFnPerField } from '@/utils/validation'

export type PaymentEstimatorModalProps = {
  handleModalClose: () => void
  isOpen: boolean
  price: number
  /**
   * Saved form values or default values in cents
   * */
  initialFormValues: PaymentEstimatorFormValues
}

enum FormId {
  LOAN_TERM = 'loanTerm',
  ANNUAL_INTEREST_RATE = 'annualInterestRate',
  DOWN_PAYMENT = 'downPayment',
  TRADE_IN = 'tradeIn',
}

/**
 * A modal that updates the monthly car payment estimate based on user
 * form input (loan term, interest rate, down payment and trade-in value).
 */
export const PaymentEstimatorModal: FC<PaymentEstimatorModalProps> = ({
  handleModalClose,
  initialFormValues,
  isOpen,
  price,
}) => {
  const { spacing, colors } = useTheme()
  const { routeLocale } = useLocale()
  const { t } = useTranslation('payment_estimator')
  const { validateForm, errors } = useFormValidation()

  /**
   * All conversions to/from dollars should only be performed when using/setting currentFormValues.
   * Dollars is a display value. All calculations are performed in cents.
   *
   * Update the form with the latest saved payment estimator form values if they exist.
   */
  const [currentFormValuesDollars, setCurrentFormValuesDollars] =
    useState<PaymentEstimatorFormValues>(convertFormValuesToDollars(initialFormValues))

  const getTrackingLabel = (values: PaymentEstimatorFormValues) => {
    //Monetary values should be tracked in dollars
    return `partner="payment_estimator";loan_term="${values?.loanTerm}";interest_rate="${values?.annualInterestRate}";down_payment="${values?.downPayment}";trade_in="${values?.tradeIn}";`
  }

  const handleCancel = () => {
    const trackingLabel = getTrackingLabel(currentFormValuesDollars)

    trackEvent({ action: GA_EVENT.ModalClose, label: trackingLabel })
    handleModalClose()
  }

  const handleValidation = (values: PaymentEstimatorFormValues) => {
    const fieldsToValidate = {
      annualInterestRate: validationFnPerField.interest_rate,
      downPayment: validationFnPerField.monetary_input,
      tradeIn: validationFnPerField.monetary_input,
    }

    return validateForm(fieldsToValidate, values)
  }

  const handleSubmitPaymentEstimatorForm = (values: PaymentEstimatorFormValues) => {
    const trackingLabel = getTrackingLabel(values)

    trackEvent({ action: GA_EVENT.PaymentEstimatorSave, label: trackingLabel })

    /** Save on local storage in cents */
    saveFormValues(convertFormValuesToCents(values))
    handleModalClose()
  }

  const updateInputValue = (
    e: ChangeEvent<HTMLInputElement>,
    handleChange: FormikHandlers['handleChange'],
    values: PaymentEstimatorFormValues
  ) => {
    handleChange(e) // Update Formik's state
    setCurrentFormValuesDollars(values)
  }

  const loanTermOptions = getLoanTermOptions(t)

  const updateLoanTerm = (selectedItem?: SelectItem): number => {
    if (!selectedItem) return DEFAULT_FORM_VALUES.loanTerm
    return parseInt(selectedItem.value.toString())
  }

  return (
    <Modal
      label={t('payment_estimator:modal.header')}
      id="payment-estimator-modal"
      data-testid="payment-estimator-modal"
      isOpen={isOpen}
      hasCloseButton
      onCancel={handleCancel}
      padding={{ small: spacing.default, medium: spacing.xLarge }}
      width={{ small: '100vw', medium: '43rem' }}
    >
      <Spacing
        mBottom={spacing.large}
        small={{ mTop: spacing.xLarge }}
        medium={{ mTop: spacing.large }}
      >
        <HeadlineText as="h2" size="large">
          {t('payment_estimator:modal.header')}
        </HeadlineText>
      </Spacing>

      <Formik
        initialValues={currentFormValuesDollars}
        onSubmit={handleSubmitPaymentEstimatorForm}
        validate={handleValidation}
        validateOnBlur={true}
        /** It will only validate on change if the form has errors */
        validateOnChange={Object.keys(errors).length ? true : false}
      >
        {({ handleChange, handleBlur, handleSubmit, values, setFieldValue, errors }) => (
          <form
            aria-live="polite"
            data-testid="payment-estimator-form"
            onSubmit={handleSubmit}
            noValidate
          >
            <ModalBody>
              <Select
                id={FormId.LOAN_TERM}
                label={t('payment_estimator:modal.loan_term')}
                name={FormId.LOAN_TERM}
                onBlur={handleBlur}
                onSelectChange={(selectedItem) => {
                  /**
                   * Our select component returns a custom onChange value, SelectItem. Consequently we
                   * need to modify the Formik state directly, as opposed to using the handleChange method.
                   */
                  setFieldValue(FormId.LOAN_TERM, selectedItem?.value)
                  setCurrentFormValuesDollars({
                    ...values,
                    [FormId.LOAN_TERM]: updateLoanTerm(selectedItem),
                  })
                }}
                options={loanTermOptions}
                selectedValue={values.loanTerm}
              />
              <TextField
                endAdornment="%"
                error={errors.annualInterestRate && t(`${errors.annualInterestRate}`)}
                id={FormId.ANNUAL_INTEREST_RATE}
                label={t('payment_estimator:modal.interest_rate')}
                name={FormId.ANNUAL_INTEREST_RATE}
                onBlur={handleBlur}
                onChange={(e) => updateInputValue(e, handleChange, values)}
                type="number"
                value={values.annualInterestRate}
              />
              <TextField
                error={errors.downPayment && t(`${errors.downPayment}`)}
                id={FormId.DOWN_PAYMENT}
                label={t('payment_estimator:modal.down_payment')}
                name={FormId.DOWN_PAYMENT}
                onBlur={handleBlur}
                onChange={(e) => updateInputValue(e, handleChange, values)}
                startAdornment="$"
                type="number"
                value={values.downPayment}
              />
              <TextField
                error={errors.tradeIn && t(`${errors.tradeIn}`)}
                id={FormId.TRADE_IN}
                label={t('payment_estimator:modal.trade_in_value')}
                name={FormId.TRADE_IN}
                onBlur={handleBlur}
                onChange={(e) => updateInputValue(e, handleChange, values)}
                startAdornment="$"
                type="number"
                value={values.tradeIn}
              />

              <PaymentEstimateWrapper>
                <PriceEstimateContainer flexDirection="column" gap={spacing.mini}>
                  <BodyText size="xSmall" color={colors.grey.primary}>
                    {t('payment_estimator:modal.vehicle_price')}
                  </BodyText>

                  <BodyText size="xLarge" color={colors.grey.primary} weight="medium">
                    {formatPrice(price, { isFrench: routeLocale === ROUTE_LOCALE.fr })}
                  </BodyText>
                </PriceEstimateContainer>

                <VerticalDivider />

                <PriceEstimateContainer flexDirection="column" gap={spacing.mini}>
                  <BodyText size="xSmall" color={colors.grey.primary} textAlign="right">
                    {t('payment_estimator:modal.estimated')}
                  </BodyText>
                  <BodyText
                    size="xLarge"
                    color={colors.grey.primary}
                    weight="medium"
                    textAlign="right"
                  >
                    {`${calculateMonthlyPayment({
                      ...convertFormValuesToCents(values),
                      locale: routeLocale,
                      price,
                    })}/${t('payment_estimator:modal.months_abbreviated')}`}
                  </BodyText>
                </PriceEstimateContainer>
              </PaymentEstimateWrapper>

              <Spacing mBottom={spacing.defaultSmall}>
                <BodyText size="xSmall" color={colors.grey.light1}>
                  {t('payment_estimator:modal.disclaimer')}
                </BodyText>
              </Spacing>
            </ModalBody>

            <ModalFooter>
              <Button type="submit" isFullWidth variant="secondary">
                {t('payment_estimator:modal.save_button')}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  )
}
