import { type CoreListing, type GetPosterOtherAdsQuery } from '@kijiji/generated/graphql-types'

// TODO (KJCA-5418) Update get poster other ads mapping in ANVIL

/**
 * Transform POA Data to CoreListing Data
 *
 *
 * @param listings - The input listing data to be mapped.
 * @returns The mapped core listing data.
 */
export const mapPOADataToListingsData = (
  listings?: GetPosterOtherAdsQuery['profile']['listings']
): CoreListing[] => {
  if (!listings) return []

  return listings.map((item) => ({
    id: item.id,
    categoryId: item.categoryId,
    imageUrls: item.imageUrls,
    adSource: item.adSource,
    price: item.price,
    url: item.url,
    title: item.title,
    location: item.location,
  }))
}
