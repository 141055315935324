import ExpandIcon from '@kijiji/icons/src/icons/Expand'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import {
  ListingGalleryCountBadge,
  VipGalleryMobileBadgesContainer,
} from '@/features/listing-gallery/components/styled'
import { VirtualTourBadge } from '@/features/listing-gallery/components/VirtualTourBadge'
import { BodyText } from '@/ui/atoms/body-text'

export type VipGalleryMobileBadgeProps = {
  /**
   * Specifies how many images are present in the listing
   */
  imageCount: number
  /**
   * Specifies the position of the current active image in the list
   */
  position: number

  /**
   * Specifies if virtual tour is available
   */
  isVirtualTourAvailable?: boolean
}

export const VipGalleryMobileBadge: FC<VipGalleryMobileBadgeProps> = ({
  imageCount,
  position,
  isVirtualTourAvailable,
}) => {
  const { colors, spacing } = useTheme()
  const hasMultipleImages = imageCount > 1

  return (
    <VipGalleryMobileBadgesContainer gap={spacing.mini}>
      {isVirtualTourAvailable && position !== imageCount ? <VirtualTourBadge /> : null}

      <ListingGalleryCountBadge hasMoreThanOneImage={hasMultipleImages}>
        {hasMultipleImages ? (
          <BodyText size="small" color={colors.white} style={{ width: 'max-content' }}>
            {position} / {imageCount}
          </BodyText>
        ) : (
          <ExpandIcon aria-hidden data-testid="gallery-main-expand-icon" />
        )}
      </ListingGalleryCountBadge>
    </VipGalleryMobileBadgesContainer>
  )
}
