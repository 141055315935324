import { RequestViewingIcon } from '@kijiji/icons/src/icons'
import { useTranslation } from 'next-i18next'
import { type ButtonHTMLAttributes, type ComponentProps } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { StyledRequestViewingButton } from '@/features/listing/components/request-viewing/styled'
import { Badge, BadgeSize } from '@/ui/atoms/badge'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

type RequestViewingButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  showNewBadge?: boolean
  href?: string
}

/**
 * A button component that allows users to request a viewing.
 * It can be rendered as either a button or an anchor tag based on the presence of an href prop.
 *
 * @component
 * @param props - The component props
 * @param props.showNewBadge - Whether to show a "New" badge on the button
 * @param props.href - If provided, renders the component as an anchor tag with this URL
 *
 * @returns A button or anchor tag with request viewing functionality
 */
export const RequestViewingButton = ({
  showNewBadge,
  href,
  ...rest
}: RequestViewingButtonProps) => {
  const { t } = useTranslation(TRANSLATION_KEYS.REQUEST_VIEWING)
  const { colors, spacing } = useTheme()

  const linkProps: ComponentProps<typeof StyledRequestViewingButton> = href
    ? {
        forwardedAs: 'a',
        href,
        target: '_blank',
      }
    : {}

  return (
    <StyledRequestViewingButton variant="tonal" isFullWidth {...linkProps} {...rest}>
      <Flex gap={spacing.mini}>
        <RequestViewingIcon size="default" color={colors.purple.primary} />
        <BodyText color={colors.purple.primary}>{t('request_viewing:title')}</BodyText>
      </Flex>
      {showNewBadge ? (
        <Badge
          label={t('request_viewing:button.badge')}
          size={BadgeSize.LARGE}
          backgroundColor={colors.purple.light2}
          color={colors.white}
          isBorderless
        />
      ) : null}
    </StyledRequestViewingButton>
  )
}
