import styled from 'styled-components'

import { ImageCustom } from '@/components/shared/image'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const SellerCardDetailsContainer = styled.div(
  ({ theme }) => `
  column-gap: ${theme.spacing.giant};
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${theme.spacing.default};

  
  ${MEDIA_QUERIES(theme).medium}{
    grid-template-columns: max-content auto;
  }
`
)

/** Side Profile */

export const VipSideProfileTopContainer = styled.div(
  ({ theme }) => `  
  background-color: ${theme.colors.white};

  ${MEDIA_QUERIES(theme).large} {
    box-shadow: 0 .2rem .4rem #2d2f3b1f;
    padding: ${theme.spacing.large} ${theme.spacing.large} 0;
    border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;
    border: .1rem solid ${theme.colors.grey.light4};
    border-bottom: none;
  }
`
)

export const VipSideProfileInfoContainer = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.white};

  ${MEDIA_QUERIES(theme).large}{
    border: .1rem solid ${theme.colors.grey.light4};
    border-bottom: none;
    padding: ${theme.spacing.default} ${theme.spacing.large} ${theme.spacing.default};
  } 
  `
)

export const VipSideProfileStickyContainer = styled.div<{
  hasTopContainer?: boolean
  isComfreeAd?: boolean
}>(
  ({ theme, hasTopContainer, isComfreeAd }) => `
  background-color: ${theme.colors.white};
  ${!isComfreeAd && `border-top: .1rem solid ${theme.colors.grey.light4};`}
  
  position: sticky;
  top: 0;
  
  
  ${MEDIA_QUERIES(theme).large}{
    border-radius: 0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium};
    border-top: none;
    border: .1rem solid ${theme.colors.grey.light4};
    box-shadow: 0 .2rem .4rem #2d2f3b1f;
    margin-bottom: ${theme.spacing.husky};
    padding: ${theme.spacing.default} ${theme.spacing.large} ${isComfreeAd ? theme.spacing.large : 0};

    // Ensure the sticky container will fit small desktops
    // It the calculation is in PX because the ads height is not defined in "rem"
    max-height: calc(100vh - 90px - ${theme.spacing.default});
    overflow: auto;

    ${
      !hasTopContainer &&
      `      border-top: .1rem solid ${theme.colors.grey.light4};
      border-radius: ${theme.borderRadius.medium};`
    }
  }
`
)

export const VipSideProfileLogoContainer = styled.div(
  ({ theme }) => `
  display: none;
  width: 100%;

  ${MEDIA_QUERIES(theme).large} {
    display: block;
  }
`
)

export const VipSideProfileLogoImage = styled(ImageCustom)(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  max-height: 6rem;
  max-width: 12rem;
  object-fit: contain;
  padding-bottom: ${theme.spacing.default};
`
)

/** Inline With Icon Items */
export const VipSideProfileOverviewSection = styled(Flex)(
  ({ theme }) => `
  & > svg {
    color: ${theme.colors.purple.primary};
  }
`
)

export const RequestViewingMobileContainer = styled(Spacing)(
  ({ theme }) => `
  border-bottom: .1rem solid ${theme.colors.grey.light4};
  
  ${MEDIA_QUERIES(theme).large} {
    display: none;
  }
`
)
