import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { BaseLayout } from '@/components/shared/layouts/BaseLayout'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import {
  VipContainer,
  VipGridLayout,
  VipSectionWrapper,
  VipSidebarContainer,
} from '@/components/vip/styled'
import { VipBreadcrumbs } from '@/components/vip/vip-breadcrumbs/VipBreadcrumbs'
import { type VipQueryListing } from '@/types/listings'
import { Skeleton } from '@/ui/atoms/skeleton'
import { Spacing } from '@/ui/atoms/spacing'

type VipLoadingSkeletonProps = {
  listingId: string
  categoryId?: number
  listingLocation?: VipQueryListing['location']
}

/**
 * Generic VIP Loading placeholder
 * It implements the generic layout of the VIP in a loading format
 */
export const VipLoadingSkeleton: FC<VipLoadingSkeletonProps> = ({
  categoryId,
  listingId,
  listingLocation,
}) => {
  const { colors, spacing } = useTheme()

  return (
    <BaseLayout showSearchBar bodyColor={colors.white}>
      <VipContainer>
        {/* Breadcrumbs loading */}
        <VipSectionWrapper>
          {categoryId ? (
            <VipBreadcrumbs adId={listingId} location={listingLocation} categoryId={categoryId} />
          ) : (
            <Spacing mTop={spacing.large} pBottom={spacing.large}>
              <Skeleton width="30%" />
            </Spacing>
          )}
        </VipSectionWrapper>

        <VipGridLayout>
          {/* Images Gallery loading */}
          <VipSectionWrapper $removePaddingOnMobile>
            <Spacing pBottom={spacing.default}>
              <Skeleton height="46rem" width="100%" />
            </Spacing>

            {/* VIP Overview */}
            <Spacing pBottom={spacing.default}>
              <Skeleton height="11rem" width="50%" />
            </Spacing>

            {/* Action bar */}
            <VipSectionContainer pTop={spacing.default} pBottom={spacing.default}>
              <Skeleton height="7.3rem" width="100%" />
            </VipSectionContainer>

            {/* Attributes */}
            <VipSectionContainer pTop={spacing.default} pBottom={spacing.default}>
              <Skeleton height="30rem" width="100%" />
            </VipSectionContainer>
          </VipSectionWrapper>

          {/* Sidebar */}
          <VipSidebarContainer>
            <Skeleton height="31rem" width="100%" removeBorder />
          </VipSidebarContainer>
        </VipGridLayout>

        {/* Carousels */}
        <VipSectionContainer pTop={spacing.default} pBottom={spacing.default}>
          <Spacing pBottom={spacing.default}>
            <Skeleton height="28rem" width="100%" />
          </Spacing>
        </VipSectionContainer>
      </VipContainer>
    </BaseLayout>
  )
}
