import styled from 'styled-components'

import { Spacing } from '@/ui/atoms/spacing'

export const VipSectionContainer = styled(Spacing)(
  ({ theme, pBottom, pTop }) => `
    border-top: 0.1rem solid ${theme.colors.grey.light4};
    padding-bottom: ${pBottom ?? theme.spacing.husky};
    padding-top: ${pTop ?? theme.spacing.husky};
`
)
