import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'

/**
 * Map attributes to a dictionary form to easily find the information we need
 */
export const getAttributesDictionary = (
  attributes?: ListingAttributeV2[]
): Record<string, ListingAttributeV2> => {
  return (
    attributes?.reduce((acc, curr) => {
      if (!curr?.values[0]) return acc
      return { ...acc, [curr.canonicalName]: curr }
    }, {}) ?? []
  )
}
