import { type ListingPriceRating } from '@kijiji/generated/graphql-types'

export type R2SPhoneTrackingLabelProps = {
  /**
   * The categoryID of the listing triggering the phone event
   */
  categoryId: number

  /**
   * The location of the event. This determines where the click originated from:
   *
   * - `top`: User click comes from the sticky sidebar.
   * - `bottom`: User click comes from the seller profile.
   * - `sticky`: User click comes from the sticky bottom bar.
   */
  eventLocation: 'bottom' | 'top' | 'sticky'

  /**
   * Indicates whether the listing is a dealer listing
   */
  isDealerListing: boolean

  /**
   * The rating classification on a dealer price if any
   */
  priceInsightRate?: ListingPriceRating | null
}

/**
 * Generates a tracking label for phone number interactions.
 *
 * @returns A formatted tracking label combining the dealer listing status and event location.
 */
export const generateVIPPhoneTrackingLabel = ({
  categoryId,
  eventLocation,
  isDealerListing,
  priceInsightRate,
}: R2SPhoneTrackingLabelProps): string => {
  let label = `isDealerListing=${isDealerListing};event_loc=${eventLocation};categoryid=${categoryId};`

  if (priceInsightRate) {
    label += `price_badge=${priceInsightRate.toLocaleLowerCase()};`
  }

  return label
}
