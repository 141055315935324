import { type IconProps } from '@kijiji/icons/src/components/Icon'
import { RehomeIcon } from '@kijiji/icons/src/icons'
import AgeIcon from '@kijiji/icons/src/icons/Age'
import AgreementTypeIcon from '@kijiji/icons/src/icons/AgreementType'
import AirConditioningIcon from '@kijiji/icons/src/icons/AirConditioning'
import AutosAlloyWheelsIcon from '@kijiji/icons/src/icons/AutosAlloyWheels'
import AutosConvertibleIcon from '@kijiji/icons/src/icons/AutosConvertible'
import AutosCoupeIcon from '@kijiji/icons/src/icons/AutosCoupe'
import AutosCruiseControlIcon from '@kijiji/icons/src/icons/AutosCruiseControl'
import AutosDoorsIcon from '@kijiji/icons/src/icons/AutosDoors'
import AutosDrivetrainIcon from '@kijiji/icons/src/icons/AutosDrivetrain'
import AutosHatchbackIcon from '@kijiji/icons/src/icons/AutosHatchback'
import AutosMakeIcon from '@kijiji/icons/src/icons/AutosMake'
import AutosModelIcon from '@kijiji/icons/src/icons/AutosModel'
import AutosOtherIcon from '@kijiji/icons/src/icons/AutosOther'
import AutosPickupIcon from '@kijiji/icons/src/icons/AutosPickup'
import AutosPriceIcon from '@kijiji/icons/src/icons/AutosPrice'
import AutosSeatsSimpleIcon from '@kijiji/icons/src/icons/AutosSeatsSimple'
import AutosSedanIcon from '@kijiji/icons/src/icons/AutosSedan'
import AutosSuvIcon from '@kijiji/icons/src/icons/AutosSuv'
import AutosTrimIcon from '@kijiji/icons/src/icons/AutosTrim'
import AutosVanIcon from '@kijiji/icons/src/icons/AutosVan'
import AutosWagonIcon from '@kijiji/icons/src/icons/AutosWagon'
import BarrierFreeIcon from '@kijiji/icons/src/icons/BarrierFree'
import BathsIcon from '@kijiji/icons/src/icons/Baths'
import BedsIcon from '@kijiji/icons/src/icons/Beds'
import BirthdayIcon from '@kijiji/icons/src/icons/Birthday'
import CalenderIcon from '@kijiji/icons/src/icons/Calender'
import CashlessPaymentIcon from '@kijiji/icons/src/icons/CashlessPayment'
import CityIcon from '@kijiji/icons/src/icons/City'
import ColorSimpleIcon from '@kijiji/icons/src/icons/ColorSimple'
import CompanyIcon from '@kijiji/icons/src/icons/Company'
import ConditionCertifiedPreOwnedIcon from '@kijiji/icons/src/icons/ConditionCertifiedPreOwned'
import ConditionDamagedIcon from '@kijiji/icons/src/icons/ConditionDamaged'
import ConditionNewIcon from '@kijiji/icons/src/icons/ConditionNew'
import ConditionSalvageIcon from '@kijiji/icons/src/icons/ConditionSalvage'
import ConditionUsedIcon from '@kijiji/icons/src/icons/ConditionUsed'
import CurbsidePickupIcon from '@kijiji/icons/src/icons/CurbsidePickup'
import DeliveryIcon from '@kijiji/icons/src/icons/Delivery'
import DesktopBrandIcon from '@kijiji/icons/src/icons/DesktopBrand'
import ElectrfuelIcon from '@kijiji/icons/src/icons/Electrfuel'
import EndDateIcon from '@kijiji/icons/src/icons/EndDate'
import EngineIcon from '@kijiji/icons/src/icons/Engine'
import FemaleIcon from '@kijiji/icons/src/icons/Female'
import FrameSizeIcon from '@kijiji/icons/src/icons/FrameSize'
import FuelIcon from '@kijiji/icons/src/icons/Fuel'
import FuelHybridIcon from '@kijiji/icons/src/icons/FuelHybrid'
import JewelleryTypeIcon from '@kijiji/icons/src/icons/JewelleryType'
import JobOfferedByIcon from '@kijiji/icons/src/icons/JobOfferedBy'
import JobTypeIcon from '@kijiji/icons/src/icons/JobType'
import KmSimpleIcon from '@kijiji/icons/src/icons/KmSimple'
import LaptopBrandIcon from '@kijiji/icons/src/icons/LaptopBrand'
import MaleIcon from '@kijiji/icons/src/icons/Male'
import MinStayIcon from '@kijiji/icons/src/icons/MinStay'
import MoveInDateIcon from '@kijiji/icons/src/icons/MoveInDate'
import NoPetsIcon from '@kijiji/icons/src/icons/NoPets'
import OtherIcon from '@kijiji/icons/src/icons/Other'
import ParkingIcon from '@kijiji/icons/src/icons/Parking'
import PersonalOutdoorSpaceIcon from '@kijiji/icons/src/icons/PersonalOutdoorSpace'
import PetsIcon from '@kijiji/icons/src/icons/Pets'
import PhoneBrandIcon from '@kijiji/icons/src/icons/PhoneBrand'
import PhoneCarrierIcon from '@kijiji/icons/src/icons/PhoneCarrier'
import ScreenSizeIcon from '@kijiji/icons/src/icons/ScreenSize'
import ShippingIcon from '@kijiji/icons/src/icons/Shipping'
import SizeIcon from '@kijiji/icons/src/icons/Size'
import SleepsIcon from '@kijiji/icons/src/icons/Sleeps'
import SmokingIcon from '@kijiji/icons/src/icons/Smoking'
import SportIcon from '@kijiji/icons/src/icons/Sport'
import SquareFootageIcon from '@kijiji/icons/src/icons/SquareFootage'
import StartDateIcon from '@kijiji/icons/src/icons/StartDate'
import TabletBrandIcon from '@kijiji/icons/src/icons/TabletBrand'
import TractorIcon from '@kijiji/icons/src/icons/Tractor'
import TransmissionIcon from '@kijiji/icons/src/icons/Transmission'
import TypeIcon from '@kijiji/icons/src/icons/Type'
import UnitTypeIcon from '@kijiji/icons/src/icons/UnitType'
import UnitTypeApartmentIcon from '@kijiji/icons/src/icons/UnitTypeApartment'
import UnitTypeBasementIcon from '@kijiji/icons/src/icons/UnitTypeBasement'
import UnitTypeCondoIcon from '@kijiji/icons/src/icons/UnitTypeCondo'
import UnitTypeDuplexIcon from '@kijiji/icons/src/icons/UnitTypeDuplex'
import UnitTypeHouseIcon from '@kijiji/icons/src/icons/UnitTypeHouse'
import UnitTypeTownhouseIcon from '@kijiji/icons/src/icons/UnitTypeTownhouse'
import VacationTypeIcon from '@kijiji/icons/src/icons/VacationType'
import VisualAidIcon from '@kijiji/icons/src/icons/VisualAid'
import { type TFunction } from 'i18next'
import { type FC } from 'react'

import {
  ATTRIBUTES,
  BOOLEAN_TEXT_VALUES,
  CAR_BODY_TYPE_VALUES,
  CAR_FUEL_VALUES,
  FULFILLMENT_VALUES,
  PAYMENT_VALUES,
  PET_FRIENDLY_VALUES,
  PET_GENDER_VALUES,
  UNIT_TYPE_VALUES,
  VEHICLE_TYPE_VALUES,
} from '@/features/attributes/constants/attributes'
import {
  type ConcatenatedLabelOverride,
  type LabelOverrideProps,
  type NameOverrideProps,
  ATTRIBUTES_LABEL_OVERRIDE,
  CONCATENATED_LABEL_OVERRIDE,
} from '@/features/attributes/constants/attributesLabelOverride'
import { getAnimalAge } from '@/features/attributes/utils/petAttributeUtils'

export const ATTRIBUTE_ICON_DEFAULT = OtherIcon

export type AttributesConfigConcatenate = {
  /**
   * Canonical names of the attributes to be concatenated
   * Those attributes won't be duplicated in the list of attributes as an isolated one, but concatenated with the main one.
   * The list should include the main one and appear in order to be concatenated
   * */
  canonicalNames: string[]
  labelOverride?: ConcatenatedLabelOverride
}

/**
 * This list will contain the attributes that should have dynamic (custom) content
 * All attributes that are not a part of this list should take the default attributes behaviour
 */
export type AttributeConfig = {
  [attrName: string]: {
    /**
     * Icon assigned for the attribute. It will only be returned if there is a specific icon defined for the attribute
     * */
    Icon?: FC<IconProps>
    /**
     * Icon for each value of an attribute.
     * It is only returned when there are dynamic icons per attr value.
     * */
    valueIcon?: { [canonicalValue: string]: FC<IconProps> }
    /**
     * Value that should be returned when the label to be displayed should not follow
     * the dynamic pattern established for all other fields.
     */
    labelOverride?: LabelOverrideProps
    /**
     * Specifies a new name for the attribute if needs a custom one
     * The default is to use the BE value returned for the attribute
     */
    nameOverride?: NameOverrideProps
    /**
     * A few attributes have more than one value
     * The "labelOverride" will return all of them joined by default,
     * this field will indicate when/how to treat each of the values individually so they can be separated to be displayed
     *
     * use "separateMultipleAttributeValues" to separate each value into a separated attribute
     */
    multipleLabelOverride?: (
      attribute: { canonicalName: string; canonicalValue: string },
      t: TFunction
    ) => string
    /**
     * Some attributes will need their labels to be a concatenation of multiple different attributes
     * This happens the most with range attributes, or dates (i.e. start - end)
     */
    concatenateMultipleAttributes?: AttributesConfigConcatenate

    /**
     * Attribute should return a list of attributes separated
     * Use "multipleLabelOverride" prop to return a list of attributes in the same string
     */
    separateMultipleAttributeValues?: {
      /**
       * Ensure the canonical returned on multiple labels are the same as their individual one to avoid duplication
       *
       * i.e. RENTAL_OPTIONS returns "onlineapplication" as one of its multiple values, and the single attr will return "onlineapp" as canonical
       * We should set this parameter as: { onlineapplication: "onlineapp" }
       */
      overrideCanonical?: {
        [currentCanonical: string]: string
      }
    }
  }
}

/**
 * Shared definitions
 * Those are reused when multiple categories have different names for the same type of attribute
 */
const SHARED_CONFIG = {
  DRIVETRAIN: { Icon: AutosDrivetrainIcon },
  TRANSMISSION: { Icon: TransmissionIcon },
  TRIM: { Icon: AutosTrimIcon },
  MAKE: { Icon: AutosMakeIcon },
  MODEL: {
    Icon: AutosModelIcon,
    /**
     * It will concatenate the attributes inside of the "Model" label in the following order: year + make + model
     * All the autos attributes that uses the "MODEL" shared config will automatically use this configuration
     */
    concatenateMultipleAttributes: {
      canonicalNames: [
        ATTRIBUTES.CAR_YEAR,
        ATTRIBUTES.CAR_MAKE,
        ATTRIBUTES.ATV_MAKE,
        ATTRIBUTES.BOATS_MAKE,
        ATTRIBUTES.MOTORCYCLE_MAKE,
        ATTRIBUTES.CAR_MODEL,
        ATTRIBUTES.ATV_MODEL,
        ATTRIBUTES.BOATS_MODEL,
        ATTRIBUTES.MOTORCYCLE_MODEL,
      ],
    },
  },
  CONDITION: {
    valueIcon: {
      [VEHICLE_TYPE_VALUES.DAMAGED]: ConditionDamagedIcon,
      [VEHICLE_TYPE_VALUES.NEW]: ConditionNewIcon,
      [VEHICLE_TYPE_VALUES.SALVAGE]: ConditionSalvageIcon,
      [VEHICLE_TYPE_VALUES.USED]: ConditionUsedIcon,
    },
  },
  FUEL_TYPE: {
    labelOverride: ({ attribute: { values, canonicalValues } }) => {
      const value = values[0]
      if (!canonicalValues?.length || canonicalValues[0] === CAR_FUEL_VALUES.OTHER) return ''

      return value ?? ''
    },
    Icon: FuelIcon,
    valueIcon: {
      [CAR_FUEL_VALUES.HYBRID]: FuelHybridIcon,
      [CAR_FUEL_VALUES.ELECTRIC]: ElectrfuelIcon,
    },
  },
  ENGINE_POWER: {
    Icon: EngineIcon,
    labelOverride: (props) =>
      ATTRIBUTES_LABEL_OVERRIDE.REPLACE_VALUE_WITH_TRANSLATION({
        ...props,
        customIntlKey: 'horse_power',
      }),
  },
  SLEEPS: {
    Icon: SleepsIcon,
    labelOverride: (props) =>
      ATTRIBUTES_LABEL_OVERRIDE.REPLACE_VALUE_WITH_TRANSLATION({
        ...props,
        customIntlKey: 'sleeps',
      }),
  },
  SEATS: {
    Icon: AutosSeatsSimpleIcon,
    labelOverride: (props) =>
      ATTRIBUTES_LABEL_OVERRIDE.REPLACE_VALUE_WITH_TRANSLATION({
        ...props,
        customIntlKey: 'seats',
      }),
  },
  RANGE_DATE: {
    Icon: CalenderIcon,
    concatenateMultipleAttributes: {
      canonicalNames: [ATTRIBUTES.AVAILABILITY_START_DATE, ATTRIBUTES.AVAILABILITY_END_DATE],
      labelOverride: CONCATENATED_LABEL_OVERRIDE.FORMAT_RANGE_DATE_TIME,
    },
  },
  START_DATE_TIME: {
    Icon: StartDateIcon,
    concatenateMultipleAttributes: {
      canonicalNames: [ATTRIBUTES.START_DATE, ATTRIBUTES.START_TIME],
      labelOverride: CONCATENATED_LABEL_OVERRIDE.FORMAT_DATE_TIME,
    },
  },
  END_DATE_TIME: {
    Icon: EndDateIcon,
    concatenateMultipleAttributes: {
      canonicalNames: [ATTRIBUTES.END_DATE, ATTRIBUTES.END_TIME],
      labelOverride: CONCATENATED_LABEL_OVERRIDE.FORMAT_DATE_TIME,
    },
  },
} satisfies AttributeConfig

/** Configuration */
export const ATTRIBUTES_CONFIG: AttributeConfig = {
  /** All fuel types attributes */
  [ATTRIBUTES.ATV_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.CAMPERS_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.CAR_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.FARMING_EQUIPMENT_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.HEAVY_EQUIPMENT_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.MOTORBOATS_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.MOTORCYCLE_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.MOTORHOMES_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.PWC_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.SAILBOATS_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.SNOW_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,
  [ATTRIBUTES.TRUCK_FUEL_TYPE]: SHARED_CONFIG.FUEL_TYPE,

  /** All engine power attributes */
  [ATTRIBUTES.BOATS_ENGINE_POWER]: SHARED_CONFIG.ENGINE_POWER,
  [ATTRIBUTES.CAR_ENGINE_POWER]: SHARED_CONFIG.ENGINE_POWER,
  [ATTRIBUTES.MOTORHOME_ENGINE_POWER]: SHARED_CONFIG.ENGINE_POWER,
  [ATTRIBUTES.SNOW_ENGINE_POWER]: SHARED_CONFIG.ENGINE_POWER,
  [ATTRIBUTES.HEAVY_EQUIPMENT_ENGINE_POWER]: SHARED_CONFIG.ENGINE_POWER,
  [ATTRIBUTES.MOTORCYCLE_ENGINE_POWER]: SHARED_CONFIG.ENGINE_POWER,

  /** All condition attributes */
  [ATTRIBUTES.VEHICLE_TYPE]: SHARED_CONFIG.CONDITION,
  [ATTRIBUTES.CONDITION]: SHARED_CONFIG.CONDITION,

  /** All Trim attributes */
  [ATTRIBUTES.ATV_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.BOATS_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.CAMPERS_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.CAR_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.FARMING_EQUIPMENT_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.MOTORCYCLE_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.MOTORHOMES_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.PARK_MODELS_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.SNOW_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.TRAILERS_TRIM]: SHARED_CONFIG.TRIM,
  [ATTRIBUTES.TRUCK_TRIM]: SHARED_CONFIG.TRIM,

  /** All Make attributes */
  [ATTRIBUTES.ATV_MAKE]: SHARED_CONFIG.MAKE,
  [ATTRIBUTES.CAR_MAKE]: SHARED_CONFIG.MAKE,
  [ATTRIBUTES.BOATS_MAKE]: SHARED_CONFIG.MAKE,
  [ATTRIBUTES.MOTORCYCLE_MAKE]: SHARED_CONFIG.MAKE,

  /** All Model attributes */
  [ATTRIBUTES.ATV_MODEL]: SHARED_CONFIG.MODEL,
  [ATTRIBUTES.BOATS_MODEL]: SHARED_CONFIG.MODEL,
  [ATTRIBUTES.CAR_MODEL]: SHARED_CONFIG.MODEL,
  [ATTRIBUTES.MOTORCYCLE_MODEL]: SHARED_CONFIG.MODEL,

  /** All Transmission attributes */
  [ATTRIBUTES.CAR_TRANSMISSION]: SHARED_CONFIG.TRANSMISSION,
  [ATTRIBUTES.ATV_TRANSMISSION]: SHARED_CONFIG.TRANSMISSION,
  [ATTRIBUTES.MOTORCYCLE_TRANSMISSION]: SHARED_CONFIG.TRANSMISSION,

  /** All Drivetrain Attributes */
  [ATTRIBUTES.DRIVETRAIN]: SHARED_CONFIG.DRIVETRAIN,
  [ATTRIBUTES.MOTORCYCLE_DRIVETRAIN]: SHARED_CONFIG.DRIVETRAIN,

  [ATTRIBUTES.AGE]: { Icon: AgeIcon },
  [ATTRIBUTES.AGREEMENT_TYPE]: { Icon: AgreementTypeIcon },
  [ATTRIBUTES.AIR_CONDITIONING]: { Icon: AirConditioningIcon },
  [ATTRIBUTES.AREA_IN_ACRES]: { Icon: SquareFootageIcon },
  [ATTRIBUTES.AREA_IN_FEET]: {
    Icon: SquareFootageIcon,
    labelOverride: (props) => {
      const value = ATTRIBUTES_LABEL_OVERRIDE.FORMAT_NUMBER(props)

      return (
        ATTRIBUTES_LABEL_OVERRIDE.NOT_AVAILABLE({
          ...props,
          attribute: { ...props.attribute, values: [value] },
        }) || props.t('listing:attribute.label_override.sqft', { value })
      )
    },
  },

  [ATTRIBUTES.AVAILABILITY_END_DATE]: SHARED_CONFIG.RANGE_DATE,
  [ATTRIBUTES.AVAILABILITY_START_DATE]: SHARED_CONFIG.RANGE_DATE,

  [ATTRIBUTES.BALCONY]: { Icon: PersonalOutdoorSpaceIcon },
  [ATTRIBUTES.BARRIER_FREE]: { Icon: BarrierFreeIcon },
  [ATTRIBUTES.BATHROOMS]: { Icon: BathsIcon },
  [ATTRIBUTES.BEDROOMS]: { Icon: BedsIcon },
  [ATTRIBUTES.BIRTH_DATE]: {
    Icon: BirthdayIcon,
    labelOverride: (props) => {
      const formattedDate = ATTRIBUTES_LABEL_OVERRIDE.FORMAT_DATE(props)
      const dateString = props.attribute.values[0]

      if (!dateString) return formattedDate

      const { translationKey, age } = getAnimalAge(dateString)

      if (!translationKey) return formattedDate

      const animalAgeLabel = props.t(`listing:attributes.pets.${translationKey}`, { value: age })

      // append the animal age to the formatted date, eg June 25, 2022 (3 years old)
      return `${formattedDate} ${animalAgeLabel}`
    },
  },
  [ATTRIBUTES.BRAND_DESKTOP]: { Icon: DesktopBrandIcon },
  [ATTRIBUTES.BRAND_LAPTOP]: { Icon: LaptopBrandIcon },
  [ATTRIBUTES.BRAND_PHONE]: { Icon: PhoneBrandIcon },
  [ATTRIBUTES.BRAND_TABLET]: { Icon: TabletBrandIcon },
  [ATTRIBUTES.BREED]: { Icon: PetsIcon },
  [ATTRIBUTES.CAR_BODY_TYPE]: {
    Icon: AutosOtherIcon,
    valueIcon: {
      [CAR_BODY_TYPE_VALUES.CONVERTIBLE]: AutosConvertibleIcon,
      [CAR_BODY_TYPE_VALUES.COUPE]: AutosCoupeIcon,
      [CAR_BODY_TYPE_VALUES.HATCHBACK]: AutosHatchbackIcon,
      [CAR_BODY_TYPE_VALUES.PICKUP_TRUCK]: AutosPickupIcon,
      [CAR_BODY_TYPE_VALUES.SEDAN]: AutosSedanIcon,
      [CAR_BODY_TYPE_VALUES.SUV_CROSSOVER]: AutosSuvIcon,
      [CAR_BODY_TYPE_VALUES.VAN]: AutosVanIcon,
      [CAR_BODY_TYPE_VALUES.WAGON]: AutosWagonIcon,
    },
  },
  [ATTRIBUTES.CAR_COLOR]: {
    Icon: ColorSimpleIcon,
    labelOverride: ATTRIBUTES_LABEL_OVERRIDE.JOIN_ALL_VALUES,
  },
  [ATTRIBUTES.CAR_ENGINE]: {
    Icon: EngineIcon,
    labelOverride: ATTRIBUTES_LABEL_OVERRIDE.REPLACE_VALUE_WITH_TRANSLATION,
  },
  [ATTRIBUTES.CAR_MILEAGE]: {
    Icon: KmSimpleIcon,
    labelOverride: ({ attribute, ...props }) => {
      const value = attribute.values?.[0] ?? attribute.canonicalValues?.[0]

      if (!value || value === '0') return ''
      return ATTRIBUTES_LABEL_OVERRIDE.FORMAT_NUMBER({ attribute, ...props })
    },
  },
  [ATTRIBUTES.CAR_YEAR]: { Icon: CalenderIcon },
  [ATTRIBUTES.CASHLESS_PAYMENT]: { valueIcon: { [BOOLEAN_TEXT_VALUES.YES]: CashlessPaymentIcon } },
  [ATTRIBUTES.CASH_ACCEPTED]: { valueIcon: { [BOOLEAN_TEXT_VALUES.YES]: AutosPriceIcon } },
  [ATTRIBUTES.CITY]: { Icon: CityIcon },
  [ATTRIBUTES.COMPANY]: { Icon: CompanyIcon },
  [ATTRIBUTES.CPO]: { Icon: ConditionCertifiedPreOwnedIcon },
  [ATTRIBUTES.CRUISE_CONTROL]: { Icon: AutosCruiseControlIcon },
  [ATTRIBUTES.CURBSIDE]: { valueIcon: { [BOOLEAN_TEXT_VALUES.YES]: CurbsidePickupIcon } },
  [ATTRIBUTES.DATE_AVAILABLE]: { Icon: MoveInDateIcon },
  [ATTRIBUTES.DROP_OFF]: { valueIcon: { [BOOLEAN_TEXT_VALUES.YES]: DeliveryIcon } },
  [ATTRIBUTES.ELECTRIC_RANGE]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
  [ATTRIBUTES.EVENT_TYPE]: { Icon: TypeIcon },
  [ATTRIBUTES.FRAME_SIZE]: { Icon: FrameSizeIcon },
  [ATTRIBUTES.FUEL_CAPACITY]: {
    Icon: FuelIcon,
    labelOverride: ATTRIBUTES_LABEL_OVERRIDE.REPLACE_VALUE_WITH_TRANSLATION,
  },
  [ATTRIBUTES.FULFILLMENT]: {
    valueIcon: {
      [FULFILLMENT_VALUES.CURBSIDE]: CurbsidePickupIcon,
      [FULFILLMENT_VALUES.DELIVERY]: DeliveryIcon,
      [FULFILLMENT_VALUES.SHIPPING]: ShippingIcon,
    },
  },
  [ATTRIBUTES.GENDER]: {
    valueIcon: { [PET_GENDER_VALUES.FEMALE]: FemaleIcon, [PET_GENDER_VALUES.MALE]: MaleIcon },
    nameOverride: ({ t }) => {
      return t('listing:attributes.pets.sex')
    },
    labelOverride: ({ attribute }) => {
      const value = attribute.canonicalValues?.[0]

      if (!value || value === PET_GENDER_VALUES.NOT_SPECIFIED) return ''
      return attribute.values?.[0] ?? ''
    },
  },
  [ATTRIBUTES.HEAVY_EQUIPMENT_TYPE]: { Icon: TractorIcon },
  [ATTRIBUTES.JEWELLERY_TYPE]: { Icon: JewelleryTypeIcon },
  [ATTRIBUTES.JOB_OFFERED_BY]: { Icon: JobOfferedByIcon },
  [ATTRIBUTES.JOB_TYPE]: { Icon: JobTypeIcon },
  [ATTRIBUTES.MIN_NIGHTS]: { Icon: MinStayIcon },
  [ATTRIBUTES.NUM_DOORS]: {
    Icon: AutosDoorsIcon,
    labelOverride: ({ attribute: { values }, t }) => {
      const value = values[0]
      if (!value || value === '0') return ''
      return t('listing:attribute.label_override.doors', { value })
    },
  },
  [ATTRIBUTES.NUM_SEATS]: SHARED_CONFIG.SEATS,
  [ATTRIBUTES.PARKING]: { Icon: ParkingIcon },
  [ATTRIBUTES.PASSENGERS]: {
    Icon: AutosSeatsSimpleIcon,
    labelOverride: ({ attribute: { values }, t }) => {
      const value = values[0]
      return value
        ? t('listing:attribute.label_override.passengers', { count: parseInt(value) })
        : ''
    },
  },
  [ATTRIBUTES.PAYMENT]: {
    valueIcon: {
      [PAYMENT_VALUES.CASHLESS]: CashlessPaymentIcon,
      [PAYMENT_VALUES.CASH_ACCEPTED]: AutosPriceIcon,
    },
  },
  [ATTRIBUTES.PET_FRIENDLY]: {
    Icon: PetsIcon,
    valueIcon: { [PET_FRIENDLY_VALUES.TRUE]: PetsIcon, [PET_FRIENDLY_VALUES.FALSE]: NoPetsIcon },
  },
  [ATTRIBUTES.PHONE_CARRIER]: { Icon: PhoneCarrierIcon },
  [ATTRIBUTES.REHOME_DATE]: { Icon: RehomeIcon },
  [ATTRIBUTES.SCREEN_SIZE_LAPTOP]: { Icon: ScreenSizeIcon },
  [ATTRIBUTES.SEATS]: SHARED_CONFIG.SEATS,
  [ATTRIBUTES.SHIPPING]: { valueIcon: { [BOOLEAN_TEXT_VALUES.YES]: ShippingIcon } },
  [ATTRIBUTES.SIZE]: { Icon: SizeIcon },
  [ATTRIBUTES.SLEEPING_CAPACITY]: SHARED_CONFIG.SLEEPS,
  [ATTRIBUTES.SLEEPS]: SHARED_CONFIG.SLEEPS,
  [ATTRIBUTES.SMOKING_PERMITTED]: { Icon: SmokingIcon },
  [ATTRIBUTES.SPORT]: { Icon: SportIcon },
  [ATTRIBUTES.START_DATE]: SHARED_CONFIG.START_DATE_TIME,
  [ATTRIBUTES.START_TIME]: SHARED_CONFIG.START_DATE_TIME,
  [ATTRIBUTES.END_DATE]: SHARED_CONFIG.END_DATE_TIME,
  [ATTRIBUTES.END_TIME]: SHARED_CONFIG.END_DATE_TIME,
  [ATTRIBUTES.UNIT_TYPE]: {
    Icon: UnitTypeIcon,
    valueIcon: {
      [UNIT_TYPE_VALUES.CONDO]: UnitTypeCondoIcon,
      [UNIT_TYPE_VALUES.APARTMENT]: UnitTypeApartmentIcon,
      [UNIT_TYPE_VALUES.TOWNHOUSE]: UnitTypeTownhouseIcon,
      [UNIT_TYPE_VALUES.DUPLEX]: UnitTypeDuplexIcon,
      [UNIT_TYPE_VALUES.BASEMENT_APARTMENT]: UnitTypeBasementIcon,
      [UNIT_TYPE_VALUES.HOUSE]: UnitTypeHouseIcon,
    },
  },
  [ATTRIBUTES.VACATION_TYPE]: { Icon: VacationTypeIcon },

  [ATTRIBUTES.VISUAL_AIDS]: { Icon: VisualAidIcon },
  [ATTRIBUTES.WHEELS]: {
    Icon: AutosAlloyWheelsIcon,
    labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME,
  },
}
