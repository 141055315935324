import { useGetCategoryPathQuery } from '@kijiji/generated/graphql-types'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { type FC } from 'react'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { formatListingDescription } from '@/domain/listings/formatListingDescription'
import { getLocationPath } from '@/domain/location/getLocationPath'
import { getSingleListingStructuredMarkup } from '@/features/seo/utils/structured-markups/getSingleListingStructuredMarkup'
import { useLocale } from '@/hooks/useLocale'
import { type VipQueryListing } from '@/types/listings'

type VipMetadataProps = {
  listing: VipQueryListing
  isPintAd: boolean
}

export const VipMetadata: FC<VipMetadataProps> = ({ listing, isPintAd }) => {
  const { t } = useTranslation(TRANSLATION_KEYS.VIP)
  const { apiLocale, cookieLocale } = useLocale()
  const { title, description, location, categoryId } = listing

  const { data: categoryData } = useGetCategoryPathQuery({
    fetchPolicy: 'cache-first',
    variables: { categoryId, locale: cookieLocale, locationId: location.id },
  })

  const formattedDescription = description
    ? formatListingDescription(description, isPintAd)
    : undefined

  /**
   * JSON-LD implementation docs: https://nextjs.org/docs/app/building-your-application/optimizing/metadata#json-ld
   * */
  const productJsonLd = getSingleListingStructuredMarkup({
    listing: { ...listing, description: formattedDescription },
    t,
    includeMarkup: { location: true, offerDateDetails: true },
  })

  const getPageTitle = () => {
    const categoryName = categoryData?.category?.localizedName ?? undefined
    return t('seo.meta.title', {
      listingName: title,
      categoryName: categoryName ? `${categoryName} | ` : undefined,
      locationName: location.name,
    })
  }

  const getOpenGraphMetaLocation = () => {
    const locationPath = getLocationPath(location.id)

    const country = locationPath[0]?.name
    const region = locationPath[2]?.name
    const city = locationPath[3]?.name

    const latitude = location.coordinates?.latitude ?? undefined
    const longitude = location.coordinates?.longitude ?? undefined

    const additionalMetaTags: { property: string; content: string }[] = []

    if (latitude) {
      additionalMetaTags.push({ property: 'og:latitude', content: latitude.toString() })
    }
    if (longitude) {
      additionalMetaTags.push({ property: 'og:longitude', content: longitude.toString() })
    }
    if (country?.[apiLocale]) {
      additionalMetaTags.push({ property: 'og:country-name', content: country[apiLocale] })
    }
    if (region?.[apiLocale]) {
      additionalMetaTags.push({ property: 'og:region', content: region[apiLocale] })
    }

    if (city?.[apiLocale]) {
      additionalMetaTags.push({ property: 'og:locality', content: city[apiLocale] })
    }

    return { additionalMetaTags }
  }

  const metaTitle = getPageTitle()
  const { additionalMetaTags } = getOpenGraphMetaLocation()

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(productJsonLd) }}
        />
      </Head>

      {/* The breadcrumbs JSON-LD is defined within the breadcrumbs component to avoid duplicating the crumbs data parsing */}

      <NextSeo
        title={metaTitle}
        description={formattedDescription}
        robotsProps={{ maxImagePreview: 'large' }}
        openGraph={{ siteName: 'Kijiji', type: 'product' }}
        additionalMetaTags={additionalMetaTags}
      />
    </>
  )
}
