import BumpUpIcon from '@kijiji/icons/src/icons/BumpUp'
import HomepageGalleryIcon from '@kijiji/icons/src/icons/HomepageGallery'
import TopAdIcon from '@kijiji/icons/src/icons/TopAd'
import UrgentIcon from '@kijiji/icons/src/icons/Urgent'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { ListingPromoteLearnMoreItem } from '@/features/listing/components/promote/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text/HeadlineText'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal } from '@/ui/molecules/modal/Modal'

export type ListingPromoteLearnMoreModalProps = {
  isOpen: boolean
  handleOnCancel: () => void
}

export const ListingPromoteLearnMoreModal: FC<ListingPromoteLearnMoreModalProps> = ({
  isOpen,
  handleOnCancel,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.COMMON, TRANSLATION_KEYS.PAID_FEATURES])

  const paidFeatures = [
    { translationKey: 'top_ad', icon: <TopAdIcon aria-hidden /> },
    { translationKey: 'bump_up', icon: <BumpUpIcon aria-hidden /> },
    { translationKey: 'urgent_ad', icon: <UrgentIcon aria-hidden /> },
    { translationKey: 'homepage_gallery', icon: <HomepageGalleryIcon aria-hidden /> },
  ]

  return (
    <Modal
      closeButtonLabel={t(`${TRANSLATION_KEYS.COMMON}:modals.close.button.label`)}
      hasCloseButton
      id="promote-listing-learn"
      isOpen={isOpen}
      label={t(`${TRANSLATION_KEYS.PAID_FEATURES}:promote_listing.modal.title`)}
      maxWidth="50rem"
      onCancel={handleOnCancel}
      padding={`${spacing.husky} ${spacing.large}`}
    >
      <HeadlineText as="h3" size="large" color={colors.purple.primary} textAlign="center">
        {t(`${TRANSLATION_KEYS.PAID_FEATURES}:promote_listing.modal.title`)}
      </HeadlineText>

      <Spacing mTop={spacing.husky}>
        <Flex gap={spacing.large} flexDirection="column" as="ul">
          {paidFeatures.map(({ translationKey, icon }) => (
            <ListingPromoteLearnMoreItem
              forwardedAs="li"
              gap={spacing.default}
              key={`feature-learn-more-${translationKey}`}
            >
              {icon}
              <div>
                <HeadlineText as="h4" size="medium" color={colors.purple.primary} weight="bold">
                  {t(
                    `${TRANSLATION_KEYS.PAID_FEATURES}:promote_listing.learn.${translationKey}.title`
                  )}
                </HeadlineText>

                <BodyText color={colors.grey.light1}>
                  {t(
                    `${TRANSLATION_KEYS.PAID_FEATURES}:promote_listing.learn.${translationKey}.description`
                  )}
                </BodyText>
              </div>
            </ListingPromoteLearnMoreItem>
          ))}
        </Flex>
      </Spacing>
    </Modal>
  )
}
