import { StyledGptFooter } from '@/components-page/vip/advertisement/styled'
import { VIP_GPT_ID } from '@/components-page/vip/advertisement/vipAdSlots'
import { LEGACY_MIN_DESKTOP, LEGACY_MIN_TABLET } from '@/constants/pageSettings'
import { triggerManualAdRefresh } from '@/features/advertisement/utils/triggerManualAdRefresh'
import { useBreakpointChange } from '@/hooks/useBreakpointChange'

export const GptFooter = () => {
  useBreakpointChange([LEGACY_MIN_DESKTOP, LEGACY_MIN_TABLET], () => {
    triggerManualAdRefresh(VIP_GPT_ID.FOOTER)
  })

  return <StyledGptFooter id={VIP_GPT_ID.FOOTER} data-testid={VIP_GPT_ID.FOOTER} />
}
