import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const SLIDE_SPACING = '2.4rem'
const THUMBNAIL_SIZE = '7.5rem'
const THUMBNAIL_SLIDE_SPACING = '1.6rem'

export const GalleryWrapper = styled.section`
  align-items: center;
  background: rgba(47, 47, 47, 0.99);
  display: flex;
  height: 100vh;
  position: relative;
`

export const ImageGalleryContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr auto;
  height: 100%;
  margin: auto;
  max-width: 100vw;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    max-width: 80vw;
  }
`
)

export const ImageGalleryMainContainer = styled.div`
  align-self: center;
  grid-column: 2 / span 1;
  grid-row: 1 / 2;
  margin: 0 auto;
  max-width: 100rem;
  overflow: hidden;
  width: 100%;
  height: 59%;

  & > div {
    display: flex;
    margin-left: calc(${SLIDE_SPACING} * -1);
    touch-action: pan-y pinch-zoom;
    height: 100%;
  }
`

// eslint-disable-next-line @kijiji/prefer-image-custom
export const ImageGalleryPictureSlideStyled = styled.img`
  flex: 0 0 100%;
  margin: 0 ${SLIDE_SPACING};
  min-width: 0;
  transform: translate3d(0, 0, 0);
  object-fit: contain;
`

export const ImageGalleryYoutubeSlideStyled = styled.div`
  flex: 0 0 96%;
  margin: 0 ${SLIDE_SPACING};
  min-width: 0;
  transform: translate3d(0, 0, 0);
`

export const ImageGalleryVirtualSlideStyled = styled.div`
  flex: 0 0 96%;
  transform: translate3d(0, 0, 0);
`

export const ImageGalleryThumbnailContainer = styled.div`
  grid-column: 2 / span 1;
  grid-row: 2 / 3;
  margin: 1.6rem auto 5rem;
  max-width: 80vw;

  & > div {
    overflow: hidden;
  }
`

export const ImageGalleryThumbnailSlides = styled.div`
  display: flex;
`

const ThumbnailSelectedStyle = (theme: ThemeProps) => `
  border: .2rem solid ${theme.colors.green.primary};
  opacity: 1;
`

// eslint-disable-next-line @kijiji/prefer-button-component
export const ImageGalleryThumbnailSlide = styled.button<{ selected?: boolean }>(
  ({ theme, selected }) => `
  flex: 0 0 ${THUMBNAIL_SIZE};
  height: ${THUMBNAIL_SIZE};
  margin-left: ${THUMBNAIL_SLIDE_SPACING};
  min-width: 0;
  opacity: .5;
  position: relative;
  transition: opacity .3s ease-in-out;
  width: ${THUMBNAIL_SIZE};

  ${selected ? ThumbnailSelectedStyle(theme) : ''}

  &:hover {
    opacity: 1;
  }

  & img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`
)

export const ImageGalleryButtonsContainer = styled.div(
  ({ theme }) => `
  position: relative;
  display: none;

  ${MEDIA_QUERIES(theme).medium}{
    display: block;
  }
`
)
