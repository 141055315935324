import styled from 'styled-components'

import { ImageCustom } from '@/components/shared/image'
import { Flex } from '@/ui/atoms/flex'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const BADGE_HEIGHT = '7.2rem'

export const AutocanBannerWrapper = styled(Flex)(
  ({ theme }) => `
  ${TRANSITION_STYLE}
  border-radius: ${theme.borderRadius.small};
  border: .1rem solid ${theme.colors.grey.light4};
  box-shadow: 0px 2px 4px 0px #0000000D;
  height: ${BADGE_HEIGHT};
  overflow: hidden;
  padding-right: ${theme.spacing.defaultSmall};
  text-decoration: none;
  width: 100%;

  &:hover {
    box-shadow: 0px 2px 4px 0px #00000024;
  }
`
)

export const AutocanBannerImageContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.yellow.light4};
  display: flex;
  height: ${BADGE_HEIGHT};
  justify-content: center;
  margin-right: 2.6rem;
  position: relative;
  width: 7.2rem;

  ${MEDIA_QUERIES(theme).medium} {
    background-color: ${theme.colors.purple.light5};
  }
`
)

const illustrationStyle = `
  height: auto;
  width: 5.2rem;
`
const imageStyle = `
  height: 100%;
  object-fit: cover;
  width: 100%;
`

export const AutocanBannerImage = styled(ImageCustom)<{ $isIllustration?: boolean }>(
  ({ $isIllustration }) => `
  ${$isIllustration ? illustrationStyle : imageStyle}
`
)

export const AutocanBannerIconWrapper = styled.div<{ iconHeight?: string }>(
  ({ iconHeight = '4.7rem' }) => `
  height: ${iconHeight};
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  width: auto;

  & img {
    height: 100%;
  }
`
)

export const IconContainer = styled.div`
  & svg {
    height: auto;
    width: 2.4rem;
  }
`
