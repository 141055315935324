import {
  type GetMapProviderQuery,
  GetMapProviderDocument,
  useGetMapProviderQuery,
} from '@kijiji/generated/graphql-types'
import { useCallback, useEffect } from 'react'

import { getGoogleMapsKey } from '@/features/map/utils/getGoogleMapsKey'
import { useLocale } from '@/hooks/useLocale'

export type UseMapProviderResponse = {
  updateMapProvider: (newData: Partial<GetMapProviderQuery['mapProvider']>) => void
  isProviderLoading: boolean
  mapApiKey?: string
  mapProviderError?: boolean | null
}
export const useMapProvider = (fetchApiKey?: boolean): UseMapProviderResponse => {
  const { languageKey } = useLocale()
  const { data, client } = useGetMapProviderQuery()
  const { apiKey, isLoading, error } = data?.mapProvider ?? {}

  const updateMapProvider = useCallback(
    (newData: Partial<GetMapProviderQuery['mapProvider']>) => {
      client.cache.writeQuery({
        query: GetMapProviderDocument,
        data: { mapProvider: { ...data?.mapProvider, ...newData } },
      })
    },
    [client.cache, data?.mapProvider]
  )

  useEffect(() => {
    if (!fetchApiKey || error) return

    updateMapProvider({ isLoading: true, apiKey: '', error: false })
    getGoogleMapsKey(languageKey).then(({ apiKey, error }) => {
      if (error) {
        return updateMapProvider({ error: true, isLoading: false, apiKey: '' })
      }

      return updateMapProvider({ error: false, apiKey, isLoading: false })
    })
  }, [error, fetchApiKey, languageKey, updateMapProvider])

  return {
    updateMapProvider,
    isProviderLoading: !!isLoading,
    mapApiKey: apiKey,
    mapProviderError: error,
  }
}
