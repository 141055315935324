import { type VipQueryListing } from '@/types/listings'

export const PINT_EXTERNAL_SOURCE_PROVIDER_ID = 9001

/**
 * Checks if a listing is from the PINT external source provider
 * @param listing - The listing to check
 * @returns True if the listing is from PINT, false otherwise
 */
export const isPintListing = (listing: VipQueryListing) =>
  Number(listing.externalSource?.sourceProviderId) === PINT_EXTERNAL_SOURCE_PROVIDER_ID
