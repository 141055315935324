import PlayButtonIcon from '@kijiji/icons/src/icons/PlayButton'
import styled from 'styled-components'

import { ImageCustom } from '@/components/shared/image'
import { Flex } from '@/ui/atoms/flex'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { hexToRgb } from '@/ui/helpers/hexToRgb'

const imageBlurStyle = (imageSrc?: string) => {
  if (!imageSrc) return ''

  return `&::before {
    content: '';
    background-image: url(${imageSrc});
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transform: scale(1.1);
  }`
}

type ListingGalleryMainImageButtonProps = { imageSrc?: string; notInteractive?: boolean }
// eslint-disable-next-line @kijiji/prefer-button-component
export const ListingGalleryMainImageButton = styled.button<ListingGalleryMainImageButtonProps>(
  ({ theme, imageSrc, notInteractive }) => `
  align-items: center;
  background-color: ${theme.colors.grey.light4};
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: ${theme.borderRadius.small};

  // Create blur effect behind main image
  ${imageBlurStyle(imageSrc)}

  ${MEDIA_QUERIES(theme).medium} {
    border-radius: ${theme.borderRadius.medium};
    border: 0.1rem solid ${theme.colors.grey.light3};
  }

  ${
    !notInteractive &&
    `
    &:hover img {
      transform: scale(1.05);
    }`
  }
`
)

export const ListingGalleryMainImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const ListingGalleryImage = styled(ImageCustom)`
  ${TRANSITION_STYLE}
  height: 100%;
  object-fit: contain;
  position: relative;
  width: 100%;
`

export const StyledPlayIcon = styled(PlayButtonIcon)<{ variant: 'sm' | 'md' }>(
  ({ variant }) => `
  ${TRANSITION_STYLE}
  position: absolute;
  z-index: 1;
  width: ${variant === 'sm' ? '5rem' : '10rem'};
  height: ${variant === 'sm' ? '5rem' : '10rem'};
`
)

export const ListingGalleryCountBadge = styled.div<{ hasMoreThanOneImage?: boolean }>(
  ({ theme, hasMoreThanOneImage }) => `
  background-color: ${hexToRgb(theme.colors.grey.primary, 0.6)};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.colors.white};
  display: flex;
  padding: ${theme.spacing.mini};

  ${MEDIA_QUERIES(theme).medium}{
    display: ${hasMoreThanOneImage ? 'none' : 'flex'};
  }
`
)

/** Thumbnail CSS */

export const ListingThumbnailImage = styled(ImageCustom).attrs({ skipNextImage: true })`
  ${TRANSITION_STYLE}
  height: 100%;
  object-fit: cover;
  width: 100%;
`

export const ListingThumbnailOverlay = styled.div(
  ({ theme }) => `
  ${TRANSITION_STYLE}
  align-items: center;
  background: ${hexToRgb(theme.colors.grey.primary, 0.6)};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  & p {
    font-size: 2.2rem;
  }

  &:hover {
    background: ${hexToRgb(theme.colors.grey.light1, 0.85)};
  }

  ${MEDIA_QUERIES(theme).large} {
    & p {
      font-size: 3.2rem;
    }
  }
`
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const ListingThumbnailButton = styled.button(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.medium};
  border: 0.1rem solid ${theme.colors.grey.light3};
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    & img,
    & svg {
      transform: scale(1.05);
    }
  }
`
)

export const VirtualTourBadgeContainer = styled.span(
  ({ theme }) => `
  background-color: ${hexToRgb(theme.colors.grey.primary, 0.6)};
  padding: 0.3rem;
  border-radius: ${theme.borderRadius.small};
  display: flex;
  align-items: center;
  justify-content: center;
`
)

export const VipGalleryMobileBadgesContainer = styled(Flex)(
  ({ theme }) => `
  position: absolute;
  bottom: ${theme.spacing.defaultSmall};
  right: ${theme.spacing.defaultSmall};

  // It will prevent the badges from interfering with the gallery images click
  pointer-events: none;
`
)
