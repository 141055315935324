import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { FadeOutSection } from '@/components/shared/fade-out-section/FadeOutSection'
import { VipSectionTitle } from '@/components/vip/shared/vip-section-title/VipSectionTitle'
import {
  VipDescriptionContainer,
  VipDescriptionWrapper,
} from '@/components/vip/vip-description/styled'
import { XssSanitizer } from '@/components/vip/vip-description/XssSanitizer'
import { formatListingDescription } from '@/domain/listings/formatListingDescription'
import { type VipQueryListing } from '@/types/listings'

export type VipDescriptionProps = {
  categoryId: number
  description: VipQueryListing['description']
  isPintAd: boolean
  isSM360Ad: boolean
}

export const VipDescription: FC<VipDescriptionProps> = ({
  categoryId,
  description,
  isPintAd,
  isSM360Ad,
}) => {
  const { t } = useTranslation('vip')
  const { colors, spacing } = useTheme()

  if (!description) return null

  return (
    <VipDescriptionContainer pBottom={spacing.xLarge}>
      <VipSectionTitle title={t('vip:description.title')} />

      <FadeOutSection sectionColor={colors.white}>
        <VipDescriptionWrapper data-testid="vip-description-wrapper">
          <XssSanitizer
            content={formatListingDescription(description, isPintAd)}
            categoryId={categoryId}
            isPintAd={isPintAd}
            isSM360Ad={isSM360Ad}
          />
        </VipDescriptionWrapper>
      </FadeOutSection>
    </VipDescriptionContainer>
  )
}
