import { UserType } from '@kijiji/generated/graphql-types'

/**
 * Returns true if the supplied profile is an autos dealer profile, otherwise
 * false. This function is distinct from `isDealer` where types across
 * categories are considered dealers in general. An autos dealer profile can
 * be one of a number of types, including the deprecated DEALER type.
 *
 * @param {Profile } profile A user's profile or a commercial profile
 *
 * @return {boolean}
 */
export const isAutosDealer = (userType?: UserType) => {
  return (
    userType === UserType.Dealer ||
    userType === UserType.HeDealer ||
    userType === UserType.PvDealer ||
    userType === UserType.RvDealer
  )
}
