import { isLongTermRentalsCategory } from '@kijiji/category'

import { type VipQueryListing } from '@/types/listings'

/**
 * Determines if a listing is eligible for the request viewing feature.
 *
 * @param listing - The listing object to evaluate
 * @returns True if the listing belongs to long term rentals category, false otherwise
 */
export const isListingEligibleForRequestViewing = (listing: VipQueryListing) =>
  isLongTermRentalsCategory(listing.categoryId)
