import {
  CATEGORIES,
  isAnyCarsVehiclesCategory,
  isAnyRealEstateCategory,
  isCarsTrucks,
  isVerticalCategory,
} from '@kijiji/category'
import {
  AuthModalVariant,
  ListingStatus,
  LogoType,
  useGetListingQuery,
  useGetListingsSimilarQuery,
  useGetSellerListingDataQuery,
} from '@kijiji/generated/graphql-types'
import { differenceInDays } from 'date-fns/differenceInDays'
import { type GetServerSideProps } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { ReCaptchaProvider } from 'next-recaptcha-v3'
import { type FC, type ReactNode, useCallback, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { BaseLayout } from '@/components/shared/layouts/BaseLayout'
import { VIP_LOCATION_VARIANT, VipLocation } from '@/components/vip/shared/vip-location/VipLocation'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import {
  VipContainer,
  VipGridLayout,
  VipSectionWrapper,
  VipSidebarContainer,
} from '@/components/vip/styled'
import { VipActionButtons } from '@/components/vip/vip-action-buttons/VipActionButtons'
import { VipAdditionalOptionsSection } from '@/components/vip/vip-additional-options/VipAdditionalOptionsSection'
import { VipAttributesSection } from '@/components/vip/vip-attributes/VipAttributesSection'
import { VipAutoCanSection } from '@/components/vip/vip-autocan-features/VipAutocanSection'
import { VipBreadcrumbs } from '@/components/vip/vip-breadcrumbs/VipBreadcrumbs'
import { VipDealerReviews } from '@/components/vip/vip-dealer-reviews/VipDealerReviews'
import { VipDescription } from '@/components/vip/vip-description/VipDescription'
import { VipFinancingSection } from '@/components/vip/vip-financing/VipFinancingSection'
import { VipGallery } from '@/components/vip/vip-gallery/VipGallery'
import { VipJobsDisclaimer } from '@/components/vip/vip-jobs-disclaimer/VipJobsDisclaimer'
import { VipMetadata } from '@/components/vip/vip-metadata/VipMetadata'
import { MileageAnalysisSection } from '@/components/vip/vip-mileage-analysis/MileageAnalysisSection'
import { VipPostedDate } from '@/components/vip/vip-posted-date/VipPostedDate'
import { POACarousel } from '@/components/vip/vip-poster-other-ads/POACarousel'
import { VipPrice } from '@/components/vip/vip-price/VipPrice'
import { VipPriceInsight } from '@/components/vip/vip-price-insights/VipPriceInsight'
import { VipSafetyDisclaimer } from '@/components/vip/vip-safety-disclaimer/VipSafetyDisclaimer'
import { SellerVipStickyBottomBar } from '@/components/vip/vip-seller/SellerVipStickyBottomBar'
import { SellerVipTopBanner } from '@/components/vip/vip-seller/SellerVipTopBanner'
import { VipSellerCardSection } from '@/components/vip/vip-seller-profile/VipSellerCardSection'
import { VipSideProfileSection } from '@/components/vip/vip-seller-profile/VipSideProfileSection'
import { VipSponsoredAds } from '@/components/vip/vip-sponsored-ads/VipSponsoredAds'
import { VipStickyBottom } from '@/components/vip/vip-sticky-components/VipStickyBottom'
import { VipStickyHeader } from '@/components/vip/vip-sticky-components/VipStickyHeader'
import { VipTitle } from '@/components/vip/vip-title/VipTitle'
import { ExpiredVip } from '@/components/vip/vip-variations/ExpiredVip'
import { VipError } from '@/components/vip/vip-variations/VipError'
import { VipLoadingSkeleton } from '@/components/vip/vip-variations/VipLoadingSkeleton'
import { VipVehicleHistory } from '@/components/vip/vip-vehicle-history/VipVehicleHistory'
import { GptBigbox } from '@/components-page/vip/advertisement/GptBigbox'
import { GptStickyBottom } from '@/components-page/vip/advertisement/GptStickyBottom'
import { TopBannerSlots } from '@/components-page/vip/advertisement/TopBannerSlots'
import { VipAdsWrapper } from '@/components-page/vip/advertisement/VipAdsWrapper'
import { HEADERS } from '@/constants/headers'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { RADIUS_SLIDER_HALF_POINT_KMS } from '@/constants/location'
import { getCategoryPath } from '@/domain/category/getCategoryPath'
import { initializePageServer } from '@/domain/initializePageServer'
import { getListing } from '@/domain/listings/getListing'
import { getListingSimilar } from '@/domain/listings/getListingSimilar'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { isListingFromMove } from '@/domain/listings/isListingFromMove'
import { isValidListingId } from '@/domain/listings/isValidListingId'
import { AdditionalOptionsChips } from '@/features/attributes/components/AdditionalOptionsChips'
import { filterNullAttributes } from '@/features/attributes/utils/filterNullAttributes'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { useSignInModal } from '@/features/auth/hooks/useSignInModal'
import { WarrantyQuoteDisclaimer } from '@/features/autocanada/components/WarrantyQuoteDisclaimer'
import { getAutocanFeatures } from '@/features/autocanada/utils/getAutocanFeatures'
import { CertifiedPreOwned } from '@/features/certified-pre-owned/components/CertifiedPreOwned'
import { CustomTabGroup } from '@/features/custom-tab-groups/components/CustomTabGroup'
import { CAMPAIGN_PLACEMENT } from '@/features/custom-tab-groups/types/customTabGroupTypes'
import { RentalBadge } from '@/features/listing/components/rental-badge/RentalBadge'
import { R2SModal } from '@/features/listing/components/reply-to-seller/R2SModal'
import { ListingReport } from '@/features/listing/components/report/ListingReport'
import { ListingsSimilarCarousel } from '@/features/listing/components/similar/ListingsSimilarCarousel'
import { ListingViews } from '@/features/listing/components/views/ListingViews'
import { useFavouriteOnLoginCallback } from '@/features/listing/hooks/useFavouriteOnLoginCallback'
import { useGSITracking } from '@/features/listing/hooks/useGSITracking/useGSITracking'
import { isDealerListing } from '@/features/listing/utils/isDealerListing'
import { isExternalListingId } from '@/features/listing/utils/isExternalListingId'
import { isPintListing } from '@/features/listing/utils/isPintListing'
import { isSM360Listing } from '@/features/listing/utils/isSM360Listing'
import { isListingEligibleForRequestViewing } from '@/features/listing/utils/request-viewing/isListingEligibleForRequestViewing'
import { LivePerson } from '@/features/liveperson/components/LivePerson'
import { GenericMapProvider } from '@/features/map/components/generic/GenericMapProvider'
import { PaymentEstimator } from '@/features/payment-estimator/components/PaymentEstimator'
import { isPaymentEstimatorAvailable } from '@/features/payment-estimator/utils/isPaymentEstimatorAvailable'
import { getProfile } from '@/features/profile/utils/getProfile'
import { getSearchUrl } from '@/features/search/utils/getSearchUrl'
import { getSearchUrlVariablesFromListing } from '@/features/search/utils/getSearchUrlVariablesFromListing'
import { SellerMetrics } from '@/features/seller-metrics/components/SellerMetrics'
import { addApolloState } from '@/lib/apollo/apolloClient'
import { RemoteConfigs } from '@/lib/firebase/config/configs'
import { useVipTracking } from '@/lib/ga/hooks/useVipTracking'
import profile from '@/lib/metrics/profileWrapper'
import { type BasePageProps } from '@/pages/_app'
import { isRealEstateListingFlags, isRealEstateListingType } from '@/types/listings'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { processCorrelationId } from '@/utils/correlation-id/processCorrelationId'
import { useDeleteCorrelationId } from '@/utils/correlation-id/useDeleteCorrelationId'
import { isVisibleInViewport } from '@/utils/isVisibleInViewport'
import { sendToLogger } from '@/utils/sendToLogger'

const RequestViewingModal = dynamic(
  () =>
    import('@/features/listing/components/request-viewing/RequestViewingModal').then(
      (mod) => mod.RequestViewingModal
    ),
  {
    ssr: false,
  }
)

export const MAX_EVIP_LISTINGS = 20

export interface ViewItemPageProps extends BasePageProps {
  correlationId?: string | null
  isExternalId: boolean
  listingId: string
  serverFailedToFetch?: boolean
}

const ViewItemPage: FC<ViewItemPageProps> = ({
  correlationId,
  isExternalId,
  listingId,
  serverFailedToFetch,
  userAgent = '',
}) => {
  const { query, asPath } = useRouter()
  const { status, data: userData } = useSession()
  const { colors, spacing } = useTheme()
  const { openSignInModal } = useSignInModal()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  /**
   * Specifies if the listing was just posted
   * User would have landed on this page from the PAF
   *
   * The system message will remove the query parameter from the URL after the initial load
   * but this state will be preserved
   */
  const [isPostedListing] = useState<boolean>(query.posted === 'true')

  const vipSideProfileSectionRef = useRef<HTMLDivElement | null>(null)
  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState<boolean>(false)

  /** TODO: We should make this work with apollo cache as well as other modals */
  const [isR2SModalOpen, setIsR2SModalOpen] = useState<boolean>(false)

  /** Reference for the sticky header to be visible after an element */
  const stickyHeaderTopVisibilityRef = useRef<HTMLDivElement>(null)
  /** Reference for the sticky header to be invisible after an element */
  const stickyHeaderVisibilityLimitRef = useRef<HTMLDivElement>(null)
  /** Reference for the phone section for the bottom sticky bar */
  const phoneReference = useRef<HTMLDivElement>(null)

  /**
   * If the server failed to fetch, it will fetch from network
   * so the status and loading change properly and the client can handle
   * And all the next requests will go back to cache-first
   */
  const { data, loading, error } = useGetListingQuery({
    fetchPolicy: serverFailedToFetch ? 'network-only' : 'cache-first',
    nextFetchPolicy: 'cache-first',
    variables: { listingId, trackView: true, isExternalId },
    context: {
      headers: {
        ...(correlationId && {
          [HEADERS.X_ANVIL_CORRELATION_ID_HEADER]: correlationId,
        }),
      },
    },
  })

  const listing = data?.listing

  /**
   * The listing in VIEW has been expired for less than 30 days
   * */
  const isEVIP = listing?.status === ListingStatus.Expired

  /**
   * The user viewing this page is the owner of the listing
   * */
  const isSellerVip = userData ? userData?.user.sub === listing?.posterInfo.posterId : false

  /**
   * This query will only run client-side after we know if this is an SVIP
   */
  const { data: sellerListing } = useGetSellerListingDataQuery({
    skip: !isSellerVip,
    fetchPolicy: 'cache-first',
    variables: { listingId },
  })

  /**
   * This query will run server-side after once we know if this is an EVIP
   * It is important for SEO that it runs on the server
   */
  const { data: similarListingsData } = useGetListingsSimilarQuery({
    skip: !isEVIP,
    fetchPolicy: 'cache-first',
    variables: { listingId, limit: MAX_EVIP_LISTINGS, isExternalId },
    onError: (err) =>
      sendToLogger(err, { fingerprint: ['listings-similar-evip'], extra: { listingId } }),
  })

  const sellerData = sellerListing?.listing
  const eVipListings = similarListingsData?.listingsSimilar ?? []

  const { isGSITrackingEnabled } = useGSITracking(listing)

  useVipTracking({ listing, eVipListings })
  useFavouriteOnLoginCallback()
  useDeleteCorrelationId()

  const callbackForR2S = useCallback(() => {
    setIsFormSubmitSuccess(true)
  }, [])

  /**
   * It should show "Not Found" page when the query is completed from the client
   * AND a listing was not found
   */
  if (
    serverFailedToFetch &&
    error?.graphQLErrors?.[0]?.extensions?.statusCode === 404 &&
    !listing
  ) {
    return <VipError variant="not_found" />
  }

  /**
   * It should return the error page when the query completed from the client
   * AND returned an error that is not a "404"
   */
  if (serverFailedToFetch && !!error && !listing) {
    return <VipError />
  }

  /**
   * If there was a server error and the listing request is still loading on client
   * we will show the VIP loading skeleton
   */
  if (!listing || loading) {
    return (
      <VipLoadingSkeleton
        listingId={listingId}
        categoryId={listing?.categoryId}
        listingLocation={listing?.location}
      />
    )
  }

  const isPintAd = isPintListing(listing)
  const isListingFromDealer = isDealerListing(listing.attributes)
  const allowedAutoCanFeatures = getAutocanFeatures(listing, isListingFromDealer)
  const shouldShowAutoCanFeatures = allowedAutoCanFeatures.length > 0

  const isMoveListing = isListingFromMove(listingId)
  const isSM360Ad = isSM360Listing(listing)
  const isVerticalAd = isVerticalCategory(listing.categoryId)

  const isGuestMessageEnabled = (isPintAd || isSM360Ad || isListingFromDealer) && isVerticalAd

  const handleOpenR2SModal = () => {
    if (!isUserAuthenticated(status) && !isGuestMessageEnabled) {
      openSignInModal({ modalVariant: AuthModalVariant.LoginToMessage, callbackUrl: asPath })
      return
    }

    setIsR2SModalOpen(true)
  }
  const handleCloseR2SModal = () => setIsR2SModalOpen(false)

  const isRealEstateListing =
    isAnyRealEstateCategory(listing.categoryId) &&
    listing.categoryId !== CATEGORIES.REAL_ESTATE_SERVICES_CATEGORY_ID &&
    isRealEstateListingType(listing)

  const filteredAttributes = filterNullAttributes(listing.attributes?.all)
  const listingViews = listing.metrics?.views

  const cpoPartnerId = filteredAttributes.find((attr) => attr.canonicalName === 'cpoprogram')
    ?.values?.[0]

  const isComfreeAd = isRealEstateListingFlags(listing.flags) && listing.flags.comfree

  const shouldShowSellerMetrics = !isVerticalAd || (!isListingFromDealer && !isComfreeAd)

  /**
   * Render the page wrapper with the relevant components
   */
  const renderPageWrapper = (children: ReactNode) => (
    <ReCaptchaProvider useEnterprise reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
      <VipMetadata listing={listing} isPintAd={isPintAd} />

      <BaseLayout
        showSearchBar
        includeRUMScripts={true}
        rumPageLabel="VIP New"
        bodyColor={colors.white}
        withAuthModal
      >
        <GenericMapProvider>
          <VipAdsWrapper listing={listing} isModalVisible={isModalVisible}>
            {children}
          </VipAdsWrapper>
        </GenericMapProvider>
      </BaseLayout>
    </ReCaptchaProvider>
  )

  /**
   * The expired seller VIP will show the normal VIP page instead of the EVIP,
   * with a banner on top with the option to re-post the listing
   */
  if (isEVIP && !isSellerVip) {
    // TODO: do we want seller section for expired listings?
    return renderPageWrapper(
      <VipContainer>
        <VipSectionWrapper>
          <VipBreadcrumbs
            adId={listingId}
            location={listing.location}
            categoryId={listing.categoryId}
          />
        </VipSectionWrapper>

        <VipSectionWrapper>
          <ExpiredVip
            listing={listing}
            isPintAd={isPintAd}
            isSM360Ad={isSM360Ad}
            similarListings={eVipListings}
          />
        </VipSectionWrapper>
      </VipContainer>
    )
  }

  const onSuccessOrErrorRequestViewing = () => {
    if (
      vipSideProfileSectionRef.current &&
      !isVisibleInViewport(vipSideProfileSectionRef.current)
    ) {
      vipSideProfileSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return renderPageWrapper(
    <>
      <VipContainer>
        <VipSectionWrapper>
          {isSellerVip ? (
            <VipSectionWrapper mBottom={spacing.large} mTop={spacing.large}>
              <SellerVipTopBanner
                isExpiredVip={isEVIP}
                isSellerVip={isSellerVip}
                listingId={listingId}
                replies={sellerData?.metrics?.replies}
                views={listing.metrics?.views}
              />
            </VipSectionWrapper>
          ) : null}

          <VipBreadcrumbs
            adId={listingId}
            location={listing.location}
            categoryId={listing.categoryId}
          />

          <VipSectionWrapper>
            <TopBannerSlots
              isFormSubmitSuccess={isFormSubmitSuccess}
              isPostedListing={isPostedListing}
            />
          </VipSectionWrapper>
        </VipSectionWrapper>

        <VipGridLayout>
          <VipSectionWrapper $removePaddingOnMobile>
            <VipGallery
              categoryId={listing.categoryId}
              imageUrls={listing.imageUrls}
              isModalVisible={isModalVisible}
              location={listing.location}
              setIsModalVisible={setIsModalVisible}
              title={listing.title}
              virtualTourUrl={isRealEstateListing ? listing.virtualTourUrl : undefined}
              youtubeVideoId={listing.youtubeVideoId}
            />
          </VipSectionWrapper>
          <VipSectionWrapper>
            {/* Reference point for the sticky header to appear */}
            <div ref={stickyHeaderTopVisibilityRef} />

            <Spacing mBottom={spacing.defaultSmall}>
              <VipTitle title={listing.title} adType={listing.type} />
            </Spacing>

            <Spacing pBottom={spacing.default}>
              <VipPrice
                categoryId={listing.categoryId}
                listingFlags={listing.flags}
                price={listing.price}
                showPriceInsight
              />
            </Spacing>

            {isPaymentEstimatorAvailable(listing.categoryId, isListingFromDealer) &&
              isAmountPrice(listing.price) && (
                <Spacing pBottom={spacing.defaultSmall}>
                  <PaymentEstimator price={listing.price} />
                </Spacing>
              )}

            {isRealEstateListing ? (
              <VipLocation location={listing.location} variant={VIP_LOCATION_VARIANT.LINK} />
            ) : null}

            <VipPostedDate sortingDate={listing.sortingDate} />

            <VipJobsDisclaimer categoryId={listing.categoryId} locationId={listing.location.id} />

            {isRealEstateListing && !!listing.rentalBadge && (
              <Spacing pBottom={spacing.default}>
                <RentalBadge rentalBadge={listing.rentalBadge} />
              </Spacing>
            )}

            <VipActionButtons listing={listing} isSellerVip={isSellerVip} />

            <VipAttributesSection
              categoryId={listing.categoryId}
              attributes={listing.attributes?.all}
            />

            <VipAutoCanSection
              userAgent={userAgent}
              listing={listing}
              allowedFeatures={allowedAutoCanFeatures}
            />

            <VipFinancingSection flags={listing.flags} listingId={listingId} />

            {!shouldShowAutoCanFeatures && (
              <CustomTabGroup
                locationId={listing.location.id}
                categoryId={listing.categoryId}
                placement={CAMPAIGN_PLACEMENT.VIP}
              />
            )}

            <VipDescription
              categoryId={listing.categoryId}
              description={listing.description}
              isPintAd={isPintAd}
              isSM360Ad={isSM360Ad}
            />

            {shouldShowAutoCanFeatures && (
              <CustomTabGroup
                locationId={listing.location.id}
                categoryId={listing.categoryId}
                placement={CAMPAIGN_PLACEMENT.VIP}
              />
            )}

            <VipAdditionalOptionsSection
              categoryId={listing.categoryId}
              attributes={listing.attributes?.all}
            />

            <VipVehicleHistory
              attributes={listing.attributes?.all}
              categoryId={listing.categoryId}
              isDealer={isListingFromDealer}
              listingId={listingId}
              isExternalId={isExternalId}
            />

            {isCarsTrucks(listing.categoryId) && (
              <>
                <VipPriceInsight listingPrice={listing.price} />
                <MileageAnalysisSection categoryId={listing.categoryId} listingId={listingId} />
              </>
            )}

            {cpoPartnerId && (
              <CertifiedPreOwned partnerId={cpoPartnerId} handleOpenR2SForm={handleOpenR2SModal} />
            )}
          </VipSectionWrapper>

          {/* -------- START: MOBILE COMBINATION OF SELLER SECTION + SIDE BAR -------- */}
          <VipSectionWrapper
            small={{ mBottom: spacing.large }}
            medium={{ mBottom: spacing.default }}
          >
            <GptBigbox />

            <VipSellerCardSection
              listing={listing}
              isDealerListing={isListingFromDealer}
              phoneReference={phoneReference}
              isGSITrackingEnabled={isGSITrackingEnabled}
            />

            {isAnyCarsVehiclesCategory(listing.categoryId) ||
            isAnyRealEstateCategory(listing.categoryId) ? (
              <AdditionalOptionsChips
                attributes={filteredAttributes}
                categoryId={listing.categoryId}
                sectionId="vip-seller"
              />
            ) : null}
          </VipSectionWrapper>

          {shouldShowSellerMetrics ? (
            <VipSectionWrapper>
              <VipSectionContainer pBottom="0" pTop="0">
                <SellerMetrics posterId={listing?.posterInfo.posterId} />
              </VipSectionContainer>
            </VipSectionWrapper>
          ) : null}

          {/* The side section will automatically collapse to the right position in the main content on mobile view*/}
          <VipSidebarContainer>
            <VipSideProfileSection
              ref={vipSideProfileSectionRef}
              isListingFromDealer={isListingFromDealer}
              isSellerVip={isSellerVip}
              listing={listing}
              callbackForR2S={callbackForR2S}
              isGSITrackingEnabled={isGSITrackingEnabled}
            />
          </VipSidebarContainer>
          {/* -------- END: MOBILE SELLER SECTION + SIDE BAR -------- */}
          <VipDealerReviews
            isDealerListing={isListingFromDealer}
            posterId={listing.posterInfo.posterId}
          />

          {/* Reference point for the sticky header to disappear */}
          <div ref={stickyHeaderVisibilityLimitRef} />

          {/* It should not show the entire section if listing view AND listing report are not displayed */}
          {(isMoveListing || isSellerVip) && (isMoveListing || isPintAd || !listingViews) ? null : (
            <VipSectionWrapper>
              <VipSectionContainer pTop={spacing.xLarge} pBottom={spacing.xLarge}>
                <Flex gap={spacing.defaultSmall}>
                  <ListingViews
                    views={listingViews}
                    isMoveListing={isMoveListing}
                    isPintAd={isPintAd}
                  />

                  <ListingReport
                    isMoveListing={isMoveListing}
                    isSellerVip={isSellerVip}
                    listingId={listingId}
                  />
                </Flex>
              </VipSectionContainer>
            </VipSectionWrapper>
          )}

          {/* The sticky header is at the end of the DOM to prevent coming before the H1 (Accessibility) */}
          <VipStickyHeader
            bottomVisibilityLimitRef={stickyHeaderVisibilityLimitRef}
            isSellerVip={isSellerVip}
            listing={listing}
            topVisibilityRef={stickyHeaderTopVisibilityRef}
          />
        </VipGridLayout>

        <VipSectionWrapper $removePaddingOnMobile>
          {listing.flags.showcase && <POACarousel sellerId={listing?.posterInfo?.posterId} />}
          <ListingsSimilarCarousel listingId={listingId} />
        </VipSectionWrapper>

        <VipSectionWrapper>
          <VipSponsoredAds />
          <Spacing mBottom={spacing.husky}>
            <Flex flexDirection="column" gap={spacing.default}>
              <VipSafetyDisclaimer />

              <WarrantyQuoteDisclaimer listingId={listingId} />
            </Flex>
          </Spacing>
        </VipSectionWrapper>
      </VipContainer>

      {!isSellerVip && (
        <GptStickyBottom
          topVisibilityRef={stickyHeaderTopVisibilityRef}
          bottomVisibilityLimitRef={stickyHeaderVisibilityLimitRef}
        />
      )}

      {'chatOptIn' in listing.flags && listing.flags.chatOptIn && (
        <LivePerson
          attributes={filteredAttributes}
          chatOptIn={listing.flags.chatOptIn}
          creditOptIn={listing.flags.creditOptIn ?? false}
          tradeInOptIn={listing.flags.tradeInOptIn ?? false}
          listingId={listingId}
          sellerId={listing?.posterInfo?.posterId}
          address={listing?.location?.address ?? ''}
        />
      )}

      <SellerVipStickyBottomBar listingId={listingId} isSellerVip={isSellerVip} />
      {!isSellerVip && !isComfreeAd && (
        <VipStickyBottom
          listing={listing}
          phoneReference={phoneReference}
          handleOpenR2SForm={handleOpenR2SModal}
        />
      )}

      {isR2SModalOpen && (
        <R2SModal handleClose={handleCloseR2SModal} isOpen={isR2SModalOpen} listing={listing} />
      )}

      {isListingEligibleForRequestViewing(listing) ? (
        <RequestViewingModal
          listingId={listing.id}
          onSuccess={onSuccessOrErrorRequestViewing}
          onError={onSuccessOrErrorRequestViewing}
          source="VIP"
        />
      ) : null}
    </>
  )
}

const DELETED_STATUS = [
  ListingStatus.AdminDeleted,
  ListingStatus.Deleted,
  ListingStatus.DeletedExpired,
  ListingStatus.SystemDeleted,
  ListingStatus.UserDeleted,
]

const STATUS_TO_REDIRECT = [
  ...DELETED_STATUS,
  ListingStatus.Blocked,
  ListingStatus.Pending,
  ListingStatus.Sold,
]

export const getServerSideProps: GetServerSideProps<ViewItemPageProps> = async (context) => {
  const { query, req } = context

  /**
   * Find the last parameter of the URL to identify the listingID.
   * If the listing ID doesn't exist or is invalid, it should send the user to a 404 page
   * */
  let listingId = query?.params?.at(-1) ?? '0'

  if (req.url?.includes('/v-view-details.html') && typeof query.adId === 'string') {
    listingId = query.adId
  }

  /** Check for the external ID flag */
  const isExternalId = isExternalListingId(query)
  const correlationId = processCorrelationId('vip', listingId, context)

  if (!isValidListingId(listingId).isValid) {
    return { notFound: true }
  }

  const { basePageProps, apolloClient, locale } = await initializePageServer('vip', context, [
    TRANSLATION_KEYS.AUTOCANADA,
    TRANSLATION_KEYS.CPO,
    TRANSLATION_KEYS.FAVOURITES,
    TRANSLATION_KEYS.LISTING,
    TRANSLATION_KEYS.PAID_FEATURES,
    TRANSLATION_KEYS.PAYMENT_ESTIMATOR,
    TRANSLATION_KEYS.PROFILE,
    TRANSLATION_KEYS.R2S,
    TRANSLATION_KEYS.REQUEST_VIEWING,
    TRANSLATION_KEYS.VIP,
  ])

  try {
    const {
      data: { listing },
    } = await profile(
      'vip',
      'getListing',
      getListing
    )({ apolloClient, listingId, trackView: true, isExternalId, correlationId })

    /**
     * It should redirect to the 404 page if there was no error on the fetch, but no listing was found
     * */
    if (!listing?.id) {
      return { redirect: { destination: '/404', permanent: false } }
    }

    /**
     * the breadcrumbs need to be fetched on server side for SEO
     *
     * >>> After the rollout of the VIP is done we can only fetch the category path at the end when all the redirect checks have been completed
     * */

    if (listing.categoryId) {
      const { data: category } = await profile(
        'vip',
        'getCategoryPath',
        getCategoryPath
      )({
        apolloClient,
        categoryId: listing?.categoryId,
        locationId: listing?.location.id ?? 0,
        locale: locale.cookieLocale,
      })

      /** Remove this rollout verification once the NWA VIP is 100% live in production */
      const rawRolloutCategoryIds =
        basePageProps.firebaseConfig?.configs[RemoteConfigs.VIP_ROLLOUT_CATEGORY_IDS.key].data
      const allowedRolloutCategoryIds =
        typeof rawRolloutCategoryIds === 'string' ? JSON.parse(rawRolloutCategoryIds ?? '').ids : []

      const categoryPath = category.category?.categoryPaths ?? []

      /**
       * It checks for the category itself or for its L1
       * ONLY in PROD
       *
       * If users try to access a v-view-details.html page on a category that shouldn't be displayed by NWA
       * then we will redirect them to the same path with a query parameter so the middleware takes them to FES
       *
       * This logic shouldn't happen locally, or when the user has manually added our internal testing cookies
       * */
      if (
        !req.cookies['kj-vip-beta'] &&
        process.env.NODE_ENV === 'production' &&
        !allowedRolloutCategoryIds.includes(listing.categoryId.toString()) &&
        !allowedRolloutCategoryIds.includes(categoryPath[0]?.id.toString())
      ) {
        return {
          redirect: {
            destination: `/v-view-details.html?adId=${listing.id}&rdr=true`,
            permanent: false,
          },
        }
      }
    }

    const today = new Date()
    /**
     * Redirect to SRP if:
     * - listing has been expired for at least 30 days or if it is not active
     * - listing has a status included in the list of redirects (i.e. DELETED)
     * */
    if (
      (listing?.status && STATUS_TO_REDIRECT.includes(listing.status)) ||
      (listing?.status === ListingStatus.Expired &&
        listing?.endDate &&
        differenceInDays(today, listing.endDate) >= 30)
    ) {
      const searchUrlInput = getSearchUrlVariablesFromListing(listing, RADIUS_SLIDER_HALF_POINT_KMS)
      const { data } = await profile(
        'vip',
        'getSearchUrl',
        getSearchUrl
      )(apolloClient, searchUrlInput.input)
      const redirectUrl = new URL(data.searchUrl ?? searchUrlInput.fallbackUrl)

      /** Add query parameter to redirect url if the ad was deleted */
      if (DELETED_STATUS.includes(listing.status)) {
        redirectUrl.searchParams.append('adRemoved', listingId)
      }

      return { redirect: { destination: redirectUrl.toString(), permanent: false } }
    }

    /**
     * Ensure profile is available form the server as it is needed for tracking
     * ideally the userType information necessary to populate the datalayer would be part of the listing response
     * */
    if (listing.posterInfo.posterId) {
      await profile(
        'vip',
        'getProfile',
        getProfile
      )(apolloClient, { profileId: listing.posterInfo.posterId, size: LogoType.ViewAd })
    }

    /** Fetch similar listings to show on page load if listing has been expired for less than 30 days */
    if (listing?.status === ListingStatus.Expired) {
      await profile(
        'vip',
        'getListingSimilar',
        getListingSimilar
      )({ apolloClient, listingId, limit: MAX_EVIP_LISTINGS })
    }
  } catch (err) {
    sendToLogger(err, { fingerprint: ['vip-server'] })

    const props: ViewItemPageProps = {
      ...basePageProps,
      listingId,
      isExternalId,
      serverFailedToFetch: true,
    }
    return { props: addApolloState(apolloClient, props) }
  }

  const props: ViewItemPageProps = { ...basePageProps, isExternalId, listingId, correlationId }
  return { props: addApolloState(apolloClient, props) }
}

export default ViewItemPage
