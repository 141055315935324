import VisibleIcon from '@kijiji/icons/src/icons/Visible'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { Badge, BadgeSize } from '@/ui/atoms/badge'

type ListingViewsProps = {
  /**
   * The number of views to display in the badge
   */
  views?: number | null

  /**
   * Whether the listing is a PintAd
   */
  isPintAd: boolean

  /**
   * Whether the listing is an MoVe listing
   */
  isMoveListing: boolean
}

/**
 * A badge that displays the number of views for a VIP listing.
 */
export const ListingViews: FC<ListingViewsProps> = ({ views, isMoveListing, isPintAd }) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])

  // The badge should not be displayed on these conditions
  if (isMoveListing || isPintAd || !views) {
    return null
  }

  return (
    <Badge
      label={t('listing:views_badge', { count: views })}
      leftIcon={<VisibleIcon size="xsm" aria-hidden />}
      backgroundColor={colors.grey.light5}
      color={colors.grey.primary}
      size={BadgeSize.XLARGE}
      gap={spacing.defaultSmall}
      isBorderless
    />
  )
}
