import styled from 'styled-components'

export const VipPriceInsightDisclaimer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.mini};
  row-gap: ${theme.spacing.defaultSmall};
`
)
