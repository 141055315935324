import { useGetCategoryPathQuery } from '@kijiji/generated/graphql-types'
import { breadcrumbsSeo } from '@kijiji/seo/configs'
import Head from 'next/head'
import qs from 'query-string'
import { type FC, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { Breadcrumbs } from '@/components/shared/breadcrumbs/Breadcrumbs'
import { getBreadcrumbs } from '@/domain/breadcrumbs'
import { isValidListingId } from '@/domain/listings/isValidListingId'
import { getAreaQueryParamsForSrpUrl } from '@/domain/srp/addUserLocationToUrl'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useLocale } from '@/hooks/useLocale'
import { type VipQueryListing } from '@/types/listings'
import { Spacing } from '@/ui/atoms/spacing'

type VipBreadcrumbsProps = {
  adId: string
  categoryId: number
  location?: VipQueryListing['location']
}

export const VipBreadcrumbs: FC<VipBreadcrumbsProps> = ({ adId, categoryId, location }) => {
  const { location: userLocation } = useGetLocation()
  const { cookieLocale, routeLocale } = useLocale()
  const { spacing } = useTheme()

  const { data, loading } = useGetCategoryPathQuery({
    variables: { categoryId, locationId: location?.id, locale: cookieLocale },
  })

  const getNumberFromString = useMemo(() => {
    const { listingNumber } = isValidListingId(adId)
    return listingNumber
  }, [adId])

  const category = data?.category

  /**
   * Since the category path doesn't accept a location area we need to append it if the location is not in a region
   *
   * The location fetched for the SEO route needs to be the listing location,
   * but the area location should be the user so the global location doesn't change when user is navigating from VIP to SRP
   */
  const locationAreaParams = getAreaQueryParamsForSrpUrl(userLocation)
  const categoryPaths = category?.categoryPaths?.map((path) => {
    const url = path?.searchSeoUrl
    if (!path || !url) return path

    return { ...path, searchSeoUrl: qs.stringifyUrl({ url, query: locationAreaParams }) }
  })

  const breadcrumbs = category
    ? getBreadcrumbs({
        activeCategory: {
          ...category,
          categoryPaths,
          /** The last category in the path is the active one */
          searchSeoUrl: categoryPaths?.at(-1)?.searchSeoUrl,
        },
        /** It should take only number portion of the ID */
        adId: getNumberFromString,
        locationName: location?.name ?? '',
        routeLocale,
      })
    : []

  const breadcrumbsJsonLd = breadcrumbsSeo(breadcrumbs)

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbsJsonLd) }}
        />
      </Head>

      <Spacing mTop={spacing.default} mBottom={spacing.default}>
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          loading={loading}
          pageName="vip"
          shouldPreventLineWrap
        />
      </Spacing>
    </>
  )
}
