import { isAnyCarsVehiclesCategory, isAnyRealEstateCategory } from '@kijiji/category'

import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { type AttributeConfig } from '@/features/attributes/constants/attributesConfig'
import { ATTRIBUTES_LABEL_OVERRIDE } from '@/features/attributes/constants/attributesLabelOverride'

/**
 * Some categories will have attributes that need to be displayed in a different way as if they were featured attributes.
 * This is the case for attributes that are not part either of the core attributes or the attribute groups.
 *
 * These attributes are statically defined in the FE.
 */

const labelOverride = ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME

const FEATURED_ATTRIBUTES_REAL_ESTATE: AttributeConfig = {
  [ATTRIBUTES.CONCIERGE]: { labelOverride },
  [ATTRIBUTES.GYM]: { labelOverride },
  [ATTRIBUTES.POOL]: { labelOverride },
  [ATTRIBUTES.TWENTY_FOUR_HOUR_SECURITY]: { labelOverride },
  [ATTRIBUTES.BICYCLE_PARKING]: { labelOverride },
  [ATTRIBUTES.ELEVATOR]: { labelOverride },
  [ATTRIBUTES.STORAGE_LOCKER]: { labelOverride },
}

const FEATURED_ATTRIBUTES_AUTOS: AttributeConfig = {
  [ATTRIBUTES.ADJUSTABLE_LEVERS]: { labelOverride },
  [ATTRIBUTES.ADJUSTABLE_SUSPENSION]: { labelOverride },
  [ATTRIBUTES.AIR_CONDITIONING]: { labelOverride },
  [ATTRIBUTES.ALLOY_WHEELS]: { labelOverride },
  [ATTRIBUTES.AMENITIES]: { labelOverride },
  [ATTRIBUTES.APPLIANCES]: { labelOverride },
  [ATTRIBUTES.AUDIO_SYSTEM]: { labelOverride },
  [ATTRIBUTES.BLUETOOTH]: { labelOverride },
  [ATTRIBUTES.CARGO_RACKS]: { labelOverride },
  [ATTRIBUTES.CRUISE_CONTROL]: { labelOverride },
  [ATTRIBUTES.DUAL_CHANNEL_ABS]: { labelOverride },
  [ATTRIBUTES.ELECTRONIC_REVERSE_CONTROL]: { labelOverride },
  [ATTRIBUTES.ELECTRONIC_SUSPENSION_SYSTEM]: { labelOverride },
  [ATTRIBUTES.ENTERTAINMENT_SYSTEM]: { labelOverride },
  [ATTRIBUTES.FIRE_EXTINGUISHER]: { labelOverride },
  [ATTRIBUTES.GENERATOR]: { labelOverride },
  [ATTRIBUTES.GPS]: { labelOverride },
  [ATTRIBUTES.HAZARD_LIGHTS]: { labelOverride },
  [ATTRIBUTES.HEATED_SEATS]: { labelOverride },
  [ATTRIBUTES.HIGHWAY_PEGS]: { labelOverride },
  [ATTRIBUTES.INTERCOM]: { labelOverride },
  [ATTRIBUTES.LED_LIGHTS]: { labelOverride },
  [ATTRIBUTES.LIFE_RAFT]: { labelOverride },
  [ATTRIBUTES.LIQUID_COOLING]: { labelOverride },
  [ATTRIBUTES.LUGGAGE_RACK]: { labelOverride },
  [ATTRIBUTES.NAV_SYSTEM]: { labelOverride },
  [ATTRIBUTES.NAVIGATION_SYSTEM]: { labelOverride },
  [ATTRIBUTES.PARKING_ASSIST]: { labelOverride },
  [ATTRIBUTES.PASSENGER_ARMRESTS]: { labelOverride },
  [ATTRIBUTES.PUSH_BUTTON_START]: { labelOverride },
  [ATTRIBUTES.REAR_LIFT_PREVENTION]: { labelOverride },
  [ATTRIBUTES.REVERSE_GEAR]: { labelOverride },
  [ATTRIBUTES.SATELLITE_RADIO]: { labelOverride },
  [ATTRIBUTES.STEREO]: { labelOverride },
  [ATTRIBUTES.STORAGE]: { labelOverride },
  [ATTRIBUTES.SUNROOF]: { labelOverride },
  [ATTRIBUTES.TOUCH_SCREEN]: { labelOverride },
  [ATTRIBUTES.TRAILER_HITCH]: { labelOverride },
  [ATTRIBUTES.TRAILER_INCLUDED]: { labelOverride },
  [ATTRIBUTES.WINCH]: { labelOverride },
  [ATTRIBUTES.WIRELESS_CHARGING]: { labelOverride },
}

/**
 * Gets the featured attributes configuration for a given category ID
 * @param categoryId - The ID of the category to get featured attributes for
 * @returns The featured attributes configuration for the category, or undefined if none exist
 */
export const getFeaturedAttrByCategoryId = (categoryId: number): AttributeConfig | undefined => {
  if (isAnyRealEstateCategory(categoryId)) {
    return FEATURED_ATTRIBUTES_REAL_ESTATE
  }

  if (isAnyCarsVehiclesCategory(categoryId)) {
    return FEATURED_ATTRIBUTES_AUTOS
  }
}

/**
 * Gets the translation key for the featured attributes title for a given category ID
 * @param categoryId - The ID of the category to get the title key for
 * @returns The translation key for the featured attributes title
 */
export const getFeaturedAttributesTitleKey = (categoryId: number): string => {
  if (isAnyRealEstateCategory(categoryId)) {
    return 'vip:featured_attributes.real_estate.title'
  }

  return 'vip:featured_attributes.default.title'
}
