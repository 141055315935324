import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { hexToRgb } from '@/ui/helpers/hexToRgb'

export const FadeOutWrapper = styled.div<{
  isExpanded: boolean
  maxSectionHeight: number
  isMobileOnly?: boolean
}>(
  ({ theme, isExpanded, maxSectionHeight, isMobileOnly }) => `
  max-height: ${isExpanded ? 'fit-content' : `${maxSectionHeight}rem`};
  overflow: hidden;
  position: relative;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium}{
    max-height: ${isExpanded || isMobileOnly ? 'fit-content' : `${maxSectionHeight}rem`};
  }
`
)

export const FadeOutOverlay = styled.div<{
  sectionColor?: string
  isMobileOnly?: boolean
  disableShowMoreButton?: boolean
}>(
  ({ theme, isMobileOnly, disableShowMoreButton, sectionColor = theme.colors.white }) => `
  background: linear-gradient(0deg,${sectionColor} 50%,${hexToRgb(sectionColor, 0)} 100%);
  bottom: 0;
  max-height: ${disableShowMoreButton ? '3rem' : '8rem'};
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    display: ${isMobileOnly ? 'none' : 'block'};
  }
`
)

export const FadeOutButtonWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing.default};

  ${MEDIA_QUERIES(theme).medium}{
    justify-content: flex-start;  
  }
`
)

export const ButtonDividerWrapper = styled.div<{ isExpanded: boolean; isMobileOnly?: boolean }>(
  ({ theme, isExpanded, isMobileOnly }) => `
  align-items: center;
  background-color: ${theme.colors.white};
  bottom: 0;
  display: flex;
  gap: ${theme.spacing.default};
  justify-content: center;
  position: ${isExpanded ? 'relative' : 'absolute'};
  width: 100%;

  ${isExpanded ? `margin-top: ${theme.spacing.defaultSmall};` : ''}

  & button {
    flex-shrink: 0;
  }

  ${MEDIA_QUERIES(theme).medium} {
    display: ${isMobileOnly ? 'none' : 'flex'};
    justify-content: start;
  }
`
)
