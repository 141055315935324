import { HTMLAttributes } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { StarMeter, StarMeterProps } from '@/ui/atoms/star-meter'
import { TextLink } from '@/ui/atoms/text-link'

import { RatingContainer } from './styled'

export type RatingProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Accessibility label to be used for the total ratings link
   */
  accessibilityLabel?: string

  /**
   * Function to handle the click event on the review url
   */
  handleReviewUrlClick?: () => void

  /**
   * Defines if the data to populate this component is loading - It will load the loading version
   */
  isLoading?: boolean

  /**
   * URL to the reviews page
   */
  shouldOpenInNewTab?: boolean

  /**
   * URL to the reviews page
   */
  reviewUrl?: string

  /**
   * Label for the total number of reviews
   */
  reviewsLabel: string

  /**
   * Score out of 5, used to calculate progress and displayed to the user
   */
  score: number

  /**
   * Size of the rating component to determine the layout
   */
  size?: StarMeterProps['size']
}

/**
 * Rating component that displays user score, rating, and total number of reviews
 */
export const Rating = ({
  accessibilityLabel,
  handleReviewUrlClick,
  isLoading,
  shouldOpenInNewTab = false,
  reviewsLabel,
  reviewUrl,
  score,
  size = 'medium',
}: RatingProps) => {
  const theme = useTheme()
  const isLarge = size === 'large'
  const target = shouldOpenInNewTab ? '_blank' : '_self'

  if (score < 0 || score > 5) {
    throw new Error('Rating score must be between 0 and 5')
  }

  return (
    <RatingContainer data-testid="star-rating">
      <StarMeter
        aria-describedby="score totalReviews"
        isLoading={isLoading}
        score={score}
        size={size}
      />

      {isLoading ? null : (
        <>
          <BodyText
            color={theme.colors.grey.primary}
            id="score"
            weight="bold"
            size={isLarge ? 'xLarge' : 'small'}
          >
            {score.toFixed(1)}
          </BodyText>

          {reviewUrl ? (
            <TextLink
              href={reviewUrl}
              id="totalReviews"
              onClick={handleReviewUrlClick}
              size={isLarge ? 'medium' : 'small'}
              variant="secondary"
              target={target}
              aria-label={
                accessibilityLabel
                  ? `${reviewsLabel} ${accessibilityLabel}`
                  : undefined
              }
            >
              ({reviewsLabel})
            </TextLink>
          ) : (
            <BodyText
              color={theme.colors.grey.light2}
              id="totalReviews"
              size={isLarge ? 'medium' : 'small'}
            >
              {reviewsLabel}
            </BodyText>
          )}
        </>
      )}
    </RatingContainer>
  )
}
