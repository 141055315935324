import styled from 'styled-components'

import { VipContainer } from '@/components/vip/styled'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const VipStickyHeaderConstraints = styled(VipContainer)<{ isVisible: boolean }>(
  ({ theme, isVisible }) => `
  left: 0;
  padding: 0;
  position: fixed;
  top: ${isVisible ? '0' : '-20rem'};
  transition: top 0.3s ease-in-out;
  width: 100%;
  z-index: ${zIndexRegistry.VIP_STICKY_HEADER};
  background: ${theme.colors.white};

  & > div {
    max-width: unset;
  }

  ${MEDIA_QUERIES(theme).large} {
    left: unset;
    padding-left: 0;
    width: calc(100% - ${theme.spacing.husky});

    & > div {
      max-width: calc(100% - ${theme.spacing.xLarge});
    }
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    & > div {
      max-width: unset;
    }
  }
`
)

export const VipStickyHeaderContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  background: ${theme.colors.white};
  border-bottom: .1rem solid ${theme.colors.grey.light4};
  display: flex;
  gap: ${theme.spacing.mini};
  height: fit-content;
  padding: ${theme.spacing.defaultSmall} 0;
  position: sticky;
  top: 0;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium}{
    gap: ${theme.spacing.defaultSmall};
    padding: ${theme.spacing.default};
  }

  ${MEDIA_QUERIES(theme).large}{
    padding: ${theme.spacing.default} 0;
  }
`
)

export const VipStickyHeaderImageContainer = styled.div(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  height: 3.6rem;
  width: 5.2rem;
`
)

export const VipStickyHeaderTitle = styled(HeadlineText)`
  align-self: center;
  flex: 1;
  justify-self: flex-start;
  text-align: start;
  max-width: 29rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const VipStickyHeaderFlexDividers = styled(Flex)(
  ({ theme }) => `
  gap: ${theme.spacing.defaultSmall};
`
)

/** VIP Sticky Bottom */

export const VipStickyBottomSection = styled.div(
  ({ theme }) => `
  background: ${theme.colors.white};
  border: .1rem solid ${theme.colors.grey.light4};
  bottom: 0;
  box-shadow: ${theme.boxShadow['shadow-2']};
  display: flex;
  gap: ${theme.spacing.default};
  padding: ${theme.spacing.defaultSmall} ${theme.spacing.default};
  position: fixed;
  width: 100%;
  z-index: ${zIndexRegistry.MAIN_MENU};

  ${MEDIA_QUERIES(theme).medium} {
    display: none;
  }
`
)
