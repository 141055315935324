import { IconProps } from '@kijiji/icons/src/components/Icon'
import { ButtonHTMLAttributes, FC, LiHTMLAttributes, useCallback } from 'react'
import { AnchorHTMLAttributes } from 'react'

import { DropdownListItem, DropdownListItemIconWrapper } from './styled'

// Omit onClick to prevent misuse, onClick should be handled by inner element
type ListItemProps = Omit<LiHTMLAttributes<HTMLLIElement>, 'onClick'>
type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

type CommonProps = ListItemProps & {
  /**
   * Text to display in the dropdown item
   */
  label: string

  /**
   * Icon to display in the dropdown item
   */
  icon?: FC<IconProps>

  /**
   * Specifies a background color for the icon
   */
  iconBackground?: string

  /**
   * Whether to show a divider below the item
   */
  dividerAfter?: boolean
}

type DropdownItemAsButton = CommonProps & {
  /**
   * Type of the inner element
   */
  as: 'button'

  /**
   * Optional click handler
   */
  onClick?: ButtonProps['onClick']

  /**
   * Props to be passed to the inner button element
   */
  innerProps?: Omit<ButtonProps, 'onClick'>
}

type DropdownItemAsAnchor = CommonProps & {
  /**
   * Type of the inner element
   */
  as: 'a'

  /**
   * URL to navigate to
   */
  href: AnchorProps['href']

  /**
   * Optional click handler
   */
  onClick?: AnchorProps['onClick']

  /**
   * Props to be passed to the inner anchor element
   */
  innerProps?: Omit<AnchorProps, 'href' | 'onClick'>
}

type DropdownItemProps = DropdownItemAsButton | DropdownItemAsAnchor

/**
 * DropdownItem component renders a list item with either an anchor or button element.
 * It displays an optional icon alongside a label.
 *
 * Usage examples:
 * ```jsx
 * <DropdownItem as="a" href="#" innerProps={{ rel: 'noreferrer' }} label="Kijiji" />
 * <DropdownItem as="button" label="Kijiji 2" disableCloseOnClick />
 * ```
 *
 * `innerProps` will adjust as per the `as` prop to be passed to the inner element.
 */
export const DropdownItem: FC<DropdownItemProps> = (props) => {
  /**
   * Helper function to determine if the inner element is an anchor
   */
  const isAnchor = (props: DropdownItemProps): props is DropdownItemAsAnchor =>
    props.as === 'a'

  /**
   * Helper function to render the label with an optional icon
   */
  const renderLabel = (
    Icon: DropdownItemProps['icon'],
    label: DropdownItemProps['label']
  ) => {
    return (
      <>
        {Icon ? (
          <DropdownListItemIconWrapper backgroundColor={props.iconBackground}>
            <Icon aria-hidden />
          </DropdownListItemIconWrapper>
        ) : null}
        {label}
      </>
    )
  }

  /**
   * Helper function to handle the click event, added for future extensibility.
   * Doesn't do anything special at the moment.
   */
  const handleClick = useCallback(
    <T,>(event: React.MouseEvent<T>, f?: React.MouseEventHandler<T>) => {
      f?.(event)
    },
    []
  )

  /**
   * I wish there was a better way to do this.. but due to the spread operation
   * on the props, we need to destructure the props based on the type of the
   * inner element.
   *
   * Long live TypeScript!
   */
  if (isAnchor(props)) {
    const {
      label,
      icon,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      as,
      onClick,
      href,
      innerProps,
      dividerAfter,
      ...liProps
    } = props
    return (
      <DropdownListItem
        $dividerAfter={dividerAfter}
        {...liProps}
        role="menuitem"
      >
        <a href={href} onClick={(e) => handleClick(e, onClick)} {...innerProps}>
          {renderLabel(icon, label)}
        </a>
      </DropdownListItem>
    )
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { label, icon, as, onClick, innerProps, dividerAfter, ...liProps } =
      props
    return (
      <DropdownListItem
        $dividerAfter={dividerAfter}
        {...liProps}
        role="menuitem"
      >
        <button
          type="button"
          onClick={(e) => handleClick(e, onClick)}
          {...innerProps}
        >
          {renderLabel(icon, label)}
        </button>
      </DropdownListItem>
    )
  }
}
