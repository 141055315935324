import React, { useEffect, useMemo } from 'react'

import { type DrawerProps } from './Drawer'

type UseKeyboardInteractionsProps = Pick<DrawerProps, 'isOpen' | 'onCancel'> & {
  portalRef: React.MutableRefObject<HTMLElement | null>
}

export const useKeyboardInteractions = ({
  isOpen,
  onCancel,
  portalRef,
}: UseKeyboardInteractionsProps) => {
  const nodeElements = portalRef.current?.querySelectorAll<HTMLElement>(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  )
  const [firstElement, lastElement] = useMemo(
    () => [nodeElements?.[0], nodeElements?.[nodeElements.length - 1]],
    [nodeElements]
  )

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onCancel()
    }

    if (event.key === 'Tab') {
      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault()
        lastElement?.focus()
      } else if (!event.shiftKey && document.activeElement === lastElement) {
        event.preventDefault()
        firstElement?.focus()
      }
    }
  }

  useEffect(() => {
    if (!isOpen) return

    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [isOpen])
}
