import { type UserStatus } from 'next-auth'
import { getSession, signIn } from 'next-auth/react'

import { isUserAuthenticated } from '@/features/auth/constants/user'

/**
 * The user status might changed between the time the user clicked the button and the time
 * they load the page. We will *try* to refresh the session if it does change.
 *
 * This is a temporary fix until we get refreshing built into our Apollo middleware.
 */
export const refreshSession = async (status: UserStatus) => {
  if (isUserAuthenticated(status)) {
    const currentSession = await getSession()
    if (!currentSession) {
      await signIn('refresh', { redirect: false })
      // I could add checks to see if refresh failed, but.. overkill for now? 🤷‍♂️
    }
  }
}
