/**
 * Verify if format of listingId is valid
 * This function doesn't check if the listing actually exists
 */
export const isValidListingId = (
  listingId: string
): { isValid: boolean; listingNumber: number } => {
  const isNumberRegex = /^\d+$/

  if (listingId === '0') return { isValid: false, listingNumber: 0 }

  /** It is a valid listing ID if is just a number */
  if (isNumberRegex.exec(listingId)) return { isValid: true, listingNumber: parseInt(listingId) }

  /**
   * If there are any new integrations, it should be added to this array
   * */
  const validPrefixes = ['m']

  const prefix = validPrefixes.find((prefix) => listingId.startsWith(prefix))
  if (!prefix) return { isValid: false, listingNumber: 0 }

  /**
   * If the listing has one of the valid prefixes, check if the rest of the string is a number
   */
  const restOfId = listingId.slice(prefix.length)
  const isNumber = isNumberRegex.test(restOfId)

  return { isValid: isNumber, listingNumber: isNumber ? parseInt(restOfId) : 0 }
}
