import { VirtualTourIcon } from '@kijiji/icons/src/icons'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ListingVirtualTourThumbnailContainer } from '@/components/vip/vip-gallery/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ListingImageType } from '@/features/listing/types/listings'
import {
  ListingThumbnailButton,
  ListingThumbnailImage,
  ListingThumbnailOverlay,
} from '@/features/listing-gallery/components/styled'

export type ListingVirtualTourThumbnailProps = {
  onClick: (index: number, imageType: ListingImageType) => void
  imageUrls: string[]
  galleryItemIndex: number
}

export const ListingVirtualTourThumbnail: FC<ListingVirtualTourThumbnailProps> = ({
  galleryItemIndex,
  imageUrls,
  onClick,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const hasImages = imageUrls.length > 0

  const handleClick = () => {
    /**
     * Anytime the user clicks on the expanded block is to open the gallery
     * on the last index as Virtual Tour will be the last item in the gallery
     *
     **/
    onClick(galleryItemIndex, ListingImageType.TOUR)
  }

  return (
    <ListingThumbnailButton
      onClick={handleClick}
      data-testid="listing-thumbnail-virtual-tour"
      aria-label={t('listing:virtual_tour.title')}
    >
      <ListingVirtualTourThumbnailContainer>
        {hasImages ? (
          <>
            <ListingThumbnailOverlay data-testid="listing-thumbnail-overlay">
              <VirtualTourIcon color={colors.white} size="md" />
            </ListingThumbnailOverlay>
            <ListingThumbnailImage src={imageUrls[0]} data-testid="gallery-thumbnail" alt="" />
          </>
        ) : (
          <VirtualTourIcon color={colors.white} size="md" />
        )}
      </ListingVirtualTourThumbnailContainer>
    </ListingThumbnailButton>
  )
}
