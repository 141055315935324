import { type PaymentEstimatorFormValues } from '@/features/payment-estimator/types/paymentEstimator'
import { centsToDollars } from '@/utils/price'

/**
 * Converts the monetary fields in the payment estimator form from cents to dollars.
 *
 * @param formValues - The form values containing amounts in cents.
 * @returns A new object with the down payment and trade-in value converted to dollars.
 */
export const convertFormValuesToDollars = (
  formValues: PaymentEstimatorFormValues
): PaymentEstimatorFormValues => ({
  ...formValues,
  downPayment: centsToDollars(formValues.downPayment),
  tradeIn: centsToDollars(formValues.tradeIn),
})
