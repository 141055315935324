import { type EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { type FC, type ReactNode, useEffect, useState } from 'react'

import {
  GalleryMobileCarouselContainer,
  GalleryMobileCarouselList,
  GalleryMobileCarouselSlide,
  GalleryMobileCarouselViewport,
} from '@/components/vip/vip-gallery/styled'
import { VipGalleryMobileBadge } from '@/components/vip/vip-gallery/VipGalleryMobileBadge'

export type VipGalleryMobileCarouselProps = {
  /**
   * name for generating unique keys
   */
  name: string
  /**
   * Tiles/Slides to be displayed in the carousel
   */
  slides: ReactNode[]

  /**
   * Specifies if Virtual Tour is available
   */
  isVirtualTourAvailable?: boolean
}

export const VipGalleryMobileCarousel: FC<VipGalleryMobileCarouselProps> = ({
  isVirtualTourAvailable,
  name,
  slides,
}) => {
  const showcaseOptions: EmblaOptionsType = {
    active: true,
    containScroll: 'trimSnaps',
    dragFree: false,
    dragThreshold: 1,
    inViewThreshold: 1,
    skipSnaps: true,
    slidesToScroll: 1,
  }

  const [emblaRef, emblaApi] = useEmblaCarousel(showcaseOptions)
  const [currentSlideInView, setCurrentSlideInView] = useState<number>(1)

  useEffect(() => {
    if (!emblaApi) return

    // hard reset carousel for options changes
    emblaApi.reInit()
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return

    const onSelect = () => {
      const slidePosition = emblaApi.selectedScrollSnap() + 1
      setCurrentSlideInView(slidePosition)
    }

    /** On change listener*/
    emblaApi.on('select', onSelect)
    /** On Load */
    onSelect()

    return () => {
      emblaApi.off('select', onSelect)
    }
  }, [emblaApi])

  return (
    <GalleryMobileCarouselContainer>
      <GalleryMobileCarouselViewport ref={emblaRef}>
        <GalleryMobileCarouselList>
          {slides.map((child, index) => (
            <GalleryMobileCarouselSlide key={`${name}-slide-${index}`}>
              {child}
            </GalleryMobileCarouselSlide>
          ))}
        </GalleryMobileCarouselList>
      </GalleryMobileCarouselViewport>

      <VipGalleryMobileBadge
        isVirtualTourAvailable={isVirtualTourAvailable}
        imageCount={slides.length}
        position={currentSlideInView}
      />
    </GalleryMobileCarouselContainer>
  )
}
