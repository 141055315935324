import { isAnyCarsVehiclesCategory, isAnyRealEstateCategory } from '@kijiji/category'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { VipSectionTitle } from '@/components/vip/shared/vip-section-title/VipSectionTitle'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { VipAdditionalOptionsContainer } from '@/components/vip/vip-additional-options/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { filterNullAttributes } from '@/features/attributes/utils/filterNullAttributes'
import { getAdditionalOptionsToDisplay } from '@/features/attributes/utils/getDisplayableAttributes'
import { type VipQueryAttributeAll } from '@/types/listings'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'

type VipAdditionalOptionsProps = {
  categoryId: number
  attributes: VipQueryAttributeAll
}

export const VipAdditionalOptionsSection: FC<VipAdditionalOptionsProps> = ({
  attributes,
  categoryId,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)

  /**
   * This section should not be visible for Autos or RE categories
   * or when there are no additional options available
   */
  if (isAnyCarsVehiclesCategory(categoryId) || isAnyRealEstateCategory(categoryId)) return null

  const filteredAttributes = filterNullAttributes(attributes)
  const additionalOptions = getAdditionalOptionsToDisplay(t, filteredAttributes)

  if (!additionalOptions.length) return null

  return (
    <VipSectionContainer>
      <VipSectionTitle title={t('listing:additional_options.title')} />

      <Spacing pBottom={spacing.default}>
        <BodyText size="medium" color={colors.grey.primary}>
          {t('listing:additional_options.description')}
        </BodyText>
      </Spacing>

      <VipAdditionalOptionsContainer>
        {additionalOptions.map((item, index) => {
          const { Icon, label } = item

          return (
            <Flex alignItems="center" gap={spacing.mini} key={`additional-options-${index}`}>
              <Icon aria-hidden />
              <BodyText size="medium" color={colors.grey.primary}>
                {label}
              </BodyText>
            </Flex>
          )
        })}
      </VipAdditionalOptionsContainer>
    </VipSectionContainer>
  )
}
