import FlagIcon from '@kijiji/icons/src/icons/Flag'
import { useTranslation } from 'next-i18next'
import { type FC, type MouseEventHandler } from 'react'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { StyledReportButton } from '@/features/listing/components/report/styled'

type ReportListingProps = {
  /**
   * Callback to handle the click event.
   * TODO: this should be a MouseEventHandler<HTMLButtonElement>, but types break
   */
  onClick: MouseEventHandler
}

/**
 * Button to report a listing.
 */
export const ListingReportButton: FC<ReportListingProps> = ({ onClick }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.VIP])

  return (
    <StyledReportButton forwardedAs="button" onClick={onClick} variant="secondary" size="small">
      <FlagIcon size="xsm" aria-hidden />
      {t('listing:report.button')}
    </StyledReportButton>
  )
}
