import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { Badge, BadgeSize } from '@/ui/atoms/badge'

export type RentalBadgeProps = {
  rentalBadge?: string
}

export const RentalBadge: FC<RentalBadgeProps> = ({ rentalBadge }) => {
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { colors } = useTheme()
  if (!rentalBadge) return null

  return (
    <Badge
      borderRadius="rounded"
      borderColor={colors.grey.light4}
      backgroundColor={colors.white}
      color={colors.grey.primary}
      data-testid="rental-badge"
      label={t(`listing:rental_badge.label.${rentalBadge}`)}
      size={BadgeSize.XLARGE}
    />
  )
}
