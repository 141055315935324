import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const VipActionButtonsWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.default};
  
  & div > button {
    padding: 0;
  }

  ${MEDIA_QUERIES(theme).medium}{
    & div > button {
      padding: ${theme.spacing.defaultSmall};
    }
  }
`
)
