/**
 * Appends referral parameters to the given URL if none of the referral parameters already exist.
 *
 * @param url - The URL to which the referral parameters should be appended.
 * @returns - The updated URL with the referral parameters appended, if applicable.
 *
 * @example
 * const originalUrl = 'https://example.com/product?existing_param=value';
 * const updatedUrl = appendReferral(originalUrl);
 * console.log(updatedUrl);
 * // Output: "https://example.com/product?existing_param=value&utm_campaign=kijiji_vdp&utm_medium=referral&utm_source=kijiji"
 *
 * @example
 * const urlWithOneParam = 'https://example.com/product?utm_campaign=kijiji_vdp';
 * const updatedUrlWithOneParam = appendReferral(urlWithOneParam);
 * console.log(updatedUrlWithOneParam);
 * // Output: "https://example.com/product?utm_campaign=kijiji_vdp"
 */

export const appendReferralIfNeeded = (url: string): string => {
  const urlObj = new URL(url)
  const params = urlObj.searchParams

  const referralParams = {
    utm_campaign: 'kijiji_vdp',
    utm_medium: 'referral',
    utm_source: 'kijiji',
  }

  const hasAnyReferralParam = Object.keys(referralParams).some((key) => params.has(key))

  if (!hasAnyReferralParam) {
    Object.entries(referralParams).forEach(([key, value]) => {
      params.append(key, value)
    })
  }

  return urlObj.toString()
}
