import {
  type ListingAttributeV2,
  AdSource,
  LogoType,
  useGetProfileQuery,
  useLivePersonGetIDsQuery,
  useLivePersonSendVipDataMutation,
  useVehicleReportQuery,
} from '@kijiji/generated/graphql-types'
import Head from 'next/head'
import Script from 'next/script'
import { useTranslation } from 'next-i18next'
import { type FC, useEffect, useState } from 'react'

import { ChatButtonContainer } from '@/features/liveperson/components/styled'
import { transformLivePersonVipData } from '@/features/liveperson/utils/transformLivePersonVipData'
import { useLocale } from '@/hooks/useLocale'

export type LivePersonProps = {
  address: string
  attributes: ListingAttributeV2[]
  chatOptIn: boolean
  creditOptIn: boolean
  tradeInOptIn: boolean
  listingId: string
  sellerId?: string
}

export const LivePerson: FC<LivePersonProps> = ({
  address,
  attributes,
  chatOptIn,
  creditOptIn,
  tradeInOptIn,
  listingId,
  sellerId,
}) => {
  const [conversationId, setConversationId] = useState<string | null>(null)

  const [lpTagState, setLPTagState] = useState<string | null>(null)

  const { cookieLocale } = useLocale()
  const { t } = useTranslation('listing')

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    // Check if the LivePerson tag has loaded.
    // Insert the legal disclaimer into the chat bot window.
    const handleStateChange = async ({ state }: { state: string }) => {
      setLPTagState(state)

      if (state === 'waiting' || state === 'interactive') {
        // We set a short time out to ensure that the chat has loaded.
        timeoutId = setTimeout(() => {
          // At the top of every chat, LivePerson has a line that says "Today" as a
          // timestamp (like in other messaging software). We're going to hijack that
          // and add a legal disclaimer.
          const todayElement =
            document.getElementById('lp_date_line_Today') ??
            document.getElementById('lp_date_line_Aujourd’hui')

          if (todayElement) {
            todayElement.innerHTML = t('listing:liveperson.disclaimer')
            // It's annoying that we have to use !important, but we're stuck with it
            // because LivePerson previously set bold !important.
            todayElement.setAttribute('style', 'font-weight: normal !important;')
          }
        }, 100)
      }
    }

    window.lpTag?.events?.bind?.('lpUnifiedWindow', 'state', handleStateChange)

    const handleConversationInfo = (conversationInfo: { conversationId?: string }) => {
      if (conversationInfo.conversationId) setConversationId(conversationInfo.conversationId)
    }

    // Get the conversation ID from LivePerson by listening for the
    // conversationInfo event.
    window.lpTag?.events?.bind?.('lpUnifiedWindow', 'conversationInfo', handleConversationInfo)

    // clean up listeners & timeout
    return () => {
      window.lpTag?.events?.unbind?.('lpUnifiedWindow', 'state', handleStateChange)
      window.lpTag?.events?.unbind?.('lpUnifiedWindow', 'conversationInfo', handleConversationInfo)
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [t])

  // Attach the locale callback to the window load event.
  useEffect(() => {
    // Set the locale for the LivePerson chat via their script.
    const sendLocaleToLP = () => {
      if (window.lpTag) {
        window.lpTag.sdes?.send?.({ type: 'personal', cookieLocale })
        window.lpTag.section = [cookieLocale]
      }
    }

    // If the user's locale changes, update the LivePerson chat locale.
    sendLocaleToLP()

    // Attach the locale callback to the window load event.
    window.addEventListener('load', sendLocaleToLP)
    return () => window.removeEventListener('load', sendLocaleToLP)
  }, [cookieLocale])

  // Get the bot ID and user platform ID from LivePerson.
  const { data: livePersonIDsData } = useLivePersonGetIDsQuery({
    skip: lpTagState !== 'interactive' || !conversationId,
    variables: { conversationId: conversationId ?? '' },
  })

  const botId = livePersonIDsData?.livePersonGetIDs?.botId ?? ''
  const userPlatformId = livePersonIDsData?.livePersonGetIDs?.userId ?? ''

  /* GET DATA FOR LIVEPERSON */

  /**
   * This data is already queried by other components in the page, so we can
   * just use the version from the cache rather than having to contact Anvil
   * again.
   */

  // Get the CARFAX report URL for the vehicle
  const vehicleReportData = useVehicleReportQuery({
    fetchPolicy: 'cache-only',
    variables: { adId: parseInt(listingId.replace('m', '')), source: AdSource.VerticalAutos },
  })
  let carfaxReportUrl = ''
  if (vehicleReportData.data?.ad && 'vehicleReport' in vehicleReportData.data.ad) {
    carfaxReportUrl = vehicleReportData.data.ad.vehicleReport?.reportUrl ?? ''
  }

  // Get the Dealership name
  const profileData = useGetProfileQuery({
    fetchPolicy: 'cache-only',
    variables: { profileId: sellerId ?? '', size: LogoType.ViewAd },
  })
  const dealershipName = profileData.data?.profile?.name ?? ''

  /* SEND DATA TO LIVEPERSON */

  const [livePersonSendVipData] = useLivePersonSendVipDataMutation()
  useEffect(() => {
    if (!botId || !conversationId || !userPlatformId) return

    const contextVariables = transformLivePersonVipData({
      attributes,
      sellerId: sellerId ?? '',
      carfaxReportUrl,
      dealershipName,
      address,
      creditOptIn,
      tradeInOptIn,
    })

    livePersonSendVipData({
      variables: {
        input: {
          // Because of the check above, none of these should be nullish but I
          // added fallbacks to '' to satisfy Typescript.
          botId,
          conversationId: conversationId ?? '',
          userId: userPlatformId,
          contextVariables,
        },
      },
    })
  }, [
    address,
    attributes,
    botId,
    carfaxReportUrl,
    conversationId,
    dealershipName,
    livePersonSendVipData,
    userPlatformId,
    sellerId,
    creditOptIn,
    tradeInOptIn,
  ])

  /* UI MODIFICATIONS */

  // Minimize the window before leaving the page.
  useEffect(() => {
    const minimizeLPChat = () => {
      ;(document.querySelector('.lpc_maximized-header__minimize-button') as HTMLElement)?.click()
    }
    window.addEventListener('beforeunload', minimizeLPChat)
    return () => window.removeEventListener('beforeunload', minimizeLPChat)
  }, [])

  // Make the tooltip appear after six seconds, then disappear again after
  // another 12 seconds. This was a requirement from UX/product.
  useEffect(() => {
    const showTooltip = () => {
      const tooltip = document.getElementById('lp_bubble_tooltip')
      if (tooltip) tooltip.style.display = 'flex'
    }

    const hideTooltip = () => {
      const tooltip = document.getElementById('lp_bubble_tooltip')
      if (tooltip) tooltip.style.display = 'none'
    }

    window.addEventListener('load', () => {
      setTimeout(showTooltip, 6000)
      setTimeout(hideTooltip, 18000)
    })

    return () => {
      window.removeEventListener('load', showTooltip)
      window.removeEventListener('load', hideTooltip)
    }
  }, [])

  /* SCRIPTS, STYLES, DIV FOR LIVEPERSON TO TARGET */

  // I'm checking this in VIP as well, but I want to be extra sure.
  if (!chatOptIn) {
    return null
  }

  return (
    <>
      <Head>
        {/**
         * Modify the style of elements in the LivePerson chat window.
         * Again, it's annoying that we have to use !important, but we have to
         * do it to override LivePerson's built-in styles.
         */}
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .lpc_window *, #lp_bubble_left_side_text {
            font-family: LarsseitRegular, Arial, sans-serif !important;
            font-weight: 400 !important;
          }

          .lpc_confirmation-dialog__confirm-button,
          .lpc_confirmation-dialog__cancel-button {
            color: white !important;
          }
        `,
          }}
        />
      </Head>
      {/**
       * This script is used to load the LivePerson chat button. Having to
       * include this much code ourselves is not ideal. I wish LivePerson could
       * host a script that we could include.
       */}
      <Script
        id="liveperson-script"
        data-testid="liveperson-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `window.lpTag=window.lpTag||{},'undefined'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:'7703733'||'',section:lpTag.section||'',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:'1.10.0',_tagCount:1,protocol:'https:',events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0===e?(this._defB=this._defB||[],this._defB.push(t)):1===e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+'//'+(this.ovr&&this.ovr.domain?this.ovr.domain:'lptag.liveperson.net')+'/tag/tag.js?site='+this.site);var o=document.createElement('script');o.setAttribute('charset',e?e:'UTF-8'),i&&o.setAttribute('id',i),o.setAttribute('src',n),document.getElementsByTagName('head').item(0).appendChild(o)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();var t=this;window.attachEvent?window.attachEvent('onload',function(){t._domReady('domReady')}):(window.addEventListener('DOMContentLoaded',function(){t._domReady('contReady')},!1),window.addEventListener('load',function(){t._domReady('domReady')},!1)),'undefined'===typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger('LPT','DOM_READY',{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;`,
        }}
      />
      {/**
       * LivePerson injects the chat button into this div.
       */}
      <ChatButtonContainer id="chatButton" data-testid="chatButton" />
    </>
  )
}
