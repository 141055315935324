import styled from 'styled-components'

import { ImageCustom } from '@/components/shared/image'
import { Spacing } from '@/ui/atoms/spacing'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const VipSectionTitleContainer = styled(Spacing)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${MEDIA_QUERIES(theme).large}{
    margin-bottom: ${theme.spacing.default};
  }
`
)

export const VipSectionTitleImage = styled(ImageCustom)(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  max-height: 6rem;
  max-width: 12rem;
  object-fit: contain;
`
)
