import { ClientRender } from '@/components/shared/client-render'
import { GptLeader } from '@/components-page/vip/advertisement/GptLeader'
import { GptPosted } from '@/components-page/vip/advertisement/GptPosted'
import { GptR2S } from '@/components-page/vip/advertisement/GptR2S'
import { GptLeaderWrapper } from '@/components-page/vip/advertisement/styled'

type GptLeaderProps = {
  isFormSubmitSuccess: boolean
  /**
   * Specifies if the ad was just posted and user was redirected to this page
   * It will be defined by a query parameter used to show a system message
   */
  isPostedListing?: boolean
}

/**
 * Displays either the GptLeader or GptR2S ad slot,
 * based on whether the R2S form is has been submitted successfully
 */
export const TopBannerSlots = ({ isFormSubmitSuccess, isPostedListing }: GptLeaderProps) => {
  /* Only the GptLeader should not show up on mobile */
  return (
    <GptLeaderWrapper shouldShowOnMobile={isPostedListing || isFormSubmitSuccess}>
      <ClientRender>
        {/* must apply min-height outside of ClientRender to prevent layout shift */}
        {isPostedListing ? <GptPosted /> : isFormSubmitSuccess ? <GptR2S /> : <GptLeader />}
      </ClientRender>
    </GptLeaderWrapper>
  )
}
