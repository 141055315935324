import qs from 'query-string'

import {
  type ICOPageType,
  AUTOCAN_CTA_IDS,
  ICO_UTM_PARAMS,
  ICO_UTM_PARAMS_PAGE_SPECIFIC,
} from '@/constants/ico'
import { PROVINCES_ABBREVIATION } from '@/constants/location'
import { isAutosDealerAmountPrice } from '@/domain/listings/AutosDealerAmountPrice'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { type RouteLocale, ROUTE_LOCALE } from '@/domain/locale'
import { type VipQueryListing } from '@/types/listings'
import { centsToDollars } from '@/utils/price'
import { sendToLogger } from '@/utils/sendToLogger'

const WARRANTY_ATTRIBUTE_MAP: { [x: string]: string } = {
  caryear: 'year',
  carmake: 'make',
  carmodel: 'model',
  cartrim: 'trim',
  carmileageinkms: 'odometer',
  vin: 'vin',
}

type WarrantyFields =
  | 'adId'
  | 'coverImage'
  | 'ctaId'
  | 'locationId'
  | 'make'
  | 'model'
  | 'odometer'
  | 'province'
  | 'purchasePrice'
  | 'sessionId'
  | 'trim'
  | 'userId'
  | 'utm_campaign'
  | 'utm_content'
  | 'utm_medium'
  | 'utm_source'
  | 'vin'
  | 'year'

type WarrantyQueryParameters = {
  [x in WarrantyFields]?: string
}

type GetAutocanWarrantyUrlParameters = {
  baseUrl?: string
  hasQuote: boolean
  isMobile: boolean
  listing: VipQueryListing
  pageType?: ICOPageType
  provinceId: number
  routeLocale: RouteLocale
  userId?: string
}

export const getAutocanWarrantyUrl = ({
  baseUrl,
  hasQuote,
  isMobile,
  listing,
  pageType,
  provinceId,
  routeLocale,
  userId,
}: GetAutocanWarrantyUrlParameters): string | undefined => {
  if (!baseUrl) {
    if (process.env.NODE_ENV === 'production') {
      sendToLogger('Empty config_autocan warrantyUrl in prod', { fingerprint: ['vip-autocan'] })
      return
    }

    // eslint-disable-next-line no-console
    console.error('Empty config_autocan warrantyUrl')
    return
  }

  const localePath = routeLocale === ROUTE_LOCALE.en ? '' : '/fr'

  const warrantyPath = '/purchase/coverage?source=kijiji'

  const attributeParameters = listing.attributes?.all?.reduce(
    (acc: { [x: string]: string }, curr) => {
      const canonicalName = curr?.canonicalName ?? ''
      const value = curr?.values[0]
      const warningAttribute = WARRANTY_ATTRIBUTE_MAP[canonicalName]

      if (!!warningAttribute && !!value) {
        return { ...acc, [warningAttribute]: value }
      }

      return acc
    },
    {}
  )

  const price = listing.price
  let amount = undefined
  if ((isAmountPrice(price) || isAutosDealerAmountPrice(price)) && price.amount) {
    amount = centsToDollars(price.amount)
  }

  const provinceAbbreviation = PROVINCES_ABBREVIATION[provinceId]

  const conditionalQueryParams = {
    ctaId: hasQuote ? AUTOCAN_CTA_IDS.WARRANTY_VIP_WITH_QUOTE : AUTOCAN_CTA_IDS.WARRANTY_VIP,
    utm_content: hasQuote
      ? ICO_UTM_PARAMS.UTM_CONTENT_WARRANTY_WITH_QUOTE
      : ICO_UTM_PARAMS.UTM_CONTENT_WARRANTY,
  }

  const queryParameters: WarrantyQueryParameters = {
    ...attributeParameters,
    ...conditionalQueryParams,
    adId: listing.id,
    coverImage: listing.imageUrls[0] ?? undefined,
    locationId: listing.location.id.toString(),
    province: provinceAbbreviation,
    purchasePrice: amount?.toString(),
    userId: userId,
    ...(pageType && {
      utm_campaign: ICO_UTM_PARAMS_PAGE_SPECIFIC[pageType].UTM_CAMPAIGN,
    }),
    utm_medium: isMobile ? ICO_UTM_PARAMS.UTM_MEDIUM_MWEB : ICO_UTM_PARAMS.UTM_MEDIUM_WEB,
    utm_source: ICO_UTM_PARAMS.UTM_SOURCE,
  }

  return qs.stringifyUrl({ url: `${baseUrl}${localePath}${warrantyPath}`, query: queryParameters })
}
