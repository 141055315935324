import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import {
  type AttributeWithIconProps,
  type IconBackgroundVariant,
} from '@/features/attributes/components/AttributeWithIcon'
import { Flex } from '@/ui/atoms/flex'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

const iconBackground = ({ colors }: ThemeProps): Record<IconBackgroundVariant, string> => ({
  'no-bg': 'none',
  'primary-bg': colors.purple.light5,
  'secondary-bg': colors.grey.light5,
})

export const AttributeWithIconWrapper = styled(Flex)(
  ({ theme }) => `
  display: flex;

  & svg {
    color: ${theme.colors.grey.primary};
    height: 3.2rem;
    width: 3.2rem;
  }
`
)

export const AttributeIconContainer = styled.div<{
  iconBackgroundVariant: NonNullable<AttributeWithIconProps['iconBackgroundVariant']>
}>(
  ({ theme, iconBackgroundVariant }) => `
  align-items: center;
  background-color: ${iconBackground(theme)[iconBackgroundVariant]};
  border-radius: ${theme.borderRadius.rounded};
  display: flex;
  height: 4rem;
  justify-content: center;
  width: 4rem;
`
)

export const AttributesFeaturedContainer = styled(Flex)(
  ({ theme }) => `
  gap: ${theme.spacing.default};
  background-color: ${theme.colors.grey.light5};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing.default};
`
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const ShowMoreButton = styled.button(
  ({ theme }) => `
  transition: background-color 0.2s ease;
  border: 0.1rem solid ${theme.colors.grey.light3};
  border-radius: ${theme.borderRadius.rounded};
  padding: 0.7rem 1.2rem;
  background-color: ${theme.colors.white};
  ${styleFromTypography(theme.typography.body.small)}
  color: ${theme.colors.grey.light1};

  &:hover {
    background-color: ${theme.colors.grey.light4};
  }
`
)
