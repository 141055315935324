import styled from 'styled-components'

import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { textLinkBaseStyle } from '@/ui/atoms/text-link/styled'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

/**
 * This CSS is nested because the elements from the Description are dynamic
 * We don't control the input, and are styling any combination of markup
 * */
export const VipDescriptionWrapper = styled.div(
  ({ theme }) => `
  line-height: 2.4rem;
  color: ${theme.colors.grey.primary};

  // Preserves any line breaks found in raw description text
  & > div {
    white-space: pre-line;
  }

  & > div a {
    ${textLinkBaseStyle(theme, {
      $hasUnderline: true,
      $size: 'medium',
      $variant: 'secondary',
      $weight: 'medium',
    })}
  }

  // Styles for lists in PINT ads (eg. autos, jobs)
  & > div h3 {
    ${styleFromTypography(theme.typography.headline.large)}
    color: ${theme.colors.purple.primary};
    margin: 3.5rem 0 2rem;
  }

  // TODO: This block can be removed when PINT is retired
  & > div li {
    ${styleFromTypography(theme.typography.body.medium)}
    color: ${theme.colors.grey.primary};
    list-style: none;
    margin: 0.5rem 0;
    padding-left: 2rem;
    position: relative;

    &::before {
      content: '•';
      color: ${theme.colors.grey.light3};
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & > div ul {
    margin: 0;
    padding: 0;
  }

  & p {
    ${styleFromTypography(theme.typography.body.medium)}
    margin: 1em 0;
    color: ${theme.colors.grey.primary};

    & strong {
      font-weight: bold;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  // Avoids margins not collapsing when this component is wrapped (e.g. ShowMore)
  &:last-child,
  & p:last-child {
    margin-bottom: 0;
  }
`
)

export const VipDescriptionContainer = styled(VipSectionContainer)(
  ({ theme }) => `
  padding-top: ${theme.spacing.husky};
  
  ${MEDIA_QUERIES(theme).medium}{
    display: block;
    padding-bottom: ${theme.spacing.husky};
  }
`
)
