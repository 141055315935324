import { isAnyBuyAndSellCategory, isAnyJobsCategory, isAnyPetsCategory } from '@kijiji/category'
import ProfileTypeIcon from '@kijiji/icons/src/icons/ProfileType'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { InlineIconLink } from '@/components/vip/vip-seller-profile/InlineIconLink'
import { TRANSLATION_KEYS } from '@/constants/localization'
import {
  ATTRIBUTES,
  FOR_SALE_BY_PETS_VALUES,
  FOR_SALE_BY_VALUES,
  JOB_OFFERED_BY_VALUES,
} from '@/features/attributes/constants/attributes'
import { filterNullAttributes } from '@/features/attributes/utils/filterNullAttributes'
import { getForSaleByLabel } from '@/features/attributes/utils/getForSaleByLabel'
import { type VipQueryAttributeAll, type VipQueryListing } from '@/types/listings'

const allowedProfileTypes = [
  ATTRIBUTES.FOR_SALE_BY_PETS,
  ATTRIBUTES.JOB_OFFERED_BY,
  ATTRIBUTES.FOR_SALE_BY,
]
const allowedProfileValues: string[] = [
  FOR_SALE_BY_PETS_VALUES.REGISTERED_BREEDER,
  JOB_OFFERED_BY_VALUES.PROFESSIONAL,
  FOR_SALE_BY_VALUES.DEALER,
]

export type VipSideProfileForSaleByProps = {
  categoryId: VipQueryListing['categoryId']
  attributes: VipQueryAttributeAll
}

export const VipSideProfileForSaleBy: FC<VipSideProfileForSaleByProps> = ({
  categoryId,
  attributes,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { colors } = useTheme()

  /**
   * The "For Sale By" label should only appear for professional profiles on specific categories
   * Those are categories that don't have a lot of information on their side profile
   * And this information helps building trust
   */
  const isAllowedCategory =
    isAnyPetsCategory(categoryId) ||
    isAnyJobsCategory(categoryId) ||
    isAnyBuyAndSellCategory(categoryId)

  if (!isAllowedCategory) return null

  const filterAttributes = filterNullAttributes(attributes)
  const shouldShowProfileType = filterAttributes?.find(({ canonicalName, canonicalValues }) => {
    const canonicalValue = canonicalValues?.[0]

    return (
      allowedProfileTypes.includes(canonicalName) &&
      canonicalValue &&
      allowedProfileValues.includes(canonicalValue)
    )
  })

  const forSaleByLabel = shouldShowProfileType
    ? getForSaleByLabel({ attributes: filterAttributes, t })
    : undefined

  return forSaleByLabel ? (
    <InlineIconLink
      data-testid="side-profile-for-sale-by"
      Icon={ProfileTypeIcon}
      label={forSaleByLabel}
      iconColor={colors.purple.primary}
      isNonInteractive
    />
  ) : null
}
