/**
 * Generates a numeric identifier based on the first 3 characters of a username. Returns '0' if the username is empty.
 *
 * @param userName The username to generate an identifier from.
 * */
export const generateNumericIdentifierFromName = (userName: string): string => {
  if (!userName) return '0' // Handle empty strings
  return userName
    .slice(0, 3)
    .toLowerCase() // Normalize case
    .split('')
    .reduce((acc, char) => acc + (char.codePointAt(0) ?? 0), 0) // Sum the unicode values to create a numeric identifier. Use codePointAt for full Unicode support.
    .toString()
}
