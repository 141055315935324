import { type PaymentEstimatorFormValues } from '@/features/payment-estimator/types/paymentEstimator'

/**
 * Default "Payment Estimator" form values in Dollars
 */
export const DEFAULT_FORM_VALUES: PaymentEstimatorFormValues = {
  annualInterestRate: 7.99,
  downPayment: 0,
  loanTerm: 72,
  tradeIn: 0,
}
