import { getImageBySize } from '@kijiji/image'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { useTranslation } from 'next-i18next'
import { type FC, useCallback, useEffect, useState } from 'react'

import { NextButton, PrevButton } from '@/components/shared/carousel-buttons/CarouselButtons'
import { ClientRender } from '@/components/shared/client-render'
import { ImageCustom } from '@/components/shared/image/ImageCustom'
import { ImageGalleryPictureSlide } from '@/components/shared/image-gallery-modal/slides/ImageGalleryPictureSlide'
import { ImageGalleryVirtualSlide } from '@/components/shared/image-gallery-modal/slides/ImageGalleryVirtualSlide'
import { ImageGalleryYoutubeSlide } from '@/components/shared/image-gallery-modal/slides/ImageGalleryYoutubeSlide'
import {
  GalleryWrapper,
  ImageGalleryButtonsContainer,
  ImageGalleryContainer,
  ImageGalleryMainContainer,
  ImageGalleryThumbnailContainer,
  ImageGalleryThumbnailSlide,
  ImageGalleryThumbnailSlides,
} from '@/components/shared/image-gallery-modal/styled'
import { GptImageGalleryOverlay } from '@/components-page/vip/advertisement/GptImageGalleryOverlay'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { type GalleryContent } from '@/features/listing-gallery/utils/getGalleryContent'
import { Modal } from '@/ui/molecules/modal/Modal'
import { getYouTubeEmbedUrl } from '@/utils/url'

export type ImageGalleryModalProps = {
  isVisible: boolean
  handleCloseModal: (event: React.MouseEvent | React.KeyboardEvent) => void
  galleryContent: GalleryContent[]
  /**
   * Slide to start scrolling
   * */
  startIndex?: number
}

export const ImageGalleryModal: FC<ImageGalleryModalProps> = ({
  isVisible,
  handleCloseModal,
  galleryContent,
  startIndex = 0,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.COMMON, TRANSLATION_KEYS.VIP])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [lastImageLoaded, setLastImageLoaded] = useState(-1)
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({ startIndex }, [])
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({ dragFree: true }, [
    WheelGesturesPlugin(),
  ])

  const onPrevButtonClick = useCallback(() => {
    if (!emblaMainApi) return

    emblaMainApi.scrollPrev(true)
  }, [emblaMainApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaMainApi) return

    emblaMainApi.scrollNext(true)
  }, [emblaMainApi])

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return

      emblaMainApi.scrollTo(index, true)
    },
    [emblaMainApi, emblaThumbsApi]
  )

  /** Add keypress support of the carousel to move right and left */
  useEffect(() => {
    if (!emblaMainApi) return

    const handleKeydown = (event: KeyboardEvent): void => {
      switch (event.key) {
        case 'ArrowLeft':
          onPrevButtonClick()
          break
        case 'ArrowRight':
          onNextButtonClick()
          break
        default:
          break
      }
    }

    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [emblaMainApi, onNextButtonClick, onPrevButtonClick])

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return

    // Had to add this for our Jest tests, strange
    const selectedIndex = emblaMainApi.selectedScrollSnap()
    if (selectedIndex === undefined) return

    setSelectedIndex(emblaMainApi.selectedScrollSnap())
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap(), true)
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaMainApi) return
    onSelect()

    emblaMainApi.on('select', () => onSelect()).on('reInit', () => onSelect())
  }, [emblaMainApi, onSelect])

  // function that will return the right react element based on the type of the content
  const renderGalleryContent = (content: GalleryContent, index: number) => {
    const key = `image-gallery-main-${index}`
    const dataTestId = `${key}${index === startIndex ? '-initial' : ''}`
    const inView = selectedIndex === index

    /**
     * If the selected image is loaded, then the images that are 1 before and 3 after the selected
     * image will start preloading in the background.
     */
    const selectedImageLoaded = lastImageLoaded === selectedIndex
    const shouldShowFullSize =
      selectedImageLoaded && index - selectedIndex >= -1 && index - selectedIndex <= 3

    switch (content.type) {
      case 'picture':
        return (
          <ImageGalleryPictureSlide
            alt=""
            id={key}
            key={key}
            src={content.source}
            data-testid={dataTestId}
            inView={inView}
            shouldShowFullSize={shouldShowFullSize}
            onLoadAndInView={() => setLastImageLoaded(index)}
          />
        )
      case 'youtube':
        return (
          <ImageGalleryYoutubeSlide
            key={key}
            src={getYouTubeEmbedUrl(content.videoId)}
            title={t('vip:gallery_label.youtube')}
            allow="autoplay"
            allowFullScreen
            data-testid={dataTestId}
            style={{ width: '100%', height: '100%' }}
            inView={inView}
          />
        )
      case 'virtual-tour':
        return (
          <ImageGalleryVirtualSlide
            key={key}
            data-testid={dataTestId}
            url={content.url}
            inView={inView}
          />
        )
    }
  }

  return (
    <Modal
      closeButtonLabel={t('common:modals.close.button.label')}
      hasCloseButton
      height="100vh"
      id="image-gallery-modal"
      isDarkModal
      isOpen={isVisible}
      label={t('vip:gallery_label')}
      margin="0"
      onCancel={handleCloseModal}
      padding="0"
      width="100vw"
      borderRadius="0"
      allowPinchZoom
    >
      <GalleryWrapper data-testid="image-gallery-modal">
        <ClientRender>
          <GptImageGalleryOverlay />
        </ClientRender>

        <ImageGalleryContainer>
          <ImageGalleryButtonsContainer>
            <PrevButton enabled onClick={onPrevButtonClick} variation="gallery" />
          </ImageGalleryButtonsContainer>

          <ImageGalleryMainContainer ref={emblaMainRef}>
            <div>{galleryContent.map((item, index) => renderGalleryContent(item, index))}</div>
          </ImageGalleryMainContainer>

          <ImageGalleryThumbnailContainer>
            <div ref={emblaThumbsRef}>
              <ImageGalleryThumbnailSlides>
                {galleryContent.map((item, index) => (
                  <ImageGalleryThumbnailSlide
                    selected={index === selectedIndex}
                    key={`image-gallery-thumb-${index}`}
                    onClick={() => onThumbClick(index)}
                    type="button"
                  >
                    <ImageCustom
                      alt=""
                      fill
                      src={
                        item.type === 'picture'
                          ? getImageBySize(item.source, 140, 'WEBP', true)
                          : item.thumbnailUrl
                      }
                    />
                  </ImageGalleryThumbnailSlide>
                ))}
              </ImageGalleryThumbnailSlides>
            </div>
          </ImageGalleryThumbnailContainer>

          <ImageGalleryButtonsContainer>
            <NextButton enabled onClick={onNextButtonClick} variation="gallery" />
          </ImageGalleryButtonsContainer>
        </ImageGalleryContainer>
      </GalleryWrapper>
    </Modal>
  )
}
