import { formatPrice } from '@kijiji/money'

import { type CalculateMonthlyPaymentProps } from '@/features/payment-estimator/types/paymentEstimator'
import { ROUTE_LOCALE } from '@/hooks/useLocale'

/**
 * Calculates the monthly car payment in cents based on the price, down payment, trade-in value,
 * annual interest rate, and loan term.
 *
 * @returns The formatted monthly payment amount.
 */
export const calculateMonthlyPayment = ({
  annualInterestRate,
  downPayment,
  loanTerm,
  locale,
  price,
  tradeIn,
}: CalculateMonthlyPaymentProps): string => {
  //We do not consider an interest rate of more than 100% or less than 0% a valid input
  if (annualInterestRate > 100 || annualInterestRate < 0) {
    return ''
  }

  /** Principal Loan amount */
  const remainingTotal = price - downPayment - tradeIn
  const isFrench = locale === ROUTE_LOCALE.fr

  if (annualInterestRate === 0) {
    const monthlyPaymentNoInterest = Math.round(remainingTotal / loanTerm)
    return formatPrice(monthlyPaymentNoInterest, { isFrench }) ?? ''
  }

  /** Converts the annual interest rate from input into monthly */
  const monthlyInterestRate = annualInterestRate / 12 / 100

  // Calculate monthly payment in cents
  let monthlyPayment =
    remainingTotal *
    ((monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTerm)) /
      (Math.pow(1 + monthlyInterestRate, loanTerm) - 1))

  // If the monthly payment works out to a negative number, then set it to 0
  if (monthlyPayment < 0) {
    monthlyPayment = 0
  }

  // Round the payment to the nearest cent, and format it according to the user's locale
  const formattedMonthlyPayment = formatPrice(Math.round(monthlyPayment), { isFrench }) ?? ''

  return formattedMonthlyPayment
}
