import { type FC, type ReactNode } from 'react'
import { useTheme } from 'styled-components'

import {
  VipSectionTitleContainer,
  VipSectionTitleImage,
} from '@/components/vip/shared/vip-section-title/styled'
import { HeadlineText } from '@/ui/atoms/headline-text'

export type VipSectionTitleProps = {
  logoSrc?: string | null
  logo?: ReactNode
  title: string
}

export const VipSectionTitle: FC<VipSectionTitleProps> = ({ logo, logoSrc, title }) => {
  const { spacing, colors } = useTheme()

  return (
    <VipSectionTitleContainer mBottom={spacing.defaultSmall}>
      <HeadlineText
        as="h2"
        color={colors.grey.primary}
        size={{ small: 'large', large: 'xLarge' }}
        weight="medium"
      >
        {title}
      </HeadlineText>
      {logo || (logoSrc && <VipSectionTitleImage alt="" src={logoSrc} skipNextImage />)}
    </VipSectionTitleContainer>
  )
}
