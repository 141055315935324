import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const ChatButtonContainer = styled.div(
  ({ theme }) => `
    position: absolute;

    ${MEDIA_QUERIES(theme).medium} {
      z-index: ${zIndexRegistry.LIVEPERSON_CHAT};
    }

`
)
