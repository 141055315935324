import { isAnyCarsVehiclesCategory } from '@kijiji/category'
import {
  AdSource,
  LogoType,
  useEventsExternalClickMutation,
  useGetProfileQuery,
} from '@kijiji/generated/graphql-types'
import WebsiteIcon from '@kijiji/icons/src/icons/Website'
import { useTranslation } from 'next-i18next'
import { type FC, type RefObject } from 'react'
import { useTheme } from 'styled-components'

import { shouldRenderLocationSection } from '@/components/vip/shared/vip-location/shouldRenderLocationSection'
import { VIP_LOCATION_VARIANT, VipLocation } from '@/components/vip/shared/vip-location/VipLocation'
import { VipSectionTitle } from '@/components/vip/shared/vip-section-title/VipSectionTitle'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { NeighbourhoodScores } from '@/components/vip/vip-neighbourhood-info/NeighbourhoodScores'
import { shouldRenderNeighbourhoodScoresSection } from '@/components/vip/vip-neighbourhood-info/shouldRenderNeighbourhoodScoresSection'
import { InlineIconLink } from '@/components/vip/vip-seller-profile/InlineIconLink'
import { SellerCardDetailsContainer } from '@/components/vip/vip-seller-profile/styled'
import { websiteClickHandler } from '@/components/vip/vip-seller-profile/utils/websiteClickHandler'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { shouldRenderNeighbourhoodLocationInfo } from '@/domain/neighbourhoods/shouldRenderNeighbourhoodLocationInfo'
import { getPriceClassification } from '@/features/listing/utils/getPriceClassification'
import { ProfilePhoneNumberInline } from '@/features/profile/components/phone-number/ProfilePhoneNumberInline'
import { SellerOverviewCard } from '@/features/profile/components/SellerOverviewCard'
import { getCommercialProfileFromData } from '@/features/profile/utils/getCommercialProfileFromData'
import { getGenericProfilePath } from '@/features/profile/utils/getGenericProfilePath'
import { getSellerListingNumberLabel } from '@/features/profile/utils/getSellerListingNumberLabel'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryListing, isRealEstateListingLocation } from '@/types/listings'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { generateVIPPhoneTrackingLabel } from '@/utils/vip/generateVIPPhoneTrackingLabel'

export type VipSellerCardSectionProps = {
  listing: VipQueryListing
  isDealerListing: boolean
  /**
   * Reference of the phone element section to take the user on click
   */
  phoneReference: RefObject<HTMLDivElement>
  /**
   * This is used to check if a GSI event should be tracked when the external url link gets clicked
   */
  isGSITrackingEnabled?: boolean
}

export const VipSellerCardSection: FC<VipSellerCardSectionProps> = ({
  isDealerListing,
  listing,
  phoneReference,
  isGSITrackingEnabled,
}) => {
  const { LISTING, VIP, PROFILE } = TRANSLATION_KEYS
  const { t } = useTranslation([LISTING, VIP, PROFILE])
  const { spacing } = useTheme()

  const [eventsExternalClick] = useEventsExternalClickMutation()

  const { data } = useGetProfileQuery({
    fetchPolicy: 'cache-first',
    variables: {
      profileId: listing.posterInfo.posterId,
      size: LogoType.ViewAd,
    },
  })

  if (!data?.profile) return null

  const commercialData = getCommercialProfileFromData(data.profile, listing)
  const websiteUrl = commercialData?.websiteUrl ?? listing?.posterInfo.websiteUrl

  const sellerListingCount = getSellerListingNumberLabel(data.profile, t, listing.categoryId)
  const hasLocationSection = shouldRenderLocationSection(listing.categoryId)
  const isEligibleForNeighbourhoodScoresSection = shouldRenderNeighbourhoodScoresSection(listing)
  const isEligibleForNeighbourhoodLocationInfo = shouldRenderNeighbourhoodLocationInfo(
    listing.categoryId,
    listing.location
  )

  const profilePath = data.profile.profilePath ?? getGenericProfilePath(data.profile.id)

  const handleViewSellerListingsClick = () => {
    trackEvent({ action: GA_EVENT.ViewUsersOtherAds, label: 'activeAds' })
  }

  const handleViewWebsiteClick = () => {
    websiteClickHandler(
      eventsExternalClick,
      listing.id,
      AdSource.Organic,
      listing.categoryId,
      websiteUrl,
      isGSITrackingEnabled
    )
  }

  const priceRating = getPriceClassification(listing.price)?.rating
  const r2sPhoneTrackingLabel = generateVIPPhoneTrackingLabel({
    isDealerListing,
    eventLocation: 'bottom',
    priceInsightRate: priceRating,
    categoryId: listing.categoryId,
  })

  const isAutosDealer = isDealerListing && isAnyCarsVehiclesCategory(listing.categoryId)

  return (
    <>
      {hasLocationSection ? (
        <VipSectionContainer
          pTop={spacing.husky}
          pBottom={spacing.default}
          data-testid="vip-location"
        >
          <VipSectionTitle
            title={
              isEligibleForNeighbourhoodLocationInfo &&
              isRealEstateListingLocation(listing.location)
                ? t('vip:location.neighbourhood.title', {
                    neighbourhoodName: listing.location.neighbourhoodInfo?.name,
                  })
                : t('vip:location.title')
            }
          />

          <VipLocation
            loadingSizeVariation="small"
            location={listing.location}
            variant={
              isEligibleForNeighbourhoodLocationInfo
                ? VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD
                : VIP_LOCATION_VARIANT.LINK_MAP
            }
          />
        </VipSectionContainer>
      ) : null}

      {isEligibleForNeighbourhoodScoresSection ? (
        <VipSectionContainer pBottom="0" pTop="0">
          <Flex flexDirection="column" gap={spacing.large}>
            <Spacing pTop={spacing.large} data-testid="vip-location">
              <VipSectionTitle title={t('vip:neighbourhood.scores.title')} />

              <NeighbourhoodScores scores={listing.location.neighbourhoodInfo?.scores ?? {}} />
            </Spacing>
          </Flex>
        </VipSectionContainer>
      ) : null}

      <Flex flexDirection="column" gap={spacing.large}>
        <VipSectionContainer pBottom="0" data-testid="vip-about-seller">
          <VipSectionTitle title={t('vip:seller.title')} logoSrc={commercialData?.logoUrl} />

          {!hasLocationSection ? (
            <VipLocation
              loadingSizeVariation="small"
              location={listing.location}
              variant={VIP_LOCATION_VARIANT.MAP}
            />
          ) : null}

          <Spacing mBottom={spacing.default}>
            <SellerOverviewCard
              hideAvatar={isAutosDealer}
              hideRating={isAutosDealer}
              listing={listing}
              logoType={LogoType.ViewAd}
              userId={listing.posterInfo.posterId}
            />
          </Spacing>

          <SellerCardDetailsContainer>
            {/* Phone Number */}
            {listing.posterInfo.phoneNumber ? (
              <div>
                <div ref={phoneReference} />
                <ProfilePhoneNumberInline
                  categoryId={listing.categoryId}
                  sellerType={listing.posterInfo.sellerType}
                  profileId={listing.posterInfo.posterId}
                  listingId={listing.id}
                  isGSITrackingEnabled={isGSITrackingEnabled}
                  trackingLabel={r2sPhoneTrackingLabel}
                />
              </div>
            ) : null}

            {/* Listing Count */}
            {sellerListingCount ? (
              <InlineIconLink
                Icon={sellerListingCount.icon}
                label={sellerListingCount.label}
                onClick={handleViewSellerListingsClick}
                href={profilePath}
              />
            ) : null}

            {/* Location */}
            {hasLocationSection ? null : (
              <VipLocation location={listing.location} variant={VIP_LOCATION_VARIANT.LINK} />
            )}

            {/* Website URL */}
            {websiteUrl ? (
              <InlineIconLink
                Icon={WebsiteIcon}
                href={websiteUrl}
                isExternalLink
                label={t('profile:profile.metrics.website')}
                onClick={handleViewWebsiteClick}
              />
            ) : null}
          </SellerCardDetailsContainer>
        </VipSectionContainer>
      </Flex>
    </>
  )
}
