import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import {
  type SocialShareDirection,
  type SocialShareMenuProps,
} from '@/features/social-sharing/components/SocialShareMenu'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'

export const SocialShareButtonWrapper = styled.div<{ isSimplified?: boolean }>(
  ({ theme, isSimplified }) => `
  position: relative;
  width: fit-content;

  & > button > span {
    ${isSimplified ? 'display: none;' : ''}
  }

  ${MEDIA_QUERIES(theme).medium}{
    & > button > span {
      display: inline-block;
    }
  }
`
)

const stylePerDirection: Record<SocialShareDirection, string> = {
  'bottom-left': 'top: 100%; left: 0; right: unset',
  'bottom-right': 'top: 100%; right: 0; left: unset',
  bottom: 'top: 100%; left: -50%;',
  left: 'top: 0; right: 100%; left: unset;',
  right: 'top: 0; left: 100%; right: unset;',
}

const getResponsiveDirection = (value: SocialShareDirection) => stylePerDirection[value]

export const SocialShareMenuWrapper = styled.ul<Pick<SocialShareMenuProps, 'direction'>>(
  ({ theme, direction = 'right' }) => `
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  border: .1rem solid ${theme.colors.grey.light4};
  box-shadow: ${theme.boxShadow['shadow-3']};
  display: flex;
  flex-direction: column;
  min-width: 26rem;
  padding: ${theme.spacing.default} 0;
  position: absolute;
  width: fit-content;
  z-index: ${zIndexRegistry.DROPDOWN};

  ${applyResponsiveStyle(direction, theme, getResponsiveDirection)}
`
)

export const ShareMenuItem = styled.li(
  ({ theme }) => `
  list-style-type: none;

  & button, 
  & a {
    color: ${theme.colors.grey.primary};
    align-items: center;
    display: flex;
    gap: ${theme.spacing.defaultSmall};
    padding: ${theme.spacing.defaultSmall} ${theme.spacing.default};
    text-decoration: none;
    width: 100%;

    &:hover {
      background: ${theme.colors.grey.light5};
    }
  }
`
)
