import { type CoreListing, useGetSearchUrlQuery } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { CoreListingFeedCard } from '@/components/shared/core-listing-card/CoreListingFeedCard'
import { FadeOutSection } from '@/components/shared/fade-out-section/FadeOutSection'
import { XssSanitizer } from '@/components/vip/vip-description/XssSanitizer'
import { VipSponsoredAds } from '@/components/vip/vip-sponsored-ads/VipSponsoredAds'
import { ExpiredVipBanner, ExpiredVipImageContainer } from '@/components/vip/vip-variations/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { formatListingDescription } from '@/domain/listings/formatListingDescription'
import { getListingPrice } from '@/domain/listings/getListingPrice'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { ListingGalleryMainImage } from '@/features/listing-gallery/components/ListingGalleryMainImage'
import { getSearchUrlVariablesFromListing } from '@/features/search/utils/getSearchUrlVariablesFromListing'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryListing } from '@/types/listings'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

type ExpiredVipProps = {
  listing: VipQueryListing
  isPintAd: boolean
  isSM360Ad: boolean
  similarListings?: CoreListing[]
}

const MAX_TITLE_LENGTH = 32

/**
 * This is the version of the page that should show up
 */
export const ExpiredVip: FC<ExpiredVipProps> = ({
  listing,
  isPintAd,
  isSM360Ad,
  similarListings,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.VIP])
  const {
    location: { area },
  } = useGetLocation()
  const { routeLocale } = useLocale()
  const { colors, spacing } = useTheme()

  const searchUrlInput = getSearchUrlVariablesFromListing(listing, area?.radius)
  const { data: searchUrlData } = useGetSearchUrlQuery({
    variables: { input: searchUrlInput.input },
  })
  const seeMoreUrl = searchUrlData?.searchUrl ?? searchUrlInput.fallbackUrl

  const price = isAmountPrice(listing.price)
    ? getListingPrice({ price: listing.price, t, routeLocale })
    : ''

  /**
   * The title should be capped in 32 characters
   */
  const titleLength = listing.title.length
  const title = `${listing.title.slice(0, MAX_TITLE_LENGTH)}${titleLength > MAX_TITLE_LENGTH ? '...' : ''}`

  const handleTrackListingClick = (position: number, listingId: string) => {
    trackEvent({
      action: GA_EVENT.SelectPromotion,
      label: `partneradid=${listingId};position=${position};promotion_name=evip`,
    })
  }

  const handleSeeMoreListingsClick = () => {
    trackEvent({ action: GA_EVENT.ResultsAdClick, label: `partner=organic;position=0` })
  }

  return (
    <>
      <Spacing pBottom={spacing.large}>
        <ExpiredVipBanner>
          <Flex gap={spacing.default} alignItems="center">
            <ExpiredVipImageContainer>
              <ListingGalleryMainImage
                imageUrls={listing.imageUrls}
                youtubeVideoId={listing.youtubeVideoId}
                notInteractive
              />
            </ExpiredVipImageContainer>

            <Flex flexDirection="column" gap={spacing.mini} justifyContent="center">
              <HeadlineText
                as="h1"
                color={colors.grey.primary}
                size={{ small: 'small', medium: 'large' }}
              >
                {t('vip:e_vip.status.not_available', {
                  title: `${title} ${price ?? ''}`,
                })}
              </HeadlineText>

              {listing.description ? (
                // TODO: this is an accessibility issue, we should fix it
                <FadeOutSection sectionColor={colors.purple.light5} disableShowMoreButton>
                  <BodyText
                    color={colors.grey.primary}
                    size={{ small: 'small', medium: 'medium' }}
                    as="div"
                  >
                    <XssSanitizer
                      content={formatListingDescription(listing.description, isPintAd)}
                      categoryId={listing.categoryId}
                      isPintAd={isPintAd}
                      isSM360Ad={isSM360Ad}
                    />
                  </BodyText>
                </FadeOutSection>
              ) : null}
            </Flex>
          </Flex>

          <Button as="a" size="small" href={seeMoreUrl} onClick={handleSeeMoreListingsClick}>
            {t('vip:e_vip.see_more')}
          </Button>
        </ExpiredVipBanner>
      </Spacing>

      {similarListings?.length ? (
        <Flex flexDirection="column" gap={spacing.default}>
          <HeadlineText as="h2" color={colors.grey.primary} size="large">
            {t('e_vip.similar_listings_title')}
          </HeadlineText>

          {similarListings?.map((item, index) => (
            <CoreListingFeedCard
              handleTrackListingClick={handleTrackListingClick}
              listing={item}
              index={index}
              key={`similar-listing-evip-${item.id}`}
            />
          ))}

          <Spacing pBottom={spacing.default}>
            <Button
              as="a"
              size="small"
              variant="primaryDark"
              href={seeMoreUrl}
              onClick={handleSeeMoreListingsClick}
            >
              {t('vip:e_vip.see_more')}
            </Button>
          </Spacing>
        </Flex>
      ) : null}
      <VipSponsoredAds isEvip />
    </>
  )
}
