import {
  type EventsExternalClickMutationHookResult,
  AdSource,
  EventsUrlType,
} from '@kijiji/generated/graphql-types'

import { trackGSIExternalLink } from '@/features/listing/utils/GSITracking'
import { trackEvent } from '@/lib/ga'

/**
 * This function is used to handle the click event on the website link.
 * Mainly used for tracking the event.
 *
 * @param eventsExternalClick - Mutation hook result for eventsExternalClick
 * @param listingId - The listing id
 * @param listingSource - The source of the listing
 * @param categoryId - The listing's category id
 * @param website - The website URL
 * @param isGSITrackingEnabled - Used to check if a GSI event should be tracked
 *
 */
export const websiteClickHandler = (
  eventsExternalClick: EventsExternalClickMutationHookResult[0],
  listingId: string,
  listingSource: AdSource,
  categoryId: number,
  website?: string | null,
  isGSITrackingEnabled?: boolean
) => {
  if (!website) return

  if (listingSource === AdSource.Organic) {
    trackEvent({ action: 'R2SExternalBegin', label: 'btn=WebsiteURL;' })
  }

  if (isGSITrackingEnabled) {
    trackGSIExternalLink(categoryId)
  }

  eventsExternalClick({
    variables: {
      eventsExternalClickId: listingId,
      url: website,
      urlType: EventsUrlType.SellerHomepage,
    },
  })
}
