import { Form as FormikForm } from 'formik'
import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'
import { Drawer as BaseDrawer } from '@/ui/atoms/drawer'
import { Flex } from '@/ui/atoms/flex'

export const StyledRequestViewingButton = styled(Button)(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.default};
  justify-content: flex-start;
  padding: 1rem ${theme.spacing.defaultSmall};
`
)

// Drawer
export const Drawer = styled(BaseDrawer)`
  padding: 0 !important;
`

export const DrawerFlexFormContainer = styled(Flex)(
  ({ theme }) => `
  overflow-y: auto;
  padding-top: ${theme.spacing.giant};
`
)

// Modal
export const ModalFlexFormContainer = styled(Flex)`
  overflow-y: auto;
`

// Form
export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`

export const FormFooter = styled.footer(
  ({ theme }) => `
  border-top: .1rem solid ${theme.colors.grey.light4};
  padding: ${theme.spacing.large};
`
)

export const FormBodyFlexContainer = styled(Flex)(
  ({ theme }) => `
  padding: ${theme.spacing.large};
`
)

export const SubscriptionFormBodyContainer = styled(Flex)(
  () => `
  overflow-y: auto;
  `
)

export const SubscriptionFormSection = styled(Flex)(
  ({ theme }) => `
  padding: ${theme.spacing.large};
  background-color: ${theme.colors.grey.light5};
`
)

export const PrePopulateNameSection = styled(Flex)(
  ({ theme }) => `
  padding: ${theme.spacing.default} ${theme.spacing.large} ${theme.spacing.large};
`
)

export const SubscriptionFormFooter = styled.footer(
  ({ theme }) => `
  padding: ${theme.spacing.large};
  border-top: .1rem solid ${theme.colors.grey.light4};
`
)

export const DateOptionLabel = styled.label<{ isSelected: boolean; isDisabled: boolean }>(
  ({ theme, isSelected, isDisabled }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.0rem 0;
  flex: 1;
  border: 0.1rem solid ${isDisabled ? theme.colors.grey.light4 : theme.colors.grey.light3};
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.colors.white};
  cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;

  ${
    !isDisabled
      ? `
    &:focus-within {
      border-color: ${theme.colors.purple.primary};
    }

    &:hover {
      border-color: ${theme.colors.purple.primary};
    }
  `
      : ''
  }


  ${
    isSelected
      ? `
    background-color: ${theme.colors.purple.light5};
    border-color: ${theme.colors.purple.light4};
  `
      : ''
  }
`
)

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`
