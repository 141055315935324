import { AdType } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { HeadlineText } from '@/ui/atoms/headline-text'
import { type StyledAs } from '@/ui/typings/helpers'

type VipTitleProps = {
  adType: string
  title: string
  size?: 'default' | 'medium'
  as?: StyledAs
}

export const VipTitle: FC<VipTitleProps> = ({
  adType,
  title,
  size = 'default',
  as: headlineAs,
}) => {
  const { t } = useTranslation('vip')
  const { colors } = useTheme()

  return (
    <HeadlineText
      as={headlineAs ?? 'h1'}
      color={colors.grey.primary}
      size={size === 'default' ? { small: 'large', medium: 'xLarge' } : 'medium'}
      weight="medium"
      style={{ wordWrap: 'break-word' }}
    >
      {adType === AdType.Wanted ? t('ad_type.title.wanted') : ''} {title}
    </HeadlineText>
  )
}
