import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'

export const PaymentEstimateWrapper = styled(Flex)(
  ({ theme }) => `
  border: 0.1rem solid ${theme.colors.grey.light3};
  border-radius: ${theme.borderRadius.small};
  margin-bottom: ${theme.spacing.default};
  padding: ${theme.spacing.default};
`
)

export const PriceEstimateContainer = styled(Flex)`
  width: calc(50% - 0.1rem);
`

/**
 * Yet another vertical divider
 * TODO: We need to either modify the existing Divider component, or create a new divider component
 * that uses a div. At the moment we have 6 different vertical dividers.
 */
export const VerticalDivider = styled.div(
  ({ theme }) => `
  background: ${theme.colors.grey.light3};
  height: 4.6rem;
  width: 0.1rem;
`
)
