import { type ApolloError } from '@apollo/client/errors'
import { isAnyRealEstateCategory } from '@kijiji/category'
import {
  PhoneNumberSource,
  SellerType,
  useGetProfilePhoneNumberLazyQuery,
} from '@kijiji/generated/graphql-types'
import { useReCaptcha } from 'next-recaptcha-v3'
import { useState } from 'react'

import { lap } from '@/features/listing/scripts/lap/lap.min.js'
import { GSI_ELEMENT_NAME, GSIElementName } from '@/features/listing/utils/GSITracking'
import { type ProfilePhoneNumberProps } from '@/features/profile/components/phone-number/ProfilePhoneNumber'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { sendToLogger } from '@/utils/sendToLogger'

const RECAPTCHA_SOURCE = 'PHONE_NUMBER_REVEAL'

type UseProfilePhoneNumberResult = {
  /**
   * The phone number of the listing. If the phone number has not been revealed,
   * this will be `null`.
   */
  revealedPhoneNumber: string | null

  /**
   * The current loading state of revealing the phone number.
   */
  isRevealPhoneNumberLoading: boolean

  /**
   * The current error state of revealing the phone number.
   */
  revealPhoneNumberError: ApolloError | undefined

  /**
   * Function to reveal the phone number.
   */
  triggerPhoneNumberReveal: () => Promise<void>
}

/**
 * Custom hook that fetches the phone number of a seller. Handles Google
 * reCAPTCHA, tracking, and loading states.
 *
 * @returns See return type for details of what's exposed.
 */
export function useProfilePhoneNumber({
  profileId,
  categoryId,
  sellerType,
  listingId,
  trackingLabel,
  isGSITrackingEnabled,
}: ProfilePhoneNumberProps): UseProfilePhoneNumberResult {
  const { executeRecaptcha } = useReCaptcha()
  const [
    revealPhoneNumber,
    { loading: isRevealPhoneNumberLoading, error: revealPhoneNumberError },
  ] = useGetProfilePhoneNumberLazyQuery()
  const [revealedPhoneNumber, setRevealedPhoneNumber] = useState<string | null>(null)

  /**
   * This is returned by the hook and is used to reveal the phone number.
   */
  const triggerPhoneNumberReveal = async () => {
    if (revealedPhoneNumber) return
    let source
    /* 
      We only want to use the dynamic source for real estate listings that are not FSBO.
      That means, that any real estate listing that is KMB or Commercial will use the dynamic source independently if they 
      are Salesforce or not. For anything else, the source will be undefined and Anvil will handle the source. 
    */
    if (isAnyRealEstateCategory(categoryId) && sellerType !== SellerType.Fsbo) {
      source = PhoneNumberSource.Dynamic
    }

    const recaptchaToken = await executeRecaptcha(RECAPTCHA_SOURCE)
    const { data } = await revealPhoneNumber({
      variables: { profileId, listingId, recaptchaToken, source },
    })

    if (data?.profile.phoneNumber) {
      setRevealedPhoneNumber(data.profile.phoneNumber)

      if (isGSITrackingEnabled) {
        try {
          lap.clickTrack({
            elementName: GSIElementName.CLICK_TO_CALL,
            eventName: GSI_ELEMENT_NAME,
          })
        } catch (err) {
          sendToLogger(err, { fingerprint: ['gsi-tracking-vip'] })
        }
      }

      trackEvent({
        action: GA_EVENT.R2SPhoneBegin,
        label: trackingLabel,
      })
    }
  }

  return {
    revealedPhoneNumber,
    isRevealPhoneNumberLoading,
    revealPhoneNumberError,
    triggerPhoneNumberReveal,
  }
}
