import { type ListingPrice } from '@kijiji/generated/graphql-types'
import { formatPrice } from '@kijiji/money'
import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { VipSectionTitle } from '@/components/vip/shared/vip-section-title/VipSectionTitle'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { VipPriceInsightDisclaimer } from '@/components/vip/vip-price-insights/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ROUTE_LOCALE } from '@/domain/locale'
import { PriceInsightsModal } from '@/features/listing/components/price-insights/PriceInsightsModal'
import { PriceInsightsProgressBar } from '@/features/listing/components/price-insights/PriceInsightsProgressBar'
import { getPriceClassification } from '@/features/listing/utils/getPriceClassification'
import { useLocale } from '@/hooks/useLocale'
import { RemoteToggles } from '@/lib/firebase/config/toggles'
import { useToggle } from '@/lib/firebase/hooks'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { isAutosDealerAmountPrice } from '@/types/search'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { TextLink } from '@/ui/atoms/text-link'

export type VipPriceInsightProps = {
  listingPrice?: ListingPrice | null
}

export const VipPriceInsight: FC<VipPriceInsightProps> = ({ listingPrice }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.COMMON, TRANSLATION_KEYS.LISTING])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { routeLocale } = useLocale()
  const { colors, spacing } = useTheme()
  const priceInsightToggle = useToggle(RemoteToggles.AUTOS_PRICE_INSIGHT.key)

  const priceClassification = getPriceClassification(listingPrice)

  if (
    priceInsightToggle?.enabled !== true ||
    !isAutosDealerAmountPrice(listingPrice) ||
    !priceClassification?.averagePrice
  ) {
    return null
  }

  /** Difference between average price provided by the BE and the listing price */
  const priceDifference = priceClassification.averagePrice - listingPrice.amount
  const isPriceAboveMarket = priceDifference < 0

  /** Format the absolute value to show in the description */
  const formattedPriceDifference = formatPrice(Math.abs(priceDifference), {
    isFrench: routeLocale === ROUTE_LOCALE.fr,
    suppressCents: true,
  })

  const handleLearnMoreClick = () => {
    trackEvent({ action: GA_EVENT.LearnMoreClick, label: 'partner=price_insights' })
    setIsModalOpen(true)
  }
  const handleModalClose = () => setIsModalOpen(false)

  return (
    <>
      <VipSectionContainer>
        <Spacing mBottom={spacing.default}>
          <VipSectionTitle title={t('listing:price_insights.title')} />
        </Spacing>

        <Flex
          flexDirection="column"
          gap={spacing.large}
          media={{ medium: { gap: spacing.xLarge } }}
        >
          <BodyText size="medium" color={colors.grey.primary}>
            {t(
              `listing:price_insights.${isPriceAboveMarket ? 'above' : 'below'}_market.description`,
              { priceDifference: formattedPriceDifference }
            )}
          </BodyText>

          <PriceInsightsProgressBar
            price={listingPrice.amount}
            averagePrice={priceClassification.averagePrice}
            rating={priceClassification.rating}
          />
          <VipPriceInsightDisclaimer>
            <BodyText size={{ small: 'xSmall', medium: 'small' }} color={colors.grey.light1}>
              {t('listing:price_insights.disclaimer')}{' '}
            </BodyText>

            <TextLink
              aria-label={t('listing:price_insights.learn_more.accessibility_label')}
              as="button"
              size="small"
              hasUnderline
              variant="secondary"
              onClick={handleLearnMoreClick}
            >
              {t('common:labels.learn_more')}
            </TextLink>
          </VipPriceInsightDisclaimer>
        </Flex>
      </VipSectionContainer>

      {isModalOpen && (
        <PriceInsightsModal isOpen={isModalOpen} handleModalClose={handleModalClose} />
      )}
    </>
  )
}
