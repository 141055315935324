import { type ListingFlags } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import {
  CoreListingPriceBadge,
  CoreListingPriceContainer,
  CoreListingPriceDropLabel,
} from '@/components/shared/core-listing-card/card-details/styled'
import { ListingSurcharges } from '@/components/shared/listing-surcharges/ListingSurcharges'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { getListingBadgeLabel } from '@/domain/listings/getListingBadgeLabel'
import { getListingPrice } from '@/domain/listings/getListingPrice'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { type GenericListing } from '@/features/listing/types/listings'
import { useLocale } from '@/hooks/useLocale'
import { isAutosDealerAmountPrice } from '@/types/search'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'

export type CoreListingPrice = {
  categoryId: number
  flags?: ListingFlags
  price: GenericListing['price']
}

export const CoreListingPrice: FC<CoreListingPrice> = ({ categoryId, price, flags }) => {
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { routeLocale } = useLocale()
  const { colors, spacing } = useTheme()

  const formattedPrice = getListingPrice({ price, t, routeLocale })
  const autosPrice = price && isAutosDealerAmountPrice(price) ? price : undefined
  const amountPrice = price && isAmountPrice(price) ? price : undefined

  const badgeLabel = flags
    ? getListingBadgeLabel({
        categorySpecificBadge: flags.categorySpecificBadge ?? false,
        categoryId,
      })
    : undefined

  return (
    <Flex alignItems="center">
      <CoreListingPriceContainer mRight={spacing.mini}>
        <HeadlineText as="h2" color={colors.grey.primary} size="medium">
          {formattedPrice}
        </HeadlineText>

        <ListingSurcharges surcharges={autosPrice?.surcharges} />
      </CoreListingPriceContainer>

      {/* Should show badge when there is a price drop */}
      {amountPrice?.originalAmount ? (
        <CoreListingPriceBadge mRight={spacing.defaultSmall}>
          <CoreListingPriceDropLabel size="small" data-testid="price-drop-original">
            {getListingPrice({ price: amountPrice.originalAmount, t, routeLocale })}
          </CoreListingPriceDropLabel>
        </CoreListingPriceBadge>
      ) : null}

      {badgeLabel ? (
        <CoreListingPriceBadge mRight={spacing.defaultSmall} data-testid="price-badge">
          <BodyText size="small">{t(`listing:badges.${badgeLabel}`).toUpperCase()}</BodyText>
        </CoreListingPriceBadge>
      ) : null}
    </Flex>
  )
}
