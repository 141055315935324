import { type FC } from 'react'

import {
  VipSideProfileLogoContainer,
  VipSideProfileLogoImage,
  VipSideProfileTopContainer,
} from '@/components/vip/vip-seller-profile/styled'

export type VipSideProfileLogoProps = {
  logoUrl?: string | null
}

export const VipSideProfileLogo: FC<VipSideProfileLogoProps> = ({ logoUrl }) => {
  if (!logoUrl) return null

  return (
    <VipSideProfileTopContainer data-testid="profile-logo-section">
      <VipSideProfileLogoContainer>
        <VipSideProfileLogoImage data-testid="profile-logo" alt="" src={logoUrl} skipNextImage />
      </VipSideProfileLogoContainer>
    </VipSideProfileTopContainer>
  )
}
