import {
  type Maybe,
  type TransportationScore,
  type TransportationScoreData as TransportationScoreDataGraphQL,
} from '@kijiji/generated/graphql-types'

const PREFERRED_ORDER = ['walk', 'transit', 'bike']

type TransportationScoreKeys = keyof Omit<TransportationScore, '__typename'>
type TransportationScoreData = {
  [key in keyof Omit<TransportationScoreDataGraphQL, '__typename'>]: Omit<
    TransportationScoreDataGraphQL,
    '__typename'
  >[key]
}

/**
 * Orders transportation scores according to a predefined sequence (walk, transit, bike)
 * and filters out GraphQL typename fields.
 *
 * @param transportation - The transportation scores object from the GraphQL response
 * containing walk, transit and bike score data. Can be undefined/null.
 *
 * @returns An object with ordered transportation scores. Non-ordered transportation types are placed at the end.
 * Returns undefined if no valid transportation data.
 */
export const getOrderedTransportationScores = (
  transportation: Maybe<TransportationScore> | undefined
): Partial<Record<TransportationScoreKeys, TransportationScoreData>> | undefined => {
  if (!transportation || Object.keys(transportation).length === 0) return undefined

  const orderedEntries = Object.entries(transportation)
    .filter(([, value]) => typeof value !== 'string' && value) // Filter out __typename
    .sort(([keyA], [keyB]) => {
      const indexA = PREFERRED_ORDER.indexOf(keyA)
      const indexB = PREFERRED_ORDER.indexOf(keyB)
      if (indexA === -1) return 1 // Move non-ordered keys at the end
      if (indexB === -1) return -1
      return indexA - indexB
    })

  const scores = Object.fromEntries(orderedEntries)
  return scores
}
