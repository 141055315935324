import { SearchIcon } from '@kijiji/icons/src/icons'
import SvgLocationOutline from '@kijiji/icons/src/icons/LocationOutline'
import SvgLocationSolid from '@kijiji/icons/src/icons/LocationSolid'
import { useTranslation } from 'next-i18next'
import { type FC, useCallback, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { ShowWhenSmaller } from '@/components/shared/breakpoint'
import { FadeOutSection } from '@/components/shared/fade-out-section/FadeOutSection'
import {
  VipLocationClickableOverlay,
  VipLocationDirectionsButton,
  VipLocationExploreButton,
  VipLocationMapContainer,
} from '@/components/vip/shared/vip-location/styled'
import { InlineIconLink } from '@/components/vip/vip-seller-profile/InlineIconLink'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { type GenericMapProps, GenericMap } from '@/features/map/components/generic/GenericMap'
import { MapModal } from '@/features/map/components/generic/MapModal'
import { MAP_ZOOM_CONTROL } from '@/features/map/constants/map'
import { useFetchLocationFromPlaceId } from '@/hooks/useFetchLocationFromPlaceId'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryListing, isRealEstateListingLocation } from '@/types/listings'
import { BodyText } from '@/ui/atoms/body-text'
import { Divider } from '@/ui/atoms/divider'
import { Spacing } from '@/ui/atoms/spacing'

type ListingLocation = VipQueryListing['location']
export enum VIP_LOCATION_VARIANT {
  LINK = 'link',
  LINK_MAP = 'link-with-map',
  MAP = 'map',
  MAP_NEIGHBOURHOOD = 'map-with-neighbourhood-info',
}

export type VipLocationProps = Pick<GenericMapProps, 'loadingSizeVariation'> & {
  location: ListingLocation
  showSolidIcon?: boolean
  variant: VIP_LOCATION_VARIANT
}

/**
 * This component encapsulates all the variants to display a VIP Location
 * It can return just the address as a link, the link with a map, just the map or the map with the neighbourhood information (only if a listing has neighbourhood information)
 *
 * When clicking on the location (any of the UI variants) a modal with an interactive map will automatically open.
 *
 * The "map" variation of this component is not interactive, only the map inside of the modal is.
 */
export const VipLocation: FC<VipLocationProps> = ({
  location,
  showSolidIcon,
  variant,
  loadingSizeVariation,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.VIP)
  const { colors, spacing } = useTheme()

  const [mapFailed, setMapFailed] = useState<boolean>(false)
  const [isLocationModalOpen, setIsLocationModalOpen] = useState<boolean>(false)
  const [coordinates, setCoordinates] = useState<ListingLocation['coordinates'] | null>(
    location.coordinates
  )

  const { fetchLocationFromPlaceId } = useFetchLocationFromPlaceId()

  useEffect(() => {
    /** Find coordinates if not returned as part of the listing */
    if (coordinates) return

    fetchLocationFromPlaceId({
      placeId: location.id.toString(),
      address: location.address ?? location.name,
    }).then((resp) => {
      if (!resp?.area) return

      const latitude = resp.area.latitude
      const longitude = resp.area.longitude

      setCoordinates({ latitude, longitude })
    })
  }, [coordinates, fetchLocationFromPlaceId, location])

  const openMapModal = useCallback(() => {
    trackEvent({
      action: GA_EVENT.ViewMapClick,
      label:
        variant === VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD
          ? 'section=neighbourhoods'
          : 'btn=staticmap',
    })
    setIsLocationModalOpen(true)
  }, [variant])
  const closeMapModal = () => setIsLocationModalOpen(false)

  const handleOnExpandNeighbourhoodSummary = () => {
    trackEvent({ action: GA_EVENT.ViewMore, label: 'section=neighbourhoods' })
  }

  /* 
    Depending on the variant we render a different button with a different style and text.
   - If the variant is MAP_NEIGHBOURHOOD we render the explore button, centered in the map with an grey overlay.
   - Otherwise, we render the directions button, on the top left of the map.
  */
  const renderOpenModalButton = useCallback(() => {
    if (mapFailed) return null
    if (variant !== VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD) {
      return (
        <VipLocationDirectionsButton variant="secondary" size="small" onClick={openMapModal}>
          {t('vip:seller.map.directions')}
        </VipLocationDirectionsButton>
      )
    }

    return (
      <>
        <VipLocationClickableOverlay
          aria-roledescription={t('vip:seller.map.overlay')}
          data-testid="vip-location-clickable-overlay"
          onClick={openMapModal}
        />
        <VipLocationExploreButton
          gap={spacing.mini}
          variant="tonal"
          size="small"
          onClick={openMapModal}
        >
          <SearchIcon size="default" color={colors.purple.primary} />
          {t('vip:seller.map.explore')}
        </VipLocationExploreButton>
      </>
    )
  }, [colors.purple.primary, mapFailed, openMapModal, spacing.mini, t, variant])

  const showMap =
    variant === VIP_LOCATION_VARIANT.LINK_MAP ||
    variant === VIP_LOCATION_VARIANT.MAP ||
    variant === VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD
  const showOnlyMap =
    variant === VIP_LOCATION_VARIANT.MAP || variant === VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD
  const hasSummary =
    isRealEstateListingLocation(location) && location.neighbourhoodInfo?.profile?.summary
  const shouldRenderNeighbourhoodSummary =
    variant === VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD && hasSummary

  const nonInteractiveMap = {
    draggable: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    gestureHandling: 'none',
  }

  const address = location.address ?? location.name

  if (!address) return null
  return (
    <>
      {showOnlyMap ? null : (
        <Spacing pBottom={showMap ? spacing.default : '0'} style={{ overflow: 'hidden' }}>
          <InlineIconLink
            Icon={showSolidIcon ? SvgLocationSolid : SvgLocationOutline}
            limitOnSingleLine
            iconColor={colors.purple.primary}
            isButton
            label={address}
            onClick={openMapModal}
          />
        </Spacing>
      )}

      {showMap && coordinates ? (
        <VipLocationMapContainer>
          <GenericMap
            coordinates={coordinates}
            loadingSizeVariation={loadingSizeVariation}
            mapProps={{
              zoomControl: false,
              zoom: VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD
                ? MAP_ZOOM_CONTROL.STREETS
                : MAP_ZOOM_CONTROL.CITY,
              ...nonInteractiveMap,
            }}
            showMarker={variant !== VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD}
            onError={() => setMapFailed(true)}
          />

          {renderOpenModalButton()}
        </VipLocationMapContainer>
      ) : null}

      {shouldRenderNeighbourhoodSummary ? (
        <FadeOutSection onExpand={handleOnExpandNeighbourhoodSummary}>
          <BodyText color={colors.grey.primary}>
            {location.neighbourhoodInfo?.profile?.summary}
          </BodyText>
          <Spacing
            pTop={spacing.default}
            medium={{
              pTop: spacing.defaultSmall,
            }}
          >
            <BodyText size="small" color={colors.grey.light1}>
              {t('vip:neighbourhood.disclaimer')}
            </BodyText>
          </Spacing>
        </FadeOutSection>
      ) : null}

      {variant === VIP_LOCATION_VARIANT.MAP_NEIGHBOURHOOD && !hasSummary ? (
        <ShowWhenSmaller breakpoint="medium">
          <Divider />
        </ShowWhenSmaller>
      ) : null}

      <MapModal
        address={address}
        coordinates={coordinates}
        handleClose={closeMapModal}
        isOpen={isLocationModalOpen}
      />
    </>
  )
}
