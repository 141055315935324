import { ClientRender } from '@/components/shared/client-render'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { VipAdSenseWrapper } from '@/components/vip/vip-sponsored-ads/styled'
import { GptSponsored } from '@/components-page/vip/advertisement/GptSponsored'
import { VIP_GPT_ID } from '@/components-page/vip/advertisement/vipAdSlots'
import { AdSense } from '@/features/advertisement/components/adsense'
import { Flex } from '@/ui/atoms/flex'

type VipSponsoredAdsProps = {
  isEvip?: boolean
}

export const VipSponsoredAds = ({ isEvip }: VipSponsoredAdsProps) => {
  return (
    <VipSectionContainer pBottom="0">
      <ClientRender>
        {!isEvip && (
          <Flex flexDirection="column" alignItems="center">
            <GptSponsored gptId={VIP_GPT_ID.GPT_SPONSORED_LEFT} />
            <GptSponsored gptId={VIP_GPT_ID.GPT_SPONSORED_RIGHT} />
          </Flex>
        )}

        <VipAdSenseWrapper>
          <AdSense id="AFSBottom" />
        </VipAdSenseWrapper>
      </ClientRender>
    </VipSectionContainer>
  )
}
