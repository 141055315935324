import {
  isAnyBuyAndSellCategory,
  isAnyJobsCategory,
  isAnyRealEstateCategory,
} from '@kijiji/category'

import { lap } from '@/features/listing/scripts/lap/lap.min.js'
import { isPintListing } from '@/features/listing/utils/isPintListing'
import { type VipQueryListing, isRealEstateListingType } from '@/types/listings'
import { sendToLogger } from '@/utils/sendToLogger'

export enum GSIApplicationId {
  MLS = 'kijiji_mls',
  REAL_ESTATE = 'kijiji_realestate',
  JOBS = 'kijiji_jobs',
  RETAIL = 'kijiji_retail',
}

export enum GSIElementName {
  CLICK_TO_CALL = 'clickToCall',
  APPLY_ONLINE = 'applyOnline',
  VISIT_WEBSITE = 'visitWebsite',
}

export const GSI_ELEMENT_NAME = 'click'

/**
 * Retrieves the GSI Application ID for a given listing, based on category type and certain category attributes
 *
 * @param  - The listing object to evaluate.
 * @returns The GSI Application ID if the listing matches specific criteria, otherwise undefined.
 *
 */
export const getGSIApplicationId = (listing?: VipQueryListing) => {
  // Reject if listing is not a Pint/KMB listing
  if (!listing || !isPintListing(listing)) {
    return undefined
  }

  if (isAnyRealEstateCategory(listing.categoryId) && isRealEstateListingType(listing)) {
    if (listing.mlsAd) {
      return GSIApplicationId.MLS
    } else {
      return GSIApplicationId.REAL_ESTATE
    }
  }

  if (isAnyJobsCategory(listing.categoryId)) {
    return GSIApplicationId.JOBS
  }

  if (isAnyBuyAndSellCategory(listing.categoryId)) {
    return GSIApplicationId.RETAIL
  }
}

/**
 * Tracks an external link click event for GSI.
 *
 * Depending on the Category ID, it determines the element name to be tracked.
 *
 * @param categoryId - The ID of the category to determine the element name.
 */
export const trackGSIExternalLink = (categoryId: number) => {
  const elementName = isAnyJobsCategory(categoryId)
    ? GSIElementName.APPLY_ONLINE
    : GSIElementName.VISIT_WEBSITE

  try {
    lap.clickTrack({
      elementName,
      eventName: GSI_ELEMENT_NAME,
    })
  } catch (err) {
    sendToLogger(err, { fingerprint: ['gsi-tracking-vip'] })
  }
}
