import { AdvertisingSlot } from 'react-advertising'
import styled from 'styled-components'

type StyledDftTab = {
  $isLastTab: boolean
}

/**
 * GPT injects a 1x1 pixel iframe into this slot
 * because the iframe is display: inline, it gets its min-height from
 * the inherited font metrics and ends up with a height of 19px
 */
export const StyledGptSponsoredImpression = styled(AdvertisingSlot)`
  font-size: 0;
`

export const StyledDftTab = styled(AdvertisingSlot)<StyledDftTab>(
  ({ theme, $isLastTab }) => `
  &[data-google-query-id] iframe { 
    margin-bottom: ${$isLastTab ? theme.spacing.husky : theme.spacing.default};   
  }
`
)
