// constants for the Vip Ad slots

import { AD_NETWORK_CODE, AD_PATH_BASE } from '@/features/advertisement/constants/adSlots'
import { type PrebidConfig } from '@/features/advertisement/constants/prebid'
import { type AdSizesMap } from '@/features/advertisement/types/adConfig'

export const VIP_GPT_ID = {
  FOOTER: 'gpt-vip-footer',
  GPT_BIGBOX: 'GPTBigbox',
  GPT_SPONSORED_LEFT: 'gpt-sponsored-left',
  GPT_SPONSORED_RIGHT: 'gpt-sponsored-right',
  GPT_STICKY_BOTTOM: 'gpt-sticky-bottom',
  IMAGE_GALLERY_OVERLAY: 'ImageLeaderBoard',
  LEADER: 'gpt-vip-leader',
  POSTED: 'gpt-posted-vip',
  R2S: 'gpt-r2s-vip',
  SPONSORED_IMPRESSION: 'gpt-sponsored-tab-impression',
  TAB: 'gpt-vip-tab',
}

export const adSizesMapVip: AdSizesMap = {
  [VIP_GPT_ID.GPT_BIGBOX]: [
    [300, 250],
    [325, 50],
  ],
  [VIP_GPT_ID.GPT_SPONSORED_LEFT]: [
    [575, 210],
    [350, 90],
  ],
  [VIP_GPT_ID.GPT_SPONSORED_RIGHT]: [
    [575, 210],
    [350, 90],
  ],
  [VIP_GPT_ID.GPT_STICKY_BOTTOM]: [[728, 90]],
  [VIP_GPT_ID.IMAGE_GALLERY_OVERLAY]: [
    [320, 50],
    [728, 90],
  ],
  [VIP_GPT_ID.LEADER]: [
    [728, 90],
    [970, 90],
  ],
  [VIP_GPT_ID.FOOTER]: [
    [300, 250],
    [320, 50],
    [728, 90],
    [970, 90],
  ],
  [VIP_GPT_ID.R2S]: [
    [300, 250],
    [320, 50],
    [728, 90],
    [970, 250],
  ],
  [VIP_GPT_ID.POSTED]: [
    [300, 250],
    [320, 50],
    [728, 90],
    [970, 250],
  ],
  [VIP_GPT_ID.TAB]: [
    [352, 85],
    [720, 88],
  ],
  [VIP_GPT_ID.SPONSORED_IMPRESSION]: [[1, 1]],
}

/**
 * ad unit path the for vip
 * this is how GAM determines what ad inventory to serve
 */
export const BASE_AD_PATH_FOR_VIP = `/${AD_NETWORK_CODE}/${AD_PATH_BASE}vip`

/**
 * page test group array to be passed as a GPT targeting value in the ad config
 * based on what we're sending on legacy
 */
export const PTG = [
  'CASINT4862_0',
  'CASINT5487_1',
  'KCA34949_0',
  'KCA38991_0',
  'KCAN1050__1',
  'KCAN10715__1',
  'KCAN13748__-99',
  'KCAN15957__0',
  'KCAN17895__1',
  'KCAN1806__1',
  'KCAN18206__1',
  'KCAN18697__1',
  'KCAN19025__-99',
  'KCAN20660__1',
  'KCAN20710__0',
  'KCAN21013__1',
  'KCAN24506__0',
  'KCAN25089__0',
  'KCAN25434__2',
  'KCAN4308__-99',
  'KCAN5349__-99',
  'KCAN6062__0',
  'KCAN6084__-99',
  'KCAN667_1',
  'KCAN810__1',
  'KCAN909__0',
  'gpt-vip-footer_R',
]

/**
 * Based on production legacy VIP
 */
export const VIP_PREBID_SLOTS: Record<string, PrebidConfig> = {
  [VIP_GPT_ID.GPT_BIGBOX]: {
    bids: [
      {
        bidder: 'appnexus',
        params: {
          placementId: '10477243',
        },
        labels: ['GPTBigbox-990', 'GPTBigbox-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190669',
          size: adSizesMapVip[VIP_GPT_ID.GPT_BIGBOX][0], // 300x250
        },
        labels: ['GPTBigbox-990', 'GPTBigbox-0'],
        responsiveRules: {},
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714690',
        },
        labels: ['GPTBigbox-990', 'GPTBigbox-0'],
        responsiveRules: {},
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '155984',
          adSlot: 'PUBMATIC_VIEW_ITEM_PAGE_BOTTOM_ENGLISH_300x250@300x250',
        },
        labels: ['GPTBigbox-990', 'GPTBigbox-0'],
        responsiveRules: {},
      },
      {
        bidder: 'rubicon',
        params: {
          siteId: '45324',
          accountId: '12230',
          zoneId: '582472',
        },
        labels: ['GPTBigbox-990', 'GPTBigbox-0'],
        responsiveRules: {},
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_ViewItem_Desktop_InFeed_Bottom',
        },
        labels: ['GPTBigbox-990', 'GPTBigbox-0'],
        responsiveRules: {},
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '508684',
          pageId: '1599356',
          formatId: '112837',
        },
        labels: ['GPTBigbox-990', 'GPTBigbox-0'],
        responsiveRules: {},
      },
    ],
    mediaTypes: {
      banner: {
        sizes: adSizesMapVip[VIP_GPT_ID.GPT_BIGBOX],
      },
    },
  },
  [VIP_GPT_ID.POSTED]: {
    bids: [
      {
        bidder: 'appnexus',
        params: {
          placementId: '10477092',
        },
        labels: ['gpt-posted-vip-990', 'gpt-posted-vip-760', 'gpt-posted-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190665',
          size: adSizesMapVip[VIP_GPT_ID.POSTED][2], // 728x90
        },
        labels: ['gpt-posted-vip-990', 'gpt-posted-vip-760', 'gpt-posted-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190665',
          size: adSizesMapVip[VIP_GPT_ID.POSTED][3], // 970x250
        },
        labels: ['gpt-posted-vip-990', 'gpt-posted-vip-760', 'gpt-posted-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714686',
        },
        labels: ['gpt-posted-vip-990', 'gpt-posted-vip-760', 'gpt-posted-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '155984',
          adSlot: 'PUBMATIC_ADFLOW_TOP_ENGLISH_728x90@728x90',
        },
        labels: ['gpt-posted-vip-990', 'gpt-posted-vip-760', 'gpt-posted-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'rubicon',
        params: {
          siteId: '45324',
          accountId: '12230',
          zoneId: '582460',
        },
        labels: ['gpt-posted-vip-990', 'gpt-posted-vip-760', 'gpt-posted-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_Adflow_Desktop_InFeed_Top',
        },
        labels: ['gpt-posted-vip-990', 'gpt-posted-vip-760', 'gpt-posted-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '508684',
          pageId: '1599357',
          formatId: '112837',
        },
        labels: ['gpt-posted-vip-990', 'gpt-posted-vip-760', 'gpt-posted-vip-0'],
        responsiveRules: {},
      },
    ],
    mediaTypes: {
      banner: {
        sizes: adSizesMapVip[VIP_GPT_ID.POSTED],
      },
    },
  },
  [VIP_GPT_ID.LEADER]: {
    bids: [
      {
        bidder: 'appnexus',
        params: {
          placementId: '10477242',
        },
        labels: ['gpt-vip-leader-990', 'gpt-vip-leader-760', 'gpt-vip-leader-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190668',
          size: adSizesMapVip[VIP_GPT_ID.LEADER][0], // 728x90
        },
        labels: ['gpt-vip-leader-990', 'gpt-vip-leader-760', 'gpt-vip-leader-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190668',
          size: adSizesMapVip[VIP_GPT_ID.LEADER][1], // 970x90
        },
        labels: ['gpt-vip-leader-990', 'gpt-vip-leader-760', 'gpt-vip-leader-0'],
        responsiveRules: {},
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714689',
        },
        labels: ['gpt-vip-leader-990', 'gpt-vip-leader-760', 'gpt-vip-leader-0'],
        responsiveRules: {},
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '155984',
          adSlot: 'PUBMATIC_VIEW_ITEM_PAGE_TOP_ENGLISH_728x90@728x90',
        },
        labels: ['gpt-vip-leader-990', 'gpt-vip-leader-760', 'gpt-vip-leader-0'],
        responsiveRules: {},
      },
      {
        bidder: 'rubicon',
        params: {
          siteId: '45324',
          accountId: '12230',
          zoneId: '582470',
        },
        labels: ['gpt-vip-leader-990', 'gpt-vip-leader-760', 'gpt-vip-leader-0'],
        responsiveRules: {},
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_ViewItem_Desktop_InFeed_Top',
        },
        labels: ['gpt-vip-leader-990', 'gpt-vip-leader-760', 'gpt-vip-leader-0'],
        responsiveRules: {},
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '508684',
          pageId: '1599356',
          formatId: '112839',
        },
        labels: ['gpt-vip-leader-990', 'gpt-vip-leader-760', 'gpt-vip-leader-0'],
        responsiveRules: {},
      },
    ],
    mediaTypes: {
      banner: {
        sizes: adSizesMapVip[VIP_GPT_ID.LEADER],
      },
    },
  },
  [VIP_GPT_ID.IMAGE_GALLERY_OVERLAY]: {
    bids: [
      {
        bidder: 'appnexus',
        params: {
          placementId: '10477092',
        },
        labels: ['ImageLeaderBoard-840', 'ImageLeaderBoard-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190665',
          size: adSizesMapVip[VIP_GPT_ID.IMAGE_GALLERY_OVERLAY][0], // 728x90
        },
        labels: ['ImageLeaderBoard-840', 'ImageLeaderBoard-0'],
        responsiveRules: {},
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714686',
        },
        labels: ['ImageLeaderBoard-840', 'ImageLeaderBoard-0'],
        responsiveRules: {},
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '155984',
          adSlot: 'PUBMATIC_ADFLOW_TOP_ENGLISH_728x90@728x90',
        },
        labels: ['ImageLeaderBoard-840', 'ImageLeaderBoard-0'],
        responsiveRules: {},
      },
      {
        bidder: 'rubicon',
        params: {
          siteId: '45324',
          accountId: '12230',
          zoneId: '582460',
        },
        labels: ['ImageLeaderBoard-840', 'ImageLeaderBoard-0'],
        responsiveRules: {},
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_Adflow_Desktop_InFeed_Top',
        },
        labels: ['ImageLeaderBoard-840', 'ImageLeaderBoard-0'],
        responsiveRules: {},
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '508684',
          pageId: '1599357',
          formatId: '112837',
        },
        labels: ['ImageLeaderBoard-840', 'ImageLeaderBoard-0'],
        responsiveRules: {},
      },
    ],
    mediaTypes: {
      banner: {
        sizes: adSizesMapVip[VIP_GPT_ID.IMAGE_GALLERY_OVERLAY],
      },
    },
  },
  [VIP_GPT_ID.R2S]: {
    bids: [
      {
        bidder: 'appnexus',
        params: {
          placementId: '10477092',
        },
        labels: ['gpt-r2s-vip-990', 'gpt-r2s-vip-760', 'gpt-r2s-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190665',
          size: adSizesMapVip[VIP_GPT_ID.R2S][2], // 728x90
        },
        labels: ['gpt-r2s-vip-990', 'gpt-r2s-vip-760', 'gpt-r2s-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190665',
          size: adSizesMapVip[VIP_GPT_ID.R2S][3], // 970x250
        },
        labels: ['gpt-r2s-vip-990', 'gpt-r2s-vip-760', 'gpt-r2s-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714686',
        },
        labels: ['gpt-r2s-vip-990', 'gpt-r2s-vip-760', 'gpt-r2s-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '155984',
          adSlot: 'PUBMATIC_ADFLOW_TOP_ENGLISH_728x90@728x90',
        },
        labels: ['gpt-r2s-vip-990', 'gpt-r2s-vip-760', 'gpt-r2s-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'rubicon',
        params: {
          siteId: '45324',
          accountId: '12230',
          zoneId: '582460',
        },
        labels: ['gpt-r2s-vip-990', 'gpt-r2s-vip-760', 'gpt-r2s-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_Adflow_Desktop_InFeed_Top',
        },
        labels: ['gpt-r2s-vip-990', 'gpt-r2s-vip-760', 'gpt-r2s-vip-0'],
        responsiveRules: {},
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '508684',
          pageId: '1599357',
          formatId: '112837',
        },
        labels: ['gpt-r2s-vip-990', 'gpt-r2s-vip-760', 'gpt-r2s-vip-0'],
        responsiveRules: {},
      },
    ],
    mediaTypes: {
      banner: {
        sizes: adSizesMapVip[VIP_GPT_ID.R2S],
      },
    },
  },
  [VIP_GPT_ID.FOOTER]: {
    bids: [
      {
        bidder: 'appnexus',
        params: {
          placementId: '16687876',
        },
        labels: ['gpt-vip-footer-990', 'gpt-vip-footer-760', 'gpt-vip-footer-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '400482',
          size: adSizesMapVip[VIP_GPT_ID.FOOTER][2], // 728x90
        },
        labels: ['gpt-vip-footer-990', 'gpt-vip-footer-760', 'gpt-vip-footer-0'],
        responsiveRules: {},
      },
      {
        bidder: 'ix',
        params: {
          siteId: '400482',
          size: adSizesMapVip[VIP_GPT_ID.FOOTER][3], // 970x90
        },
        labels: ['gpt-vip-footer-990', 'gpt-vip-footer-760', 'gpt-vip-footer-0'],
        responsiveRules: {},
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '540825836',
        },
        labels: ['gpt-vip-footer-990', 'gpt-vip-footer-760', 'gpt-vip-footer-0'],
        responsiveRules: {},
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '155984',
          adSlot: 'PUBMATIC_VIEW_ITEM_PAGE_BASEBOARD_ENGLISH_728x90@728x90',
        },
        labels: ['gpt-vip-footer-990', 'gpt-vip-footer-760', 'gpt-vip-footer-0'],
        responsiveRules: {},
      },
      {
        bidder: 'rubicon',
        params: {
          siteId: '45324',
          accountId: '12230',
          zoneId: '1390056',
        },
        labels: ['gpt-vip-footer-990', 'gpt-vip-footer-760', 'gpt-vip-footer-0'],
        responsiveRules: {},
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_TRIPLELIFT_VIEW_ITEM_PAGE_BASEBOARD_ENGLISH_HDX',
        },
        labels: ['gpt-vip-footer-990', 'gpt-vip-footer-760', 'gpt-vip-footer-0'],
        responsiveRules: {},
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '508684',
          pageId: '1599356',
          formatId: '112838',
        },
        labels: ['gpt-vip-footer-990', 'gpt-vip-footer-760', 'gpt-vip-footer-0'],
        responsiveRules: {},
      },
    ],
    mediaTypes: {
      banner: {
        sizes: adSizesMapVip[VIP_GPT_ID.FOOTER],
      },
    },
  },
}
