import {
  ListingStatus,
  LogoType,
  useGetCategoryPathQuery,
  useGetProfileQuery,
} from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { type FC, type PropsWithChildren } from 'react'

import { createVipGptConfig } from '@/components-page/vip/advertisement/createVipGptConfig'
import { generateVipTargetingValues } from '@/components-page/vip/advertisement/generateVipGptTargetingValues'
import { AdsWrapper } from '@/features/advertisement/components/ads-wrapper/AdsWrapper'
import { prebidConfig } from '@/features/advertisement/components/prebid/prebidConfig'
import { useAdSense } from '@/features/advertisement/hooks/useAdSense'
import { isCommercialProfile } from '@/features/profile/types/profile'
import useCookiePreferences from '@/hooks/useCookiePreferences'
import { useLocale } from '@/hooks/useLocale'
import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import { type VipQueryListing, isRealEstateListingType } from '@/types/listings'
import { removeSpecialCharsAndLowerCase } from '@/utils/string'

export type VipAdsWrapperProps = PropsWithChildren & {
  listing: VipQueryListing
  isModalVisible: boolean
}

export const VipAdsWrapper: FC<VipAdsWrapperProps> = ({ listing, children, isModalVisible }) => {
  const { hasUserOptedOutTargeting = false } = useCookiePreferences()
  const { routeLocale, cookieLocale } = useLocale()
  const { status: userStatus } = useSession()

  const { data } = useGetCategoryPathQuery({
    fetchPolicy: 'cache-first',
    variables: {
      categoryId: listing.categoryId,
      locale: cookieLocale,
      locationId: listing.location.id,
    },
  })

  const { data: sellerData } = useGetProfileQuery({
    fetchPolicy: 'cache-first',
    variables: { profileId: listing.posterInfo.posterId, size: LogoType.ViewAd },
  })

  const categoryPath = data?.category?.categoryPaths ?? []

  const categoryPathIds = categoryPath.map((item) => String(item?.id))

  const isMlsAd = isRealEstateListingType(listing) ? !!listing.mlsAd : false

  const companyName =
    sellerData?.profile && isCommercialProfile(sellerData.profile)
      ? removeSpecialCharsAndLowerCase(sellerData?.profile.name)
      : undefined

  const gptTargetingValues = generateVipTargetingValues({
    adType: listing.type,
    categoryPathIds,
    categoryId: listing.categoryId,
    hasUserOptedOutTargeting,
    isMlsAd,
    listingAttributes: listing.attributes?.all,
    listingFlags: listing.flags,
    listingId: listing.id,
    listingPrice: listing.price,
    locationId: listing.location.id,
    routeLocale,
    userStatus,
    companyName,
  })

  const vipGptConfig = data
    ? createVipGptConfig({
        categoryPath,
        gptTargetingValues,
        routeLocale,
        isModalVisible,
      })
    : undefined

  const configParams = {
    allCategoryIds: categoryPathIds,
    locationId: listing.location.id,
    englishLocationName: listing.location.name,
    currentPage: 1, // based on legacy VIP
    keywordsOrTitle: listing.title,
    routeLocale,
    categoryPath,
    pageType: GA_PAGE_TYPE.VIEW_AD,
  }

  const isEVIP = listing?.status === ListingStatus.Expired

  useAdSense({
    configParams,
    active: true,
    containers: [{ container: 'AFSBottom', width: '100%', number: isEVIP ? 2 : 6 }],
  })

  /**
   * It should wait for the categoryPath to be available before it can load the ads
   * */
  return (
    <AdsWrapper gptConfig={vipGptConfig} prebidConfig={prebidConfig} skipBlockthrough>
      {children}
    </AdsWrapper>
  )
}
