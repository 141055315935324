import { isCarsTrucks } from '@kijiji/category'
import { SellerType } from '@kijiji/generated/graphql-types'

import { WARRANTY_LOCATION_ALLOWLIST } from '@/constants/autoCanada'
import { type LocationPath } from '@/types/location'

export const isAutocanWarrantyAvailable = ({
  categoryId,
  isDealerListing,
  locationPath,
  sellerType,
}: {
  categoryId: number
  isDealerListing: boolean
  locationPath: LocationPath[]
  sellerType: SellerType
}) => {
  /** It should be in correct province */
  const province = locationPath[1]
  const allowedLocation = WARRANTY_LOCATION_ALLOWLIST.includes(province?.id)

  /** It should be a FSBO listing */
  const isAllowedSeller = sellerType === SellerType.Fsbo && !isDealerListing

  /** It should be on Cars & Trucks Category */
  const allowedCategory = isCarsTrucks(categoryId)

  return allowedLocation && allowedCategory && isAllowedSeller
}
