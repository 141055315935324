import { BikeIcon, IconEllipsisIcon, SubwayIcon, WalkIcon } from '@kijiji/icons/src/icons'
import { createElement } from 'react'
import { useTheme } from 'styled-components'

import { ScoreItemContainer, ScoreText } from '@/components/vip/vip-neighbourhood-info/styled'
import { AttributeIconContainer } from '@/features/attributes/components/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'

const SCORE_ICONS = {
  walk: WalkIcon,
  transit: SubwayIcon,
  cycle: BikeIcon,
  default: IconEllipsisIcon,
}

type NeighbourhoodScoreItemProps = {
  /**
   * The type of score (walk, transit, cycle) used to determine which icon to display
   */
  name: string
  /**
   * The label for this score type
   */
  label: string
  /**
   * The numerical score value (between 0 and 10)
   */
  score: number
  /**
   * A text description of the score (e.g. "Excellent Transit")
   */
  description: string
}

/**
 * Component that displays a single neighbourhood score item with an icon, label, score and description
 * Used within the NeighbourhoodScores component to show individual transportation scores
 * @returns A list item containing the score information with consistent styling
 */
export const NeighbourhoodScoreItem = ({
  name,
  label,
  score,
  description,
}: NeighbourhoodScoreItemProps) => {
  const { colors, spacing } = useTheme()

  return (
    <ScoreItemContainer forwardedAs="li" gap={spacing.default} alignItems="center">
      <Flex>
        <AttributeIconContainer iconBackgroundVariant="secondary-bg">
          {createElement(SCORE_ICONS[name as keyof typeof SCORE_ICONS] ?? SCORE_ICONS.default, {
            size: 'default',
            color: colors.grey.primary,
          })}
        </AttributeIconContainer>
      </Flex>
      <Flex gap={spacing.mini} flexDirection="column">
        <Flex alignItems="center">
          <HeadlineText as="p" size="medium">
            {label}
          </HeadlineText>
          <ScoreText forwardedAs="p" color={colors.grey.primary} weight="bold" size="medium">
            {score}
          </ScoreText>
        </Flex>
        <BodyText color={colors.grey.light1}>{description}</BodyText>
      </Flex>
    </ScoreItemContainer>
  )
}
