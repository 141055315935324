import { CATEGORIES } from '@kijiji/category'
import { Trans, useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { PetsDisclaimerPanel } from '@/components/vip/vip-pets-disclaimer/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { ListItem } from '@/ui/atoms/list-item'
import { Spacing } from '@/ui/atoms/spacing'
import { List } from '@/ui/molecules/list'

export type VipPetsDisclaimerProps = {
  categoryId: number
}

export const VipPetsDisclaimer: FC<VipPetsDisclaimerProps> = ({ categoryId }) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.VIP)

  if (
    categoryId !== CATEGORIES.CATS_KITTENS_FOR_SALE_CATEGORY_ID &&
    categoryId !== CATEGORIES.DOGS_PUPPIES_FOR_SALE_CATEGORY_ID
  ) {
    return null
  }

  const renderListItem = (text: string) => {
    return (
      <ListItem variant="bullet" bulletColor={colors.grey.light2} size="xSmall">
        <BodyText size="small" color={colors.grey.primary}>
          {text}
        </BodyText>
      </ListItem>
    )
  }

  return (
    <Spacing mBottom={spacing.husky} pTop={spacing.default} medium={{ pTop: '0' }}>
      <Flex flexDirection="column" gap={spacing.default}>
        <PetsDisclaimerPanel>
          <Flex flexDirection="column" gap={spacing.default}>
            <HeadlineText as="h3" size="medium" weight="semiBold" color={colors.grey.primary}>
              {t('vip:disclaimer.pets.title')}
            </HeadlineText>

            <List spacing={spacing.defaultSmall}>
              {renderListItem(t('vip:disclaimer.pets.bullet_point_1'))}
              {renderListItem(t('vip:disclaimer.pets.bullet_point_2'))}
              {renderListItem(t('vip:disclaimer.pets.bullet_point_3'))}
            </List>
          </Flex>
        </PetsDisclaimerPanel>

        <BodyText size="xSmall" color={colors.grey.primary} data-testid="pets-legal-disclaimer">
          <Trans
            i18nKey="vip:disclaimer.pets.legal_text"
            components={[
              <LinkCustom
                size="xSmall"
                hasUnderline
                href={t('vip:disclaimer.pets.faq.link')}
                key="petsDisclaimerLink"
                noChildren
                target="_blank"
                variant="secondary"
              />,
            ]}
          />
        </BodyText>
      </Flex>
    </Spacing>
  )
}
