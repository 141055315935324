import { CARS_VEHICLES_CATEGORIES, isAnyCarsVehiclesCategory } from '@kijiji/category'

/**
 * It should be available for all PV (Power Vehicles) & NPV (Non-Power Vehicles)
 * */
const CATEGORIES_TO_EXCLUDE = [
  CARS_VEHICLES_CATEGORIES.AUTOMOTIVE_SERVICES,
  CARS_VEHICLES_CATEGORIES.OTHER,
]

/**
 * Defines if the payment estimator should be displayed
 * Depends on category and profile type
 *
 * @param categoryId - Category of listing to display calculator
 * @param isDealer - If the seller of the listing is a dealer
 */
export const isPaymentEstimatorAvailable = (categoryId: number, isDealer?: boolean) => {
  return (
    isAnyCarsVehiclesCategory(categoryId) && !CATEGORIES_TO_EXCLUDE.includes(categoryId) && isDealer
  )
}
