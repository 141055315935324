import { useRouter } from 'next/router'
import { useEffect } from 'react'

/**
 * Hook that deletes the correlation ID from the URL query parameters.
 *
 * The correlation ID is handled on the server side, so a separate hook is needed to remove it from
 * the URL query parameters once the page loads on the client side.
 */
export const useDeleteCorrelationId = () => {
  const router = useRouter()
  const { pathname } = router

  useEffect(() => {
    if (router.query.cid === undefined) return // No correlation ID to delete

    const newUrl = new URL(global.window.location.href)

    newUrl.searchParams.delete('cid')
    delete router.query['cid']

    router.replace({ pathname, query: router.query }, newUrl.href, { shallow: true })
  }, [pathname, router])
}
