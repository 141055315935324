import { type ParsedUrlQuery } from 'querystring'

/**
 * Kijiji can access MoVe listings from their external Id
 * This flag will tell BE this ID is not a Kijiji ID.
 *
 * The indication an id is external comes from the eid parameter in the VIP
 * That is the only page we can lookup external listings
 */
export const isExternalListingId = (query: ParsedUrlQuery) => {
  const isExternalId = query.eid === '1'

  return isExternalId
}
