import ChevronIcon from '@kijiji/icons/src/icons/Chevron'
import { theme } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'
import { type ButtonHTMLAttributes } from 'react'

import { Dot, PrevNextBtn } from '@/components/shared/carousel-buttons/styled'

type PrevNextButtonPropType = ButtonHTMLAttributes<HTMLButtonElement> & {
  enabled: boolean
  onClick?: () => void
  variation?: 'gallery' | 'carousel'
}

export const PrevButton: React.FC<PrevNextButtonPropType> = ({
  enabled,
  onClick,
  variation = 'carousel',
  ...props
}) => {
  const { t } = useTranslation('common')
  return (
    <PrevNextBtn
      type="button"
      aria-label={t('carousel.pagination.prev')}
      data-testid="prev-button"
      disabled={!enabled}
      onClick={onClick}
      prev
      variation={variation}
      {...props}
    >
      <ChevronIcon stroke={theme.colors.white} />
    </PrevNextBtn>
  )
}

export const NextButton: React.FC<PrevNextButtonPropType> = ({
  enabled,
  onClick,
  variation = 'carousel',
  ...props
}) => {
  const { t } = useTranslation('common')

  return (
    <PrevNextBtn
      type="button"
      aria-label={t('carousel.pagination.next')}
      data-testid="next-button"
      disabled={!enabled}
      next
      onClick={onClick}
      variation={variation}
      {...props}
    >
      <ChevronIcon stroke={theme.colors.white} />
    </PrevNextBtn>
  )
}

type DotButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isSelected: boolean
  index: number
  minItems: number
}

export const DotButton: React.FC<DotButtonProps> = ({
  isSelected,
  onClick,
  index,
  minItems,
  ...props
}) => {
  const { t } = useTranslation('common')

  const adsFromNumber = index === 0 ? 1 : index * minItems
  const adsToNumber = minItems * index + minItems

  return (
    <Dot
      type="button"
      onClick={onClick}
      isSelected={isSelected}
      aria-label={t('carousel.ads.dot', {
        fromNumber: adsFromNumber,
        toNumber: adsToNumber,
      })}
      {...props}
    />
  )
}
