import { type AnchorHTMLAttributes, type ComponentType, type FC } from 'react'
import { useTheme } from 'styled-components'

import { EXTERNAL_LINKS_REL } from '@/constants/externalLinks'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { TextLink } from '@/ui/atoms/text-link'

export type InlineIconLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  Icon: ComponentType<React.SVGProps<SVGSVGElement>>
  /**
   * Defines if the text should be capped before the text wraps to the next line
   */
  limitOnSingleLine?: boolean
  href?: string
  isButton?: boolean
  isExternalLink?: boolean

  /**
   * Specifies if this inline label should not be interactive
   * It will be rendered with the same style as a "p" tag
   */
  isNonInteractive?: boolean

  label: string
  /**
   * Specifies a custom color for the icon
   */
  iconColor?: string
  onClick?: () => void
}

export const InlineIconLink: FC<InlineIconLinkProps> = ({
  Icon,
  href,
  isButton,
  isExternalLink,
  label,
  onClick,
  iconColor,
  limitOnSingleLine,
  target,
  isNonInteractive,
  ...rest
}) => {
  const { colors, spacing } = useTheme()

  const ellipsisStyle = limitOnSingleLine
    ? {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    : {}

  const linkProps = isButton
    ? {}
    : { href, rel: isExternalLink ? EXTERNAL_LINKS_REL : undefined, target: target ?? '_blank' }

  return (
    <Flex alignItems="flex-start" flexDirection="row" gap={spacing.defaultSmall}>
      <Icon
        aria-hidden
        width="2.4rem"
        color={iconColor ?? colors.purple.primary}
        style={{ flexShrink: 0 }}
      />

      {isNonInteractive ? (
        <BodyText size="medium" color={colors.purple.primary} textAlign="left" {...rest}>
          {label}
        </BodyText>
      ) : (
        <TextLink
          as={isButton ? 'button' : 'a'}
          hasUnderline
          onClick={onClick}
          size="medium"
          style={{ textAlign: 'left', ...ellipsisStyle }}
          variant="secondary"
          {...linkProps}
          {...rest}
        >
          {label}
        </TextLink>
      )}
    </Flex>
  )
}
