import { AdType } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC, type RefObject } from 'react'
import { useTheme } from 'styled-components'

import { VerticalDivider } from '@/components/shared/vertical-divider/VerticalDivider'
import { VipGridLayout, VipSectionWrapper } from '@/components/vip/styled'
import { VipActionButtons } from '@/components/vip/vip-action-buttons/VipActionButtons'
import { VipPrice } from '@/components/vip/vip-price/VipPrice'
import { useScrollVisibility } from '@/components/vip/vip-sticky-components/hooks/useScrollVisibility'
import {
  VipStickyHeaderConstraints,
  VipStickyHeaderContainer,
  VipStickyHeaderFlexDividers,
  VipStickyHeaderImageContainer,
  VipStickyHeaderTitle,
} from '@/components/vip/vip-sticky-components/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ListingGalleryMainImage } from '@/features/listing-gallery/components/ListingGalleryMainImage'
import { type VipQueryListing } from '@/types/listings'

export type VipStickyHeaderProps = {
  /**
   * Reference of the element the sticky header needs to pass to disappear
   */
  bottomVisibilityLimitRef: RefObject<HTMLDivElement>

  listing: VipQueryListing
  /**
   * Reference of the element the sticky header needs to pass by to be visible
   */
  topVisibilityRef: RefObject<HTMLDivElement>

  isSellerVip: boolean
}

export const VipStickyHeader: FC<VipStickyHeaderProps> = ({
  bottomVisibilityLimitRef,
  isSellerVip,
  listing,
  topVisibilityRef,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.VIP)
  const { colors } = useTheme()
  const { categoryId, flags, imageUrls, price, title, type } = listing
  const isVisible = useScrollVisibility(topVisibilityRef, bottomVisibilityLimitRef)

  return (
    <VipStickyHeaderConstraints isVisible={isVisible}>
      <VipGridLayout>
        <VipSectionWrapper>
          <VipStickyHeaderContainer data-testid="vip-sticky-header">
            <VipStickyHeaderFlexDividers alignItems="center">
              {imageUrls.length ? (
                <VipStickyHeaderImageContainer>
                  <ListingGalleryMainImage imageUrls={imageUrls} notInteractive />
                </VipStickyHeaderImageContainer>
              ) : null}

              <VipStickyHeaderFlexDividers>
                <VipPrice
                  categoryId={categoryId}
                  listingFlags={flags}
                  price={price}
                  isSimplified
                  size="medium"
                />

                <VerticalDivider />
              </VipStickyHeaderFlexDividers>
            </VipStickyHeaderFlexDividers>

            <VipStickyHeaderTitle
              forwardedAs="h2"
              color={colors.grey.primary}
              size={{ small: 'small', medium: 'medium' }}
              weight="regular"
            >
              {type === AdType.Wanted ? t('vip:ad_type.title.wanted') : ''} {title}
            </VipStickyHeaderTitle>

            <div style={{ marginLeft: 'auto' }}>
              <VipActionButtons listing={listing} isSimplified isSellerVip={isSellerVip} />
            </div>
          </VipStickyHeaderContainer>
        </VipSectionWrapper>
      </VipGridLayout>
    </VipStickyHeaderConstraints>
  )
}
