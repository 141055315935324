import { useListingRequestFinancingMutation } from '@kijiji/generated/graphql-types'
import { Formik } from 'formik'
import { type Session } from 'next-auth'
import { Trans, useTranslation } from 'next-i18next'
import { type FC, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { useFormValidation } from '@/hooks/useFormValidation'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal } from '@/ui/molecules/modal/Modal'
import { SystemMessage } from '@/ui/molecules/system-message'
import { TextField } from '@/ui/molecules/text-field'
import { sendToLogger } from '@/utils/sendToLogger'
import { validationFnPerField } from '@/utils/validation'

enum FORM_FIELDS {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
}

const validationObj = {
  [FORM_FIELDS.EMAIL]: validationFnPerField.email,
  [FORM_FIELDS.FIRST_NAME]: validationFnPerField.first_name,
  [FORM_FIELDS.LAST_NAME]: validationFnPerField.last_name,
  [FORM_FIELDS.PHONE]: validationFnPerField.phone_number,
}

export type VipFinancingModalProps = {
  isOpen: boolean
  handleClose: () => void
  userData: Session['user']
  listingId: string
}

export const VipFinancingModal: FC<VipFinancingModalProps> = ({
  isOpen,
  handleClose,
  userData,
  listingId,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.VIP, TRANSLATION_KEYS.COMMON])
  const { validateForm } = useFormValidation()
  const { spacing } = useTheme()
  const hasFiredFocusEvent = useRef(false)

  const [completedInquiry, setCompletedInquiry] = useState<boolean>(false)
  const [requestFinancing, { loading, error: requestError }] = useListingRequestFinancingMutation()

  const defaultFormValues = {
    [FORM_FIELDS.FIRST_NAME]: userData.name ?? '',
    [FORM_FIELDS.LAST_NAME]: '',
    [FORM_FIELDS.EMAIL]: userData.email ?? '',
    [FORM_FIELDS.PHONE]: '',
  }

  const handleValidation = (values: typeof defaultFormValues) => {
    const errors = validateForm(validationObj, values)

    if (Object.keys(errors).length > 0) {
      trackEvent({ action: GA_EVENT.AutoFinancingAttempt })
      trackEvent({ action: GA_EVENT.AutoFinancingFail })
    }

    return errors
  }

  const handleRequestSubmit = async (values: typeof defaultFormValues) => {
    trackEvent({ action: GA_EVENT.AutoFinancingAttempt })

    try {
      await requestFinancing({
        variables: {
          listingRequestFinancingId: listingId,
          email: values[FORM_FIELDS.EMAIL],
          firstName: values[FORM_FIELDS.FIRST_NAME],
          lastName: values[FORM_FIELDS.LAST_NAME],
          phoneNumber: values[FORM_FIELDS.PHONE],
        },
      })

      trackEvent({ action: GA_EVENT.AutoFinancingSuccess })
      setCompletedInquiry(true)
    } catch (err) {
      sendToLogger(err, { fingerprint: ['requestFinancing'] })
      trackEvent({ action: GA_EVENT.AutoFinancingFail })
    }
  }

  const handleFieldFocus = () => {
    // Only fire the focus event once
    if (!hasFiredFocusEvent.current) {
      hasFiredFocusEvent.current = true
      trackEvent({
        action: GA_EVENT.AutoFinancingBegin,
      })
    }
  }

  return (
    <Modal
      closeButtonLabel={t('common:modals.close.button.label')}
      hasCloseButton
      id="financing-inquiry-modal"
      isOpen={isOpen}
      label={t('vip:financing.modal.title')}
      onCancel={handleClose}
      width={{ small: '100vw', medium: '60rem' }}
    >
      <Spacing pTop={spacing.large} pBottom={spacing.large}>
        <Flex flexDirection="column" gap={spacing.default}>
          <HeadlineText as="h3" size="large" textAlign="center">
            {t('vip:financing.modal.title')}
          </HeadlineText>

          <BodyText textAlign="center" size="medium">
            {t('vip:financing.modal.description')}
          </BodyText>

          {requestError ? (
            <SystemMessage
              description={t('common:forms.errors.generic.description')}
              hideCloseButton
              title={t('common:forms.errors.generic.title')}
              variation="error"
            />
          ) : null}
        </Flex>
      </Spacing>

      {completedInquiry ? (
        <SystemMessage
          variation="success"
          hideCloseButton
          title={t('vip:financing.success_message.title')}
        />
      ) : (
        <Formik
          initialValues={defaultFormValues}
          onSubmit={handleRequestSubmit}
          validate={handleValidation}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {({ handleChange, values, errors, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Flex flexDirection="column" gap={spacing.large}>
                  <Flex flexDirection="column" gap={spacing.defaultSmall}>
                    <TextField
                      bottom="0"
                      error={
                        errors[FORM_FIELDS.FIRST_NAME] &&
                        t(`common:${errors[FORM_FIELDS.FIRST_NAME]}`)
                      }
                      id={FORM_FIELDS.FIRST_NAME}
                      label={t('common:forms.inputs.first_name.label')}
                      onChange={handleChange}
                      onFocus={handleFieldFocus}
                      autoComplete="given-name"
                      value={values[FORM_FIELDS.FIRST_NAME]}
                    />

                    <TextField
                      bottom="0"
                      error={
                        errors[FORM_FIELDS.LAST_NAME] &&
                        t(`common:${errors[FORM_FIELDS.LAST_NAME]}`)
                      }
                      id={FORM_FIELDS.LAST_NAME}
                      label={t('common:forms.inputs.last_name.label')}
                      onChange={handleChange}
                      onFocus={handleFieldFocus}
                      autoComplete="family-name"
                      value={values[FORM_FIELDS.LAST_NAME]}
                    />

                    <TextField
                      bottom="0"
                      error={errors[FORM_FIELDS.EMAIL] && t(`common:${errors[FORM_FIELDS.EMAIL]}`)}
                      id={FORM_FIELDS.EMAIL}
                      label={t('common:forms.inputs.email.label')}
                      onChange={handleChange}
                      onFocus={handleFieldFocus}
                      autoComplete="email"
                      value={values[FORM_FIELDS.EMAIL]}
                    />

                    <TextField
                      bottom="0"
                      error={errors[FORM_FIELDS.PHONE] && t(`common:${errors[FORM_FIELDS.PHONE]}`)}
                      id={FORM_FIELDS.PHONE}
                      label={t('common:forms.inputs.phone.label')}
                      onChange={handleChange}
                      onFocus={handleFieldFocus}
                      autoComplete="tel"
                      value={values[FORM_FIELDS.PHONE]}
                    />
                  </Flex>

                  <Button isFullWidth type="submit" isLoading={loading} disabled={loading}>
                    {t('vip:financing.inquiry_label')}
                  </Button>
                </Flex>
              </form>
            )
          }}
        </Formik>
      )}

      <Spacing mTop={spacing.default}>
        <BodyText size="xSmall">
          <Trans
            i18nKey="vip:financing.form.disclaimer"
            components={[
              <LinkCustom
                size="xSmall"
                key="privacyCheckboxLink"
                variant="secondary"
                href={`${t('routes:external.terms_of_use.href')}`}
                openInNewTab={true}
                noChildren={true}
              />,
              <LinkCustom
                size="xSmall"
                key="privacyCheckboxLink"
                variant="secondary"
                href={`${t('routes:external.privacy_policy.href')}`}
                openInNewTab={true}
                noChildren={true}
              />,
            ]}
          />
        </BodyText>
      </Spacing>
    </Modal>
  )
}
