import { StyledGptSponsored } from '@/components-page/vip/advertisement/styled'
import { LEGACY_MIN_TABLET } from '@/constants/pageSettings'
import { triggerManualAdRefresh } from '@/features/advertisement/utils/triggerManualAdRefresh'
import { useBreakpointChange } from '@/hooks/useBreakpointChange'

type GptSponsoredProps = {
  gptId: string
}

export const GptSponsored = ({ gptId }: GptSponsoredProps) => {
  useBreakpointChange([LEGACY_MIN_TABLET], () => {
    triggerManualAdRefresh(gptId)
  })

  return <StyledGptSponsored id={gptId} data-testid={gptId} />
}
