import { isDogsOrCatsRehomingCategory } from '@kijiji/category'
import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'
import { type TFunction } from 'next-i18next'

import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { getAttributesDictionary } from '@/features/attributes/utils/getAttributesDictionary'
import { formatStartEndDate } from '@/utils/date/formatDateTime'
import { WEEK_IN_MS, WEEKS_IN_YEAR } from '@/utils/time'

const MIN_AGE_IN_WEEKS = 8

/**
 * Get the age of the animal in weeks
 * @param dateString
 * @returns age in weeks
 */
const getAgeInWeeks = (dateString: string) => {
  const today = new Date()
  const birthdate = new Date(dateString)

  return (today.getTime() - birthdate.getTime()) / WEEK_IN_MS
}

/**
 * Check if the animal can be rehomed (over 8 weeks old)
 * @param dateString
 * @returns true if the animal can be rehomed
 */
const canAnimalBeRehomed = (dateString: string) => {
  return getAgeInWeeks(dateString) >= MIN_AGE_IN_WEEKS
}

/**
 * Gets earliest possible rehoming date
 * birthdate.toISOString() returns in format YYYY-MM-DDTHH:MM:SS.000Z, which fails the isValidDate check
 * so passing directly to formatStartEndDate() function
 *
 * @param dateString
 * @returns earliest possible rehoming date
 */
const getMinimumDateFromBirth = (dateString: string) => {
  const birthdate = new Date(dateString)
  birthdate.setTime(birthdate.getTime() + WEEK_IN_MS * MIN_AGE_IN_WEEKS)

  return formatStartEndDate(birthdate.toISOString())
}

/**
 * Get the rehoming attribute for pets; must be over 8 weeks old to be rehomed
 * If not, we create an attribute with the minimum date from birth
 * then prepend it to the attributes array
 * @param t
 * @param attributes
 * @param categoryId
 * @returns rehoming date and updated attributes without mutating the original array
 */
export const addPetsRehomingAttribute = (
  t: TFunction,
  attributes: ListingAttributeV2[],
  categoryId: number
) => {
  if (!isDogsOrCatsRehomingCategory(categoryId)) {
    return attributes
  }

  const attributesDictionary = getAttributesDictionary(attributes)
  const birthdateString = attributesDictionary[ATTRIBUTES.BIRTH_DATE]?.values[0]

  if (!birthdateString || canAnimalBeRehomed(birthdateString)) {
    return attributes
  }

  const rehomeDate = getMinimumDateFromBirth(birthdateString)

  const rehomeAttribute: ListingAttributeV2 = {
    __typename: 'ListingAttributeV2',
    canonicalName: ATTRIBUTES.REHOME_DATE,
    values: [rehomeDate],
    name: t('listing:attributes.pets.rehomedate'),
  }

  return [rehomeAttribute, ...attributes]
}

export const getAnimalAge = (dateString: string) => {
  const numWeeks = getAgeInWeeks(dateString)

  switch (true) {
    case numWeeks < -1:
      return { translationKey: 'born_in', age: Math.abs(Math.floor(numWeeks)) }
    case numWeeks < 0:
      return { translationKey: 'born_this_week', age: null }
    case numWeeks < 1:
      return { translationKey: 'week_old', age: null }
    case numWeeks < WEEKS_IN_YEAR:
      return { translationKey: 'weeks_old_other', age: Math.round(numWeeks) }
    case Math.round(numWeeks / WEEKS_IN_YEAR) === 1:
      return { translationKey: 'year_old', age: null }
    case numWeeks > WEEKS_IN_YEAR:
      return { translationKey: 'years_old_other', age: Math.round(numWeeks / WEEKS_IN_YEAR) }

    // fallback to cover NaN
    default:
      return { translationKey: null, age: null }
  }
}
