import { type CoreListing } from '@kijiji/generated/graphql-types'
import { type ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { CoreListingCard } from '@/components/shared/core-listing-card/CoreListingCard'
import {
  ListingSimilarModalContainer,
  SystemMessageWrapper,
} from '@/features/listing/components/similar/styled'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { SystemMessage } from '@/ui/molecules/system-message'

export type ListingsSimilarModalBodyProps = {
  successMessage?: {
    title: string
    description: string | ReactNode
  }
  listings: CoreListing[]
  title?: string
}

export const ListingsSimilarModalBody = ({
  successMessage,
  listings,
  title,
}: ListingsSimilarModalBodyProps) => {
  const { spacing, colors } = useTheme()

  const handleListingClick = (position: number, listingId: string) => {
    trackEvent({
      action: GA_EVENT.SelectPromotion,
      label: `partneradid=${listingId};position=${position};promotion_name=recommendationsOnReply`,
    })
  }

  return (
    <>
      {successMessage ? (
        <SystemMessageWrapper>
          <SystemMessage
            description={
              <BodyText color={colors.grey.light1}>{successMessage.description}</BodyText>
            }
            hideCloseButton
            title={successMessage.title}
            variation="success"
          />
        </SystemMessageWrapper>
      ) : null}

      {title ? (
        <Spacing mBottom={spacing.default}>
          <HeadlineText
            as="h2"
            size={{ small: 'medium', medium: 'large' }}
            color={colors.grey.primary}
            weight="medium"
          >
            {title}
          </HeadlineText>
        </Spacing>
      ) : null}

      <ListingSimilarModalContainer>
        {listings?.map((listing, index) => {
          return (
            <CoreListingCard
              index={index}
              key={`listing-${listing.id}`}
              listing={listing}
              onClick={() => handleListingClick(index + 1, listing.id)}
              allowFavourite
            />
          )
        })}
      </ListingSimilarModalContainer>
    </>
  )
}
