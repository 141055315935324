import { HTMLAttributes, type ReactNode } from 'react'

import {
  LabelLine,
  LabelWrapper,
  ProgressBar,
  ProgressBarCompleted,
} from './styled'

export type ProgressBarPercentProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Unique ID for differentiating multiple progress bars
   */
  id?: string
  /**
   * The colour of the progress bar
   */
  color?: string
  /**
   * Name used for progress bar aria-label
   */
  label: string
  /**
   * Determines the progress the bar displays in % (i.e. 75)
   */
  progress: number
  /**
   * Optional React node progress label.
   * Will be displayed above the end of the completed portion of the progress bar.
   */
  progressLabel?: ReactNode
}

export const ProgressBarPercent = ({
  id = 'default',
  color,
  label,
  progress,
  progressLabel,
  ...rest
}: ProgressBarPercentProps) => {
  return (
    <ProgressBar
      aria-label={label}
      aria-valuemax={100}
      aria-valuemin={0}
      aria-valuenow={progress}
      role="progressbar"
      data-testid={`progress-bar-container-${id}`}
      {...rest}
    >
      <ProgressBarCompleted
        color={color}
        data-testid={`progress-bar-progress-${id}`}
        hasPointer={!!progressLabel}
        progress={progress}
      >
        {progressLabel && (
          <LabelWrapper data-testid={`progress-bar-label-${id}`}>
            {progressLabel}
            <LabelLine />
          </LabelWrapper>
        )}
      </ProgressBarCompleted>
    </ProgressBar>
  )
}
