/**
 * Checks if a given HTML element is visible within the viewport.
 * This function is useful for determining if an element is visible before scrolling to it. However, this function is less performant than using IntersectionObserver,
 * but if used it carefully, for example, knowing the function only be called once, it can be a good alternative due to its simplicity and ease of us without impacting performance.
 * (!) Use this function whenever you don't need to check the visibility of an element multiple times or when you don't need to track the visibility of an element over time.
 *
 * @param element - The HTML element to check for visibility in the viewport.
 * @returns A boolean indicating whether the element is visible in the viewport.
 */
export const isVisibleInViewport = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
