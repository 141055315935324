import { Reason, useListingReportMutation } from '@kijiji/generated/graphql-types'
import { Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { useFormValidation } from '@/hooks/useFormValidation'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { RadiosField } from '@/ui/molecules/radios-field'
import { SystemMessage } from '@/ui/molecules/system-message'
import { TextField } from '@/ui/molecules/text-field'
import { TextAreaField } from '@/ui/molecules/textarea-field'
import { sendToLogger } from '@/utils/sendToLogger'
import { validationFnPerField } from '@/utils/validation'

type ListingReportFormProps = {
  /**
   * The ID of the listing to report.
   */
  listingId: string

  /**
   * Callback to close the modal.
   */
  onClose: () => void
}

/**
 * Form to report a listing.
 */
export const ListingReportForm: FC<ListingReportFormProps> = ({ listingId, onClose }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING, TRANSLATION_KEYS.COMMON])
  const { validateForm } = useFormValidation()
  const { colors, spacing } = useTheme()

  const [reportListing, { error: reportListingError, loading }] = useListingReportMutation()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const handleValidation = (values: { email: string; reason: Reason; comment: string }) => {
    let isValid = validateForm(
      {
        // Validate the email field if it is not empty
        email: (value: string) => {
          if (value.trim()) {
            const validation = validationFnPerField.email(value)
            // TODO: do we want this common logic handled by the validator itself?
            if (validation) return `common:${validation}`
          }

          return null
        },
      },
      values
    )

    // If the user selects "Other" as the reason, they must provide a comment
    if (values.reason === Reason.Other && !values.comment.trim()) {
      isValid = { ...isValid, comment: t('listing:report.modal.comment_missing') }
    }

    if (Object.keys(isValid).length) {
      trackEvent({ action: GA_EVENT.ReportAdFail, label: 'error=UserInput;' })
    }

    return isValid
  }

  const handleRequestSubmit = async (values: {
    reason: Reason
    email?: string
    comment?: string
  }) => {
    trackEvent({ action: GA_EVENT.ReportAdAttempt })

    try {
      await reportListing({
        variables: {
          listingReportId: listingId,
          reason: values.reason,
          email: values.email,
          comment: values.comment,
        },
      })

      trackEvent({ action: GA_EVENT.ReportAdSuccess })
      setIsSuccess(true)
    } catch (err) {
      sendToLogger(err, { fingerprint: ['ListingReportForm'] })
      trackEvent({ action: GA_EVENT.ReportAdFail })
    }
  }

  // If the report was successful, show a success message
  if (isSuccess) {
    return (
      <>
        <SystemMessage
          hideCloseButton
          variation="success"
          title={t('listing:report.modal.success.title')}
          description={t('listing:report.modal.success.body')}
        />

        <Button isFullWidth onClick={onClose} type="button">
          {t('listing:report.modal.close_button')}
        </Button>
      </>
    )
  }

  return (
    <>
      {reportListingError && (
        <SystemMessage
          hideCloseButton
          variation="error"
          title={t('common:forms.errors.generic.title')}
          description={t('common:forms.errors.generic.description')}
        />
      )}

      <Formik
        initialValues={{ email: '', reason: Reason.Prohibited, comment: '' }}
        onSubmit={handleRequestSubmit}
        validate={handleValidation}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleChange, handleSubmit, values, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Flex flexDirection="column" gap={spacing.default}>
                <BodyText color={colors.grey.light1}>
                  {t('listing:report.modal.why_reported')}
                </BodyText>

                <RadiosField
                  bottom="0"
                  id="vip-report-reasons"
                  legend={t('listing:report.modal.why_reported')}
                  name="reason"
                  radios={[
                    {
                      checked: values.reason === Reason.Prohibited,
                      id: Reason.Prohibited,
                      label: t('listing:report.modal.reasons.prohibited'),
                      onChange: handleChange,
                      value: Reason.Prohibited,
                    },
                    {
                      checked: values.reason === Reason.Duplicated,
                      id: Reason.Duplicated,
                      label: t('listing:report.modal.reasons.duplicated'),
                      onChange: handleChange,
                      value: Reason.Duplicated,
                    },
                    {
                      checked: values.reason === Reason.Irrelevant,
                      id: Reason.Irrelevant,
                      label: t('listing:report.modal.reasons.not_relevant'),
                      onChange: handleChange,
                      value: Reason.Irrelevant,
                    },
                    {
                      checked: values.reason === Reason.Miscategorized,
                      id: Reason.Miscategorized,
                      label: t('listing:report.modal.reasons.miscategorized'),
                      onChange: handleChange,
                      value: Reason.Miscategorized,
                    },
                    {
                      checked: values.reason === Reason.Other,
                      id: Reason.Other,
                      label: t('listing:report.modal.reasons.other'),
                      onChange: handleChange,
                      value: Reason.Other,
                    },
                  ]}
                />

                <TextAreaField
                  error={errors.comment ? t(errors.comment) : undefined}
                  bottom="0"
                  formNoValidate
                  id="comment"
                  label={t('listing:report.modal.comment_label')}
                  onChange={handleChange}
                  value={values.comment}
                />

                <TextField
                  autoComplete="email"
                  bottom="0"
                  error={errors.email && t(errors.email)}
                  id="email"
                  label={t('common:forms.inputs.email.label')}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                />

                <Button
                  isFullWidth
                  isLoading={loading}
                  loadingLabel={t('common:forms.buttons.loading')}
                  type="submit"
                >
                  {t('common:forms.buttons.submit')}
                </Button>

                <BodyText color={colors.grey.light1} size="small" textAlign="center">
                  {t('listing:report.modal.disclaimer')}
                </BodyText>
              </Flex>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
