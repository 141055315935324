import { replaceEmptyParagraphs } from '@/utils/string'

export const formatListingDescription = (description?: string | null, isPintAd?: boolean) => {
  if (!description) return ''

  let formattedDescription = description

  if (isPintAd) {
    /**
     *  Replace/collapse any consecutive series of 2+ line breaks.
     * */
    formattedDescription = formattedDescription.replace(/(\r\n|\r|\n){2,}/g, '\n\n')
  } else {
    /**
     * Descriptions for regular ads coming from Box have incorrect line breaks
     * For example, if a user pressed ENTER once for a single break, we get a double.
     * Double line breaks come back triple – an extra control character is always present!
     * This function will normalize these break while avoiding side effects for Pint, etc.
     * Collapse any 4 or more breaks in a row and then collapse any remaining pairs.
     */
    formattedDescription = formattedDescription
      .replace(/(\r\n|\r|\n){4,}/g, '\n\n\n')
      .replace(/(\r\n|\r|\n){2}/g, '\n')
  }

  /**
   * Extract the contents inside a CDATA tag
   * Ex. <![CDATA[I am inside the CDATA tag]]> --> I am inside the CDATA tag
   */
  formattedDescription = formattedDescription.replace(/<!\[CDATA\[([\s\S]*?)\]\]>/g, '$1')
  /**
   * Find each <div> immediately followed by a <ul> and replace with an <h3> tag,
   *  only if the inner text does not contain the '<' sign.
   *  Ex. <div>I am a heading</div><ul>... -> <h3>I am a heading</h3><ul>...
   */
  formattedDescription = formattedDescription.replace(
    /<div[^/]*?\s[^</]>(.*?)<\/div>(?=<ul)/g,
    '<h3>$1</h3>'
  )

  /**
   * Paragraphs that have nothing are not rendered in the browser. This includes
   * <p>&nbsp;</p> and <p> </p>.
   */
  formattedDescription = replaceEmptyParagraphs(formattedDescription)

  /**
   * Remove any leading or trailing line breaks. e.g., <br /> <br/> hello world <br>
   */
  formattedDescription = formattedDescription.replace(/^(<br\s*\/?>\s*)+|(\s*<br\s*\/?>)+$/g, '')

  return formattedDescription
}
