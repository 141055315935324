import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgAttach: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.347 15.54c0 1.515-.522 2.803-1.566 3.867-1.043 1.063-2.32 1.595-3.828 1.595-1.51 0-2.789-.532-3.839-1.595-1.05-1.064-1.575-2.352-1.575-3.867V6.81c0-1.058.364-1.958 1.091-2.698.728-.74 1.62-1.11 2.678-1.11 1.058 0 1.95.37 2.678 1.11.728.74 1.092 1.64 1.092 2.697v8.27c0 .587-.205 1.092-.615 1.517-.41.424-.91.636-1.498.636a2.091 2.091 0 01-1.52-.626 2.062 2.062 0 01-.637-1.528V6.771h1v8.308c0 .319.11.59.328.816.219.225.488.337.807.337.32 0 .588-.112.807-.337.218-.225.328-.497.328-.816V6.79c-.004-.777-.271-1.436-.802-1.977-.53-.541-1.186-.812-1.968-.812-.775 0-1.43.274-1.966.822a2.737 2.737 0 00-.803 1.986v8.731c-.004 1.235.424 2.287 1.283 3.157.859.87 1.905 1.305 3.138 1.305 1.215 0 2.248-.435 3.099-1.305.85-.87 1.28-1.922 1.288-3.157V6.77h1v8.77z"
        fill="#3E4153"
      />
    </Icon>
  )
}
export default SvgAttach
