import {
  type CoreListing,
  ListingStatus,
  LogoType,
  useGetProfileQuery,
} from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'

import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { useTracking } from '@/lib/ga/hooks/useTracking'
import { type DataLayerListing } from '@/lib/ga/types/dataLayer'
import { trackEvent } from '@/lib/ga/utils'
import { getDatalayerListing } from '@/lib/ga/utils/getDatalayerListing'
import { getDatalayerSearch } from '@/lib/ga/utils/getDatalayerSearch'
import {
  type VipQueryListing,
  isRealEstateListingLocation,
  isRealEstateListingType,
} from '@/types/listings'

/**
 * Custom fields for VIP-specific data layer
 */
export type VipDatalayer = DataLayerListing

export type useVipTrackingProps = {
  listing?: VipQueryListing | null
  /** List of listings returned on eVip */
  eVipListings?: CoreListing[]
}

export const useVipTracking = ({ listing, eVipListings }: useVipTrackingProps) => {
  const { asPath } = useRouter()

  /**
   * We need the seller userType for tracking
   * ideally this would be send as part of the listing data from ANVIL
   */
  const { data: profileData } = useGetProfileQuery({
    skip: !listing?.posterInfo.posterId,
    fetchPolicy: 'cache-only',
    variables: {
      profileId: listing?.posterInfo.posterId ?? '',
      size: LogoType.ViewAd,
    },
  })

  const vipDataLayer = getDatalayerListing(listing, profileData?.profile)

  const isEVIP = listing?.status === ListingStatus.Expired

  /**
   * eVips will display similar listings instead of the ad
   * We need to append Impression tracking for them on the datalayer
   */
  const eVipSearchData =
    isEVIP && eVipListings?.length
      ? getDatalayerSearch({
          asPath,
          listingsData: eVipListings,
        })
      : undefined

  const handleExtraPageLoadingTracking = () => {
    if (
      listing &&
      isRealEstateListingType(listing) &&
      isRealEstateListingLocation(listing.location)
    ) {
      const { neighbourhoodInfo } = listing.location
      if (neighbourhoodInfo) {
        trackEvent({
          action: GA_EVENT.NeighbourhoodLoad,
        })
      }
    }
  }

  useTracking({
    categoryId: listing?.categoryId,
    locationId: listing?.location.id,
    pageData: { ...vipDataLayer, ...eVipSearchData },
    pageType: isEVIP ? GA_PAGE_TYPE.VIEW_EXPIRED_AD : GA_PAGE_TYPE.VIEW_AD,
    extraPageLoadingTracking: handleExtraPageLoadingTracking,
  })
}
