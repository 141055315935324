import { type RefObject, useEffect, useRef } from 'react'
import { AdvertisingSlot } from 'react-advertising'

import { useScrollVisibility } from '@/components/vip/vip-sticky-components/hooks/useScrollVisibility'
import { GptStickyBottomContainer } from '@/components-page/vip/advertisement/styled'
import { VIP_GPT_ID } from '@/components-page/vip/advertisement/vipAdSlots'
import { triggerManualAdRefresh } from '@/features/advertisement/utils/triggerManualAdRefresh'

type GptStickyBottomProps = {
  /**
   * Reference of the element the sticky header needs to pass to disappear
   */
  bottomVisibilityLimitRef: RefObject<HTMLDivElement>
  /**
   * Reference of the element the sticky header needs to pass by to be visible
   */
  topVisibilityRef: RefObject<HTMLDivElement>
}

export const GptStickyBottom = ({
  topVisibilityRef,
  bottomVisibilityLimitRef,
}: GptStickyBottomProps) => {
  const skipAdRefresh = useRef(true)
  const isVisible = useScrollVisibility(topVisibilityRef, bottomVisibilityLimitRef)

  // Effect to handle ad refresh
  useEffect(() => {
    if (isVisible && !skipAdRefresh.current) {
      triggerManualAdRefresh(VIP_GPT_ID.GPT_STICKY_BOTTOM)
    }

    if (isVisible && skipAdRefresh.current) {
      skipAdRefresh.current = false
    }
  }, [isVisible])

  return (
    <GptStickyBottomContainer isVisible={isVisible} data-testid="gpt-sticky-bottom-container">
      <AdvertisingSlot
        id={VIP_GPT_ID.GPT_STICKY_BOTTOM}
        data-testid={VIP_GPT_ID.GPT_STICKY_BOTTOM}
      />
    </GptStickyBottomContainer>
  )
}
