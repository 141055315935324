import throttle from 'lodash/throttle'
import { type RefObject, useEffect, useState } from 'react'

/**
 * Hook that manages visibility state based on scroll position between two elements
 * @param topVisibilityRef Reference to the element that triggers visibility when scrolled past
 * @param bottomVisibilityLimitRef Reference to the element that triggers hiding when scrolled past
 * @param throttleMs Throttle time in milliseconds
 * @returns Object containing isVisible state
 */
export const useScrollVisibility = (
  topVisibilityRef: RefObject<HTMLElement>,
  bottomVisibilityLimitRef: RefObject<HTMLElement>,
  throttleMs: number = 100
) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = throttle(() => {
      setIsVisible((prevState) => {
        if (!topVisibilityRef.current || !bottomVisibilityLimitRef.current) {
          return prevState
        }
        const displayTop = topVisibilityRef.current.getBoundingClientRect().top

        const hideTop = bottomVisibilityLimitRef.current.getBoundingClientRect().top
        const shouldBeVisible = displayTop < 0 && hideTop > 0

        return shouldBeVisible
      })
    }, throttleMs)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      handleScroll.cancel()
    }
  }, [topVisibilityRef, bottomVisibilityLimitRef, throttleMs])

  return isVisible
}
