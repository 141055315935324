import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { FadeOutSection } from '@/components/shared/fade-out-section/FadeOutSection'
import { VipGroupAttributesWrapper } from '@/components/vip/vip-attributes/styled'
import { divideArrayInTwoColumns } from '@/components/vip/vip-attributes/utils'
import { VipGenericAttributes } from '@/components/vip/vip-attributes/VipGenericAttributes'
import { AttributeGroup } from '@/features/attributes/components/AttributeGroup'
import {
  type DisplayableAttribute,
  type DisplayableGroupAttributes,
} from '@/features/attributes/types/listingAttributes'
import { Flex } from '@/ui/atoms/flex'

export type VipAttributesBodyProps = {
  groupAttributes: DisplayableGroupAttributes[]
  genericAttributes: DisplayableAttribute[]
  hasCoreAttributes?: boolean
  categoryId: number
}

export const VipAttributesBody: FC<VipAttributesBodyProps> = ({
  genericAttributes,
  groupAttributes,
  hasCoreAttributes,
}) => {
  const { spacing } = useTheme()

  /**
   * It should not return section if there are no attributes returned
   *
   * This check shouldn't be done for "attributes" before the transformations because there are attributes that come back from the API
   * but are removed from the list after parsed
   * */
  if (!groupAttributes.length && !genericAttributes.length) return null

  /**
   * Attributes need to respect their order and populate the first column
   * before moving to the next column. CSS grid won't respect this order,
   * therefore we will need to manually split the columns and populate them in side-by-side containers.
   */
  const groupColumns = groupAttributes.length ? divideArrayInTwoColumns(groupAttributes) : null
  const genericColumns = genericAttributes.length
    ? divideArrayInTwoColumns(genericAttributes)
    : null

  return (
    <FadeOutSection isMobileOnly maxSectionHeightRem={42}>
      <VipGroupAttributesWrapper data-testid="vip-attributes-body">
        {groupColumns ? (
          <>
            <Flex flexDirection="column" gap={spacing.large} data-testid="vip-attributes-group">
              {groupColumns.firstColumn.map(({ Icon, attributes, displayInline, label }, index) => {
                return (
                  <AttributeGroup
                    Icon={Icon}
                    attributes={attributes}
                    displayInline={displayInline}
                    iconBackgroundVariant={hasCoreAttributes ? 'no-bg' : 'secondary-bg'}
                    key={`group-attr-col-1-${index}`}
                    label={label}
                  />
                )
              })}
            </Flex>

            <Flex flexDirection="column" gap={spacing.large} data-testid="vip-attributes-group2">
              {groupColumns.secondColumn.map(
                ({ Icon, attributes, displayInline, label }, index) => {
                  return (
                    <AttributeGroup
                      Icon={Icon}
                      attributes={attributes}
                      displayInline={displayInline}
                      iconBackgroundVariant={hasCoreAttributes ? 'no-bg' : 'secondary-bg'}
                      key={`group-attr-col-2-${index}`}
                      label={label}
                    />
                  )
                }
              )}
            </Flex>
          </>
        ) : (
          <VipGenericAttributes attributes={genericColumns} />
        )}
      </VipGroupAttributesWrapper>
    </FadeOutSection>
  )
}
