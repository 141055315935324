export enum CPO_ALLOWED_PROGRAMS {
  ACURA = 'acura',
  GM = 'gm',
  HONDA = 'honda',
  KIA = 'kia',
  LEXUS = 'lexus',
  MAZDA = 'mazda',
  MERCEDES = 'mercedes',
  NISSAN = 'nissan',
  PORSCHE = 'porsche',
  TOYOTA = 'toyota',
  VOLKSWAGEN = 'volkswagen',
  VOLVO = 'volvo',
}

/**
 * Those programs were intentionally removed, but still returned from BE
 * We are mapping them here to prevent logging errors on Sentry
 */
export enum CPO_REMOVED_PROGRAMS {
  FORD = 'ford',
}

export const CPO_PROGRAMS_WITH_TYPOS: Record<string, CPO_ALLOWED_PROGRAMS> = {
  /**
   * The MoVe API returns this value to the BE
   * We need to support it in the FE
   */
  volkwagen: CPO_ALLOWED_PROGRAMS.VOLKSWAGEN,
}

/**
 * Identifies how many bullet points each partner has available
 * It will help map the keys to display
 */
export const CPO_BULLETS_PER_PARTNER: Record<string, number> = {
  [CPO_ALLOWED_PROGRAMS.ACURA]: 4,
  [CPO_ALLOWED_PROGRAMS.GM]: 4,
  [CPO_ALLOWED_PROGRAMS.HONDA]: 3,
  [CPO_ALLOWED_PROGRAMS.KIA]: 3,
  [CPO_ALLOWED_PROGRAMS.LEXUS]: 4,
  [CPO_ALLOWED_PROGRAMS.MAZDA]: 4,
  [CPO_ALLOWED_PROGRAMS.MERCEDES]: 4,
  [CPO_ALLOWED_PROGRAMS.NISSAN]: 4,
  [CPO_ALLOWED_PROGRAMS.PORSCHE]: 4,
  [CPO_ALLOWED_PROGRAMS.TOYOTA]: 4,
  [CPO_ALLOWED_PROGRAMS.VOLKSWAGEN]: 4,
  [CPO_ALLOWED_PROGRAMS.VOLVO]: 4,
} satisfies Record<CPO_ALLOWED_PROGRAMS, number>
