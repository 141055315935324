import VirtualTourIcon from '@kijiji/icons/src/icons/VirtualTour'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { VirtualTourBadgeContainer } from '@/features/listing-gallery/components/styled'

export const VirtualTourBadge = () => {
  const { colors } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)

  return (
    <VirtualTourBadgeContainer aria-label={t('listing:virtual_tour.title')}>
      <VirtualTourIcon color={colors.white} size="xsm" />
    </VirtualTourBadgeContainer>
  )
}
