import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { CoreListingCardLoading } from '@/components/shared/core-listing-card/CoreListingCardLoading'
import { LocatingListingsCarouselContainer } from '@/components/shared/listings-carousel/styled'
import { Flex } from '@/ui/atoms/flex'
import { Skeleton } from '@/ui/atoms/skeleton'

export type ListingsCarouselLoadingProps = {
  /** Carousel ID */
  id: string
  /**
   * Should show loading skeleton for title above the gallery
   */
  hasLoadingTitle?: boolean
  /**
   * Defines how many items in the gallery loading state
   * @default 5
   */
  itemsCount?: number
}

export const ListingsCarouselLoading: FC<ListingsCarouselLoadingProps> = ({
  hasLoadingTitle,
  id,
  itemsCount = 5,
}) => {
  const { spacing } = useTheme()

  return (
    <LocatingListingsCarouselContainer flexDirection="column" gap={spacing.defaultSmall}>
      {hasLoadingTitle && (
        <Skeleton count={1} data-testid="loading-gallery-title" variant="inline" width="25rem" />
      )}

      <Flex
        gap={spacing.default}
        data-testid="loading-gallery-slides-list"
        style={{ width: '100vh', overflow: 'hidden' }}
      >
        {Array(itemsCount)
          .fill('')
          .map((_, index) => (
            <CoreListingCardLoading key={`${id}-${index}`} />
          ))}
      </Flex>
    </LocatingListingsCarouselContainer>
  )
}
