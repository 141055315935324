import styled from 'styled-components'

export const RatingContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  display: flex;
  gap: ${theme.spacing.defaultSmall};
  justify-content: center;
`
)
