import SvgLocationSolid from '@kijiji/icons/src/icons/LocationSolid'
import { type MapProps, Map as GoogleMap, Marker } from '@vis.gl/react-google-maps'
import { useTranslation } from 'next-i18next'
import { type FC, useEffect } from 'react'
import { useTheme } from 'styled-components'

import { ErrorBoundary } from '@/components/shared/error-boundary'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { GenericMapProvider } from '@/features/map/components/generic/GenericMapProvider'
import {
  GenericMapContainer,
  GenericMapIconContainer,
} from '@/features/map/components/generic/styled'
import { MAP_ZOOM_CONTROL } from '@/features/map/constants/map'
import { useMapProvider } from '@/features/map/hooks/useMapProvider'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Loading } from '@/ui/atoms/loading'

export type GenericMapProps = {
  coordinates: {
    latitude: number
    longitude: number
  }

  mapProps?: Partial<MapProps>

  /**
   * Should only be true when the entire page is not wrapped with the map provider
   * and the location feature is contained in this modal
   */
  wrapMapWithProvider?: boolean

  /** Defines loading animation size variation */
  loadingSizeVariation?: 'default' | 'small'

  /**
   * Callback to handle the map error
   */
  onError?: () => void

  /**
   * Whether to show the marker on the map
   */
  showMarker?: boolean
}

/**
 * Generic Map component that renders a map
 */
export const GenericMap: FC<GenericMapProps> = ({
  coordinates,
  mapProps,
  wrapMapWithProvider,
  loadingSizeVariation,
  showMarker = true,
  onError,
}) => {
  const { isProviderLoading, mapProviderError, mapApiKey } = useMapProvider(wrapMapWithProvider)
  const { t } = useTranslation(TRANSLATION_KEYS.COMMON)
  const { colors } = useTheme()

  useEffect(() => {
    if (mapProviderError && onError) {
      onError()
    }
  }, [mapProviderError, onError])

  /** Error trying to fetch the key or is still Loading */
  if (isProviderLoading || mapProviderError || !mapApiKey) {
    return (
      <GenericMapContainer>
        <GenericMapIconContainer
          loadingSizeVariation={loadingSizeVariation}
          variation={mapProviderError ? 'error' : 'loading'}
          data-testid={`${mapProviderError ? 'map-error-container' : 'map-loading-container'}`}
        >
          {loadingSizeVariation === 'small' && !mapProviderError ? (
            <Loading size="medium" />
          ) : (
            <SvgLocationSolid aria-hidden />
          )}

          {mapProviderError ? (
            <Flex alignItems="center" justifyContent="center">
              <BodyText textAlign="center" size="xLarge" color={colors.grey.primary}>
                {t('common:map.loading.error')}
              </BodyText>
            </Flex>
          ) : null}
        </GenericMapIconContainer>
      </GenericMapContainer>
    )
  }

  const mapElement = (
    <GoogleMap
      fullscreenControl={false}
      style={{ width: '100%', height: '100%' }}
      defaultCenter={{ lat: coordinates.latitude, lng: coordinates.longitude }}
      defaultZoom={MAP_ZOOM_CONTROL.STREETS}
      zoomControl={true}
      {...mapProps}
    >
      {showMarker ? (
        <Marker position={{ lat: coordinates.latitude, lng: coordinates.longitude }} />
      ) : null}
    </GoogleMap>
  )

  return (
    <ErrorBoundary hideError hideHelpFixIssue>
      {wrapMapWithProvider ? <GenericMapProvider>{mapElement}</GenericMapProvider> : mapElement}
    </ErrorBoundary>
  )
}
