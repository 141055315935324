import { LocalStorageRegistry } from '@/constants/localStorageRegistry'
import { type PaymentEstimatorFormValues } from '@/features/payment-estimator/types/paymentEstimator'
import { isServer } from '@/utils/isSSR'

/**
 * Retrieves the saved payment estimator form values from localStorage.
 *
 * This function checks localStorage for the form data associated with
 * the 'PaymentEstimatorForm' key. If the form data exists, it is
 * parsed and returned; otherwise, it returns null.
 *
 * @returns
 *   - Returns the saved form values as a FormValues object if they exist.
 *   - Returns `null` if no saved form data is found.
 */
export const getSavedFormValues = (): PaymentEstimatorFormValues | null => {
  if (isServer()) return null

  const savedFormData = localStorage.getItem(LocalStorageRegistry.PAYMENT_ESTIMATOR_FORM)

  if (savedFormData) {
    try {
      return JSON.parse(savedFormData)
    } catch (error) {
      return null
    }
  }

  return null
}
