import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'

/**
 * A styled button used across the `listing` feature for actions such as Phone, structured messages, auto-verify, etc.
 *
 * - By default, this component renders as a styled secondary button.
 * - Includes optional container-specific styling.
 */
export const ChipActionButton = styled(Button).attrs({
  variant: 'secondary',
  isFullWidth: true,
})<{ isDark?: boolean }>(
  ({ theme, isDark }) => `
  border-radius: ${theme.borderRadius.small};
  border: 0.1rem solid ${theme.colors.purple.light2};
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;
  
  ${isDark ? `background-color:${theme.colors.purple.light5};` : ''}

  & a {
    color: ${theme.colors.purple.primary};
  }

  & svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`
)
