import { isCarsTrucks } from '@kijiji/category'
import {
  AdSource,
  LogoType,
  useEventsExternalClickMutation,
  useGetProfileQuery,
} from '@kijiji/generated/graphql-types'
import { forwardRef } from 'react'
import { useTheme } from 'styled-components'

import {
  VipSideProfileInfoContainer,
  VipSideProfileOverviewSection,
  VipSideProfileStickyContainer,
} from '@/components/vip/vip-seller-profile/styled'
import { websiteClickHandler } from '@/components/vip/vip-seller-profile/utils/websiteClickHandler'
import { VipSideProfileActionButtons } from '@/components/vip/vip-seller-profile/VipSideProfileActionButtons'
import { VipSideProfileLogo } from '@/components/vip/vip-seller-profile/VipSideProfileLogo'
import { VipSideProfileSellerInformation } from '@/components/vip/vip-seller-profile/VipSideProfileSellerInformation'
import { R2S } from '@/features/listing/components/reply-to-seller/R2S'
import { getCommercialProfileFromData } from '@/features/profile/utils/getCommercialProfileFromData'
import { type VipQueryListing, isRealEstateListingFlags } from '@/types/listings'
import { Flex } from '@/ui/atoms/flex'

export type VipSideProfileSectionProps = {
  listing: VipQueryListing
  isListingFromDealer: boolean
  isSellerVip: boolean
  /**
   * Callback to be passed down to the R2SForm component
   */
  callbackForR2S?: () => void
  /**
   * This is used to check if a GSI event should be tracked when the external url link gets clicked
   */
  isGSITrackingEnabled?: boolean
}

export const VipSideProfileSection = forwardRef<HTMLDivElement, VipSideProfileSectionProps>(
  ({ isListingFromDealer, isSellerVip, listing, callbackForR2S, isGSITrackingEnabled }, ref) => {
    const { spacing } = useTheme()
    const [eventsExternalClick] = useEventsExternalClickMutation()

    const { data } = useGetProfileQuery({
      variables: {
        profileId: listing.posterInfo.posterId,
        size: LogoType.ViewAd,
      },
    })

    const commercialData = getCommercialProfileFromData(data?.profile, listing)
    const logoUrl = commercialData?.logoUrl
    const websiteUrl = commercialData?.websiteUrl ?? listing?.posterInfo.websiteUrl

    const handleViewWebsiteClick = () => {
      websiteClickHandler(
        eventsExternalClick,
        listing.id,
        AdSource.Organic,
        listing.categoryId,
        websiteUrl,
        isGSITrackingEnabled
      )
    }

    /**
     * The form of cars & trucks is too large to fit
     * It is the only category where it will be sticky only after the profile information
     */
    const isCarsAndTrucksListing = isCarsTrucks(listing.categoryId)
    const hasTopContainer = !!logoUrl || isCarsAndTrucksListing

    const isComfreeAd = isRealEstateListingFlags(listing.flags) && listing.flags.comfree

    return (
      <>
        <VipSideProfileLogo logoUrl={logoUrl} />

        {isCarsAndTrucksListing && (
          <VipSideProfileInfoContainer>
            <VipSideProfileSellerInformation
              isListingFromDealer={isListingFromDealer}
              listing={listing}
              handleViewWebsiteClick={handleViewWebsiteClick}
              websiteUrl={websiteUrl}
            />
          </VipSideProfileInfoContainer>
        )}

        <VipSideProfileStickyContainer
          ref={ref}
          hasTopContainer={hasTopContainer}
          isComfreeAd={!!isComfreeAd}
        >
          <Flex flexDirection="column" gap={spacing.default}>
            <VipSideProfileOverviewSection flexDirection="column" gap={spacing.default}>
              {!isCarsAndTrucksListing && (
                <VipSideProfileSellerInformation
                  isListingFromDealer={isListingFromDealer}
                  listing={listing}
                  handleViewWebsiteClick={handleViewWebsiteClick}
                  websiteUrl={websiteUrl}
                />
              )}

              <VipSideProfileActionButtons
                listing={listing}
                isListingFromDealer={isListingFromDealer}
                isGSITrackingEnabled={isGSITrackingEnabled}
              />
            </VipSideProfileOverviewSection>

            {!isComfreeAd && (
              <R2S disableForm={isSellerVip} listing={listing} callbackForR2S={callbackForR2S} />
            )}
          </Flex>
        </VipSideProfileStickyContainer>
      </>
    )
  }
)

VipSideProfileSection.displayName = 'VipSideProfileSection'
