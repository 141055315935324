import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const autocanMultipleFeaturesContainer = (theme: ThemeProps) => `
  gap: ${theme.spacing.large};
  grid-template-columns: 1fr 1fr;
`

export const AutocanFeaturesContainer = styled.div<{ useInlineVariant?: boolean }>(
  ({ theme, useInlineVariant }) => `
  display: grid;
  gap: ${theme.spacing.default};
  
  ${MEDIA_QUERIES(theme).medium} {
    ${useInlineVariant ? autocanMultipleFeaturesContainer(theme) : ''}
  }
`
)
