import { isAnyCarsVehiclesCategory, isAnyJobsCategory, isVerticalCategory } from '@kijiji/category'
import {
  type GetListingQuery,
  LogoType,
  SellerType,
  useGetCategoryPathQuery,
  useGetConversationIdByListingIdQuery,
  useGetInitialStructuredMessageQuery,
  useGetProfileQuery,
} from '@kijiji/generated/graphql-types'
import { isPositiveInteger } from '@kijiji/number/isPositiveInteger'
import { useSession } from 'next-auth/react'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { isUserAuthenticated } from '@/features/auth/constants/user'
import { R2SExistingConversation } from '@/features/listing/components/reply-to-seller/conversations/R2SExistingConversation'
import {
  type R2SFormProps,
  R2SForm,
} from '@/features/listing/components/reply-to-seller/conversations/R2SForm'
import { getPriceClassification } from '@/features/listing/utils/getPriceClassification'
import { getVehicleStockNumber } from '@/features/listing/utils/getVehicleStockNumber'
import { isDealerListing } from '@/features/listing/utils/isDealerListing'
import { isPintListing } from '@/features/listing/utils/isPintListing'
import { isSM360Listing } from '@/features/listing/utils/isSM360Listing'
import { getPrepopulatedMessage } from '@/features/listing/utils/reply-to-seller/getPrepopulatedMessage'
import { isAutosDealerProfile } from '@/features/profile/utils/isAutosDealerProfile'
import { useLocale } from '@/hooks/useLocale'
import { isAutosListing } from '@/types/search'
import { Flex } from '@/ui/atoms/flex'
import { Skeleton } from '@/ui/atoms/skeleton'

export type R2SProps = Pick<R2SFormProps, 'disableForm'> & {
  /**
   * Specifies if the R2S form should be disabled
   */
  disableForm?: boolean

  /**
   * The listing object.
   */
  listing: NonNullable<GetListingQuery['listing']>

  /**
   * Callback to be passed down to the R2SForm component
   */
  callbackForR2S?: () => void

  /**
   * To differentiate between modal and in-page R2S form
   */
  isModal?: boolean
}

/**
 * Wrapper for R2S. Encapsulates the R2S message form.
 *
 * @see {@link R2SFormProps} for the rest of the props that can be passed.
 */
export const R2S: FC<R2SProps> = ({
  disableForm,
  listing,
  callbackForR2S,
  isModal = false,
  ...r2sFormProps
}) => {
  const { cookieLocale, apiLocale } = useLocale()
  const { status } = useSession()
  const { spacing } = useTheme()

  const listingId = listing.id
  const categoryId = listing.categoryId
  const hasEnhancedContactForm = isAutosListing(listing) ? listing.flags.enhancedContactForm : false

  const { data: sellerData } = useGetProfileQuery({
    fetchPolicy: 'cache-first',
    variables: {
      profileId: listing.posterInfo.posterId,
      size: LogoType.ViewAd,
    },
  })

  /**
   * Defines if this listing is from a professional seller
   * From Dealer, or KMB or from a commercial profile
   */
  const isProfessionalSeller =
    isAutosDealerProfile(sellerData?.profile.userType) ||
    isPintListing(listing) ||
    listing.posterInfo.sellerType === SellerType.Commercial

  // Only fetch if they're logged in and the ad is not CAS / MOVE
  const shouldFetchConversationId =
    isUserAuthenticated(status) && isPositiveInteger(parseInt(listingId))

  const { data: existingConversation, loading: conversationLoading } =
    useGetConversationIdByListingIdQuery({
      skip: !shouldFetchConversationId,
      fetchPolicy: 'cache-first',
      variables: { adId: parseInt(listingId) },
    })

  const { data: { initialStructuredMessage } = {}, loading: structuredMessagesLoading } =
    useGetInitialStructuredMessageQuery({
      skip: !isUserAuthenticated(status),
      variables: { categoryId, locale: cookieLocale },
    })

  const { data: categoryData } = useGetCategoryPathQuery({
    variables: { categoryId, locationId: listing.location.id, locale: cookieLocale },
    fetchPolicy: 'cache-first',
  })

  /**
   * It should show loading state if the user is authenticated
   * while we query if there is an existing conversation for this listing
   */
  if ((isUserAuthenticated(status) && conversationLoading) || structuredMessagesLoading) {
    return <Skeleton fitContainer variant="rectangle" height="15rem" />
  }

  /**
   * It should not show the form if there is an existing conversation for the listing
   * and is not seller vip (disableForm is true)
   */
  if (!conversationLoading && existingConversation?.conversationIdByAdId?.id && !disableForm) {
    return <R2SExistingConversation conversationId={existingConversation.conversationIdByAdId.id} />
  }

  const isJobsListing = isAnyJobsCategory(categoryId)

  const isPintAd = isPintListing(listing)
  const isSM360Ad = isSM360Listing(listing)
  const isDealerAd = isDealerListing(listing.attributes)
  const isVerticalAd = isVerticalCategory(categoryId)

  const isGuestMessageEnabled = (isPintAd || isSM360Ad || isDealerAd) && isVerticalAd

  /**
   * Should only try to fetch Structured messages if the form will appear
   * And IF there is no structured messages available
   * */
  const initialMessage = !initialStructuredMessage?.length
    ? getPrepopulatedMessage(
        categoryId,
        apiLocale,
        categoryData?.category?.categoryPaths ?? undefined
      )
    : undefined

  const priceRate = getPriceClassification(listing.price)?.rating
  const isAutosDealerAd = isDealerAd && isAnyCarsVehiclesCategory(categoryId)

  const vehicleStockNumber = isAutosDealerAd ? getVehicleStockNumber(listing.attributes?.all) : null

  /**
   * It should only show the form if the user is not authenticated
   * OR if there is no conversationID on page load
   */
  return (
    <Flex flexDirection="column" data-testid="r2s" gap={spacing.default}>
      <R2SForm
        {...r2sFormProps}
        priceInsightRate={priceRate}
        categoryId={categoryId}
        disableForm={disableForm}
        hasEnhancedContactForm={hasEnhancedContactForm}
        initialMessage={initialMessage}
        isGuestMessageEnabled={isGuestMessageEnabled}
        isProfessionalSeller={isProfessionalSeller}
        listingId={listingId}
        structuredMessages={initialStructuredMessage}
        isJobsCategory={isJobsListing}
        successCallbackFn={callbackForR2S}
        isModal={isModal}
        isAutosDealerAd={isAutosDealerAd}
        vehicleStockNumber={vehicleStockNumber}
      />
    </Flex>
  )
}
