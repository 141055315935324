import { useGetCategoryPathQuery } from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import {
  type Dispatch,
  type FC,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTheme } from 'styled-components'

import { ImageGalleryModal } from '@/components/shared/image-gallery-modal/ImageGalleryModal'
import {
  VipGalleryMainWrapper,
  VipGalleryThumbnailGrid,
  VipGalleryWrapper,
} from '@/components/vip/vip-gallery/styled'
import { VipGalleryExpandThumbnail } from '@/components/vip/vip-gallery/VipGalleryExpandThumbnail'
import { VipGalleryMobileCarousel } from '@/components/vip/vip-gallery/VipGalleryMobileCarousel'
import { ListingVirtualTourThumbnail } from '@/components/vip/vip-gallery/VipGalleryVirtualTourThumbnail'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ListingImageType } from '@/features/listing/types/listings'
import { ListingGalleryMainImage } from '@/features/listing-gallery/components/ListingGalleryMainImage'
import { ListingGalleryThumbnail } from '@/features/listing-gallery/components/ListingGalleryThumbnail'
import { getGalleryContent } from '@/features/listing-gallery/utils/getGalleryContent'
import { getGalleryImages } from '@/features/listing-gallery/utils/getGalleryImages'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { type GaEvent, GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryListing } from '@/types/listings'
import { Flex } from '@/ui/atoms/flex'

export type VipGalleryProps = {
  categoryId: number
  imageUrls: VipQueryListing['imageUrls']
  isModalVisible: boolean
  location: VipQueryListing['location']
  setIsModalVisible: Dispatch<SetStateAction<boolean>>

  /**
   * Title of the gallery.
   */
  title: string

  /**
   * The ID of a youtube video. The parameters after ?v= in the youtube url.
   */
  youtubeVideoId?: string | null

  /**
   * The URL of the virtual tour.
   */
  virtualTourUrl?: string | null
}

export const VipGallery: FC<VipGalleryProps> = ({
  categoryId,
  imageUrls,
  isModalVisible,
  location,
  setIsModalVisible,
  title,
  virtualTourUrl,
  youtubeVideoId,
}) => {
  const { query, replace } = useRouter()
  const { spacing } = useTheme()
  const [modalStartingSlide, setModalStartingSlide] = useState<number>(0)
  const [tourSlideStatus, setTourSlideStatus] = useState<{
    viewed: boolean
    isDismissed: boolean
  }>({ viewed: false, isDismissed: false })
  const { cookieLocale } = useLocale()
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)

  const { data } = useGetCategoryPathQuery({
    fetchPolicy: 'cache-first',
    variables: { categoryId, locationId: location.id, locale: cookieLocale },
  })

  const imgAlt = t('listing:image.gallery.alt', {
    title,
    categoryName: data?.category?.localizedName ?? '',
    locationName: location.name,
  })

  const handleCloseModal = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      setIsModalVisible(false)
      setModalStartingSlide(0)

      // Track virtual tour cancellation if User has opened the slide and hasn't closed it yet
      if (tourSlideStatus.viewed && !tourSlideStatus.isDismissed) {
        const actionTrigger = event.type === 'keydown' ? 'escapeKey' : 'closeButton'
        setTourSlideStatus({ ...tourSlideStatus, isDismissed: true })
        trackEvent({
          action: GA_EVENT.ViewVirtualTourCancel,
          label: `closed_by=${actionTrigger};`,
        })
      }
    },
    [setIsModalVisible, tourSlideStatus]
  )

  const trackGalleryClick = useCallback(
    (imageType: ListingImageType) => {
      let action: GaEvent = GA_EVENT.ViewImageClick

      if (imageType === ListingImageType.VIDEO) {
        action = GA_EVENT.ViewVideoClick
      } else if (imageType === ListingImageType.TOUR) {
        action = GA_EVENT.ViewVirtualTourClick
        setTourSlideStatus({ ...tourSlideStatus, viewed: true })
      }

      const mediaType = imageType.toLowerCase()
      const openedBy = imageType === ListingImageType.VIDEO ? 'videoIcon' : null

      trackEvent({
        action,
        label: `media_count=${imageUrls.length};opened_by=${openedBy};media_type=${mediaType};`,
      })
    },
    [imageUrls.length, tourSlideStatus]
  )

  const handleOpenModal = useCallback(
    (index: number, imageType: ListingImageType) => {
      setModalStartingSlide(index)
      setIsModalVisible(true)
      trackGalleryClick(imageType)
    },
    [setIsModalVisible, trackGalleryClick]
  )

  const handleExpandButtonClick = (index: number, imageType: ListingImageType) => {
    setModalStartingSlide(index)
    setIsModalVisible(true)
    trackGalleryClick(imageType)
  }

  useEffect(() => {
    /**
     * A query parameter will be added when user clicks on the showcase carousel
     * and it is redirected to the VIP with the correct image opened
     */
    if (query.imageNumber && typeof query.imageNumber === 'string') {
      const index = parseInt(query.imageNumber, 10)
      if (!isNaN(index) && index >= 0 && index < imageUrls.length) {
        handleOpenModal(index, ListingImageType.SHOWCASE)
      }

      const { imageNumber, ...filteredQuery } = query
      replace({ query: filteredQuery }, undefined, { shallow: true })
    }
  }, [handleOpenModal, imageUrls, query, replace])

  const getMobileSlides = useCallback(() => {
    /** it should show youtube video first */
    let slides = []

    if (youtubeVideoId) {
      const handleClick = () => handleOpenModal(0, ListingImageType.VIDEO)

      slides.push(
        <ListingGalleryMainImage
          handleClick={handleClick}
          imageAlt={imgAlt}
          imageUrls={[]}
          youtubeVideoId={youtubeVideoId}
          isCarousel
        />
      )
    }

    const thumbIntoSlides = imageUrls.map((item, index) => {
      const imagesAlt = `${imgAlt} - ${t('listing:image.thumbnail.position.alt', { count: index + 2 })}`
      const slideIndex = slides.length + index
      const handleClick = () => handleOpenModal(slideIndex, ListingImageType.IMAGE)

      return (
        <ListingGalleryMainImage
          handleClick={handleClick}
          imageAlt={imagesAlt}
          imageUrls={[item]}
          key={`listing-slide-image-${index}`}
          isCarousel
        />
      )
    })

    slides = [...slides, ...thumbIntoSlides]

    if (virtualTourUrl) {
      const handleClick = () => handleOpenModal(slides.length, ListingImageType.TOUR)
      slides.push(
        <ListingGalleryMainImage
          handleClick={handleClick}
          imageAlt={imgAlt}
          imageUrls={[]}
          virtualTourUrl={virtualTourUrl}
          isCarousel
        />
      )
    }

    return slides
  }, [handleOpenModal, imageUrls, imgAlt, t, virtualTourUrl, youtubeVideoId])

  const imageCount = imageUrls.length
  if (!imageCount && !youtubeVideoId && !virtualTourUrl) return null

  /** It should not show component if there are no images */
  const { thumbnails } = getGalleryImages(imageUrls, youtubeVideoId, virtualTourUrl, 4)
  const { content: galleryContent } = getGalleryContent(imageUrls, youtubeVideoId, virtualTourUrl)
  const virtualTourGalleryIndex = galleryContent.findIndex((item) => item.type === 'virtual-tour')

  const hasExpandBlock = thumbnails.images.length < 3

  const mobileImageSlides = getMobileSlides()

  return (
    <>
      <VipGalleryWrapper data-testid="vip-gallery" hasThumbnails={!!thumbnails.images.length}>
        <VipGalleryMainWrapper isCarousel>
          <VipGalleryMobileCarousel
            isVirtualTourAvailable={!!virtualTourUrl}
            name="gallery-mobile-carousel"
            slides={mobileImageSlides}
          />
        </VipGalleryMainWrapper>

        <VipGalleryMainWrapper>
          <ListingGalleryMainImage
            handleClick={handleOpenModal}
            imageAlt={imgAlt}
            imageUrls={imageUrls}
            youtubeVideoId={youtubeVideoId}
            virtualTourUrl={virtualTourUrl}
          />
        </VipGalleryMainWrapper>

        {thumbnails.images.length ? (
          <VipGalleryThumbnailGrid data-testid="vip-gallery-thumbnails">
            {thumbnails.images.map((src, index) => {
              const lastItem = thumbnails.images.length - 1 === index
              /** When the last thumb image is not the "expand" icon block without an "imgSrc"*/
              const hasLastImage = lastItem && !hasExpandBlock

              /** It adds 2 to account for the main image */
              const thumbnailAlt = `${imgAlt} - ${t('listing:image.thumbnail.position.alt', { count: index + 2 })}`

              return virtualTourUrl && lastItem ? (
                <Flex gap={spacing.defaultSmall} key={`vip-thumbnail-${index}`}>
                  <ListingGalleryThumbnail
                    imageAlt={thumbnailAlt}
                    thumbnailIndex={index}
                    hasOverlay={hasLastImage}
                    imageCount={imageCount}
                    imageSrc={src}
                    onClick={handleOpenModal}
                    // The first **thumbnail** should have a play icon if there is a youtube video
                    hasPlayIcon={index === 0 && !!thumbnails.hasVideo}
                  />
                  <ListingVirtualTourThumbnail
                    galleryItemIndex={virtualTourGalleryIndex}
                    imageUrls={imageUrls}
                    onClick={handleOpenModal}
                  />
                </Flex>
              ) : (
                <ListingGalleryThumbnail
                  imageAlt={thumbnailAlt}
                  thumbnailIndex={index}
                  hasOverlay={hasLastImage}
                  imageCount={imageCount}
                  imageSrc={src}
                  key={`vip-thumbnail-${index}`}
                  onClick={handleOpenModal}
                  // The first **thumbnail** should have a play icon if there is a youtube video
                  hasPlayIcon={index === 0 && !!thumbnails.hasVideo}
                />
              )
            })}

            {hasExpandBlock ? (
              <VipGalleryExpandThumbnail
                onClick={handleExpandButtonClick}
                expandImageType={ListingImageType.IMAGE}
              />
            ) : null}
          </VipGalleryThumbnailGrid>
        ) : null}
      </VipGalleryWrapper>

      <ImageGalleryModal
        startIndex={modalStartingSlide}
        galleryContent={galleryContent}
        handleCloseModal={handleCloseModal}
        isVisible={isModalVisible}
      />
    </>
  )
}
