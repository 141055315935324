/**
 * all possible types; may not need all (see FES)
 * based on https://github.mpi-internal.com/ecg-kijiji-ca/custom-tab-manager-admin-web/blob/master/src/api/campaign/types.
 */
export type CampaignType =
  | 'carproof'
  | 'dfp' // old name for GPT, still used in CTM response data
  | 'home-verified'
  | 'mortgage-calculator'
  | 'other'
  | 'rehoming-adoption'
  | 'stay-safe'

export enum CAMPAIGN_PLACEMENT {
  CUSTOM = 'custom',
  VIP = 'vip',
  SRP = 'srp',
}
