import { isAnyCarsVehiclesCategory } from '@kijiji/category'
import { useTranslation } from 'next-i18next'
import { type FC, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { VipAttributesWrapper } from '@/components/vip/vip-attributes/styled'
import { VipAttributeCore } from '@/components/vip/vip-attributes/VipAttributeCore'
import { VipAttributesBody } from '@/components/vip/vip-attributes/VipAttributesBody'
import { VipAttributesFeatured } from '@/components/vip/vip-attributes/VipAttributesFeatured'
import { VipPetsDisclaimer } from '@/components/vip/vip-pets-disclaimer/VipPetsDisclaimer'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { ADDITIONAL_OPTIONS_FIELDS } from '@/features/attributes/constants/attributesAdditionalOptions'
import { DEALER_UPDATES_FIELDS } from '@/features/attributes/constants/attributesDealerUpdates'
import { getFeaturedAttrByCategoryId } from '@/features/attributes/constants/attributesFeatured'
import { filterNullAttributes } from '@/features/attributes/utils/filterNullAttributes'
import {
  getAttributeCoreToDisplay,
  getAttributeGroupsToDisplay,
  getAttributeToDisplay,
  getDisplayableAttributesFromName,
  getFeaturedAttributesToDisplay,
} from '@/features/attributes/utils/getDisplayableAttributes'
import { addPetsRehomingAttribute } from '@/features/attributes/utils/petAttributeUtils'
import { type VipQueryAttributeAll } from '@/types/listings'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

export type VipAttributesSectionProps = {
  attributes: VipQueryAttributeAll
  categoryId: number
}

export const VipAttributesSection: FC<VipAttributesSectionProps> = ({ attributes, categoryId }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])
  const { spacing } = useTheme()

  const filteredAttributes = filterNullAttributes(attributes)

  const coreAttributes = useMemo(
    () => getAttributeCoreToDisplay(t, categoryId, filteredAttributes),
    [t, categoryId, filteredAttributes]
  )

  /**
   * "hasGroup" will help identify if there was a group defined for this category when groups is empty.
   *
   * We only want to display the generic attributes when there is no group defined for a category, but not
   * when no attributes on the listing matches its group. On that case the generic attributes shouldn't be displayed.
   */
  const { hasGroup, groups } = useMemo(
    () => getAttributeGroupsToDisplay(t, categoryId, filteredAttributes),
    [t, categoryId, filteredAttributes]
  )

  /**
   * Gets stock and vin attributes only when is a vehicle category
   */
  const autosVehicleNumbers = isAnyCarsVehiclesCategory(categoryId)
    ? getDisplayableAttributesFromName(t, [ATTRIBUTES.STOCK, ATTRIBUTES.VIN], filteredAttributes)
    : []

  const featuredAttributes = useMemo(
    () => getFeaturedAttributesToDisplay(t, categoryId, filteredAttributes),
    [t, categoryId, filteredAttributes]
  )

  const skipGenericAttributes: string[] = hasGroup
    ? []
    : [
        ATTRIBUTES.FOR_SALE_BY,
        ATTRIBUTES.FOR_SALE_BY_HOUSING,
        ATTRIBUTES.FOR_SALE_BY_PETS,
        ATTRIBUTES.JOB_OFFERED_BY,
        ATTRIBUTES.STOCK,
        ATTRIBUTES.VIN,
        ...ADDITIONAL_OPTIONS_FIELDS,
        ...Object.keys(DEALER_UPDATES_FIELDS),
        ...Object.keys(getFeaturedAttrByCategoryId(categoryId) ?? []),
      ]

  /**
   * For dogs and cats rehoming category, if < 8 weeks old, we need to
   * display the rehoming date (prepended to the attributes array)
   */
  const updatedFilteredAttributes = addPetsRehomingAttribute(t, filteredAttributes, categoryId)

  const genericAttributes = hasGroup
    ? []
    : getAttributeToDisplay(t, updatedFilteredAttributes, skipGenericAttributes)

  const hasFeaturedAttributes = !!featuredAttributes.length
  const hasCoreAttributes = !!coreAttributes.length
  const hasGenericAttributes = !!genericAttributes.length
  const hasAutosVehicleNumbers = !!autosVehicleNumbers.length
  const hasGroupAttributes = !!groups.length

  /**
   * It should not return section if there are no displayable attributes
   * */
  if (
    !hasGroupAttributes &&
    !hasGenericAttributes &&
    !hasCoreAttributes &&
    !hasFeaturedAttributes &&
    !hasAutosVehicleNumbers
  ) {
    return <VipPetsDisclaimer categoryId={categoryId} />
  }

  return (
    <VipSectionContainer
      pBottom={hasGenericAttributes && !hasAutosVehicleNumbers ? '0' : spacing.husky}
      data-testid="vip-attributes-section"
    >
      <Flex flexDirection="column" gap={spacing.large}>
        {hasCoreAttributes || hasGroupAttributes || hasGenericAttributes ? (
          <VipAttributesWrapper
            hasGenericAttributes={hasGenericAttributes}
            hasCoreAttributes={hasCoreAttributes}
          >
            {coreAttributes ? <VipAttributeCore coreAttributes={coreAttributes} /> : null}

            <VipAttributesBody
              groupAttributes={groups}
              genericAttributes={genericAttributes}
              categoryId={categoryId}
              hasCoreAttributes={hasCoreAttributes}
            />
          </VipAttributesWrapper>
        ) : null}

        {hasFeaturedAttributes ? (
          <VipAttributesFeatured featuredAttributes={featuredAttributes} categoryId={categoryId} />
        ) : null}

        {hasAutosVehicleNumbers ? (
          <Flex
            gap={spacing.default}
            media={{ medium: { gap: spacing.xLarge } }}
            flexWrap="wrap"
            data-testid="vehicle-numbers"
          >
            {autosVehicleNumbers.map((item) => (
              <Flex gap={spacing.mini} key={`autos-numbers-${item.canonicalName}`}>
                <BodyText size="medium" weight="bold">
                  {item.name}:
                </BodyText>

                <BodyText size="medium" weight="regular">
                  {item.label}
                </BodyText>
              </Flex>
            ))}
          </Flex>
        ) : null}
      </Flex>

      <VipPetsDisclaimer categoryId={categoryId} />
    </VipSectionContainer>
  )
}
