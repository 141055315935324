import { isServer } from '@/utils/isSSR'

/**
 * Checks if the VIP status has just been posted based on URL parameters.
 *
 * This function examines the URL parameters `posted` and `adActivated` to determine
 * if both are set to 'true'. It only runs in a browser environment.
 *
 * @returns `true` if both `posted` and `adActivated` URL parameters are 'true', otherwise `false`.
 */
export const isVipJustPosted = () => {
  if (!isServer()) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('posted') === 'true' && urlParams.get('adActivated') === 'true'
  }
  return false
}

/**
 * Checks if the VIP status has just been edited based on URL parameters.
 *
 * This function examines the current window's URL search parameters to determine
 * if the VIP status has been edited. It checks for the presence of the following
 * parameters:
 * - `posted`: should be 'true'
 * - `adActivated`: should be 'true'
 * - `edited`: should be 'true'
 *
 * @returns `true` if all the specified URL parameters are present and set to 'true', otherwise `false`.
 */
export const isVipJustEdited = () => {
  if (!isServer()) {
    const urlParams = new URLSearchParams(window.location.search)
    return (
      urlParams.get('posted') === 'true' &&
      urlParams.get('adActivated') === 'true' &&
      urlParams.get('edited') === 'true'
    )
  }
  return false
}
