import { useGetCampaignsForVipQuery } from '@kijiji/generated/graphql-types'
import { Fragment, useMemo } from 'react'

import { ClientRender } from '@/components/shared/client-render'
import { DfpTab } from '@/features/custom-tab-groups/components/DfpTab'
import { GptSponsoredImpression } from '@/features/custom-tab-groups/components/GptSponsoredImpression'
import { type CAMPAIGN_PLACEMENT } from '@/features/custom-tab-groups/types/customTabGroupTypes'
import { getPlatformForCampaign } from '@/features/custom-tab-groups/utils/getPlatformForCampaign'
import { Flex } from '@/ui/atoms/flex'
import { sendToLogger } from '@/utils/sendToLogger'

type CustomTabGroupProps = {
  locationId: number
  categoryId: number
  placement: CAMPAIGN_PLACEMENT
}

export const CustomTabGroup = ({
  locationId = 0,
  categoryId = 0,
  placement,
}: CustomTabGroupProps) => {
  // only need to run once as the platform will not change
  const platform = useMemo(() => getPlatformForCampaign(), [])

  const { data } = useGetCampaignsForVipQuery({
    fetchPolicy: 'cache-first',
    variables: {
      placement,
      locationId,
      categoryId,
      platform,
    },
    onError: (err) => {
      sendToLogger(err, { fingerprint: ['CustomTabGroup'] })
    },
  })

  if (!data?.campaigns?.length) return null
  let dfpIndex = 0

  return (
    <ClientRender>
      <Flex flexDirection="column" alignItems="center">
        {data.campaigns.map((tab, index, array) => {
          if (tab?.type !== 'dfp') return null
          // if we add other possible components, this index will need to only increment for dfp campaigns
          ++dfpIndex

          const isLastTab = index === array.length - 1

          return (
            <Fragment key={`custom-tab-${tab.id}`}>
              {/* should not show GptHiddenImpression for mortgage calculator */}
              <GptSponsoredImpression gptIndex={dfpIndex} tabId={tab.id} />
              <DfpTab dfpIndex={dfpIndex} isLastTab={isLastTab} />
            </Fragment>
          )
        })}
      </Flex>
    </ClientRender>
  )
}
