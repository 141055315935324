import { type PaymentEstimatorFormValues } from '@/features/payment-estimator/types/paymentEstimator'
import { dollarsToCents } from '@/utils/price'

/**
 * Converts the monetary fields in the payment estimator form from dollars to cents.
 *
 * @param values - The form values containing amounts in dollars.
 * @returns A new object with the down payment and and trade in value converted to cents.
 */
export const convertFormValuesToCents = (
  formValues: PaymentEstimatorFormValues
): PaymentEstimatorFormValues => {
  const downPayment =
    formValues.downPayment && typeof formValues.downPayment === 'number'
      ? formValues.downPayment
      : 0
  const tradeIn =
    formValues.tradeIn && typeof formValues.tradeIn === 'number' ? formValues.tradeIn : 0

  return {
    ...formValues,
    downPayment: dollarsToCents(downPayment),
    tradeIn: dollarsToCents(tradeIn),
  }
}
