import { AdvertisingSlot } from 'react-advertising'
import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const StyledGptBigbox = styled(AdvertisingSlot)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-google-query-id] { 
    padding: ${theme.spacing.husky} 0;  
    border-top: 0.1rem solid ${theme.colors.grey.light4};
  }

  ${MEDIA_QUERIES(theme).large} {
    display: none;
  }
`
)

type GptStickyBottomContainerProps = {
  isVisible: boolean
}

export const GptStickyBottomContainer = styled.div<GptStickyBottomContainerProps>(
  ({ theme, isVisible }) => `
  display: none; // not on mobile

  ${MEDIA_QUERIES(theme).medium} {
    background-color: ${theme.colors.white};
    border: .1rem solid ${theme.colors.grey.light4};
    box-shadow: ${theme.boxShadow['shadow-2']};
    
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: ${isVisible ? '0' : '-20rem'};
    transition: bottom 0.3s ease-in-out;

    position: fixed;
    z-index: ${zIndexRegistry.FLOATING_CTA};
  }
`
)

/**
 * GPT sometimes injects a 1x1 pixel iframe into this slot
 * because the iframe is display: inline, it gets its min-height from
 * the inherited font metrics and ends up with a height of 19px
 */
export const StyledGptSponsored = styled(AdvertisingSlot)`
  font-size: 0;
`

export const StyledGptImageGalleryOverlay = styled(AdvertisingSlot)(
  ({ theme }) => `
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: absolute;
  min-height: 90px;
  top: 66px;
  width: 100%;

  // on legacy, this is set at 450px; closest breakpoint on NWA is 414px
  ${MEDIA_QUERIES(theme).mediumSmall} {
    top: 10px;
  }

  
`
)

export const GptLeaderWrapper = styled.div<{ shouldShowOnMobile?: boolean }>(
  ({ theme, shouldShowOnMobile }) => `
  display: ${shouldShowOnMobile ? 'flex' : 'none'};
  margin: ${theme.spacing.default} 0;
  min-height: 50px;

  ${MEDIA_QUERIES(theme).medium} {
    display: flex;
    min-height: 90px; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
)

export const StyledGptFooter = styled(AdvertisingSlot)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-google-query-id] {
    margin-bottom: ${theme.spacing.large};
  }
`
)

export const StyledGptR2S = styled(AdvertisingSlot)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledGptPosted = styled(AdvertisingSlot)`
  display: flex;
  justify-content: center;
  align-items: center;
`
