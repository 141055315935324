import { theme } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { TRANSLATION_KEYS } from '@/constants/localization'
import {
  MileageAnalysisScaleBar,
  MileageAnalysisScaleSectionWrapper,
  MileageAnalysisScaleText,
  MileageAnalysisScaleWrapper,
} from '@/features/listing/components/mileage-analysis/styled'
import { MileageRange } from '@/features/listing/constants/mileageAnalysis'
import { BodyText } from '@/ui/atoms/body-text'

const getMileageRangeText = (
  range: MileageRange,
  aboveAverageKm: number,
  belowAverageKm: number
): string => {
  switch (range) {
    case MileageRange.BelowAverage:
      return `≤ ${Math.floor(belowAverageKm / 1000)}k`
    case MileageRange.Average:
      return `${Math.floor(belowAverageKm / 1000)}k - ${Math.floor(aboveAverageKm / 1000)}k`
    case MileageRange.AboveAverage:
      return `≥ ${Math.floor(aboveAverageKm / 1000)}k`
  }
}

export type MileageAnalysisScaleProps = {
  /**
   * The minimum number of kilometres we consider to be an "above average" mileage
   */
  aboveAverageKm: number
  /**
   * The maximum number of kilometres we consider to be a "below average" mileage
   */
  belowAverageKm: number
  /**
   * The range that should be highlighted for the current vehicle
   */
  activeRange: MileageRange
}

export const MileageAnalysisScale: FC<MileageAnalysisScaleProps> = ({
  aboveAverageKm,
  belowAverageKm,
  activeRange,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])

  const getScaleSection = (range: MileageRange) => {
    const isActive = range === activeRange
    const textColour = isActive ? theme.colors.grey.primary : theme.colors.grey.light1
    const textWeight = isActive ? 'medium' : 'regular'

    return (
      <MileageAnalysisScaleSectionWrapper alignItems="center" flexDirection="column">
        <MileageAnalysisScaleBar data-testid={`bar-${range}`} isActive={isActive} range={range} />
        <MileageAnalysisScaleText>
          <BodyText color={textColour} weight={textWeight}>
            {t(`listing:mileage_analysis.scale.${range}`)}
          </BodyText>
          <BodyText color={textColour}>
            {getMileageRangeText(range, aboveAverageKm, belowAverageKm)}
          </BodyText>
        </MileageAnalysisScaleText>
      </MileageAnalysisScaleSectionWrapper>
    )
  }

  return (
    <MileageAnalysisScaleWrapper gap={theme.spacing.mini}>
      {getScaleSection(MileageRange.BelowAverage)}
      {getScaleSection(MileageRange.Average)}
      {getScaleSection(MileageRange.AboveAverage)}
    </MileageAnalysisScaleWrapper>
  )
}
