/** Learn More Modal */

import styled from 'styled-components'

import { ImageCustom } from '@/components/shared/image'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const PriceInsightCarfaxLogo = styled(ImageCustom)`
  max-height: 2.4rem;
  max-width: 14.5rem;
`

/* Scale */

export const PriceInsightScaleContainer = styled(Flex)(
  ({ theme }) => `
  background-color: ${theme.colors.grey.light5};
  border-radius: ${theme.borderRadius.medium};
  padding: 10rem ${theme.spacing.default} ${theme.spacing.default} ${theme.spacing.default};
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    padding: 8.1rem ${theme.spacing.default} ${theme.spacing.large} ${theme.spacing.default};
  }
`
)

export const PriceInsightsProgressBarLabel = styled(BodyText)(
  ({ theme }) => `
    ${MEDIA_QUERIES(theme).medium} {
      font-size: 2.8rem;
    }
  `
)

export const PriceInsightsProgressBarLabelContainer = styled(Flex)(
  ({ theme }) => `
      ${MEDIA_QUERIES(theme).medium} {
        flex-direction: row;
        gap: ${theme.spacing.defaultSmall};
        transform: translateX(25%);
    }
  `
)

export const AveragePriceContainer = styled(Flex)(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  border: 0.1rem solid ${theme.colors.grey.light3};
  height: 4.2rem;
  width: 17.2rem;
  position: relative;
  margin-top: ${theme.spacing.xLarge};
`
)

export const CarfaxLogoWrapper = styled(Flex)(
  ({ theme }) => `
  border-right: 0.1rem solid ${theme.colors.grey.light3};
  height: 100%;
  padding: ${theme.spacing.defaultSmall};
`
)

export const PriceInsightCarfaxLogoSmall = styled(ImageCustom)`
  max-height: 1.6rem;
  max-width: 2.5rem;
`
export const AveragePriceLabelLine = styled.div(
  ({ theme }) => `
  position: absolute;
  width: 0.1rem;
  background-color: ${theme.colors.grey.light3};
  height: 3.3rem;
  top: -3.3rem;
  left: 50%;
  transform: translateX(-50%);
`
)
