import styled from 'styled-components'

export const ProgressBar = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.grey.light3};
  border-radius: ${theme.borderRadius.small};
  height: 0.6rem;
  width: 100%;
`
)

type ProgressBarCompletedProps = {
  color?: string
  hasPointer: boolean
  progress: number
}

export const ProgressBarCompleted = styled.div<ProgressBarCompletedProps>(
  ({ theme, color, hasPointer, progress }) => `
  background-color: ${color ?? theme.colors.green.primary};
  border-radius: 0.4rem 0 0 0.4rem;
  height: 100%;
  width: ${progress}%;
  position: relative; /* For positioning the pointer and optional label */
    
    ${
      hasPointer
        ? `
      /* The pointer */
      &::after {
        content: '';
        position: absolute;
        right: -0.3rem;
        top: 50%;
        transform: translateY(-50%); /* Center the triangle pointer */
        border-left: 0.3rem solid transparent; /* Transparent left border */
        border-right: 0.3rem solid transparent; /* Transparent right border */
        border-bottom: 0.6rem solid ${theme.colors.grey.primary}; /* The grey triangle */
      }
    `
        : ''
    }
`
)

export const LabelWrapper = styled.div(
  ({ theme }) => `
  position: absolute;
  right: 0;
  bottom: calc(100% + ${theme.spacing.xLarge});
  transform: translateX(50%);
`
)

export const LabelLine = styled.div(
  ({ theme }) => `
  position: absolute;
  width: 0.1rem;
  background-color: ${theme.colors.grey.primary};
  left: 50%;
  bottom: calc(-${theme.spacing.xLarge} - 0.1rem); /* We're lowering the line by the theme.spacing.xLarge + a little extra to ensure it overlaps with the triangle */
  height: 2.5rem;
  transform: translateX(-50%);
`
)
