import { useGetListingsSimilarQuery } from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { CoreListingCard } from '@/components/shared/core-listing-card/CoreListingCard'
import { ListingsCarousel } from '@/components/shared/listings-carousel/ListingsCarousel'
import { ListingsCarouselLoading } from '@/components/shared/listings-carousel/ListingsCarouselLoading'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ListingsSimilarCarouselWrapper } from '@/features/listing/components/similar/styled'
import { LISTING_SIMILAR_MODAL_ITEM_COUNT } from '@/features/listing/constants/listingSimilar'
import { isExternalListingId } from '@/features/listing/utils/isExternalListingId'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { sendToLogger } from '@/utils/sendToLogger'

export type ListingsSimilarCarouselProps = {
  listingId: string
}

export const ListingsSimilarCarousel: FC<ListingsSimilarCarouselProps> = ({ listingId }) => {
  const { query } = useRouter()
  const isExternalId = isExternalListingId(query)

  const { data, loading, error } = useGetListingsSimilarQuery({
    fetchPolicy: 'cache-first',
    variables: { listingId, limit: LISTING_SIMILAR_MODAL_ITEM_COUNT, isExternalId },
    onError: (err) =>
      sendToLogger(err, { fingerprint: ['listings-similar-carousel'], extra: { listingId } }),
  })

  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])
  const { colors, spacing } = useTheme()

  /**
   * It should not render the carousel if there is an error, or if there are no similar listings returned
   */
  if (error || (!loading && !data?.listingsSimilar?.length)) return null

  const handleListingClick = (position: number, listingId: string) => {
    trackEvent({
      action: GA_EVENT.SelectPromotion,
      label: `partneradid=${listingId};position=${position};promotion_name=similar_listings`,
    })
  }

  const slides =
    data?.listingsSimilar?.reduce((acc: ReactNode[], coreListing, index) => {
      if (!coreListing) return acc

      return [
        ...acc,
        <CoreListingCard
          index={index}
          key={`listing-${coreListing.id}`}
          listing={coreListing}
          onClick={handleListingClick}
          allowFavourite
        />,
      ]
    }, []) ?? []

  return (
    <VipSectionContainer>
      {loading ? (
        <ListingsCarouselLoading id="similar-listings" hasLoadingTitle />
      ) : (
        <ListingsSimilarCarouselWrapper>
          <Spacing mBottom={spacing.default}>
            <HeadlineText
              as="h2"
              size={{ small: 'large', medium: 'xLarge' }}
              color={colors.grey.primary}
            >
              {t('listings_similar.title')}
            </HeadlineText>
          </Spacing>

          <ListingsCarousel
            enableImpressionsTracking
            trackSlidesListings={data?.listingsSimilar ?? []}
            slides={slides}
            name="listings-similar"
            shouldShowArrows={{ small: false, medium: true }}
          />
        </ListingsSimilarCarouselWrapper>
      )}
    </VipSectionContainer>
  )
}
