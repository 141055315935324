import { EditAltIcon } from '@kijiji/icons/src/icons'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { VipContainer } from '@/components/vip/styled'
import { SellerVipEditButton, SellerVipStickyContainer } from '@/components/vip/vip-seller/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { getListingActionUrl } from '@/features/listing/utils/getListingActionUrl'

export type SellerVipStickyBottomBarProps = {
  listingId: string
  isSellerVip?: boolean
}

export const SellerVipStickyBottomBar: FC<SellerVipStickyBottomBarProps> = ({
  listingId,
  isSellerVip,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const editListingUrl = getListingActionUrl({ action: 'edit', listingId })

  if (!isSellerVip) return null

  return (
    <SellerVipStickyContainer>
      <VipContainer>
        <SellerVipEditButton
          aria-label={t('listing:seller_metrics.edit')}
          forwardedAs="a"
          href={editListingUrl}
          size="small"
          variant="secondaryDark"
        >
          <EditAltIcon aria-hidden />
          <ShowAtOrLarger breakpoint="medium">{t('listing:seller_metrics.edit')}</ShowAtOrLarger>
        </SellerVipEditButton>
      </VipContainer>
    </SellerVipStickyContainer>
  )
}
