import { ATTRIBUTES } from '@/features/attributes/constants/attributes'

export const CONTEXT_VARIABLE_NAME_MAP: Record<string, [string] | [string, string]> = {
  [ATTRIBUTES.AIR_CONDITIONING]: ['airConditioning'],
  [ATTRIBUTES.ALLOY_WHEELS]: ['alloyWheels'],
  [ATTRIBUTES.BLUETOOTH]: ['bluetooth'],
  [ATTRIBUTES.CRUISE_CONTROL]: ['cruiseControl'],
  [ATTRIBUTES.NAV_SYSTEM]: ['navSystem'],
  [ATTRIBUTES.PARKING_ASSIST]: ['parkingAssist'],
  [ATTRIBUTES.PUSH_BUTTON_START]: ['pushButtonStart'],
  [ATTRIBUTES.SUNROOF]: ['sunRoof'],
  [ATTRIBUTES.TRAILER_HITCH]: ['trailerHitch'],
  [ATTRIBUTES.CAR_MILEAGE]: ['mileage'],
  [ATTRIBUTES.NUM_DOORS]: ['numDoors'],
  [ATTRIBUTES.NUM_SEATS]: ['numSeats'],
  [ATTRIBUTES.STOCK]: ['stockNumber'],
  [ATTRIBUTES.CAR_TRIM]: ['trim'],
  [ATTRIBUTES.CAR_YEAR]: ['year'],
  [ATTRIBUTES.VIN]: ['vin'],

  // names with en/fr variations
  [ATTRIBUTES.CAR_BODY_TYPE]: ['bodyType_en', 'bodyType_fr'],
  [ATTRIBUTES.CAR_COLOR]: ['color_en', 'color_fr'],
  [ATTRIBUTES.VEHICLE_TYPE]: ['condition_en', 'condition_fr'],
  [ATTRIBUTES.DRIVETRAIN]: ['drivetrain_en', 'drivetrain_fr'],
  [ATTRIBUTES.CAR_FUEL_TYPE]: ['fuelType_en', 'fuelType_fr'],
  [ATTRIBUTES.CAR_MAKE]: ['make_en', 'make_fr'],
  [ATTRIBUTES.CAR_MODEL]: ['model_en', 'model_fr'],
  [ATTRIBUTES.CAR_TRANSMISSION]: ['transmission_en', 'transmission_fr'],
}
