import { FlagIcon, MoreVerticalIcon } from '@kijiji/icons/src/icons'
import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { FavouriteButton } from '@/components/shared/listing/FavouriteButton'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { VipActionButtonsWrapper } from '@/components/vip/vip-action-buttons/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { ListingReportModal } from '@/features/listing/components/report/ListingReportModal'
import { SocialShareMenuButton } from '@/features/social-sharing/components/SocialShareMenuButton'
import { type VipQueryListing } from '@/types/listings'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { Dropdown } from '@/ui/molecules/dropdown/Dropdown'
import { DropdownItem } from '@/ui/molecules/dropdown/DropdownItem'

type VipActionButtonsProps = {
  listing: VipQueryListing
  isSimplified?: boolean
  isSellerVip: boolean
}

export const VipActionButtons: FC<VipActionButtonsProps> = ({
  listing,
  isSimplified,
  isSellerVip,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { colors, spacing } = useTheme()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  if (isSimplified) {
    return (
      <VipActionButtonsWrapper>
        {!isSellerVip && (
          <ListingReportModal
            listingId={listing.id}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
        )}

        <FavouriteButton
          listingId={listing.id}
          variant="simple-with-label"
          iconVariant="thin"
          isDisabled={isSellerVip}
        />

        <SocialShareMenuButton
          isSimplified={isSimplified}
          listing={listing}
          page="vip"
          direction="bottom-right"
        />

        {!isSellerVip && (
          <Dropdown
            label={t('vip:action_button.more')}
            direction="down-left"
            zIndex={zIndexRegistry.DROPDOWN}
            customTrigger={
              <Button
                variant="primaryNoBorder"
                size="small"
                aria-label={t('vip:action_button.more')}
              >
                <MoreVerticalIcon size="xsm" />
              </Button>
            }
          >
            <DropdownItem
              as="button"
              icon={FlagIcon}
              iconBackground={colors.purple.light5}
              label={t('listing:report.button')}
              onClick={handleOpenModal}
            />
          </Dropdown>
        )}
      </VipActionButtonsWrapper>
    )
  }

  return (
    <VipSectionContainer pTop={spacing.default} pBottom={spacing.default}>
      <Flex gap={spacing.default}>
        <FavouriteButton
          listingId={listing.id}
          variant="full"
          iconVariant="thin"
          isDisabled={isSellerVip}
        />

        <SocialShareMenuButton
          listing={listing}
          direction={{ small: 'bottom', medium: 'right' }}
          page="vip"
        />
      </Flex>
    </VipSectionContainer>
  )
}
