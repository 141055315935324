import { type IconProps } from '@kijiji/icons/src/components/Icon'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import {
  AttributeIconContainer,
  AttributeWithIconWrapper,
} from '@/features/attributes/components/styled'
import { type DisplayableAttribute } from '@/features/attributes/types/listingAttributes'
import { BodyText } from '@/ui/atoms/body-text'

export type IconBackgroundVariant = 'no-bg' | 'primary-bg' | 'secondary-bg'

export type AttributeWithIconProps = {
  iconBackgroundVariant?: IconBackgroundVariant
  Icon: FC<IconProps>
  label: DisplayableAttribute['label']
}

export const AttributeWithIcon: FC<AttributeWithIconProps> = ({
  iconBackgroundVariant = 'no-bg',
  Icon,
  label,
}) => {
  const { colors, spacing } = useTheme()

  return (
    <AttributeWithIconWrapper alignItems="center" gap={spacing.defaultSmall}>
      <AttributeIconContainer iconBackgroundVariant={iconBackgroundVariant}>
        <Icon aria-hidden />
      </AttributeIconContainer>

      <BodyText size="large" color={colors.grey.primary}>
        {label}
      </BodyText>
    </AttributeWithIconWrapper>
  )
}
