import { type FC, type IframeHTMLAttributes } from 'react'

import { ImageGalleryYoutubeSlideStyled } from '@/components/shared/image-gallery-modal/styled'

type ImageGalleryYoutubeSlideProps = IframeHTMLAttributes<HTMLIFrameElement> & {
  /**
   * Whether the image of the slide is in view or not
   */
  inView: boolean
}

export const ImageGalleryYoutubeSlide: FC<ImageGalleryYoutubeSlideProps> = ({
  inView,
  title,
  ...iframeProps
}) => {
  if (!inView) {
    return <div />
  }

  return (
    <ImageGalleryYoutubeSlideStyled>
      <iframe title={title} {...iframeProps} />
    </ImageGalleryYoutubeSlideStyled>
  )
}
