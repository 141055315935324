import { isAfter } from 'date-fns/isAfter'

export const isWarrantyQuoteExpired = (offerExpiry?: string) => {
  if (!offerExpiry) return true

  const expiryDate = new Date(offerExpiry)
  const currentDate = new Date()

  return isAfter(currentDate, expiryDate)
}
