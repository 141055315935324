import { type MileageAnalysisUsage } from '@kijiji/generated/graphql-types'
import { colors } from '@kijiji/theme'

export enum MileageRange {
  BelowAverage = 'below_average',
  Average = 'average',
  AboveAverage = 'above_average',
}

export const MILEAGE_RANGE_COLOUR_MAP = {
  [MileageRange.AboveAverage]: colors.yellow.light4,
  [MileageRange.Average]: colors.purple.light4,
  [MileageRange.BelowAverage]: colors.green.light4,
}

export const getMileageRangeFromUsage = (usage: MileageAnalysisUsage) => {
  switch (usage) {
    case 'HIGH':
      return MileageRange.AboveAverage
    case 'LOW':
      return MileageRange.BelowAverage
    default:
      // default to 'AVERAGE'
      return MileageRange.Average
  }
}
