import { type BreakpointKeys } from '@kijiji/theme'
import { useLayoutEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import {
  AttributesFeaturedContainer,
  ShowMoreButton,
} from '@/features/attributes/components/styled'
import { type DisplayableAttribute } from '@/features/attributes/types/listingAttributes'
import { Badge, BadgeSize, BadgeVariant } from '@/ui/atoms/badge'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { getWindowWidthBreakpointKey } from '@/ui/helpers/getWindowWidthBreakpointKey'

type AttributesFeaturedProps = {
  title: string
  attributes: DisplayableAttribute[]
}

/* 
  Maximum number of attributes to display based on screen size breakpoints.
  This limits the number of attribute badges shown before requiring user interacting
  to view more. There is a button to show the rest of the attributes at the end of the list.
*/
const MAX_ATTRIBUTES_COUNT: Record<BreakpointKeys, number> = {
  small: 6,
  mediumSmall: 6,
  medium: 8,
  large: 12,
  xLarge: 12,
}

export const AttributesFeatured = ({ title, attributes }: AttributesFeaturedProps) => {
  const { spacing } = useTheme()
  const [shouldHideExtraAttributes, setShouldHideExtraAttributes] = useState(true)
  const [maxAttributesCount, setMaxAttributesCount] = useState(MAX_ATTRIBUTES_COUNT.small)

  useLayoutEffect(() => {
    const handleResize = () => {
      const currentBreakpointKey = getWindowWidthBreakpointKey()
      setMaxAttributesCount(MAX_ATTRIBUTES_COUNT[currentBreakpointKey])
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const extraAttributesCount = attributes.length - maxAttributesCount
  const isViewMoreButtonVisible = shouldHideExtraAttributes && extraAttributesCount > 0

  const handleShowMore = () => {
    setShouldHideExtraAttributes(false)
  }

  return (
    <AttributesFeaturedContainer flexDirection="column" flexGrow={1} flexWrap="wrap">
      <HeadlineText size="medium">{title}</HeadlineText>
      <Flex gap={spacing.defaultSmall} flexWrap="wrap">
        {attributes.map((attribute, index) => {
          if (shouldHideExtraAttributes && index >= maxAttributesCount) {
            return null
          }

          return (
            <Badge
              variant={BadgeVariant.LIGHT3}
              size={BadgeSize.XXLARGE}
              borderRadius="rounded"
              key={attribute.canonicalName}
              label={attribute.name}
            />
          )
        })}
        {isViewMoreButtonVisible && (
          <ShowMoreButton onClick={handleShowMore}>+{extraAttributesCount}</ShowMoreButton>
        )}
      </Flex>
    </AttributesFeaturedContainer>
  )
}
