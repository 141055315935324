import { isAnyJobsCategory, isAnyRealEstateCategory } from '@kijiji/category'
import { type FC } from 'react'
import xss from 'xss'

type XssSanitizerProps = {
  categoryId: number
  content: string
  isPintAd?: boolean
  isSM360Ad?: boolean
}

export const XssSanitizer: FC<XssSanitizerProps> = ({
  categoryId,
  content,
  isPintAd,
  isSM360Ad,
}) => {
  if (!content) return null

  const isJobsAd = isAnyJobsCategory(categoryId)
  const isRealEstateAd = isAnyRealEstateCategory(categoryId)

  const sanitizerConfig = {
    whiteList: {
      div: [],
      br: [],
      p: [],
    },
  }
  const pintJobsREConfig = {
    whiteList: {
      ...sanitizerConfig.whiteList,
      span: [],
      ul: [],
      li: [],
      strong: [],
      em: [],
      u: [],
      b: [],
      i: [],
      h3: [],
      label: ['kit-ref-id'],
      img: [],
      a: ['href'],
    },
  }
  const pintSM360Config = { whiteList: pintJobsREConfig.whiteList }

  let config
  if ((isPintAd && isJobsAd) || (isPintAd && isRealEstateAd)) {
    config = pintJobsREConfig
  } else if (isPintAd || isSM360Ad) {
    config = pintSM360Config
  } else {
    config = sanitizerConfig
  }

  const cleanContent = xss(content, {
    ...config,
    stripIgnoreTag: true,
    stripIgnoreTagBody: ['script'],
  })

  return <div dangerouslySetInnerHTML={{ __html: cleanContent }} />
}
