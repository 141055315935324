import { isCarsTrucks } from '@kijiji/category'
import {
  type MoveAd,
  AdSource,
  EventsUrlType,
  useEventsExternalClickMutation,
  useVehicleReportQuery,
} from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import qs from 'query-string'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { VipSectionTitle } from '@/components/vip/shared/vip-section-title/VipSectionTitle'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { CarfaxLogoImage } from '@/components/vip/vip-vehicle-history/styled'
import { VehicleHistoryLearnMoreModal } from '@/components/vip/vip-vehicle-history/VehicleHistoryLearnMoreModal'
import { VehicleHistoryRequestBody } from '@/components/vip/vip-vehicle-history/VehicleHistoryRequestBody'
import { VehicleHistoryRequestModal } from '@/components/vip/vip-vehicle-history/VehicleHistoryRequestModal'
import { VehicleHistoryViewBody } from '@/components/vip/vip-vehicle-history/VehicleHistoryViewBody'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { isListingFromMove } from '@/domain/listings/isListingFromMove'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryAttributeAll } from '@/types/listings'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { TextLink } from '@/ui/atoms/text-link'

const CARFAX_LOGO = '/next-assets/images/carfax_logo.svg'

/** TODO: We will remove this check once ANVIL work to move this query is done  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMoveAd = (ad?: any): ad is MoveAd => {
  return (ad as MoveAd)?.__typename === 'MoveAd' || !!(ad as MoveAd)?.vehicleReport
}

type VehicleHistoryType = 'view' | 'request' | 'buy'

export type VipVehicleHistoryProps = {
  attributes: VipQueryAttributeAll
  categoryId: number
  isDealer: boolean
  /**
   * Specifies if listing ID is external
   */
  isExternalId?: boolean
  listingId: string
}

export const VipVehicleHistory: FC<VipVehicleHistoryProps> = ({
  attributes,
  categoryId,
  isDealer,
  isExternalId,
  listingId,
}) => {
  const { VEHICLE_TYPE, VIN } = ATTRIBUTES

  const [isLearnMoreModalVisible, setIsLearnMoreModalVisible] = useState<boolean>(false)
  const [isRequestModalVisible, setIsRequestModalVisible] = useState<boolean>(false)

  const [eventsExternalClick] = useEventsExternalClickMutation()

  const { t } = useTranslation(TRANSLATION_KEYS.VIP)
  const { routeLocale } = useLocale()
  const { spacing } = useTheme()

  const isMoveListing = isListingFromMove(listingId)
  const isMoveDealer = isDealer && isMoveListing

  const { data } = useVehicleReportQuery({
    skip: !isMoveDealer,
    variables: {
      adId: parseInt(listingId.replace('m', '')),
      source: AdSource.VerticalAutos,
      isExternalId,
    },
  })

  const getCarfaxURLFromAttributes = () => {
    const carfaxFromAttributes = attributes?.find(
      (item) => item?.canonicalName === ATTRIBUTES.CARFAX
    )

    const value = carfaxFromAttributes?.values?.[0]
    if (!value) return

    return value
  }

  const vehicleReport = isMoveAd(data?.ad) ? data.ad.vehicleReport : undefined

  const isNewVehicle = () => {
    const vehicleType = attributes?.find((item) => item?.canonicalName === VEHICLE_TYPE)
    return vehicleType?.canonicalValues?.[0]?.toLowerCase() === 'new'
  }

  /**
   * It should only show section if listing is on the Cars & Vehicles category
   * and the vehicle should not be "NEW"
   * */
  if (!isCarsTrucks(categoryId) || isNewVehicle()) return null

  const getCarfaxReportUrl = (carproofLink?: string | null) => {
    if (!carproofLink) return

    const parsedUrl = qs.parseUrl(carproofLink)
    const { query } = parsedUrl
    let { url } = parsedUrl

    /**
     * Old "reports.carproof.com" link redirect to vhr.carfax.ca
     * We should update the hostname while we get the URL
     */
    const oldHostName = 'reports.carproof.com'

    if (carproofLink.includes(oldHostName)) {
      const newHostName = 'vhr.carfax.ca'
      url = url.replace(oldHostName, newHostName)
    }

    /**
     * Verify if url comes with locale parameters and strip them to add it manually
     * The BE is always sending en parameters, we are fixing it here
     */
    url = new URL(url).origin
    return qs.stringifyUrl({ url: `${url}/${routeLocale}/`, query })
  }

  const getCarVin = () => {
    const vin = attributes?.find((item) => item?.canonicalName === VIN)
    return vin?.values[0]
  }

  const getCarHistoryVariation = (
    reportUrlFromData?: string | null
  ): { type: VehicleHistoryType; externalUrl?: string } => {
    const reportUrl = reportUrlFromData ?? getCarfaxURLFromAttributes()

    if (isDealer && isMoveListing && reportUrl) {
      return { type: 'view', externalUrl: reportUrl }
    }

    if (reportUrl) {
      const carfaxReportUrl = getCarfaxReportUrl(reportUrl)
      return { type: 'view', externalUrl: carfaxReportUrl }
    }

    const vin = getCarVin()
    if (vin) {
      return { type: 'buy', externalUrl: t('vehicle_history.vin.url', { vin }) }
    }

    return { type: 'request' }
  }

  const handleLearnMoreClick = () => {
    setIsLearnMoreModalVisible(true)
    trackEvent({ action: GA_EVENT.CarfaxLearnMore })
  }

  const handleCarfaxClick = (type: VehicleHistoryType, externalUrl: string | undefined) => {
    const isTypeBuy = type === 'buy'
    const label = isTypeBuy ? 'purchase' : type

    if (type === 'request') {
      setIsRequestModalVisible(true)
    }

    trackEvent({ action: GA_EVENT.CarfaxRequest, label: `scenario=${label}` })

    if (isTypeBuy && externalUrl) {
      eventsExternalClick({
        variables: {
          eventsExternalClickId: listingId,
          url: externalUrl,
          urlType: EventsUrlType.PartnerPage,
        },
      })
    }
  }

  const handleCloseLearnMoreModal = () => setIsLearnMoreModalVisible(false)
  const handleCloseRequestModal = () => setIsRequestModalVisible(false)

  const { type, externalUrl } = getCarHistoryVariation(vehicleReport?.reportUrl)

  const anchorProps = externalUrl ? { as: 'a', href: externalUrl, target: '_blank' } : {}

  return (
    <VipSectionContainer>
      <VipSectionTitle
        title={t('vip:vehicle_history.title')}
        logo={<CarfaxLogoImage src={CARFAX_LOGO} skipNextImage alt="" />}
      />

      <Flex flexDirection="column" gap={spacing.default}>
        {type === 'request' || type === 'buy' ? (
          <VehicleHistoryRequestBody />
        ) : (
          <VehicleHistoryViewBody reportBadgeKeys={vehicleReport?.badgeKeys} />
        )}

        <Flex gap={spacing.default}>
          <Button
            variant="secondary"
            size="small"
            onClick={() => handleCarfaxClick(type, externalUrl)}
            {...anchorProps}
          >
            {t(`vip:vehicle_history.cta.${type}`)}
          </Button>

          {isDealer && isMoveListing ? (
            <TextLink
              as="button"
              weight="medium"
              hasUnderline
              onClick={handleLearnMoreClick}
              size="small"
              variant="secondary"
            >
              {t('vip:vehicle_history.learn_more')}
            </TextLink>
          ) : null}
        </Flex>
      </Flex>

      <VehicleHistoryLearnMoreModal
        handleCloseModal={handleCloseLearnMoreModal}
        carfaxLogoSrc={CARFAX_LOGO}
        isVisible={isLearnMoreModalVisible}
      />

      <VehicleHistoryRequestModal
        handleCloseModal={handleCloseRequestModal}
        isVisible={isRequestModalVisible}
        carfaxLogoSrc={CARFAX_LOGO}
        listingId={listingId}
      />
    </VipSectionContainer>
  )
}
