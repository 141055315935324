import styled from 'styled-components'

import { MAX_APP_WIDTH } from '@/constants/others'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Spacing } from '@/ui/atoms/spacing'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

/**
 * VIP page container
 */
export const VipContainer = styled.div(
  ({ theme }) => `
  max-width: ${MAX_APP_WIDTH};
  padding: 0;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    padding: 0 ${theme.spacing.default};
  }
    
  ${MEDIA_QUERIES(theme).large} {
    padding: 0 ${theme.spacing.husky};
    margin-right: auto;
    margin-left: auto;
  }

  // The side paddings will default to 0 on screens that pass our max with by 4rem
  @media screen and (min-width: 1480px) {
    padding: 0;
  }
`
)

/**
 * @param addPaddingOnMobile Adds spacing around sizes on mobile - most of VIP components don't have this space
 * @param removeTabletPadding Remove space around sides on medium screens to accommodate components
 */
type VipSectionProps = {
  $removePaddingOnMobile?: boolean
  $removeTabletPadding?: boolean
}
export const VipSectionWrapper = styled(Spacing)<VipSectionProps>(
  ({ theme, $removePaddingOnMobile, $removeTabletPadding }) => `
  padding: ${$removePaddingOnMobile ? 0 : `0 ${theme.spacing.default}`};
  
  ${MEDIA_QUERIES(theme).medium} {
    padding: ${$removeTabletPadding ? '0' : `0 ${theme.spacing.large}`};
  }

  ${MEDIA_QUERIES(theme).large} {
    padding: 0;
  }
`
)

export const VipGridLayout = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  position: relative;

  ${MEDIA_QUERIES(theme).large} {
    display: grid;
    column-gap: ${theme.spacing.xLarge};
    grid-template-columns: minmax(auto, calc(100% - 41rem - ${theme.spacing.husky})) 41rem;

    // Place every item in the left automatically an only the last one in the right
    /* All items go to the first column by default */
    & > div {
      grid-column: 1 / 2; 
    }
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    column-gap: ${theme.spacing.husky};
    grid-template-columns: minmax(auto, calc(100% - 46rem - ${theme.spacing.husky})) 46rem;
  }

`
)

const SideColumnPlacement = styled(VipSectionWrapper).attrs({ as: 'section' })(
  ({ theme }) => `
    ${MEDIA_QUERIES(theme).large} {
      grid-column: 2 / 3;
      grid-row: 1 / 15;
    }
  `
)

export const VipSidebarContainer = styled(SideColumnPlacement)(
  ({ theme }) => `
  height: fit-content;
  
  ${MEDIA_QUERIES(theme).large} {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: ${zIndexRegistry.VIP_STICKY_SIDE};
  }
`
)
