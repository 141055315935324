import { type Category } from '@kijiji/generated/graphql-types'

import {
  adSizesMapVip,
  BASE_AD_PATH_FOR_VIP,
  VIP_GPT_ID,
  VIP_PREBID_SLOTS,
} from '@/components-page/vip/advertisement/vipAdSlots'
import {
  LEGACY_HP_LG_BREAKPOINT,
  LEGACY_MIN_DESKTOP,
  LEGACY_MIN_TABLET,
  LEGACY_MIN_TABLET_VLI,
} from '@/constants/pageSettings'
import { PREBID_GLOBAL_PROPERTIES } from '@/features/advertisement/constants/adSlots'
import { type AdConfig, type GptTargeting } from '@/features/advertisement/types/adConfig'
import { cleanCategoryPathForDisplayAds } from '@/features/advertisement/utils/cleanCategoryPathForDisplayAds'
import { flattenGptArray } from '@/features/advertisement/utils/globalOptimizationTool'
import { type RouteLocale } from '@/hooks/useLocale'

/** TODO: Cleanup schema types on ANVIL */
export type CategoryPath = (Category | null)[]

const getVipGptPath = ({
  routeLocale,
  categoryPath,
}: {
  routeLocale: RouteLocale
  categoryPath: CategoryPath
}) => {
  let adUnitPath = BASE_AD_PATH_FOR_VIP

  /** Should append locale to unit path */
  adUnitPath += `-${routeLocale}/`

  /** Format/clean localized category names to be used in ad unit path */
  const cleanedPath = cleanCategoryPathForDisplayAds(categoryPath).join('/')

  /** Append category path to unit path */
  adUnitPath += cleanedPath

  return adUnitPath
}

export const createVipGptConfig = ({
  categoryPath,
  gptTargetingValues,
  routeLocale,
  isModalVisible,
}: {
  gptTargetingValues: GptTargeting[]
  routeLocale: RouteLocale
  categoryPath: CategoryPath
  isModalVisible: boolean
}) => {
  /**
   * Mounts a path appended with localized category names in the format:
   * /{AD_NETWORK_CODE}/{AD_PATH_BASE}{PAGE_NAME}-web-{LANG}/{L1}{L2}{L3}`
   */
  const adUnitPath = getVipGptPath({ routeLocale, categoryPath })

  const vipGptConfig: AdConfig = {
    path: adUnitPath,
    enableLazyLoad: {
      marginPercent: 5, // 5% from the bottom of the viewport
      mobileScaling: 2,
    },
    prebid: PREBID_GLOBAL_PROPERTIES(),
    useAPS: false,
    sizeMappings: {
      [VIP_GPT_ID.GPT_BIGBOX]: [
        {
          viewPortSize: [0, 0],
          sizes: adSizesMapVip[VIP_GPT_ID.GPT_BIGBOX],
        },
        {
          viewPortSize: [LEGACY_MIN_TABLET, 0],
          sizes: adSizesMapVip[VIP_GPT_ID.GPT_BIGBOX],
        },
        {
          viewPortSize: [LEGACY_MIN_DESKTOP, 0],
          sizes: [], // not on desktop
        },
      ],
      [VIP_GPT_ID.GPT_SPONSORED_LEFT]: [
        {
          viewPortSize: [0, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.GPT_SPONSORED_LEFT][1]],
        },
        {
          viewPortSize: [LEGACY_MIN_TABLET, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.GPT_SPONSORED_LEFT][0]],
        },
      ],
      [VIP_GPT_ID.LEADER]: [
        {
          viewPortSize: [0, 0],
          sizes: [],
        },
        {
          viewPortSize: [LEGACY_MIN_TABLET, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.LEADER][0]],
        },
        {
          viewPortSize: [LEGACY_MIN_DESKTOP, 0],
          sizes: adSizesMapVip[VIP_GPT_ID.LEADER], // Include all
        },
      ],
      [VIP_GPT_ID.GPT_SPONSORED_RIGHT]: [
        {
          viewPortSize: [0, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.GPT_SPONSORED_RIGHT][1]],
        },
        {
          viewPortSize: [LEGACY_MIN_TABLET, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.GPT_SPONSORED_RIGHT][0]],
        },
      ],
      [VIP_GPT_ID.GPT_STICKY_BOTTOM]: [
        {
          viewPortSize: [0, 0],
          sizes: [],
        },
        {
          viewPortSize: [LEGACY_MIN_TABLET, 0],
          sizes: adSizesMapVip[VIP_GPT_ID.GPT_STICKY_BOTTOM],
        },
      ],
      ...(isModalVisible
        ? {
            [VIP_GPT_ID.IMAGE_GALLERY_OVERLAY]: [
              {
                viewPortSize: [0, 0],
                sizes: [adSizesMapVip[VIP_GPT_ID.IMAGE_GALLERY_OVERLAY][0]],
              },
              {
                viewPortSize: [LEGACY_MIN_TABLET_VLI, 0],
                sizes: [adSizesMapVip[VIP_GPT_ID.IMAGE_GALLERY_OVERLAY][1]],
              },
            ],
          }
        : {}),
      [VIP_GPT_ID.FOOTER]: [
        {
          viewPortSize: [0, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.FOOTER][0], adSizesMapVip[VIP_GPT_ID.FOOTER][1]],
        },
        {
          viewPortSize: [LEGACY_MIN_TABLET, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.FOOTER][2]],
        },
        {
          viewPortSize: [LEGACY_MIN_DESKTOP, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.FOOTER][2], adSizesMapVip[VIP_GPT_ID.FOOTER][3]],
        },
      ],
      [VIP_GPT_ID.R2S]: [
        {
          viewPortSize: [0, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.R2S][0], adSizesMapVip[VIP_GPT_ID.R2S][1]],
        },
        {
          viewPortSize: [LEGACY_MIN_TABLET, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.R2S][2]],
        },
        {
          viewPortSize: [LEGACY_MIN_DESKTOP, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.R2S][2], adSizesMapVip[VIP_GPT_ID.R2S][3]],
        },
      ],
      [VIP_GPT_ID.TAB]: [
        {
          viewPortSize: [0, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.TAB][0]],
        },
        {
          viewPortSize: [LEGACY_HP_LG_BREAKPOINT, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.TAB][1]],
        },
      ],
      [VIP_GPT_ID.POSTED]: [
        {
          viewPortSize: [0, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.POSTED][0], adSizesMapVip[VIP_GPT_ID.POSTED][1]],
        },
        {
          viewPortSize: [LEGACY_MIN_TABLET, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.POSTED][2]],
        },
        {
          viewPortSize: [LEGACY_MIN_DESKTOP, 0],
          sizes: [adSizesMapVip[VIP_GPT_ID.POSTED][2], adSizesMapVip[VIP_GPT_ID.POSTED][3]],
        },
      ],
    },

    targeting: flattenGptArray(gptTargetingValues),

    // slot-level settings override the global settings above
    slots: [
      {
        id: VIP_GPT_ID.GPT_BIGBOX,
        sizeMappingName: VIP_GPT_ID.GPT_BIGBOX,
        sizes: adSizesMapVip[VIP_GPT_ID.GPT_BIGBOX],
        targeting: { tile: '2', pos: 'bottom' },
        collapseEmptyDiv: [true, false],
        prebid: [VIP_PREBID_SLOTS[VIP_GPT_ID.GPT_BIGBOX]],
      },
      {
        id: VIP_GPT_ID.GPT_SPONSORED_LEFT,
        sizeMappingName: VIP_GPT_ID.GPT_SPONSORED_LEFT,
        sizes: adSizesMapVip[VIP_GPT_ID.GPT_SPONSORED_LEFT],
        targeting: { tile: '1' },
        collapseEmptyDiv: [true, false],
        prebid: [],
      },
      {
        id: VIP_GPT_ID.GPT_SPONSORED_RIGHT,
        sizeMappingName: VIP_GPT_ID.GPT_SPONSORED_RIGHT,
        sizes: adSizesMapVip[VIP_GPT_ID.GPT_SPONSORED_RIGHT],
        targeting: { tile: '1' },
        collapseEmptyDiv: [true, false],
        prebid: [],
      },
      {
        id: VIP_GPT_ID.GPT_STICKY_BOTTOM,
        sizeMappingName: VIP_GPT_ID.GPT_STICKY_BOTTOM,
        sizes: adSizesMapVip[VIP_GPT_ID.GPT_STICKY_BOTTOM],
        targeting: { tile: '10', pos: 'Sticky_VIP' },
        collapseEmptyDiv: [true, false],
        prebid: [],
        enableLazyLoad: false, // Sticky ads should not be lazy loaded
      },
      ...(isModalVisible
        ? [
            {
              id: VIP_GPT_ID.IMAGE_GALLERY_OVERLAY,
              sizeMappingName: VIP_GPT_ID.IMAGE_GALLERY_OVERLAY,
              sizes: adSizesMapVip[VIP_GPT_ID.IMAGE_GALLERY_OVERLAY],
              targeting: { pos: 'top', tile: '1' },
              prebid: [VIP_PREBID_SLOTS[VIP_GPT_ID.IMAGE_GALLERY_OVERLAY]],
              enableLazyLoad: true,
            },
          ]
        : []),
      {
        id: VIP_GPT_ID.LEADER,
        sizeMappingName: VIP_GPT_ID.LEADER,
        sizes: adSizesMapVip[VIP_GPT_ID.LEADER],
        targeting: { tile: '4' },
        prebid: [VIP_PREBID_SLOTS[VIP_GPT_ID.LEADER]],
      },
      {
        id: VIP_GPT_ID.FOOTER,
        sizeMappingName: VIP_GPT_ID.FOOTER,
        sizes: adSizesMapVip[VIP_GPT_ID.FOOTER],
        targeting: { pos: 'base', tile: '5' },
        collapseEmptyDiv: [true, false],
        prebid: [VIP_PREBID_SLOTS[VIP_GPT_ID.FOOTER]],
      },
      {
        id: VIP_GPT_ID.R2S,
        sizeMappingName: VIP_GPT_ID.R2S,
        sizes: adSizesMapVip[VIP_GPT_ID.R2S],
        targeting: { pos: 'top', tile: '4' },
        prebid: [VIP_PREBID_SLOTS[VIP_GPT_ID.R2S]],
      },
      {
        id: `${VIP_GPT_ID.TAB}-1`,
        sizeMappingName: VIP_GPT_ID.TAB,
        sizes: adSizesMapVip[VIP_GPT_ID.TAB],
        targeting: { pos: 'top', tile: '1' },
        collapseEmptyDiv: [true, false],
        prebid: [],
      },
      {
        id: `${VIP_GPT_ID.TAB}-2`,
        sizeMappingName: VIP_GPT_ID.TAB,
        sizes: adSizesMapVip[VIP_GPT_ID.TAB],
        targeting: { pos: 'top', tile: '2' },
        collapseEmptyDiv: [true, false],
        prebid: [],
      },
      {
        id: `${VIP_GPT_ID.SPONSORED_IMPRESSION}-1`,
        sizes: adSizesMapVip[VIP_GPT_ID.SPONSORED_IMPRESSION],
        targeting: { tile: '1' },
        collapseEmptyDiv: [true, true],
        prebid: [],
      },
      {
        id: `${VIP_GPT_ID.SPONSORED_IMPRESSION}-2`,
        sizes: adSizesMapVip[VIP_GPT_ID.SPONSORED_IMPRESSION],
        targeting: { tile: '2' },
        collapseEmptyDiv: [true, true],
        prebid: [],
      },
      {
        id: VIP_GPT_ID.POSTED,
        sizeMappingName: VIP_GPT_ID.POSTED,
        sizes: adSizesMapVip[VIP_GPT_ID.POSTED],
        targeting: { pos: 'top', tile: '4' },
        prebid: [VIP_PREBID_SLOTS[VIP_GPT_ID.POSTED]],
      },
    ],
  }

  return vipGptConfig
}
