import { isAnyCarsVehiclesCategory } from '@kijiji/category'
import { priceFromCents } from '@kijiji/money'
import Script from 'next/script'
import { type FC } from 'react'
import xss from 'xss'

import { AutoVerifyContainer } from '@/components/vip/vip-auto-verify/styled'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { getNumericUserId } from '@/features/profile/utils/getNumericUserId'
import { RemoteConfigs } from '@/lib/firebase/config/configs'
import { useConfig } from '@/lib/firebase/hooks'
import {
  type VipQueryAttributeAll,
  type VipQueryListing,
  type VipQueryPrice,
} from '@/types/listings'
import { sendToLogger } from '@/utils/sendToLogger'

const RV_CATEGORIES = [333, 334, 335, 336]
const MARINE_CATEGORIES = [327, 328]
const POWERSPORT_CATEGORIES = [303, 304, 305, 306, 307, 308, 311, 313, 330]

/**
 * The XSS sanitizer will prevent anything unexpected to be appended to the "dangerouslySetInnerHTML" tag
 */
const allowedParameters = [
  'vin',
  'condition',
  'make',
  'model',
  'trim',
  'odometer',
  'year',
  'ad-id',
  'title',
  'dealer-id',
  'certified',
]

const xssSanitizerConfig = {
  whiteList: {
    'autoverify-rv-vehicle-details': allowedParameters,
    'autoverify-marine-vehicle-details': allowedParameters,
    'autoverify-powersport-vehicle-details': allowedParameters,
    'autoverify-auto-vehicle-details': allowedParameters,
  },
  stripIgnoreTag: true,
  stripIgnoreTagBody: ['script'],
}

export type VipAutoVerifyProps = {
  listing: VipQueryListing
  isDealerListing?: boolean
}

export const VipAutoVerify: FC<VipAutoVerifyProps> = ({
  listing: { attributes, categoryId, id, posterInfo, price, title },
  isDealerListing,
}) => {
  /** The script will change depending for integration and prod env */
  const { data } = useConfig(RemoteConfigs.AUTOVERIFY_SCRIPT.key) ?? {}
  const autoVerifyScript = typeof data?.script === 'string' ? data.script : null

  /**
   * This integration should appear if the listing is from a dealer
   * AND it is in the cars & trucks category
   * */
  if (!isDealerListing || !isAnyCarsVehiclesCategory(categoryId)) return null

  if (!autoVerifyScript) {
    if (process.env.NODE_ENV === 'production') {
      sendToLogger('Empty config_autoverify_script in prod', {
        fingerprint: ['vip-auto-verify'],
      })
      return null
    }

    // eslint-disable-next-line no-console
    console.log('--- Empty config_autoverify_script ---')
    return null
  }

  const getDollarAmount = (listingPrice: VipQueryPrice) => {
    return isAmountPrice(listingPrice) && listingPrice.amount > 0
      ? ` current-price=${priceFromCents(listingPrice.amount).dollars}`
      : ''
  }

  const getVehicleProperties = ({
    attributes,
    id,
    posterId,
    price,
    title,
  }: {
    attributes: VipQueryListing['attributes']
    id: string
    posterId: string
    price: VipQueryPrice
    title: string
  }): string => {
    const {
      CAR_MAKE,
      CAR_MODEL,
      MOTORCYCLE_MAKE,
      MOTORCYCLE_MODEL,
      BOATS_MAKE,
      VIN,
      VEHICLE_TYPE,
      CAR_TRIM,
      CAR_MILEAGE,
      CAR_YEAR,
    } = ATTRIBUTES

    const allowedCarMakes: string[] = [CAR_MAKE, MOTORCYCLE_MAKE, BOATS_MAKE]
    const allowedCarModels: string[] = [CAR_MODEL, MOTORCYCLE_MODEL]

    /**
     * Build attribute properties
     */
    const attributesList = attributes?.all?.reduce(
      (acc: string, curr: NonNullable<VipQueryAttributeAll>[0] | null | undefined) => {
        const value = curr?.values[0]
        if (!curr || !value) return acc

        if (curr.canonicalName === VIN) return `${acc} vin="${value}"`
        if (curr.canonicalName === VEHICLE_TYPE) return `${acc} condition="${value}"`
        if (allowedCarMakes.includes(curr.canonicalName)) return `${acc} make="${value}"`
        if (allowedCarModels.includes(curr.canonicalName)) return `${acc} model="${value}"`
        if (curr.canonicalName === CAR_TRIM) return `${acc} trim="${value}"`
        if (curr.canonicalName === CAR_MILEAGE) return `${acc} odometer="${value}"`
        if (curr.canonicalName === CAR_YEAR) return `${acc} year="${value}"`

        return acc
      },
      ''
    )

    /**
     * Remove "m" if is a MoVe listing
     * And COMMERCIAL from the dealerId
     * */
    const listingId = id.replace('m', '')
    const dealerId = getNumericUserId(posterId)
    const dollarAmount = getDollarAmount(price)

    return `ad-id="${listingId}" title="${title}" ${attributesList}${dollarAmount} dealer-id="${dealerId}" certified=""`
  }

  const getAutoVerifyTag = (catId: number) => {
    if (RV_CATEGORIES.includes(catId)) return 'autoverify-rv-vehicle-details'
    if (MARINE_CATEGORIES.includes(catId)) return 'autoverify-marine-vehicle-details'
    if (POWERSPORT_CATEGORIES.includes(catId)) return 'autoverify-powersport-vehicle-details'

    return 'autoverify-auto-vehicle-details'
  }

  const properties = getVehicleProperties({
    attributes,
    price,
    title,
    posterId: posterInfo.posterId,
    id,
  })
  const tag = getAutoVerifyTag(categoryId)
  const cleanContent = xss(`<${tag} ${properties}></${tag}>`, xssSanitizerConfig)

  return (
    <>
      <div data-testid="vip-auto-verify">
        <div dangerouslySetInnerHTML={{ __html: cleanContent }} />

        <AutoVerifyContainer>
          <div className="av-trade-iframe"></div>
          <div className="av-credit-iframe"></div>
        </AutoVerifyContainer>
      </div>

      <Script strategy="afterInteractive" src={autoVerifyScript} />
    </>
  )
}
