import { Trans, useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { ClientRender } from '@/components/shared/client-render'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { GptFooter } from '@/components-page/vip/advertisement/GptFooter'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

export const VipSafetyDisclaimer = () => {
  const { colors } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.VIP, TRANSLATION_KEYS.ROUTES])

  return (
    <Flex flexDirection="column" justifyContent="center">
      <ClientRender>
        <GptFooter />
      </ClientRender>

      <BodyText textAlign="center" color={colors.grey.light1}>
        <Trans
          i18nKey="vip:safety_disclaimer"
          components={[
            <LinkCustom
              hasUnderline
              href={t('routes:external.safety_disclaimer.href')}
              key="safetyDisclaimerLink"
              noChildren
              target="_blank"
              variant="secondary"
            />,
          ]}
        />
      </BodyText>
    </Flex>
  )
}
