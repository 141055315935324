import ExpandIcon from '@kijiji/icons/src/icons/Expand'
import { type FC } from 'react'

import { VipGalleryExpandThumbnailContainer } from '@/components/vip/vip-gallery/styled'
import { type ListingImageType } from '@/features/listing/types/listings'
import { ListingThumbnailButton } from '@/features/listing-gallery/components/styled'

export type VipGalleryExpandThumbnailProps = {
  onClick: (index: number, imageType: ListingImageType) => void
  /**
   * The image type the expand will open in
   * If there is no Virtual Tour it should be the main image type
   * If the click comes from a Virtual tour, then it should be ListingImageType.TOUR
   */
  expandImageType: ListingImageType
}

export const VipGalleryExpandThumbnail: FC<VipGalleryExpandThumbnailProps> = ({
  onClick,
  expandImageType,
}) => {
  const handleClick = () => {
    /** Anytime the user clicks on the expanded block is to open the gallery on index 0 */
    onClick(0, expandImageType)
  }

  return (
    <ListingThumbnailButton onClick={handleClick} data-testid="vip-gallery-expand">
      <VipGalleryExpandThumbnailContainer>
        <ExpandIcon aria-hidden size="md" />
      </VipGalleryExpandThumbnailContainer>
    </ListingThumbnailButton>
  )
}
