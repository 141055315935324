import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { type R2SProps, R2S } from '@/features/listing/components/reply-to-seller/R2S'
import { R2SModalFormWrapper } from '@/features/listing/components/reply-to-seller/styled'
import { Modal } from '@/ui/molecules/modal/Modal'

type R2SModalProps = R2SProps & {
  /**
   * Controlled open state of the modal.
   */
  isOpen: boolean

  /**
   * Callback to close the modal.
   */
  handleClose: () => void
}

/**
 * Modal wrapper for R2S. Encapsulates the R2S message form.
 *
 * To see all the props that can be passed to the form, see the R2SForm component.
 *
 * @see {@link R2SForm} for the rest of the props that can be passed.
 */
export const R2SModal: FC<R2SModalProps> = ({ handleClose, isOpen, ...r2sProps }) => {
  const { spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.COMMON])

  return (
    <Modal
      closeButtonLabel={t('common:modals.close.button.label')}
      hasCloseButton
      id="r2s-message-form-modal"
      isOpen={isOpen}
      label={t('common:feedback:modal.feedback.title')}
      padding={{ small: `${spacing.giant} 0 0` }}
      onCancel={handleClose}
    >
      <R2SModalFormWrapper>
        <R2S {...r2sProps} isModal={true} />
      </R2SModalFormWrapper>
    </Modal>
  )
}
