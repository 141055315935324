import StarIcon from '@kijiji/icons/src/icons/Star'
import styled from 'styled-components'

import { StarMeterProps } from './StarMeter'

type StarProps = {
  $length: number
  $size: StarMeterProps['size']
}

type ProgressProps = {
  $width: number
}

const SIZE_MAP: Record<NonNullable<StarMeterProps['size']>, string> = {
  small: '1.6rem',
  medium: '2.4rem',
  large: '3.2rem',
}

export const Star = styled(StarIcon)<StarProps>(({
  theme,
  $length,
  $size = 'medium',
}) => {
  const size = SIZE_MAP[$size]

  return `
  width: ${size};
  height: ${size};
  margin-left: -${theme.spacing.mini};

  &:nth-child(${$length}) {
    margin-right: -${theme.spacing.mini};
  }
`
})

export const StarMeterContainer = styled.div(
  ({ theme }) => `
  color: ${theme.colors.grey.light3};
  position: relative;
  display: inline-flex;
`
)

export const StarsContainer = styled.div`
  display: flex;
`

export const Progress = styled.div<ProgressProps>(
  ({ theme, $width }) => `
  color: ${theme.colors.yellow.primary};
  position: absolute;
  top: 0;
  left: 0;
  width: ${$width}%;
  display: flex;
  overflow: hidden;
`
)
