import { useEffect, useMemo } from 'react'

import { DrawerProps } from './Drawer'

const scrollLockedAttr = 'data-scroll-locked'

export const usePreventBodyToScroll = ({
  hasBackdrop,
  isOpen,
}: Pick<DrawerProps, 'hasBackdrop' | 'isOpen'>) => {
  const bodyLockedStyle = useMemo(() => {
    if (typeof document !== 'undefined') {
      const element = document.createElement('style')
      // 15px is the removed scrollbar width
      element.innerHTML = `
        body[${scrollLockedAttr}] {
          overflow: hidden !important;
          overscroll-behavior: contain;
          position: relative !important;
          padding-left: 0px;
          padding-top: 0px;
          padding-right: 0px;
          margin-left:0;
          margin-top:0;
          margin-right: 15px !important; 
        }
      `
      element.type = 'text/css'
      return element
    }
    return null
  }, [])

  useEffect(() => {
    if (!hasBackdrop || !bodyLockedStyle) return

    if (isOpen) {
      document.head.appendChild(bodyLockedStyle)
      document.body.setAttribute(scrollLockedAttr, 'true')
    } else {
      document.body.removeAttribute(scrollLockedAttr)
      if (document.contains(bodyLockedStyle)) {
        document.head.removeChild(bodyLockedStyle)
      }
    }

    return () => {
      document.body.removeAttribute(scrollLockedAttr)
      if (document.contains(bodyLockedStyle)) {
        document.head.removeChild(bodyLockedStyle)
      }
    }
  }, [isOpen, hasBackdrop])
}
