import {
  ProfileSortBy,
  SortDirection,
  useGetPosterOtherAdsQuery,
} from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { CoreListingCard } from '@/components/shared/core-listing-card/CoreListingCard'
import { ListingsCarousel } from '@/components/shared/listings-carousel/ListingsCarousel'
import { ListingsCarouselLoading } from '@/components/shared/listings-carousel/ListingsCarouselLoading'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { mapPOADataToListingsData } from '@/components/vip/vip-poster-other-ads/utils/mapPOADataToListingsData'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

export type POACarouselProps = {
  sellerId: string
}

const MAXIMUM_POA_TO_SHOW = 10
const MINIMUM_POA_REQUIRED = 6

/**
 * POACarousel component displays a carousel of other ads from the same seller (Poster Other Ads)
 */

export const POACarousel: FC<POACarouselProps> = ({ sellerId }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.VIP])
  const { colors, spacing } = useTheme()

  const { data, loading } = useGetPosterOtherAdsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      profileId: sellerId,
      sort: { by: ProfileSortBy.Date, direction: SortDirection.Desc },
      pagination: { limit: MAXIMUM_POA_TO_SHOW, offset: 0 },
    },
  })

  const posterOtherListings = data?.profile?.listings
  const noOfPosterListings = posterOtherListings?.length ?? 0
  const sellerName = data?.profile?.name

  /**
   * Should only show the carousel if the query is done loading and there is the min required listings
   */
  if (!loading && noOfPosterListings < MINIMUM_POA_REQUIRED) return null

  // On Carousel's Item click:
  const handleListingClick = (position: number, listingId: string) => {
    trackEvent({
      action: GA_EVENT.POAClick,
      label: `AdID=${listingId};position=${position}`,
    })
  }

  const slides =
    posterOtherListings?.map((coreListing, index) => (
      <CoreListingCard
        index={index}
        key={`poa-listing-${coreListing.id}`}
        listing={coreListing}
        onClick={handleListingClick}
        allowFavourite
      />
    )) ?? []

  return (
    <VipSectionContainer pTop={spacing.husky} pBottom={spacing.husky} data-testid="poa-carousel">
      {loading ? (
        <ListingsCarouselLoading id="poster-other-ads" hasLoadingTitle />
      ) : (
        <>
          <Spacing
            mBottom={spacing.default}
            small={{ pLeft: spacing.default }}
            medium={{ pLeft: '0' }}
          >
            <HeadlineText
              as="h2"
              size={{ small: 'large', medium: 'xLarge' }}
              color={colors.grey.primary}
            >
              {sellerName
                ? t('vip:poster_other_ads.title', { sellerName })
                : t('vip:poster_other_ads.title.fallback')}
            </HeadlineText>
          </Spacing>

          <ListingsCarousel
            enableImpressionsTracking
            trackSlidesListings={mapPOADataToListingsData(posterOtherListings)}
            slides={slides}
            name="seller-other-ads"
            shouldShowArrows={{ small: false, medium: true }}
          />
        </>
      )}
    </VipSectionContainer>
  )
}
