import { type IconProps } from '@kijiji/icons/src/components/Icon'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

type VehicleHistoryInlineTextProps = {
  Icon: FC<IconProps>
  label: string
}

export const VehicleHistoryInlineText: FC<VehicleHistoryInlineTextProps> = ({ Icon, label }) => {
  const { colors, spacing } = useTheme()

  return (
    <Flex gap={spacing.mini} alignItems="center">
      <Icon aria-hidden size="default" data-testid="vehicle-history-icon" />
      <BodyText size="medium" color={colors.grey.primary}>
        {label}
      </BodyText>
    </Flex>
  )
}
