import styled from 'styled-components'

// This style is modifying the injected style from autoVerify
export const AutoVerifyContainer = styled.div`
  & .av-credit-iframe > div > div {
    padding: 0 !important;

    & > .inner {
      width: 100%;
      margin-top: 0 !important;

      & a {
        margin: 0;
        width: 100%;

        &:first-of-type {
          margin-bottom: 1rem;
        }
      }
    }
  }
`
