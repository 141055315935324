import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'

import { GPT_KEYS } from '@/features/advertisement/constants/gpt'
import { type GptTargeting } from '@/features/advertisement/types/adConfig'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'

/**
 * return the same autos-specific key-value pairs as on SRP
 * on SRP these values come from the filters, on VIP from attributes
 * @param attributes VIP item attributes
 * @returns array of targeting values to be sent to GAM via GPT
 */
export const getVipAutosTargetingValues = (attributes: ListingAttributeV2[]): GptTargeting[] => {
  const makeAttributeNames: string[] = [
    ATTRIBUTES.CAR_MAKE,
    ATTRIBUTES.ATV_MAKE,
    ATTRIBUTES.BOATS_MAKE,
    ATTRIBUTES.MOTORCYCLE_MAKE,
  ]

  let makeValue = ''
  let modelValue = ''
  let yearValue = ''
  let bodyTypeValue = ''
  let conditionValue = ''

  // Single pass through attributes array
  for (const attribute of attributes) {
    switch (attribute.canonicalName) {
      case ATTRIBUTES.CAR_MODEL:
        modelValue = attribute.values[0] ?? ''
        break
      case ATTRIBUTES.CAR_YEAR:
        yearValue = attribute.values[0] ?? ''
        break
      case ATTRIBUTES.CAR_BODY_TYPE:
        bodyTypeValue = attribute.values[0] ?? ''
        break
      case ATTRIBUTES.VEHICLE_TYPE:
        conditionValue = attribute.values[0] ?? ''
        break
      default:
        // otherwise, check if it matches one of the make attributes
        if (makeAttributeNames.includes(attribute.canonicalName)) {
          makeValue = attribute.values[0] ?? ''
        }
    }
  }

  // we'll remove empty values in generateVipGptTargetingValues
  return [
    {
      key: GPT_KEYS.CENTRAL_MAKE_LABEL, // g_cm
      value: makeValue,
    },
    {
      key: GPT_KEYS.CENTRAL_MODEL_LABEL, // g_cmo
      value: modelValue,
    },
    {
      key: GPT_KEYS.AUTOS_MODEL, // model
      value: modelValue,
    },
    {
      key: GPT_KEYS.CENTRAL_YEAR_LABEL, // g_cy
      value: yearValue,
    },
    {
      key: GPT_KEYS.AUTOS_YEAR, // year
      value: yearValue,
    },
    {
      key: GPT_KEYS.AUTOS_BODY, // body
      value: bodyTypeValue,
    },
    {
      key: GPT_KEYS.AUTOS_CONDITION, // condition
      value: conditionValue,
    },
  ]
}
