import { getYoutubeThumbnail } from '@/features/listing-gallery/utils/getYoutubeThumbnail'

type GalleryImages = {
  main: { imageSrc: string; isVideo?: boolean; isVirtualTour?: boolean }
  thumbnails: { images: string[]; hasVideo?: boolean }
}

/**
 * Get images to display in a gallery
 * This function will define what is the main image and thumbnails - considering if there is an youtube video available
 *
 * A youtube video should either be the main image (if there are no other images)
 * OR if there are other images, be the first item in the thumbnails
 *
 * @param images - All images returned from a listing
 * @param youtubeVideoId - Video ID associated with this listing
 * @param virtualTourUrl - Virtual tour URL associated with this listing
 * @param imageLimit - Limit of images (if any) for a gallery (i.e. VIP uses only 4 images)
 */
export const getGalleryImages = (
  images: string[],
  youtubeVideoId?: string | null,
  virtualTourUrl?: string | null,
  imageLimit?: number
): GalleryImages => {
  if (!images.length && youtubeVideoId) {
    return {
      main: { imageSrc: getYoutubeThumbnail(youtubeVideoId), isVideo: true },
      thumbnails: { images: [] },
    }
  }

  if (!images.length && !youtubeVideoId && virtualTourUrl) {
    return {
      main: { imageSrc: virtualTourUrl, isVirtualTour: true },
      thumbnails: { images: [] },
    }
  }

  const thumbnailImages = []

  if (imageLimit) {
    /**
     * It should show the 3 first images form the list as thumbnails if there is a youtube video
     * If there is no youtube video, the first image will be the main one, then it should show the next 3 images
     */
    thumbnailImages.push(
      ...images.slice(youtubeVideoId ? 0 : 1, youtubeVideoId ? imageLimit - 1 : imageLimit)
    )
  } else {
    thumbnailImages.push(...images)
  }

  return {
    main: youtubeVideoId
      ? { imageSrc: getYoutubeThumbnail(youtubeVideoId), isVideo: true }
      : { imageSrc: images?.[0] ?? '' },
    thumbnails: { images: thumbnailImages },
  }
}
