import { type GetSearchUrlQueryVariables, SearchOrigin } from '@kijiji/generated/graphql-types'

import { type GenericListing } from '@/features/listing/types/listings'

/**
 * This function will format a generic listing data into the input necessary to fetch a search URL
 * it is used to get a BRP url based on a listing
 *
 * @param listing - Any generic listing data
 *
 * @returns An object containing:
 * - `input`: Input to be used in the SearchUrlQuery.
 * - `fallbackUrl`: Fallback URL to use if the query fails.
 */
export const getSearchUrlVariablesFromListing = (
  listing: GenericListing,
  currUserRadius?: number
): { input: GetSearchUrlQueryVariables['input']; fallbackUrl: string } => {
  const coordinates = 'coordinates' in listing.location ? listing.location.coordinates : undefined
  const fallbackUrl = `/b-${listing.location.name}/c${listing.categoryId}l${listing.location.id}`

  const input = {
    searchQuery: {
      categoryId: listing.categoryId,
      origin: SearchOrigin.Browse,
      location: {
        id: listing.location.id,
        area:
          coordinates && currUserRadius
            ? {
                latitude: coordinates.latitude,
                longitude: coordinates.longitude,
                radius: currUserRadius,
              }
            : undefined,
      },
    },
  }

  return { input, fallbackUrl }
}
