import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'
import { type TFunction } from 'next-i18next'

import { getAttrGroupsByCategoryId } from '@/features/attributes/constants/attributeGroups'
import {
  ATTRIBUTES,
  ATTRIBUTES_URL_VALUE,
  BOOLEAN_TEXT_VALUES,
} from '@/features/attributes/constants/attributes'
import { ATTRIBUTES_ADDITIONAL_OPTIONS } from '@/features/attributes/constants/attributesAdditionalOptions'
import {
  type AttributeConfig,
  type AttributesConfigConcatenate,
  ATTRIBUTE_ICON_DEFAULT,
  ATTRIBUTES_CONFIG,
} from '@/features/attributes/constants/attributesConfig'
import { getCoreAttrByCategoryId } from '@/features/attributes/constants/attributesCore'
import { getDealerUpdatesByCategoryId } from '@/features/attributes/constants/attributesDealerUpdates'
import { getFeaturedAttrByCategoryId } from '@/features/attributes/constants/attributesFeatured'
import {
  type LabelOverrideProps,
  type NameOverrideProps,
} from '@/features/attributes/constants/attributesLabelOverride'
import {
  type DisplayableAttribute,
  type DisplayableGroupAttributes,
} from '@/features/attributes/types/listingAttributes'
import { formatAttributeLabel } from '@/features/attributes/utils/formatAttributeLabel'
import { getAttributesDictionary } from '@/features/attributes/utils/getAttributesDictionary'
import { getCpoAttributeLink } from '@/features/attributes/utils/getCpoAttributeLink'
import { getUrlAttributeLink } from '@/features/attributes/utils/getUrlAttributeLink'

/**
 * General configuration for attributes
 */
const getDefaultAttributeDefinition = (attribute: ListingAttributeV2): DisplayableAttribute => {
  return {
    canonicalValue: attribute.canonicalValues?.[0],
    Icon: ATTRIBUTE_ICON_DEFAULT,
    canonicalName: attribute.canonicalName,
    label: formatAttributeLabel(attribute),
    name: attribute.name,
  }
}

const getAttributeIcon = (
  attribute: ListingAttributeV2,
  customConfig?: AttributeConfig[string]
) => {
  /** Check if it should change icon based on attribute value */
  const valueIcon = customConfig?.valueIcon
  const defaultIcon = ATTRIBUTE_ICON_DEFAULT

  const value = attribute.canonicalValues?.[0]

  /**
   * It should only display the valueIcon if there is a configuration for that value
   * If not, default to the Icon from the main configuration
   * If there is no configuration, fallback to the default icon
   * */
  if (value && valueIcon?.[value]) {
    return valueIcon[value]
  }

  return customConfig?.Icon ?? defaultIcon
}

/** When combining attribute labels without having a special rule */
const getDefaultMultipleAttributesLabel = (attributes: ListingAttributeV2[]): string => {
  const labelsArray = attributes.reduce((acc: string[], curr: ListingAttributeV2) => {
    return curr ? [...acc, formatAttributeLabel(curr)] : acc
  }, [])

  return labelsArray.join(' ').trim()
}

/**
 * Generic function to get the label from the general definition
 * or respect the custom configuration
 */
const getAttributeDisplayableLabel = (
  t: TFunction,
  defaultLabel: DisplayableAttribute['label'],
  attribute: ListingAttributeV2,
  labelOverride?: LabelOverrideProps,
  concatenateAttributes?: {
    attributes: ListingAttributeV2[]
    config: AttributesConfigConcatenate
  }
): DisplayableAttribute['label'] => {
  let label: DisplayableAttribute['label'] = defaultLabel

  if (attribute.canonicalName === ATTRIBUTES.CPO) {
    return getCpoAttributeLink(attribute)
  }

  if (ATTRIBUTES_URL_VALUE.includes(attribute.canonicalName)) {
    return getUrlAttributeLink(attribute)
  }

  if (concatenateAttributes) {
    if (concatenateAttributes.config.labelOverride) {
      return concatenateAttributes.config.labelOverride(t, concatenateAttributes.attributes)
    }

    return getDefaultMultipleAttributesLabel(concatenateAttributes.attributes)
  }

  if (labelOverride) {
    label = labelOverride({ attribute, t })
  }

  return label
}

/**
 * Generic function to get the name from a general definition
 * or respect the custom configuration
 */
const getAttributeDisplayableName = (
  t: TFunction,
  defaultName: string,
  nameOverride?: NameOverrideProps
) => {
  let name: string = defaultName

  if (nameOverride) {
    name = nameOverride({ t })
  }

  return name
}

const getMultipleAttributesLabelConfig = (
  attributes: ListingAttributeV2[],
  customConfig?: AttributeConfig[0]
) => {
  /** Simplify finding attributes */
  const attributesDictionary = getAttributesDictionary(attributes)

  /** Check if this attribute has a custom configuration to concatenate 2 attributes (i.e. Start and end date) */

  if (customConfig?.concatenateMultipleAttributes) {
    /** It will find the related attribute to concatenate based on the configuration to return the correct label */
    const config = customConfig.concatenateMultipleAttributes
    const attributes = config.canonicalNames.map(
      (canonicalName) => attributesDictionary[canonicalName]
    )

    if (attributes.length) {
      return {
        attributes,
        config: config,
        hideAttributes: config.canonicalNames,
      }
    }
  }

  return
}

/**
 * Get attributes in for a category ready to be displayed
 */
export const getAttributeToDisplay = (
  t: TFunction,
  attributes?: ListingAttributeV2[],
  /**
   * Attributes to be skipped as they are part of another label
   * This will happen when the an attribute has a concatenated attribute configuration (i.e. Range attributes, or start-end date)
   *
   * It will also be used when the parent want to exclude specific fields from the returned list (Additional Options, forSaleBy)
   *
   * We will push to this array as we ready the attributes that have concatenated values
   */
  skipAttributes?: string[]
): DisplayableAttribute[] => {
  if (!attributes?.length) return []

  let attributesToSkip = skipAttributes?.length ? skipAttributes : []

  const displayableAttributes = attributes.reduce((acc: DisplayableAttribute[], curr) => {
    /**
     * It should remove the attribute from the list if there is no value
     * Also if is an additional option attribute and the flag to exclude them is set to true
     */
    if (!curr.values.length || attributesToSkip.includes(curr.canonicalName)) return acc

    const defaultConfig = getDefaultAttributeDefinition(curr)
    /** Check if this attribute has a custom configuration (icon, label) */
    const customConfig = ATTRIBUTES_CONFIG[curr.canonicalName]

    /** Check if this attribute has a custom configuration to concatenate 2 attributes (i.e. Start and end date) */
    const customMultipleAttrConfig = getMultipleAttributesLabelConfig(attributes, customConfig)
    if (customMultipleAttrConfig) {
      attributesToSkip = [...attributesToSkip, ...customMultipleAttrConfig.hideAttributes]
    }

    const label = getAttributeDisplayableLabel(
      t,
      defaultConfig.label,
      curr,
      customConfig?.labelOverride,
      customMultipleAttrConfig
    )

    const Icon = getAttributeIcon(curr, customConfig)

    /** It should remove attribute from the list if there is no label */
    if (!label) return acc

    const name = getAttributeDisplayableName(t, curr.name, customConfig?.nameOverride)

    /**
     * If there is no custom configuration, we return the basic format of the attribute
     */
    return [...acc, { Icon, name, label, canonicalName: curr.canonicalName }]
  }, [])

  return displayableAttributes
}

/**
 * Helper to separate values into multiple display items with individual labels and icons
 */
const separateMultipleValuesAttributes = ({
  attribute,
  customConfig,
  t,
}: {
  attribute: ListingAttributeV2
  customConfig: AttributeConfig[0]
  t: TFunction
}) => {
  const { canonicalName, name, canonicalValues } = attribute
  const labelOverrideFn = customConfig?.multipleLabelOverride

  if (!labelOverrideFn || !canonicalValues?.length) return []

  const separatedAttributes = canonicalValues.map((item) => {
    /** Get label per value */
    const label = labelOverrideFn({ canonicalName, canonicalValue: item }, t) ?? ''
    /** Get Icon per value */
    const Icon = getAttributeIcon(
      { canonicalName, canonicalValues: [item], name, values: [label] },
      customConfig
    )
    return { name: name, Icon, label, canonicalName }
  })

  return separatedAttributes
}

const separateMultipleAttributeValues = (
  attribute: ListingAttributeV2,
  customConfig: AttributeConfig[0],
  /** Ensure values are not repeated with the main array */
  existingValues: DisplayableAttribute[]
): DisplayableAttribute[] => {
  const { overrideCanonical } = customConfig.separateMultipleAttributeValues ?? {}

  return (
    attribute.canonicalValues?.reduce((acc: DisplayableAttribute[], curr, index) => {
      const value = attribute.values[index]

      const canonicalName = overrideCanonical?.[curr] ?? curr
      const hasExistingValue = existingValues.find((item) => item.canonicalName === canonicalName)
      if (!value || !curr || hasExistingValue) return acc

      /** Get the individual attribute custom config for icon reference */
      const singleAttrCustomConfig = ATTRIBUTES_CONFIG[canonicalName]

      const displayableAttribute: DisplayableAttribute = {
        canonicalName,
        canonicalValue: curr,
        label: value,
        name: value,
        Icon: getAttributeIcon(
          {
            canonicalName,
            name: value,
            values: ['Yes'],
            canonicalValues: [BOOLEAN_TEXT_VALUES.YES],
          },
          singleAttrCustomConfig
        ),
      }

      return [...acc, displayableAttribute]
    }, []) ?? []
  )
}

/**
 * Map attributes inside of group to the listing attributes available
 * This function formats the attribute definitions to either take the default generic value,
 * or to respect the custom configuration
 */
const getDisplayableAttributesFromConfig = (
  t: TFunction,
  listingAttributes: ListingAttributeV2[],
  attributeConfig: AttributeConfig
): DisplayableAttribute[] => {
  /** Simplify finding attributes */
  const attributesDictionary = getAttributesDictionary(listingAttributes)

  return Object.keys(attributeConfig).reduce((acc: DisplayableAttribute[], key) => {
    const canonicalName = key
    const listingAttribute = attributesDictionary[canonicalName]

    if (!listingAttribute) return acc

    /**
     * Merge both configuration files - ensure the base config props are always there
     * - The attributeConfig is the custom configuration passed to the function from whoever is calling.
     * It could be the "group" attributes, or the "core" attributes.
     * - The ATTRIBUTES_CONFIG is the general configuration for attributes in the application.
     * That is the center source of truth of attributes. It defines which icons each attributes have,
     * or labels if there is any general formatting we need to do.
     * This setup aims to prevent code duplication on base attribute definitions.
     * */
    const customConfig = {
      ...ATTRIBUTES_CONFIG[canonicalName],
      ...attributeConfig[canonicalName],
    }

    /**
     * If there are multiple attributes and a definition to custom their labels one by one
     * We will separate them into different items to facilitate its usage
     */
    if (customConfig.multipleLabelOverride) {
      const multipleValues = separateMultipleValuesAttributes({
        t,
        customConfig,
        attribute: listingAttribute,
      })

      return [...acc, ...multipleValues]
    }

    if (customConfig.separateMultipleAttributeValues) {
      const separatedAttributes = separateMultipleAttributeValues(
        listingAttribute,
        customConfig,
        acc
      )
      /** Ensure values are not already present on the array */
      return [...acc, ...separatedAttributes]
    }

    const defaultConfig = getDefaultAttributeDefinition(listingAttribute)
    const customMultipleAttrConfig = getMultipleAttributesLabelConfig(
      listingAttributes,
      customConfig
    )

    const label = getAttributeDisplayableLabel(
      t,
      defaultConfig.label,
      listingAttribute,
      customConfig.labelOverride,
      customMultipleAttrConfig
    )

    const Icon = getAttributeIcon(listingAttribute, customConfig)

    if (!label) return acc

    return [...acc, { name: defaultConfig.name, Icon, label, canonicalName }]
  }, [])
}

/**
 * Get group attributes for a category ready to be displayed
 *
 * @returns
 *    - hasGroup: Specifies if a group existed for the category it will help to know if empty value is because there was no group
 * or if the attributes for the listing didn't match any one in the group.
 */
export const getAttributeGroupsToDisplay = (
  t: TFunction,
  categoryId: number,
  attributes?: ListingAttributeV2[]
): { hasGroup?: boolean; groups: DisplayableGroupAttributes[] } => {
  const attributeGroups = getAttrGroupsByCategoryId(categoryId)

  if (!attributes?.length || !attributeGroups) {
    return { hasGroup: !!attributeGroups, groups: [] }
  }

  const displayableGroupAttributes = Object.keys(attributeGroups).reduce(
    (acc: DisplayableGroupAttributes[], key) => {
      const group = attributeGroups[key]
      if (!group) return acc

      const displayableAttr = getDisplayableAttributesFromConfig(t, attributes, group.attributes)

      /** The group icon should take "valueIcon" > "Icon" > fallback in order of hierarchy */
      let groupIcon
      if (group.valueIcon) {
        const canonicalName = group.valueIcon.canonicalName
        groupIcon = displayableAttr.find((item) => item.canonicalName === canonicalName)?.Icon
      }

      return [
        ...acc,
        {
          Icon: groupIcon ?? group.Icon ?? ATTRIBUTE_ICON_DEFAULT,
          attributes: displayableAttr,
          displayInline: group.displayInline,
          id: group.id,
          label: t(`listing:attribute.group_label.${key}`),
        },
      ]
    },
    []
  )

  /**
   * The Autos "condition" and "certified_pre_owned" sections are mutually exclusive
   * Whenever the CPO section is present, the "condition" one should be removed
   */
  const isCPO =
    displayableGroupAttributes[0]?.id === 'certified-pre-owned' &&
    displayableGroupAttributes[0].attributes.length

  return {
    hasGroup: true,
    groups: displayableGroupAttributes.filter((item) => {
      if (isCPO && item.id === 'condition') return false

      /** It should only return the groups that have attributes available */
      return !!item.attributes.length
    }),
  }
}

/**
 * Get core attributes for a category ready to be displayed
 */
export const getAttributeCoreToDisplay = (
  t: TFunction,
  categoryId: number,
  attributes?: ListingAttributeV2[]
): DisplayableAttribute[] => {
  const attributesCore = getCoreAttrByCategoryId(categoryId)
  if (!attributes?.length || !attributesCore) return []

  return getDisplayableAttributesFromConfig(t, attributes, attributesCore)
}

/**
 * Get all additional options ready to be displayed
 */
export const getAdditionalOptionsToDisplay = (t: TFunction, attributes?: ListingAttributeV2[]) => {
  return getDisplayableAttributesFromConfig(t, attributes ?? [], ATTRIBUTES_ADDITIONAL_OPTIONS)
}

/**
 * Get featured attributes ready to be displayed
 */
export const getFeaturedAttributesToDisplay = (
  t: TFunction,
  categoryId: number,
  attributes?: ListingAttributeV2[]
) => {
  const attributesFeatured = getFeaturedAttrByCategoryId(categoryId)

  if (!attributes?.length || !attributesFeatured) return []

  return getDisplayableAttributesFromConfig(t, attributes ?? [], attributesFeatured)
}

/**
 * Get Dealer Updates attributes ready to be displayed
 */
export const getDealerUpdatesAttributesToDisplay = (
  t: TFunction,
  categoryId: number,
  attributes?: ListingAttributeV2[]
) => {
  const attributesFeatured = getDealerUpdatesByCategoryId(categoryId)
  if (!attributes?.length || !attributesFeatured) return []

  return getDisplayableAttributesFromConfig(t, attributes ?? [], attributesFeatured)
}

/**
 * Get a list of displayable attributes from a simple list of canonical names
 */
export const getDisplayableAttributesFromName = (
  t: TFunction,
  canonicalNames: string[],
  attributes?: ListingAttributeV2[]
) => {
  if (!attributes?.length) return []

  const config = canonicalNames.reduce((acc: AttributeConfig, curr: string) => {
    return { ...acc, [curr]: {} }
  }, {})

  return getDisplayableAttributesFromConfig(t, attributes, config)
}
