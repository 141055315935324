import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'

type PrevNextBtnProps = {
  next?: boolean
  prev?: boolean
  variation?: 'gallery' | 'carousel'
}

const GalleryButtonStyle = (theme: ThemeProps) => `
  background-color: ${theme.colors.grey.primary};
  height: 6.5rem;
  width: 6.5rem;

  &:focus, &:active {
    background-color: ${theme.colors.green.primary};
  }

  & > svg {
    height: 2.4rem;
  }
`
const CarouselButtonStyle = (theme: ThemeProps) => `
  background-color: ${theme.colors.white};
  border: .1rem solid ${theme.colors.grey.light4};
  box-shadow: ${theme.boxShadow['shadow-3']};
  height: 4rem;
  width: 4rem;

  & svg {
    stroke: ${theme.colors.grey.primary};
  }

  &:focus {
    outline: .2rem solid ${theme.colors.blue.primary};
  }
  
  &:focus,
  &:hover, 
  &:active {
    border: .1rem solid ${theme.colors.purple.light4};
    background-color: ${theme.colors.purple.light5};
  }
`

// eslint-disable-next-line @kijiji/prefer-button-component
export const PrevNextBtn = styled.button<PrevNextBtnProps>(
  ({ theme, disabled, next, prev, variation }) => `
  align-items: center;
  border-radius: ${theme.borderRadius.rounded};
  cursor: pointer;
  display: ${disabled ? 'none' : 'flex'};
  justify-content: center;
  left: ${prev ? '-2rem' : 'auto'};
  opacity: 0.9;
  position: absolute;
  right: ${next ? '-2rem' : 'auto'};
  top: 50%;
  transform: translateY(-50%);
  z-index: ${zIndexRegistry.CAROUSEL_ARROWS};

  &:hover {
    opacity: 1;
  }

  &:focus, &:active {
    opacity: 1;
    outline: none;
  }

  & > svg {
    transform: ${next ? 'translateX(2px)' : 'translateX(-2px) scaleX(-1)'};
  }

  ${variation === 'carousel' ? CarouselButtonStyle(theme) : GalleryButtonStyle(theme)}
  `
)

type DotProps = {
  isSelected: boolean
}

// eslint-disable-next-line @kijiji/prefer-button-component
export const Dot = styled.button<DotProps>(
  ({ theme, isSelected }) => `
    align-items: center;
    display: flex;
    height: ${theme.spacing.large};
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    padding: 0.5rem;
    width: ${theme.spacing.large};

    &:after {
      background-color: ${isSelected ? theme.colors.green.primary : theme.colors.green.light2};
      border-radius: ${theme.borderRadius.rounded};
      box-sizing: border-box;
      content: '';
      height: ${theme.spacing.defaultSmall};
      transform: scale(${isSelected ? 1.25 : 1});
      width: ${theme.spacing.defaultSmall};
    }
  `
)
