import { CATEGORIES } from '@kijiji/category'
import { type IconProps } from '@kijiji/icons/src/components/Icon'
import { DrivetrainIcon } from '@kijiji/icons/src/icons'
import AccessibilityIcon from '@kijiji/icons/src/icons/Accessibility'
import AppliancesIcon from '@kijiji/icons/src/icons/Appliances'
import ArrowIcon from '@kijiji/icons/src/icons/Arrow'
import AtvIcon from '@kijiji/icons/src/icons/Atv'
import AutosAlloyWheelsIcon from '@kijiji/icons/src/icons/AutosAlloyWheels'
import AutosMakeIcon from '@kijiji/icons/src/icons/AutosMake'
import AutosModelIcon from '@kijiji/icons/src/icons/AutosModel'
import AutosSeatsSimpleIcon from '@kijiji/icons/src/icons/AutosSeatsSimple'
import BedsIcon from '@kijiji/icons/src/icons/Beds'
import BowRiderBoatIcon from '@kijiji/icons/src/icons/BowRiderBoat'
import CanoeIcon from '@kijiji/icons/src/icons/Canoe'
import ColorSimpleIcon from '@kijiji/icons/src/icons/ColorSimple'
import ConditionCertifiedPreOwnedIcon from '@kijiji/icons/src/icons/ConditionCertifiedPreOwned'
import DimensionsIcon from '@kijiji/icons/src/icons/Dimensions'
import EngineIcon from '@kijiji/icons/src/icons/Engine'
import FileIcon from '@kijiji/icons/src/icons/File'
import FuelIcon from '@kijiji/icons/src/icons/Fuel'
import FurnishedIcon from '@kijiji/icons/src/icons/Furnished'
import KmSimpleIcon from '@kijiji/icons/src/icons/KmSimple'
import LengthIcon from '@kijiji/icons/src/icons/Length'
import PersonalWatercraftIcon from '@kijiji/icons/src/icons/PersonalWatercraft'
import RvsIcon from '@kijiji/icons/src/icons/Rvs'
import SailboatIcon from '@kijiji/icons/src/icons/Sailboat'
import SmokingIcon from '@kijiji/icons/src/icons/Smoking'
import SnowmobileIcon from '@kijiji/icons/src/icons/Snowmobile'
import TransmissionIcon from '@kijiji/icons/src/icons/Transmission'
import UtilitiesIcon from '@kijiji/icons/src/icons/Utilities'
import WeightIcon from '@kijiji/icons/src/icons/Weight'
import WiFiAndMoreIcon from '@kijiji/icons/src/icons/WiFiAndMore'
import { type FC } from 'react'

import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { type AttributeConfig } from '@/features/attributes/constants/attributesConfig'
import { ATTRIBUTES_LABEL_OVERRIDE } from '@/features/attributes/constants/attributesLabelOverride'
import {
  type SupportedATVGroups,
  type SupportedCanoesKayaksPaddleGroups,
  type SupportedCarsVehiclesGroups,
  type SupportedMotorhomesGroups,
  type SupportedPersonalWatercraftGroups,
  type SupportedPowerboatsGroups,
  type SupportedRealEstateGroups,
  type SupportedSailboatsGroups,
  type SupportedSnowmobileGroups,
} from '@/features/attributes/types/supportedAttributeGroups'

/**
 * Some categories will have attributes grouped for better visibility
 * Groups are statically defined in the FE, therefore they will need a label and icon static specification
 */

/** The AttributeGroups type will be defined per category (i.e. Real Estate) */
type AttributeGroups<T extends string> = {
  /**
   * When creating a new group name, that should match a translation in the listing's file: "attribute.group_label.<group_name>"
   * */
  [groupName in T]: {
    /** The id is an identifier that only needs to be added to the group when we will need to find it to modify it before displaying it */
    id?: string
    /**
     * List of attributes under the group
     * We can override any ATTRIBUTE_CONFIG definitions including the labels if we need an attribute to have a different label when it appears grouped
     */
    attributes: AttributeConfig
    /**
     * Some groups have too many attributes to be shown
     * this param indicates the attributes should be hidden until the user clicks to display them
     */
    hideAttributesOnRender?: boolean
    /**
     * Specifies this class group to be shown in the same line
     */
    displayInline?: boolean
    /** Icon assigned to the group */
    Icon?: FC<IconProps>
    /** It will have a valueIcon defined when the icon depends on the value of one of the attributes defined inside */
    valueIcon?: {
      canonicalName: string
      Icon: { [value: string]: FC<IconProps> }
    }
  }
}

const ATTRIBUTE_GROUPS_REAL_ESTATE: AttributeGroups<SupportedRealEstateGroups> = {
  rental_agreement: {
    Icon: FileIcon,
    attributes: {
      [ATTRIBUTES.AGREEMENT_TYPE]: {
        labelOverride: ({ attribute: { values }, t }) =>
          t('listing:attribute.label_override.rental_agreement', { value: values[0] }),
      },
      [ATTRIBUTES.DATE_AVAILABLE]: {
        labelOverride: (props) => {
          const formattedDate = ATTRIBUTES_LABEL_OVERRIDE.FORMAT_DATE(props)
          return props.t('listing:attribute.label_override.date_available', {
            value: formattedDate,
          })
        },
      },
      [ATTRIBUTES.TERM_AGREEMENT]: {
        labelOverride: ({ attribute: { values }, t }) =>
          t('listing:attribute.label_override.yes', { value: values[0] }),
      },
    },
  },
  utilities: {
    Icon: UtilitiesIcon,
    attributes: {
      [ATTRIBUTES.HEAT]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.INCLUDED },
      [ATTRIBUTES.HYDRO]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.INCLUDED },
      [ATTRIBUTES.WATER]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.INCLUDED },
    },
  },
  furnished: {
    Icon: FurnishedIcon,
    attributes: { [ATTRIBUTES.FURNISHED]: {} },
  },
  appliances: {
    Icon: AppliancesIcon,
    attributes: {
      [ATTRIBUTES.LAUNDRY_UNIT]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.LAUNDRY_BUILDING]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.DISHWASHER]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.FRIDGE]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
    },
  },
  includes: {
    attributes: {
      [ATTRIBUTES.BALCONY]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.YARD]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.AIR_CONDITIONING]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
    },
  },
  connectivity: {
    Icon: WiFiAndMoreIcon,
    attributes: {
      [ATTRIBUTES.CABLE_TV]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.INTERNET]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
    },
  },
  smoking: {
    Icon: SmokingIcon,
    attributes: {
      [ATTRIBUTES.SMOKING_PERMITTED]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
    },
  },
  accessibility: {
    Icon: AccessibilityIcon,
    hideAttributesOnRender: true,
    attributes: {
      [ATTRIBUTES.WHEELCHAIR_ACCESSIBLE]: {
        labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME,
      },
      [ATTRIBUTES.BRAILLE_LABELS]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.AUDIO_PROMPTS]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.BARRIER_FREE]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.VISUAL_AIDS]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME },
      [ATTRIBUTES.ACCESSIBLE_WASHROOMS]: {
        labelOverride: ATTRIBUTES_LABEL_OVERRIDE.BOOLEAN_NAME,
      },
    },
  },
}

/** AUTOS GROUPS */
const getFuelGroup = (categoryFuelCanonicalName: string): AttributeGroups<'fuel'>['fuel'] => {
  return {
    Icon: FuelIcon,
    valueIcon: { canonicalName: categoryFuelCanonicalName, Icon: {} },
    attributes: {
      [categoryFuelCanonicalName]: {},
      [ATTRIBUTES.ELECTRIC_RANGE]: {},
      [ATTRIBUTES.TIME_TO_CHARGE]: {},
    },
  }
}

const SHARED_AUTOS_GROUPS: AttributeGroups<
  'condition' | 'kilometers' | 'color' | 'hours' | 'seats' | 'capacity'
> = {
  capacity: {
    Icon: AutosSeatsSimpleIcon,
    attributes: {
      [ATTRIBUTES.SLEEPING_CAPACITY]: {},
      [ATTRIBUTES.PASSENGERS]: {},
      [ATTRIBUTES.SEATS]: {},
    },
  },
  color: {
    Icon: ColorSimpleIcon,
    attributes: {
      [ATTRIBUTES.CAR_COLOR]: {},
      [ATTRIBUTES.CAR_INTERIOR_COLOR]: {
        labelOverride: ATTRIBUTES_LABEL_OVERRIDE.REPLACE_VALUE_WITH_TRANSLATION,
      },
    },
  },
  condition: {
    id: 'condition',
    valueIcon: { canonicalName: ATTRIBUTES.VEHICLE_TYPE, Icon: {} },
    attributes: { [ATTRIBUTES.VEHICLE_TYPE]: {} },
  },
  hours: { Icon: KmSimpleIcon, attributes: { [ATTRIBUTES.HOURS]: {} } },
  kilometers: { Icon: KmSimpleIcon, attributes: { [ATTRIBUTES.CAR_MILEAGE]: {} } },
  seats: {
    Icon: AutosSeatsSimpleIcon,
    attributes: { [ATTRIBUTES.NUM_SEATS]: {}, [ATTRIBUTES.SEATS]: {} },
  },
}

const ATTRIBUTE_GROUPS_CARS: AttributeGroups<SupportedCarsVehiclesGroups> = {
  certified_pre_owned: {
    id: 'certified-pre-owned',
    Icon: ConditionCertifiedPreOwnedIcon,
    attributes: { [ATTRIBUTES.CPO]: {} },
  },
  condition: SHARED_AUTOS_GROUPS.condition,
  kilometers: SHARED_AUTOS_GROUPS.kilometers,
  transmission: {
    Icon: TransmissionIcon,
    displayInline: true,
    attributes: { [ATTRIBUTES.CAR_TRANSMISSION]: {}, [ATTRIBUTES.SPEEDS]: {} },
  },
  engine: {
    Icon: EngineIcon,
    attributes: {
      [ATTRIBUTES.CYLINDERS]: {},
      [ATTRIBUTES.FUEL_CAPACITY]: {},
    },
  },
  drivetrain: { Icon: DrivetrainIcon, attributes: { [ATTRIBUTES.DRIVETRAIN]: {} } },
  fuel: getFuelGroup(ATTRIBUTES.CAR_FUEL_TYPE),
  seats: SHARED_AUTOS_GROUPS.seats,
  body_style: {
    valueIcon: { canonicalName: ATTRIBUTES.CAR_BODY_TYPE, Icon: {} },
    attributes: { [ATTRIBUTES.CAR_BODY_TYPE]: {}, [ATTRIBUTES.NUM_DOORS]: {} },
  },
  color: {
    ...SHARED_AUTOS_GROUPS.color,
    attributes: {
      [ATTRIBUTES.CAR_COLOR]: {
        labelOverride: ATTRIBUTES_LABEL_OVERRIDE.REPLACE_VALUE_WITH_TRANSLATION,
      },
      [ATTRIBUTES.CAR_INTERIOR_COLOR]: {
        labelOverride: ATTRIBUTES_LABEL_OVERRIDE.REPLACE_VALUE_WITH_TRANSLATION,
      },
    },
  },
  model: {
    Icon: AutosModelIcon,
    attributes: {
      [ATTRIBUTES.CAR_MODEL]: {
        concatenateMultipleAttributes: {
          canonicalNames: [ATTRIBUTES.CAR_YEAR, ATTRIBUTES.CAR_MAKE, ATTRIBUTES.CAR_MODEL],
        },
      },
      [ATTRIBUTES.CAR_TRIM]: {},
    },
  },
}

const ATTRIBUTE_GROUPS_MOTORHOMES: AttributeGroups<SupportedMotorhomesGroups> = {
  condition: SHARED_AUTOS_GROUPS.condition,
  kilometers: SHARED_AUTOS_GROUPS.kilometers,
  type: {
    Icon: RvsIcon,
    attributes: {
      [ATTRIBUTES.MOTORHOMES_TYPE]: {},
      [ATTRIBUTES.ROOMS]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
      [ATTRIBUTES.SLIDEOUTS]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
    },
  },
  sleeps: { Icon: BedsIcon, attributes: { [ATTRIBUTES.SLEEPING_CAPACITY]: {} } },
  size: {
    Icon: DimensionsIcon,
    attributes: {
      [ATTRIBUTES.MOTORHOMES_LENGTH]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
      [ATTRIBUTES.MOTORHOMES_HEIGHT]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
    },
  },
  engine: {
    Icon: EngineIcon,
    attributes: { [ATTRIBUTES.CAR_ENGINE]: {}, [ATTRIBUTES.MOTORHOME_ENGINE_POWER]: {} },
  },
  fuel: getFuelGroup(ATTRIBUTES.SNOW_FUEL_TYPE),
  color: SHARED_AUTOS_GROUPS.color,
  weight: { Icon: WeightIcon, attributes: { [ATTRIBUTES.MOTORHOMES_WEIGHT]: {} } },
  model: {
    Icon: AutosModelIcon,
    attributes: { [ATTRIBUTES.CAR_YEAR]: {}, [ATTRIBUTES.MOTORHOMES_TRIM]: {} },
  },
}

const ATTRIBUTE_GROUPS_SNOWMOBILES: AttributeGroups<SupportedSnowmobileGroups> = {
  condition: SHARED_AUTOS_GROUPS.condition,
  kilometers: SHARED_AUTOS_GROUPS.kilometers,
  type: { Icon: SnowmobileIcon, attributes: { [ATTRIBUTES.SNOW_TYPE]: {} } },
  engine: {
    Icon: EngineIcon,
    attributes: { [ATTRIBUTES.CAR_ENGINE]: {}, [ATTRIBUTES.SNOW_ENGINE_POWER]: {} },
  },
  fuel: getFuelGroup(ATTRIBUTES.MOTORHOMES_FUEL_TYPE),
  seats: SHARED_AUTOS_GROUPS.seats,
  color: SHARED_AUTOS_GROUPS.color,
  model: {
    Icon: AutosModelIcon,
    attributes: {
      [ATTRIBUTES.CAR_YEAR]: {},
      [ATTRIBUTES.ATV_MAKE]: {},
      [ATTRIBUTES.ATV_MODEL]: {},
      [ATTRIBUTES.SNOW_TRIM]: {},
    },
  },
}

const ATTRIBUTE_GROUPS_CANOES_KAYAKS_PADDLES: AttributeGroups<SupportedCanoesKayaksPaddleGroups> = {
  condition: SHARED_AUTOS_GROUPS.condition,
  type: {
    Icon: CanoeIcon,
    attributes: { [ATTRIBUTES.CANOES_TYPE]: {}, [ATTRIBUTES.BOATS_TRIM]: {} },
  },
  length: {
    Icon: LengthIcon,
    attributes: {
      [ATTRIBUTES.BOATS_LENGTH]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
    },
  },
  passengers: { Icon: AutosSeatsSimpleIcon, attributes: { [ATTRIBUTES.PASSENGERS]: {} } },
  color: SHARED_AUTOS_GROUPS.color,
  brand: { Icon: AutosMakeIcon, attributes: { [ATTRIBUTES.BOATS_MAKE]: {} } },
}

const ATTRIBUTE_GROUPS_PERSONAL_WATERCRAFT: AttributeGroups<SupportedPersonalWatercraftGroups> = {
  condition: SHARED_AUTOS_GROUPS.condition,
  hours: SHARED_AUTOS_GROUPS.hours,
  type: { Icon: PersonalWatercraftIcon, attributes: { [ATTRIBUTES.BOATS_TRIM]: {} } },
  engine: {
    Icon: EngineIcon,
    attributes: { [ATTRIBUTES.BOATS_ENGINE_POWER]: {} },
  },
  fuel: getFuelGroup(ATTRIBUTES.PWC_FUEL_TYPE),
  seats: SHARED_AUTOS_GROUPS.seats,
  color: SHARED_AUTOS_GROUPS.color,
  model: {
    Icon: AutosMakeIcon,
    displayInline: true,
    attributes: {
      [ATTRIBUTES.CAR_YEAR]: {},
      [ATTRIBUTES.BOATS_MAKE]: {},
      [ATTRIBUTES.BOATS_MODEL]: {},
    },
  },
}

const ATTRIBUTE_GROUPS_SAILBOATS: AttributeGroups<SupportedSailboatsGroups> = {
  condition: SHARED_AUTOS_GROUPS.condition,
  hours: SHARED_AUTOS_GROUPS.hours,
  type: { Icon: SailboatIcon, attributes: { [ATTRIBUTES.SAILBOATS_TYPE]: {} } },
  dimensions: {
    Icon: DimensionsIcon,
    attributes: {
      [ATTRIBUTES.BOATS_LENGTH]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
    },
  },
  capacity: SHARED_AUTOS_GROUPS.capacity,
  performance: { Icon: EngineIcon, attributes: { [ATTRIBUTES.BOATS_ENGINE_POWER]: {} } },
  propulsion: { Icon: ArrowIcon, attributes: { [ATTRIBUTES.PROPULSION]: {} } },
  fuel: getFuelGroup(ATTRIBUTES.SAILBOATS_FUEL_TYPE),
  color_material: {
    ...SHARED_AUTOS_GROUPS.color,
    attributes: { ...SHARED_AUTOS_GROUPS.color.attributes, [ATTRIBUTES.MATERIAL]: {} },
  },
  model: {
    Icon: AutosMakeIcon,
    displayInline: true,
    attributes: {
      [ATTRIBUTES.CAR_YEAR]: {},
      [ATTRIBUTES.BOATS_MAKE]: {},
      [ATTRIBUTES.BOATS_MODEL]: {},
      [ATTRIBUTES.BOATS_TRIM]: {},
    },
  },
}

const ATTRIBUTE_GROUPS_POWERBOATS: AttributeGroups<SupportedPowerboatsGroups> = {
  condition: SHARED_AUTOS_GROUPS.condition,
  hours: SHARED_AUTOS_GROUPS.hours,
  type: { Icon: BowRiderBoatIcon, attributes: { [ATTRIBUTES.MOTORBOATS_TYPE]: {} } },
  dimensions: {
    Icon: DimensionsIcon,
    attributes: {
      [ATTRIBUTES.BOATS_LENGTH]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
    },
  },
  capacity: SHARED_AUTOS_GROUPS.capacity,
  performance: { Icon: EngineIcon, attributes: { [ATTRIBUTES.BOATS_ENGINE_POWER]: {} } },
  propulsion: { Icon: ArrowIcon, attributes: { [ATTRIBUTES.PROPULSION]: {} } },
  fuel: getFuelGroup(ATTRIBUTES.MOTORBOATS_FUEL_TYPE),
  color_material: {
    ...SHARED_AUTOS_GROUPS.color,
    attributes: { ...SHARED_AUTOS_GROUPS.color.attributes, [ATTRIBUTES.MATERIAL]: {} },
  },
  model: {
    Icon: AutosMakeIcon,
    attributes: {
      [ATTRIBUTES.BOATS_MODEL]: {
        concatenateMultipleAttributes: {
          canonicalNames: [ATTRIBUTES.CAR_YEAR, ATTRIBUTES.BOATS_MAKE, ATTRIBUTES.BOATS_MODEL],
        },
      },
      [ATTRIBUTES.BOATS_TRIM]: {},
    },
  },
}

const ATTRIBUTE_GROUPS_ATVS: AttributeGroups<SupportedATVGroups> = {
  condition: SHARED_AUTOS_GROUPS.condition,
  kilometers: SHARED_AUTOS_GROUPS.kilometers,
  type: { Icon: AtvIcon, attributes: { [ATTRIBUTES.ATV_TYPE]: {} } },
  transmission: { Icon: TransmissionIcon, attributes: { [ATTRIBUTES.ATV_TRANSMISSION]: {} } },
  engine: { Icon: EngineIcon, attributes: { [ATTRIBUTES.CAR_ENGINE]: {} } },
  fuel: getFuelGroup(ATTRIBUTES.ATV_FUEL_TYPE),
  seats: SHARED_AUTOS_GROUPS.seats,
  wheels: { Icon: AutosAlloyWheelsIcon, attributes: { [ATTRIBUTES.WHEELS]: {} } },
  color: SHARED_AUTOS_GROUPS.color,
  model: {
    Icon: AutosMakeIcon,
    displayInline: true,
    attributes: {
      [ATTRIBUTES.ATV_MODEL]: {
        concatenateMultipleAttributes: {
          canonicalNames: [ATTRIBUTES.CAR_YEAR, ATTRIBUTES.ATV_MAKE, ATTRIBUTES.ATV_MODEL],
        },
      },
      [ATTRIBUTES.ATV_TRIM]: {},
    },
  },
}

export const getAttrGroupsByCategoryId = (
  categoryId: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AttributeGroups<any> | undefined => {
  switch (categoryId) {
    /** Long term rentals */
    case CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_REAL_ESTATE
    case CATEGORIES.ATVS_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_ATVS
    case CATEGORIES.CANOES_KAYAKS_PADDLE_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_CANOES_KAYAKS_PADDLES
    case CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_CARS
    case CATEGORIES.PERSONAL_WATERCRAFT_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_PERSONAL_WATERCRAFT
    case CATEGORIES.POWERBOATS_MOTORBOATS_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_POWERBOATS
    case CATEGORIES.RVS_MOTORHOMES_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_MOTORHOMES
    case CATEGORIES.SAILBOATS_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_SAILBOATS
    case CATEGORIES.SNOWMOBILES_CATEGORY_ID:
      return ATTRIBUTE_GROUPS_SNOWMOBILES
  }
}
