import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { R2SEnhanceLeadsContainer } from '@/features/listing/components/reply-to-seller/conversations/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { FilterChip } from '@/ui/atoms/filter-chip'
import { Flex } from '@/ui/atoms/flex'

export enum EnhanceLeadsOptions {
  FINANCING = 'financing',
  TEST_DRIVE = 'test_drive',
  TRADE_IN = 'trade_in',
}

export type R2SEnhanceLeadsProps = {
  handleChange: (value: EnhanceLeadsOptions) => void
  checkedValues: EnhanceLeadsOptions[]
  isModal?: boolean
}

/**
 * We want those values to be displayed on a specific order, otherwise we could simplify it with "Object.values(EnhanceLeadsOptions)"
 */
const ENHANCE_LEADS_OPTIONS = [
  EnhanceLeadsOptions.TEST_DRIVE,
  EnhanceLeadsOptions.FINANCING,
  EnhanceLeadsOptions.TRADE_IN,
]

export const R2SEnhanceLeads: FC<R2SEnhanceLeadsProps> = ({
  handleChange,
  checkedValues,
  isModal = false,
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.R2S)

  return (
    <Flex as="fieldset" flexDirection="column">
      <BodyText as="legend" weight="medium" size="medium" color={colors.grey.light1}>
        {t('r2s:enhance_leads.option.label')}
      </BodyText>

      <R2SEnhanceLeadsContainer>
        {ENHANCE_LEADS_OPTIONS.map((id) => {
          return (
            <FilterChip
              hideCheckmark={{ small: true, medium: false, large: true, xLarge: false }}
              checked={checkedValues.includes(id)}
              id={`enhance-leads-${isModal ? 'modal' : 'page'}-${id}`}
              key={`enhance-leads-${isModal ? 'modal' : 'page'}-${id}`}
              label={t(`r2s:enhance_leads.option.${id}`)}
              onChange={() => handleChange(id)}
            />
          )
        })}
      </R2SEnhanceLeadsContainer>
    </Flex>
  )
}
