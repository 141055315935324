import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const MileageAnalysisContainer = styled(Flex)(
  ({ theme }) => `
    background-color: ${theme.colors.grey.light5};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing.default};
  `
)

export const VehicleMileage = styled(HeadlineText)(
  ({ theme }) => `
    color: ${theme.colors.grey.primary};
    font-weight: ${theme.fontWeight.semiBold};
    ${styleFromTypography(theme.typography.headline.xLarge)};

    ${MEDIA_QUERIES(theme).medium} {
      ${styleFromTypography(theme.typography.headline.giant)}
    }
  `
)

export const MileageAnalysisFooter = styled(Flex)(
  ({ theme }) => `
    align-items: flex-start;
    flex-direction: column;
    gap: ${theme.spacing.defaultSmall};
    margin-top: ${theme.spacing.large};
    flex-wrap: wrap;

    ${MEDIA_QUERIES(theme).medium} {
      align-items: center;
      flex-direction: row;
      gap: ${theme.spacing.mini};
      margin-top: ${theme.spacing.xLarge};
    }
  `
)
