import { type Locale, type UserLocation } from '@kijiji/generated/graphql-types'
import qs from 'query-string'

import { ALL_CANADA_LABEL_EN, ALL_CANADA_LOCATION_ID } from '@/constants/location'

const hasLastParameterWithValueK0 = (url: string): boolean => {
  const lastParameter = url.split('/').pop()
  return lastParameter ? lastParameter?.indexOf('k0') >= 0 : false
}

/** The location value would always be the second parameter in the URL when All Categories is not selected */
const addLocationSegmentAfterCategory = (url: URL, locationName?: Locale): string => {
  const pathSegment = url.pathname.split('/')

  /**
   * Prepare location name to be valid for the url
   * Location on the URL should be lower case and have "-" instead of '/'
   * i.e. "Oakville / Halton" to "oakville-halton"
   * i.e. "City of Toronto" to "city-of-toronto"
   */
  const newValue = locationName?.en_CA
    ? locationName.en_CA.toLowerCase().replace(' / ', '-').replace('/', '-').replace(/ /g, '-')
    : ALL_CANADA_LABEL_EN.toLowerCase()

  pathSegment.splice(2, 0, newValue)

  url.pathname = pathSegment.join('/')
  return url.toString()
}

/**
 * Example Locationless link:
 * User LocationId: 1700273
 * Locationless URL: https://www.kijiji.ca/b-buy-sell/paint-booth/k0c10
 * Expected response: https://www.kijiji.ca/b-buy-sell/<location>/paint-booth/k0c10
 */
const appendLocationSegmentToKeywordURL = (url: string, location?: UserLocation) => {
  /** Check if URL has a keyword */
  if (hasLastParameterWithValueK0(url)) {
    const newUrl = addLocationSegmentAfterCategory(new URL(url), location?.name)

    return newUrl
  }

  return url
}

/**
 * Append user location id to the SRP URL
 *
 * Example Region:
 * User LocationId: 9004
 * Locationless URL: https://www.kijiji.ca/b-buy-sell/c10?ad=offering
 * @returns https://www.kijiji.ca/b-buy-sell/c10l9004?ad=offering
 *
 * Example Exact Location:
 * User LocationId: 500 King Street, radius: 50km
 * Locationless URL: https://www.kijiji.ca/b-buy-sell/c10?ad=offering
 * @returns https://www.kijiji.ca/b-buy-sell/city-of-toronto/c10l1700273?radius=50.0&address=500+King+St+W+%23300%2C+Toronto%2C+ON+M5V+1L9%2C+Canada&ll=43.645257%2C-79.396695
 */
export const addUserLocationToSrpUrl = (url: string, location?: UserLocation) => {
  const id = location?.id || ALL_CANADA_LOCATION_ID
  const { url: parsedUrl, query } = qs.parseUrl(url)

  let mountedUrl = appendLocationSegmentToKeywordURL(parsedUrl, location)

  /** Add exact location data to query if is not a region */
  const mountedQuery =
    !location || location.isRegion
      ? query
      : {
          ...query,
          ...getAreaQueryParamsForSrpUrl(location),
        }

  mountedUrl = mountedUrl + `l${id}`
  return qs.stringifyUrl({ url: mountedUrl, query: mountedQuery })
}

export const getAreaQueryParamsForSrpUrl = (location: UserLocation) => {
  if (location.isRegion || !location.area) return {}

  return {
    address: location?.area?.address,
    ll: `${location?.area?.latitude},${location?.area?.longitude}`,
    radius: location?.area?.radius,
  }
}
