import styled from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const CPOProgramLogo = styled.img`
  height: 6rem;
  width: min-content;
`

export const DetailList = styled.ul(
  ({ theme }) => `
  margin-top: ${theme.spacing.defaultSmall};

  ${MEDIA_QUERIES(theme).medium} {
    columns: 2;
  }

  li + li {
    margin-top: 1.2rem;
  }
`
)

export const CPOExternalLink = styled(LinkCustom)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing.mini};

  & > svg {
    height: 2.4rem;
    width: 2.4rem;
  }
`
)
