import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgUpload: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.5 17.77h1v-4.695l2.1 2.1.708-.714L12 11.154 8.692 14.46l.714.708 2.094-2.094v4.694zM6.615 21c-.46 0-.844-.154-1.152-.462A1.565 1.565 0 015 19.384V4.616c0-.46.154-.845.463-1.153A1.565 1.565 0 016.615 3H14.5L19 7.5v11.884c0 .46-.154.845-.462 1.154a1.565 1.565 0 01-1.154.462H6.616zM14 8V4H6.615a.588.588 0 00-.423.192.588.588 0 00-.192.423v14.77c0 .153.064.295.192.423s.27.192.423.192h10.77a.588.588 0 00.423-.192.588.588 0 00.192-.424V8h-4z"
        fill="#373373"
      />
    </Icon>
  )
}
export default SvgUpload
