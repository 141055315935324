import { CATEGORIES } from '@kijiji/category'

import { ATTRIBUTES, PET_FRIENDLY_VALUES } from '@/features/attributes/constants/attributes'
import { type AttributeConfig } from '@/features/attributes/constants/attributesConfig'
import { ATTRIBUTES_LABEL_OVERRIDE } from '@/features/attributes/constants/attributesLabelOverride'

/**
 * List of Core attributes
 * Those are the most important attributes for category
 *
 * Not all categories will have core attributes
 *
 * The lists are defined in order of attribute importance. It should not be alphabetized.
 */

const CORE_ATTRIBUTES_REAL_ESTATE: AttributeConfig = {
  [ATTRIBUTES.BEDROOMS]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
  [ATTRIBUTES.BATHROOMS]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
  [ATTRIBUTES.AREA_IN_FEET]: {},
  [ATTRIBUTES.UNIT_TYPE]: {},
  [ATTRIBUTES.PARKING]: { labelOverride: ATTRIBUTES_LABEL_OVERRIDE.VALUE_PLUS_NAME },
  [ATTRIBUTES.PET_FRIENDLY]: {
    labelOverride: ({ attribute: { canonicalValues, name }, t }) => {
      const value = canonicalValues?.[0]
      if (value === PET_FRIENDLY_VALUES.LIMITED)
        return t('listing:attribute.label_override.pet_friendly.limited')
      if (value === PET_FRIENDLY_VALUES.FALSE)
        return t('listing:attribute.label_override.pet_friendly.no_pets')

      return name
    },
  },
}

export const getCoreAttrByCategoryId = (categoryId: number): AttributeConfig | undefined => {
  /** Long Term Rentals */
  if (categoryId === CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID) {
    return CORE_ATTRIBUTES_REAL_ESTATE
  }
}
