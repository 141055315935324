import InfoToolTipIcon from '@kijiji/icons/src/icons/InfoToolTip'
import MetricsIcon from '@kijiji/icons/src/icons/Metrics'
import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { VerticalDivider } from '@/components/shared/vertical-divider/VerticalDivider'
import {
  SellerVipMetricItem,
  SellerVipMetricsContainer,
  SellerVipPromoteContainer,
  SellerVipPromoteLearnMoreButton,
  SellerVipStatusContainer,
  SellerVipTopBannerWrapper,
} from '@/components/vip/vip-seller/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ListingPromoteLearnMoreModal } from '@/features/listing/components/promote/ListingPromoteLearnMoreModal'
import { getListingActionUrl } from '@/features/listing/utils/getListingActionUrl'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

export type SellerVipTopBannerProps = {
  isExpiredVip?: boolean
  isSellerVip?: boolean
  listingId: string
  replies?: number | null
  views?: number
}
/**
 * Section to be displayed on top of the "Seller VIP"
 * Should be displayed when the owner viewing the page is the owner of the listing
 */
export const SellerVipTopBanner: FC<SellerVipTopBannerProps> = ({
  isExpiredVip,
  isSellerVip,
  listingId,
  replies,
  views,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING, TRANSLATION_KEYS.PAID_FEATURES])
  const { colors, spacing } = useTheme()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  if (!isSellerVip) return null

  const handleCloseModal = () => setIsModalOpen(false)
  const handleOpenModal = () => setIsModalOpen(true)

  const editListingUrl = getListingActionUrl({ action: 'edit', listingId })
  const promoteListingUrl = getListingActionUrl({ action: 'promote', listingId })
  const deleteListingUrl = getListingActionUrl({ action: 'delete', listingId })
  const repostAdUrl = getListingActionUrl({ action: 'repost', listingId })

  return (
    <>
      <SellerVipTopBannerWrapper data-testid="seller-vip-banner">
        <SellerVipStatusContainer>
          <Spacing pBottom={spacing.large}>
            <Flex alignItems="center" gap={spacing.defaultSmall} justifyContent="center">
              <MetricsIcon aria-hidden />
              <HeadlineText as="h3" size="large" color={colors.grey.primary}>
                {t(`listing:seller_metrics.status.title`)}
              </HeadlineText>
            </Flex>
          </Spacing>

          <SellerVipMetricsContainer>
            <SellerVipMetricItem>
              <BodyText size="small" color={colors.grey.primary}>
                {t(`listing:seller_metrics.visits`)}
              </BodyText>
              <BodyText size="large" color={colors.grey.primary}>
                {views}
              </BodyText>
            </SellerVipMetricItem>

            <VerticalDivider color={colors.purple.light5} />

            <SellerVipMetricItem>
              <BodyText size="small" color={colors.grey.primary}>
                {t(`listing:seller_metrics.replies`)}
              </BodyText>
              <BodyText size="large" color={colors.grey.primary}>
                {replies ?? '--'}
              </BodyText>
            </SellerVipMetricItem>
          </SellerVipMetricsContainer>

          <Spacing pRight={spacing.default} pLeft={spacing.default} pTop={spacing.defaultSmall}>
            <Flex
              gap={spacing.default}
              justifyContent="center"
              media={{ medium: { justifyContent: 'flex-start' } }}
            >
              <LinkCustom variant="secondary" href={editListingUrl}>
                {t(`listing:seller_metrics.edit`)}
              </LinkCustom>

              <VerticalDivider />

              <LinkCustom variant="secondary" href={deleteListingUrl}>
                {t(`listing:seller_metrics.delete`)}
              </LinkCustom>
            </Flex>
          </Spacing>
        </SellerVipStatusContainer>

        <SellerVipPromoteContainer flexDirection="column" gap={spacing.default}>
          {isExpiredVip ? (
            <>
              <HeadlineText as="h3" size="medium" color={colors.grey.primary}>
                {t('listing:expired_ad.title')}
              </HeadlineText>

              <BodyText size="large" color={colors.grey.primary}>
                {t('listing:expired_ad.repost.description')}
              </BodyText>

              <LinkCustom href={repostAdUrl} button={true}>
                {t(`listing:expired_ad.repost.link`)}
              </LinkCustom>
            </>
          ) : (
            <>
              <Flex alignItems="center" gap={spacing.mini}>
                <HeadlineText as="h3" size="medium" color={colors.grey.primary}>
                  {t('paidFeatures:promote_listing.title')}
                </HeadlineText>

                <SellerVipPromoteLearnMoreButton
                  aria-label={t('paidFeatures:promote_listing.learn.tooltip')}
                  onClick={handleOpenModal}
                >
                  <InfoToolTipIcon aria-hidden />
                </SellerVipPromoteLearnMoreButton>
              </Flex>

              <LinkCustom href={promoteListingUrl} button={true}>
                {t(`paidFeatures:promote_listing.button`)}
              </LinkCustom>
            </>
          )}
        </SellerVipPromoteContainer>
      </SellerVipTopBannerWrapper>

      <ListingPromoteLearnMoreModal handleOnCancel={handleCloseModal} isOpen={isModalOpen} />
    </>
  )
}
