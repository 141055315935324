import { type FC } from 'react'

import { VipAttributeCoreWrapper } from '@/components/vip/vip-attributes/styled'
import { AttributeWithIcon } from '@/features/attributes/components/AttributeWithIcon'
import { type DisplayableAttribute } from '@/features/attributes/types/listingAttributes'

type VipAttributeCoreProps = {
  coreAttributes: DisplayableAttribute[]
}
export const VipAttributeCore: FC<VipAttributeCoreProps> = ({ coreAttributes }) => {
  if (!coreAttributes.length) return null

  return (
    <VipAttributeCoreWrapper>
      {coreAttributes.map(({ Icon, label }, index) => {
        return (
          <AttributeWithIcon
            Icon={Icon}
            iconBackgroundVariant="primary-bg"
            key={`a-${index}`}
            label={label}
          />
        )
      })}
    </VipAttributeCoreWrapper>
  )
}
