import { SellerType } from '@kijiji/generated/graphql-types'

import { isDealerListing } from '@/features/listing/utils/isDealerListing'
import { type VipQueryListing } from '@/types/listings'

export const isPaidListing = (listing: VipQueryListing): boolean => {
  if (isDealerListing(listing.attributes) || listing.posterInfo.sellerType === SellerType.Kmb)
    return true

  return false
}
