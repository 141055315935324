import { APIProvider } from '@vis.gl/react-google-maps'
import { type FC, type PropsWithChildren } from 'react'

import { useMapProvider } from '@/features/map/hooks/useMapProvider'

/**
 * Generic Provider Map component allows map to be rendered
 */
export const GenericMapProvider: FC<PropsWithChildren> = ({ children }) => {
  const { mapApiKey } = useMapProvider(true)

  if (!mapApiKey) return children

  return <APIProvider apiKey={mapApiKey}>{children}</APIProvider>
}
