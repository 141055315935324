import { type SellerType } from '@kijiji/generated/graphql-types'
import PhoneOutlineIcon from '@kijiji/icons/src/icons/PhoneOutline'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ChipActionButton } from '@/components/shared/chip-action-button/ChipActionButton'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { PROFILE_PHONE_PLACEHOLDER } from '@/features/profile/constants/phoneNumber'
import { useProfilePhoneNumber } from '@/features/profile/utils/phone-number/useProfilePhoneNumber'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { TextLink } from '@/ui/atoms/text-link'

export type ProfilePhoneNumberProps = {
  /**
   * The ID of the profile to fetch the phone number for.
   */
  profileId: string

  /**
   * Category ID of the listing.
   */
  categoryId: number

  /**
   * Seller type of the listing.
   */
  sellerType: SellerType

  /**
   * The ID of the listing to fetch the phone number for. This is not required
   * but is used to get distinct phone numbers set for each listing.
   */
  listingId?: string

  /**
   * When the Google Analytics tracking is called for R2SPhoneBegin
   * events, this label is used passed with the tracking event.
   */
  trackingLabel?: string

  /**
   * This is used to check if a GSI event should be tracked when the phone number reveal gets clicked
   */
  isGSITrackingEnabled?: boolean
}

/**
 * Phone number component that reveals the phone number of a listing.
 */
export const ProfilePhoneNumber: FC<ProfilePhoneNumberProps> = (props) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.R2S, TRANSLATION_KEYS.COMMON])

  // TODO: error tracking? right now just resets.. do we want to error?
  const {
    revealedPhoneNumber,
    isRevealPhoneNumberLoading,
    revealPhoneNumberError,
    triggerPhoneNumberReveal,
  } = useProfilePhoneNumber(props)

  return (
    <ChipActionButton onClick={triggerPhoneNumberReveal}>
      <Flex alignItems="center" gap={spacing.defaultSmall}>
        <PhoneOutlineIcon aria-hidden size="default" color={colors.purple.primary} />

        {revealedPhoneNumber ? (
          <TextLink size="medium" href={`tel:${revealedPhoneNumber}`}>
            {revealedPhoneNumber}
          </TextLink>
        ) : (
          <BodyText color={colors.purple.primary} size="medium">
            {revealPhoneNumberError
              ? t('common:forms.errors.generic.description')
              : PROFILE_PHONE_PLACEHOLDER}
          </BodyText>
        )}
      </Flex>

      {revealedPhoneNumber || isRevealPhoneNumberLoading ? null : (
        <BodyText
          aria-label={t('r2s:phone_number.reveal.long_label')}
          color={colors.purple.primary}
          size="medium"
        >
          {t('r2s:phone_number.reveal.label')}
        </BodyText>
      )}
    </ChipActionButton>
  )
}
