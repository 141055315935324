import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'

export const ListingPromoteLearnMoreItem = styled(Flex)`
  & > svg {
    flex-shrink: 0;
    height: 5rem;
    width: 5rem;
  }
`
