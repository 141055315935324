import styled from 'styled-components'

import { type GenericMapProps } from '@/features/map/components/generic/GenericMap'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { hexToRgb } from '@/ui/helpers/hexToRgb'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const GenericMapContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

const animationConfig = `
  animation-direction: normal;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`

const locationIconBouncingAnimation = `
  @keyframes location-icon-bouncing {
    0%, 100% {
      /* Original position */
      transform: translateY(0); 
    }
    50% {
      /* Move up */
      transform: translateY(-4rem);
    }
  }
 
  & > svg {
    animation-name: location-icon-bouncing;
    ${animationConfig}
  }
`

const locationShadowAnimation = `
  @keyframes shadow-icon-bouncing {
    0%, 100% {
      /* Original position */
      width: 6rem;
    }
    50% {
      /* Move up */
      width: 3.5rem;
    }
  }
 
  &::after {
    animation-name: shadow-icon-bouncing;
    ${animationConfig}
  }
`

type GenericMapIconContainerProp = Pick<GenericMapProps, 'loadingSizeVariation'> & {
  variation: 'loading' | 'error'
}
export const GenericMapIconContainer = styled.div<GenericMapIconContainerProp>(
  ({ theme, variation, loadingSizeVariation = 'default' }) => `
  ${variation === 'error' || loadingSizeVariation === 'small' ? null : `${locationIconBouncingAnimation} ${locationShadowAnimation}`}

  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.default};
  height: fit-content;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    background: ${hexToRgb(theme.colors.grey.dark3, 0.2)};
    border-radius: ${theme.borderRadius.small};
    bottom: -1rem;
    filter: blur(5px);
    height: 1rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  
  & > svg {    
    color: ${variation === 'error' ? theme.colors.red.primary : theme.colors.blue.primary};
    height: ${loadingSizeVariation === 'small' ? '4rem' : '12rem'};
    width: auto;
  }

  ${MEDIA_QUERIES(theme).medium} {
    & > svg {    
      height: ${loadingSizeVariation === 'small' ? '4rem' : '20rem'};
    }
  }
`
)

// eslint-disable-next-line @kijiji/prefer-link-custom
export const GenericMapModalLink = styled.a(
  ({ theme }) => `
    ${styleFromTypography(theme.typography.body.small)}

    background: ${hexToRgb('#000000', 0.25)};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.white};
    display: inline-block;
    padding: ${theme.spacing.defaultSmall} ${theme.spacing.default};
    text-decoration: none;
    transition: background .2s linear;

    &:hover {
      background: ${hexToRgb('#000000', 0.4)};
    }
`
)

export const GenericMapModalFooter = styled.div(
  ({ theme }) => `
  align-items: flex-start;
  background-color: ${theme.colors.blue.primary};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.default};
  justify-content: space-between;
  min-height: 7rem;
  padding: ${theme.spacing.large};

  ${MEDIA_QUERIES(theme).medium} {
    align-items: center;
    flex-direction: row;
    padding: ${theme.spacing.default} ${theme.spacing.large};
  }
`
)
