import AutosPriceIcon from '@kijiji/icons/src/icons/AutosPrice'
import HistoryIcon from '@kijiji/icons/src/icons/History'
import SafetyIcon from '@kijiji/icons/src/icons/Safety'
import WrapIcon from '@kijiji/icons/src/icons/Wrap'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { VehicleSectionContent } from '@/components/vip/vip-vehicle-history/styled'
import { VehicleHistoryInlineText } from '@/components/vip/vip-vehicle-history/VehicleHistoryInlineText'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { BodyText } from '@/ui/atoms/body-text'

export const VehicleHistoryRequestBody = () => {
  const { colors } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.VIP)

  return (
    <>
      <BodyText color={colors.grey.primary} size="medium">
        {t('vehicle_history.request.description_long')}
      </BodyText>

      <VehicleSectionContent data-testid="vehicle-history-request-content">
        <VehicleHistoryInlineText
          Icon={HistoryIcon}
          label={t('vehicle_history.accident_history')}
        />
        <VehicleHistoryInlineText Icon={AutosPriceIcon} label={t('vehicle_history.money_owed')} />
        <VehicleHistoryInlineText Icon={WrapIcon} label={t('vehicle_history.service_records')} />
        <VehicleHistoryInlineText Icon={SafetyIcon} label={t('vehicle_history.safety_recalls')} />
      </VehicleSectionContent>
    </>
  )
}
