import CarfaxKilometresEn from '@kijiji/icons/src/icons/CarfaxLowKilometres'
import CarfaxKilometresFr from '@kijiji/icons/src/icons/CarfaxLowKilometresFr'
import CarfaxAccidentsEn from '@kijiji/icons/src/icons/CarfaxNoReportedAccidents'
import CarfaxAccidentsFr from '@kijiji/icons/src/icons/CarfaxNoReportedAccidentsFr'
import CarfaxOneOwnerEn from '@kijiji/icons/src/icons/CarfaxOneOwner'
import CarfaxOneOwnerFr from '@kijiji/icons/src/icons/CarfaxOneOwnerFr'
import CarfaxConditionEn from '@kijiji/icons/src/icons/CarfaxTopCondition'
import CarfaxConditionFr from '@kijiji/icons/src/icons/CarfaxTopConditionFr'
import { type TFunction } from 'next-i18next'

import { type RouteLocale, ROUTE_LOCALE } from '@/domain/locale'

type Response = {
  noAccidentsIcon: JSX.Element
  oneOwnerIcon: JSX.Element
  lowKilometresIcon: JSX.Element
  topConditionIcon: JSX.Element
}

/**
 * Returns all vehicle report icons based on user locale
 * It will add correct aria-label to each icon
 */
export const getVehicleReportBadges = (t: TFunction, routeLocale: RouteLocale): Response => {
  const isFrLocale = routeLocale === ROUTE_LOCALE.fr

  const NoAccidentsIcon = isFrLocale ? CarfaxAccidentsFr : CarfaxAccidentsEn
  const OneOwnerIcon = isFrLocale ? CarfaxOneOwnerFr : CarfaxOneOwnerEn
  const LowKilometresIcon = isFrLocale ? CarfaxKilometresFr : CarfaxKilometresEn
  const TopConditionIcon = isFrLocale ? CarfaxConditionFr : CarfaxConditionEn

  return {
    noAccidentsIcon: <NoAccidentsIcon aria-label={t('vip:vehicle_history.badges.no_accidents')} />,
    oneOwnerIcon: <OneOwnerIcon aria-label={t('vip:vehicle_history.badges.one_owner')} />,
    lowKilometresIcon: <LowKilometresIcon aria-label={t('vip:vehicle_history.badges.low_km')} />,
    topConditionIcon: (
      <TopConditionIcon aria-label={t('vip:vehicle_history.badges.top_condition')} />
    ),
  }
}
