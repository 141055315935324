import { type ListingAttributeV2, SellerType } from '@kijiji/generated/graphql-types'
import { type TFunction } from 'next-i18next'

import { ATTRIBUTES } from '@/features/attributes/constants/attributes'

/**
 * Returns the seller type label based on the forSaleBy attributes (forSaleBy, forRentByHousing, forSaleByHousing)
 *
 * if the none of these are present in the listing attributes it will check for the SellerType
 * and fallback to "Private Seller"
 *
 * @param attributes Listing Attributes
 * @param sellerType Type of listing's seller
 * @param t Translations function dependent on "listings"
 */
export const getForSaleByLabel = ({
  attributes,
  t,
  sellerType,
}: {
  attributes: ListingAttributeV2[]
  sellerType?: SellerType
  t: TFunction
}): string | undefined => {
  const forSaleByAttributes = [
    ATTRIBUTES.FOR_SALE_BY,
    ATTRIBUTES.FOR_RENT_BY_HOUSING,
    ATTRIBUTES.FOR_SALE_BY_HOUSING,
    ATTRIBUTES.FOR_SALE_BY_PETS,
    ATTRIBUTES.JOB_OFFERED_BY,
  ]

  for (const attr of forSaleByAttributes) {
    const displayValue = attributes?.find((item) => item?.canonicalName === attr)?.values[0]
    if (displayValue) {
      return displayValue
    }
  }

  if (sellerType === SellerType.Commercial || sellerType === SellerType.Kmb) {
    return t('listing:attribute.for_sale_by.business')
  }

  return t('listing:attribute.for_sale_by.private')
}
