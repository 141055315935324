import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'

import { type ListingAttributes } from '@/features/attributes/types/listingAttributes'

/**
 * The attributes returned from "Listing" is an array that can contain null
 * When expecting "ListingAttributeV2[]" we need to filter the null values
 */
export const filterNullAttributes = (attributes?: ListingAttributes): ListingAttributeV2[] => {
  return attributes?.filter((item) => !!item) ?? []
}
