import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ListingImageType } from '@/features/listing/types/listings'
import {
  ListingThumbnailButton,
  ListingThumbnailImage,
  ListingThumbnailOverlay,
  StyledPlayIcon,
} from '@/features/listing-gallery/components/styled'
import { BodyText } from '@/ui/atoms/body-text'

export type ListingGalleryThumbnailProps = {
  hasOverlay?: boolean
  /**
   * Whether to show the play icon on the thumbnail.
   */
  hasPlayIcon?: boolean

  /**
   * Alt text for thumbnail image
   */
  imageAlt: string
  /**
   * The total number of images in the gallery.
   */
  imageCount: number
  imageSrc: string
  onClick: (index: number, imageType: ListingImageType) => void
  thumbnailIndex: number
  /** Thumbnail linked to a virtual tour */
  isVirtualTour?: boolean
}

export const ListingGalleryThumbnail: FC<ListingGalleryThumbnailProps> = ({
  hasOverlay,
  hasPlayIcon,
  imageAlt,
  imageCount,
  imageSrc,
  isVirtualTour,
  onClick,
  thumbnailIndex,
}) => {
  const { colors } = useTheme()

  // Three images are shown in the gallery, the rest are hidden in the overlay
  const remainingImages = imageCount - 3

  const handleThumbnailClick = () => {
    let imageType: ListingImageType = ListingImageType.IMAGE

    if (isVirtualTour) {
      imageType = ListingImageType.TOUR
    } else if (hasPlayIcon) {
      imageType = ListingImageType.VIDEO
    }

    /**
     * If thumbnail has overlay, then the gallery will open on index 0
     * If not, then the gallery will open on the image clicked
     */
    onClick(hasOverlay ? 0 : thumbnailIndex + 1, imageType)
  }

  return (
    <ListingThumbnailButton onClick={handleThumbnailClick}>
      {hasOverlay ? (
        <ListingThumbnailOverlay data-testid="listing-thumbnail-overlay">
          <BodyText color={colors.white} size="xLarge">
            +{remainingImages}
          </BodyText>
        </ListingThumbnailOverlay>
      ) : null}

      {hasPlayIcon && <StyledPlayIcon variant="sm" data-testid="gallery-thumbnail-play-icon" />}
      <ListingThumbnailImage src={imageSrc} data-testid="gallery-thumbnail" alt={imageAlt} />
    </ListingThumbnailButton>
  )
}
