import { useGetAutosWarrantyQuery } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { isWarrantyQuoteExpired } from '@/features/autocanada/utils/isWarrantyQuoteExpired'
import { BodyText } from '@/ui/atoms/body-text'

type WarrantyQuoteDisclaimerProps = {
  listingId: string
}

/**
 * This autocan disclaimer should be used on pages that could potentially display a warranty quote.
 * This component will automatically identify if there is a warranty quote available and display the warranty
 */
export const WarrantyQuoteDisclaimer: FC<WarrantyQuoteDisclaimerProps> = ({ listingId }) => {
  const { colors } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.AUTOCANADA)

  /**
   * This query will only return a value if the autocan Warranty feature was already loaded in the page
   * Therefore it will be cache-only
   */
  const { data } = useGetAutosWarrantyQuery({
    fetchPolicy: 'cache-only',
    variables: { id: listingId },
  })

  const isOfferExpired = isWarrantyQuoteExpired(data?.autosWarranty?.offerExpiry)

  if (!data?.autosWarranty || isOfferExpired) return null

  return (
    <BodyText color={colors.grey.light1} size="small" textAlign="center">
      {t('autocanada:warranty.quote.disclaimer')}
    </BodyText>
  )
}
