import { AuthModalVariant } from '@kijiji/generated/graphql-types'
import { ChevronIcon } from '@kijiji/icons/src/icons'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { HideAtOrLarger, ShowAtOrLarger } from '@/components/shared/breakpoint'
import { ImageCustom } from '@/components/shared/image'
import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import {
  VipFinancingSectionMobileChevron,
  VipFinancingSectionWrapper,
} from '@/components/vip/vip-financing/styled'
import { VipFinancingModal } from '@/components/vip/vip-financing/VipFinancingModal'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { useSignInModal } from '@/features/auth/hooks/useSignInModal'
import { RemoteToggles } from '@/lib/firebase/config/toggles'
import { useToggle } from '@/lib/firebase/hooks'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryListing } from '@/types/listings'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import dealerFinancingImage from '~/public/next-assets/images/dealer-financing.png'

export type VipFinancingSectionProps = {
  flags: VipQueryListing['flags']
  listingId: string
}

export const VipFinancingSection: FC<VipFinancingSectionProps> = ({ flags, listingId }) => {
  const isBannerEnabled = useToggle(RemoteToggles.VIP_FINANCING_BANNER.key)?.enabled ?? false
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { spacing } = useTheme()
  const { asPath } = useRouter()
  const { data: userData, status } = useSession()
  const { openSignInModal } = useSignInModal()

  const [openFinancingModal, setOpenFinancingModal] = useState<boolean>(false)

  /**
   * It should only show section if the "financingAvailable" is true
   */
  const financingAvailable = 'financingAvailable' in flags ? flags.financingAvailable : false
  if (!financingAvailable || !isBannerEnabled) return null

  const handleClick = () => {
    // It should open the sign in modal if user is not logged in
    if (!isUserAuthenticated(status)) {
      openSignInModal({ modalVariant: AuthModalVariant.LoginToFinanceInquiry, callbackUrl: asPath })
      return
    }

    setOpenFinancingModal(true)
    trackEvent({ action: GA_EVENT.AutoFinancingShow })
  }

  const handleCloseModal = () => setOpenFinancingModal(false)

  const financingContent = () => {
    return (
      <Flex gap={spacing.default} alignItems="center" justifyContent="space-between">
        <ImageCustom src={dealerFinancingImage.src} alt="" skipNextImage />

        <Flex gap={spacing.mini} flexDirection="column">
          <HeadlineText size="medium" as="h2">
            {t('vip:financing.title')}
          </HeadlineText>
          <BodyText size="medium">{t('vip:financing.description')}</BodyText>
        </Flex>
      </Flex>
    )
  }

  return (
    <>
      <VipSectionContainer>
        <ShowAtOrLarger breakpoint="medium">
          <VipFinancingSectionWrapper>
            {financingContent()}

            <Button variant="subtle" onClick={handleClick}>
              {t('vip:financing.inquiry_label')}
            </Button>
          </VipFinancingSectionWrapper>
        </ShowAtOrLarger>

        {/* The entire wrapper turns into a button on mobile*/}
        <HideAtOrLarger breakpoint="medium">
          <VipFinancingSectionWrapper
            as="button"
            aria-label={t('vip:financing.inquiry_label')}
            onClick={handleClick}
          >
            {financingContent()}

            <VipFinancingSectionMobileChevron>
              <ChevronIcon aria-hidden />
            </VipFinancingSectionMobileChevron>
          </VipFinancingSectionWrapper>
        </HideAtOrLarger>
      </VipSectionContainer>

      {openFinancingModal && userData?.user ? (
        <VipFinancingModal
          handleClose={handleCloseModal}
          isOpen={openFinancingModal}
          listingId={listingId}
          userData={userData.user}
        />
      ) : null}
    </>
  )
}
