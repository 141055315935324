import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Spacing } from '@/ui/atoms/spacing'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const ListingsSimilarCarouselWrapper = styled.div(
  ({ theme }) => `
  & h2 {
    padding-left: ${theme.spacing.default};
  }

  ${MEDIA_QUERIES(theme).medium} {
    & h2 {
      padding-left: 0;
    }
  }
`
)

export const ListingSimilarModalContainer = styled.div(
  ({ theme }) => `
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    gap: ${theme.spacing.default};
  `
)

export const MobileFooterCloseButtonContainer = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing.default} ${theme.spacing.large};
    position: sticky;
    bottom: 0;
    background: white;
    border-top: .1rem solid ${theme.colors.grey.light4};
    z-index: ${zIndexRegistry.FLOATING_CTA};
  
    ${MEDIA_QUERIES(theme).medium} {
      display: none;
    }
  `
)

export const SystemMessageWrapper = styled(Spacing)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.default};
  `
)
