import { VIP_GPT_ID } from '@/components-page/vip/advertisement/vipAdSlots'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'
import { triggerManualAdRefresh } from '@/features/advertisement/utils/triggerManualAdRefresh'
import { StyledDftTab } from '@/features/custom-tab-groups/components/styled'
import { useBreakpointChange } from '@/hooks/useBreakpointChange'

export type DfpTabProps = {
  dfpIndex: number
  isLastTab: boolean
}

// DFP is the old name for GPT but is still used in the CTM response data
export const DfpTab = ({ dfpIndex, isLastTab }: DfpTabProps) => {
  useBreakpointChange([LEGACY_HP_LG_BREAKPOINT], () => {
    triggerManualAdRefresh(VIP_GPT_ID.TAB)
  })

  // there are only 2 dfp slots available
  if (dfpIndex > 2) return null
  const slotId = `${VIP_GPT_ID.TAB}-${dfpIndex}`

  return <StyledDftTab id={slotId} data-testid={slotId} $isLastTab={isLastTab} />
}
