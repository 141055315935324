import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const R2SModalFormWrapper = styled.div(
  ({ theme }) => `
  border-top: .1rem solid ${theme.colors.grey.light4} ;
  height: 100%;
  padding: ${theme.spacing.large} ${theme.spacing.xLarge};

  ${MEDIA_QUERIES(theme).medium}{
    padding: ${theme.spacing.default} ${theme.spacing.husky} ${theme.spacing.husky};
  }
`
)
