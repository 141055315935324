import InfoToolTip from '@kijiji/icons/src/icons/InfoToolTip'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ListingSurcharges } from '@/components/shared/listing-surcharges/ListingSurcharges'
import {
  VipPriceBadge,
  VipPriceContainer,
  VipPriceDropLabel,
  VipPriceTooltipButton,
} from '@/components/vip/vip-price/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { isAutosDealerAmountPrice } from '@/domain/listings/AutosDealerAmountPrice'
import { getListingBadgeLabel } from '@/domain/listings/getListingBadgeLabel'
import { getListingPrice } from '@/domain/listings/getListingPrice'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { PriceInsightsBadge } from '@/features/listing/components/price-insights/PriceInsightsBadge'
import { getPriceClassification } from '@/features/listing/utils/getPriceClassification'
import { useLocale } from '@/hooks/useLocale'
import { RemoteToggles } from '@/lib/firebase/config/toggles'
import { useToggle } from '@/lib/firebase/hooks'
import { type VipQueryListing, type VipQueryPrice } from '@/types/listings'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'

export type VipPriceProps = {
  categoryId: number
  listingFlags: VipQueryListing['flags']
  price?: VipQueryPrice
  size?: 'large' | 'medium'
  /** The simplified variation strips out the surcharges and badges */
  isSimplified?: boolean
  /** Specifies if price insight badge should appear if available */
  showPriceInsight?: boolean
}

const Tooltip = dynamic(() => import('@/ui/atoms/tool-tip').then((mod) => mod.Tooltip), {
  ssr: false,
})

export const VipPrice: FC<VipPriceProps> = ({
  categoryId,
  isSimplified,
  listingFlags,
  price,
  showPriceInsight,
  size = 'large',
}) => {
  const { routeLocale } = useLocale()
  const { colors, spacing } = useTheme()
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const priceInsightToggle = useToggle(RemoteToggles.AUTOS_PRICE_INSIGHT.key)

  const badgeLabel = getListingBadgeLabel({
    categorySpecificBadge: listingFlags.categorySpecificBadge ?? false,
    categoryId,
  })

  const renderPriceBadge = () => {
    return badgeLabel && !isSimplified ? (
      <VipPriceBadge mRight={spacing.defaultSmall} data-testid="vip-category-badge">
        <BodyText size="small" color={colors.grey.light5}>
          {t(`listing:badges.${badgeLabel}`).toUpperCase()}
        </BodyText>
      </VipPriceBadge>
    ) : null
  }

  /**
   * If there is no price we should still show the category badge if any
   */
  if (!price) {
    return renderPriceBadge()
  }

  const formattedPrice = getListingPrice({ price, routeLocale, t })

  const originalPrice =
    isAmountPrice(price) && price.originalAmount && !!listingFlags.priceDrop
      ? getListingPrice({ price: price.originalAmount, routeLocale, t })
      : undefined

  if (!formattedPrice) return null

  const surcharges = isAutosDealerAmountPrice(price) ? price.surcharges : undefined
  const priceInsight =
    showPriceInsight && priceInsightToggle?.enabled ? getPriceClassification(price) : undefined

  return (
    <Flex alignItems="center">
      <VipPriceContainer hasPriceRatingBadge={!!priceInsight?.rating} mRight={spacing.mini}>
        <Flex
          alignItems="center"
          gap={spacing.defaultSmall}
          media={{ medium: { gap: spacing.default } }}
        >
          <HeadlineText
            as="p"
            color={colors.grey.primary}
            data-testid="vip-price"
            size={
              size === 'large'
                ? { small: 'large', medium: 'giant' }
                : { small: 'small', medium: 'medium' }
            }
            weight="bold"
          >
            {formattedPrice}
          </HeadlineText>

          {priceInsight?.rating ? <PriceInsightsBadge insightType={priceInsight?.rating} /> : null}
        </Flex>

        {!isSimplified ? <ListingSurcharges surcharges={surcharges} size="medium" /> : null}
      </VipPriceContainer>

      {/* Should show badge when there is a price drop */}
      {originalPrice && !isSimplified ? (
        <VipPriceBadge mRight={spacing.defaultSmall} data-testid="vip-price-drop-badge">
          <VipPriceDropLabel size="medium" color={colors.white}>
            {originalPrice}
          </VipPriceDropLabel>

          <Tooltip
            orientation="bottom-left"
            tooltipId="vip-price-drop"
            tooltipText={t('listing:price.drop.tooltip')}
          >
            <VipPriceTooltipButton role="tooltip" data-testid="price-drop-tooltip-button">
              <InfoToolTip aria-hidden />
            </VipPriceTooltipButton>
          </Tooltip>
        </VipPriceBadge>
      ) : null}

      {renderPriceBadge()}
    </Flex>
  )
}
