import { type GetGoogleReviewsQuery, type UserType } from '@kijiji/generated/graphql-types'

import { type GetRatingReturnType } from '@/features/profile/utils/getUserRatingProps'
import { trackEvent } from '@/lib/ga'
import { type GaEvent } from '@/lib/ga/constants/gaEvent'
import { getAnalyticsRatingType } from '@/lib/ga/events/googleReviews'

type GetDealerRatingPropsType = {
  accountType?: UserType
  googleReviewData?: GetGoogleReviewsQuery
  googleReviewsGaEvent?: GaEvent
  loadingDealerReviews: boolean
}

/**
 * Generates the Google reviews props for the <Rating> component.
 *
 * @param accountType - The user's account type (i.e. 'PV_DEALER').
 * @param googleReviewData - Data containing reviews fetched from Google.
 * @param googleReviewsGaEvent - The Google Analytics event to track clicks on the review URL.
 * @param loadingDealerReviews - Whether the dealer reviews are currently loading.
 */
export const getDealerRatingProps = ({
  accountType,
  googleReviewData,
  googleReviewsGaEvent,
  loadingDealerReviews,
}: GetDealerRatingPropsType): GetRatingReturnType => {
  const googleReviews = googleReviewData?.reviewsFromGoogle
  const shouldShowRating = !!(loadingDealerReviews || googleReviews)
  const score = googleReviews?.rating || 0
  const reviewsLabel = googleReviews?.totalCount.toString() || '0'

  const handleReviewUrlClick = () => {
    if (!googleReviewsGaEvent || !accountType) {
      return
    }

    trackEvent({
      action: googleReviewsGaEvent,
      label: getAnalyticsRatingType(accountType),
    })
  }

  return {
    handleReviewUrlClick,
    isLoading: loadingDealerReviews,
    shouldOpenInNewTab: true,
    reviewsLabel,
    reviewUrl: googleReviews?.url,
    score,
    shouldShowRating,
  }
}
