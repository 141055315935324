import { useGetGoogleReviewsQuery } from '@kijiji/generated/graphql-types'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { VipSectionWrapper } from '@/components/vip/styled'
import { UserRating } from '@/features/profile/components/UserRating'
import { UserReviews } from '@/features/profile/components/UserReviews'
import { getNumericUserId } from '@/features/profile/utils/getNumericUserId'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

export type VipGoogleReviewsProps = {
  isDealerListing?: boolean
  posterId: string
}

export const VipDealerReviews: FC<VipGoogleReviewsProps> = ({ posterId, isDealerListing }) => {
  const { spacing } = useTheme()
  /**
   * This query will just be triggered once either here or inside of the UserRating, then it will refer to the cache
   * This check is necessary as the VIP adds extra borders to this component that should only appear when there are reviews to be loaded
   */
  const { data } = useGetGoogleReviewsQuery({
    skip: !isDealerListing,
    fetchPolicy: 'cache-first',
    variables: { dealerId: getNumericUserId(posterId).toString() },
  })

  if (!isDealerListing || !data?.reviewsFromGoogle?.reviews.length) return null

  return (
    <VipSectionWrapper data-testid="vip-dealer-reviews">
      <VipSectionContainer pTop={spacing.default} pBottom={spacing.default}>
        <UserRating
          googleReviewsGaEvent={GA_EVENT.GoogleReviewClickAboutSeller}
          userId={posterId}
          size="large"
        />

        <UserReviews userId={posterId} />
      </VipSectionContainer>
    </VipSectionWrapper>
  )
}
