import { useMemo } from 'react'

import { VIP_GPT_ID } from '@/components-page/vip/advertisement/vipAdSlots'
import { StyledGptSponsoredImpression } from '@/features/custom-tab-groups/components/styled'
import { addCtmTargetingToSlot } from '@/features/custom-tab-groups/utils/addCtmTargetingToSlot'

type GptLeaderProps = {
  gptIndex: number
  tabId: number
}

export const GptSponsoredImpression = ({ gptIndex, tabId }: GptLeaderProps) => {
  const slotId = `${VIP_GPT_ID.SPONSORED_IMPRESSION}-${gptIndex}`
  useMemo(() => addCtmTargetingToSlot(slotId, tabId), [slotId, tabId])

  return <StyledGptSponsoredImpression id={slotId} data-testid={slotId} />
}
