import { EventsUrlType, useEventsExternalClickMutation } from '@kijiji/generated/graphql-types'
import ExternalLinkIcon from '@kijiji/icons/src/icons/ExternalLink'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ShowAtOrLarger } from '@/components/shared/breakpoint'
import { ChipActionButton } from '@/components/shared/chip-action-button/ChipActionButton'
import { VipAutoVerify } from '@/components/vip/vip-auto-verify/VipAutoVerify'
import { RequestViewingMobileContainer } from '@/components/vip/vip-seller-profile/styled'
import { appendReferralIfNeeded } from '@/components/vip/vip-seller-profile/utils/appendReferralIfNeeded'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { RequestViewing } from '@/features/listing/components/request-viewing/RequestViewing'
import { getPriceClassification } from '@/features/listing/utils/getPriceClassification'
import { isListingEligibleForRequestViewing } from '@/features/listing/utils/request-viewing/isListingEligibleForRequestViewing'
import { ProfilePhoneNumber } from '@/features/profile/components/phone-number/ProfilePhoneNumber'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryListing, isRealEstateListingFlags } from '@/types/listings'
import { Flex } from '@/ui/atoms/flex'
import { generateVIPPhoneTrackingLabel } from '@/utils/vip/generateVIPPhoneTrackingLabel'

export type VipSideProfileActionButtonsProps = {
  listing: VipQueryListing
  isListingFromDealer: boolean
  isGSITrackingEnabled?: boolean
}

export const VipSideProfileActionButtons: FC<VipSideProfileActionButtonsProps> = ({
  listing,
  isListingFromDealer,
  isGSITrackingEnabled,
}) => {
  const { t } = useTranslation(TRANSLATION_KEYS.PROFILE)
  const { spacing } = useTheme()

  const shouldRenderRequestViewing = isListingEligibleForRequestViewing(listing)

  const isComfreeAd = isRealEstateListingFlags(listing.flags) && listing.flags.comfree

  const productUrl =
    listing.productUrl && isComfreeAd
      ? appendReferralIfNeeded(listing.productUrl)
      : listing.productUrl
  const [eventsExternalClick] = useEventsExternalClickMutation()

  const handleProductUrlClick = () => {
    if (!productUrl) return

    // ODIN tracking event
    eventsExternalClick({
      variables: {
        eventsExternalClickId: listing.id,
        url: productUrl,
        urlType: EventsUrlType.SellerProductPage,
      },
    })

    // GA tracking event
    if (isComfreeAd) {
      trackEvent({ action: GA_EVENT.R2SExternalBegin, label: 'btn=DetailsAndContact;' })
    } else {
      trackEvent({ action: GA_EVENT.R2SExternalBegin, label: 'btn=ProductURL;' })
    }
  }

  const priceRating = getPriceClassification(listing.price)?.rating

  const productWebsiteLabel = isComfreeAd
    ? t('profile:profile.metrics.product_website.comfree')
    : t('profile:profile.metrics.product_website')

  const r2sPhoneTrackingLabel = generateVIPPhoneTrackingLabel({
    isDealerListing: isListingFromDealer,
    eventLocation: 'top',
    priceInsightRate: priceRating,
    categoryId: listing.categoryId,
  })

  /* The real estate "request viewing" feature uses the product URL and has a different UI for it */
  const displayProductUrlActionButton = !!productUrl && !shouldRenderRequestViewing

  /** Only display section wrapper if one of the items below are available */
  if (
    !shouldRenderRequestViewing &&
    !listing.posterInfo.phoneNumber &&
    !isListingFromDealer &&
    !displayProductUrlActionButton
  ) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={spacing.defaultSmall}>
      <ShowAtOrLarger breakpoint="large">
        <Flex flexDirection="column" gap={spacing.defaultSmall}>
          {shouldRenderRequestViewing && <RequestViewing source="VIP" listing={listing} />}

          {listing.posterInfo.phoneNumber ? (
            <ProfilePhoneNumber
              categoryId={listing.categoryId}
              isGSITrackingEnabled={isGSITrackingEnabled}
              listingId={listing.id}
              profileId={listing.posterInfo.posterId}
              sellerType={listing.posterInfo.sellerType}
              trackingLabel={r2sPhoneTrackingLabel}
            />
          ) : null}
        </Flex>
      </ShowAtOrLarger>

      {shouldRenderRequestViewing && (
        <RequestViewingMobileContainer>
          <RequestViewing source="VIP" listing={listing} />
        </RequestViewingMobileContainer>
      )}

      <VipAutoVerify listing={listing} isDealerListing={isListingFromDealer} />

      {displayProductUrlActionButton && (
        <ShowAtOrLarger breakpoint="medium">
          <ChipActionButton
            data-testid="listing-product-website"
            forwardedAs="a"
            gap={spacing.default}
            href={productUrl}
            rel="noopener noreferrer"
            target="blank"
            onClick={handleProductUrlClick}
          >
            <Flex as="span" gap={spacing.mini} alignItems="center">
              <ExternalLinkIcon aria-hidden />
              {productWebsiteLabel}
            </Flex>
          </ChipActionButton>
        </ShowAtOrLarger>
      )}
    </Flex>
  )
}
