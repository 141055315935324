import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'
import { ResponsiveProp } from '@/ui/typings/helpers'

import { DropdownDirections } from './Dropdown'

const DROPDOWN_DIRECTIONS = {
  'down-right': `left: 0%;`,
  'down-left': `right: 0%;`,
  right: `top: 0; left: 100%;`,
  left: `top: 0; right: 100%;`,
}

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`

/**
 * Returns the responsive direction and margin for the dropdown
 */
const getResponsiveDirection = (
  $direction: DropdownDirections,
  theme: ThemeProps
) => {
  return `
  ${DROPDOWN_DIRECTIONS[$direction]}
  ${
    $direction === 'left' || $direction === 'right'
      ? `margin: auto ${theme.spacing.defaultSmall};`
      : `margin: ${theme.spacing.defaultSmall} 0;`
  };`
}

export const DropdownListWrapper = styled.ul<{
  $direction: ResponsiveProp<DropdownDirections>
  $zIndex: number
  $width: string
}>(
  ({ theme, $direction, $width, $zIndex }) => `
  position: absolute;

  z-index: ${$zIndex.toString()};
  border-radius: ${theme.borderRadius.small};
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.boxShadow['shadow-3-noshift']};

  display: flex;
  flex-wrap: nowrap;
  width: auto;
  flex-direction: column;
  width: ${$width};

  ${applyResponsiveStyle($direction, theme, getResponsiveDirection)}

  & > :first-child {
    margin-top: ${theme.spacing.defaultSmall};
  }

  & > :last-child {
    margin-bottom: ${theme.spacing.defaultSmall};
  }
`
)

/** It will only add a custom wrapper css if there is a specific BG colour for the icon */
export const DropdownListItemIconWrapper = styled.div<{
  backgroundColor?: string
}>(({ theme, backgroundColor }) =>
  !backgroundColor
    ? ''
    : `
  align-items: center;
  background: ${backgroundColor ? backgroundColor : 'transparent'};
  border-radius: ${theme.borderRadius.rounded};
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.mini};
  
  & > svg {
    height: 2.4rem;
    width: 2.4rem;
  }
`
)

export const DropdownListItem = styled.li<{
  $dividerAfter?: boolean
}>(
  ({ theme, $dividerAfter }) => `
  list-style-type: none;
  flex: 0 0 auto;

  & button, 
  & a {
    width: auto;
    color: ${theme.colors.grey.primary};
    align-items: center;
    display: flex;
    gap: ${theme.spacing.defaultSmall};
    padding: 1rem ${theme.spacing.default};
    text-decoration: none;
    width: 100%;
    background: inherit;

    &:hover {
      background: ${theme.colors.grey.light5};
      cursor: pointer;
    }
  }

  & svg {
    flex-shrink: 0;
  }

  ${
    $dividerAfter &&
    `&::after {
      content: '';
      display: block;
      height: 0.1rem;
      background: ${theme.colors.grey.light3};
      margin: ${theme.spacing.defaultSmall} auto;
      width: 90%;
    }`
  }
`
)
