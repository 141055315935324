import { useTranslation } from 'next-i18next'
import { type FC, type RefObject } from 'react'

import { VipStickyBottomSection } from '@/components/vip/vip-sticky-components/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import {
  RequestViewing,
  RequestViewingType,
} from '@/features/listing/components/request-viewing/RequestViewing'
import { isListingEligibleForRequestViewing } from '@/features/listing/utils/request-viewing/isListingEligibleForRequestViewing'
import { type VipQueryListing } from '@/types/listings'
import { Button } from '@/ui/atoms/button'

export type VipStickyBottomProps = {
  listing: VipQueryListing
  /**
   * Reference of the phone element section to take the user on click
   */
  phoneReference: RefObject<HTMLDivElement>

  /**
   * Function to open R2S form
   */
  handleOpenR2SForm: () => void
}

export const VipStickyBottom: FC<VipStickyBottomProps> = ({
  phoneReference,
  listing,
  handleOpenR2SForm,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.R2S, TRANSLATION_KEYS.REQUEST_VIEWING])
  const isRequestViewingButtonVisible = isListingEligibleForRequestViewing(listing)

  const scrollToPhone = () => {
    phoneReference.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <VipStickyBottomSection data-testid="vip-sticky-bottom">
      {listing.posterInfo.phoneNumber && !isRequestViewingButtonVisible ? (
        <Button variant="secondary" isFullWidth onClick={scrollToPhone}>
          {t('r2s:cta.call')}
        </Button>
      ) : null}

      <Button
        variant={isRequestViewingButtonVisible ? 'secondary' : 'primary'}
        isFullWidth
        onClick={handleOpenR2SForm}
      >
        {t('r2s:cta.send_message')}
      </Button>

      <RequestViewing source="VIP" listing={listing} type={RequestViewingType.DEFAULT} />
    </VipStickyBottomSection>
  )
}
