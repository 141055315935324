import {
  LoadingCoreCardBody,
  LoadingCoreCardContainer,
} from '@/components/shared/core-listing-card/styled'
import { Skeleton } from '@/ui/atoms/skeleton'

export const CoreListingCardLoading = () => {
  return (
    <LoadingCoreCardContainer>
      <Skeleton count={1} width="100%" height="19rem" variant="rectangle" />
      <LoadingCoreCardBody>
        <Skeleton count={1} variant="inline" height="1rem" width="6rem" />
        <Skeleton count={1} variant="inline" height="1rem" width="12rem" />
        <Skeleton count={1} variant="inline" height="1rem" width="7rem" />
      </LoadingCoreCardBody>
    </LoadingCoreCardContainer>
  )
}
