import styled from 'styled-components'

import { TextLink } from '@/ui/atoms/text-link'

// eslint-disable-next-line @kijiji/prefer-button-component
export const StyledReportButton = styled(TextLink)(
  ({ theme }) => `
  
  display: flex;
  align-items: stretch;
  white-space: nowrap;
  gap: ${theme.spacing.mini};
  background-color: ${theme.colors.purple.light5};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing.mini} ${theme.spacing.defaultSmall};
`
)
