import { type FC } from 'react'

import { ImageGalleryVirtualSlideStyled } from '@/components/shared/image-gallery-modal/styled'
import {
  type VirtualTourProps,
  VirtualTour,
} from '@/features/listing-gallery/components/VirtualTour'

type ImageGalleryVirtualSlideProps = VirtualTourProps & {
  /**
   * Whether the image of the slide is in view or not
   */
  inView: boolean

  /**
   * Data test id
   */
  'data-testid'?: string
}

export const ImageGalleryVirtualSlide: FC<ImageGalleryVirtualSlideProps> = ({
  inView,
  'data-testid': dataTestId,
  ...virtualTourProps
}) => {
  if (!inView) {
    return <div />
  }

  return (
    <ImageGalleryVirtualSlideStyled data-testid={dataTestId}>
      <VirtualTour {...virtualTourProps} />
    </ImageGalleryVirtualSlideStyled>
  )
}
