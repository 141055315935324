import { type IconProps } from '@kijiji/icons/src/components/Icon'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import {
  AttributeIconContainer,
  AttributeWithIconWrapper,
} from '@/features/attributes/components/styled'
import { type DisplayableAttribute } from '@/features/attributes/types/listingAttributes'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'

export type IconBackgroundVariant = 'no-bg' | 'primary-bg' | 'secondary-bg'

export type AttributeGroupProps = {
  Icon: FC<IconProps>
  attributes?: DisplayableAttribute[]
  displayInline?: boolean
  iconBackgroundVariant?: IconBackgroundVariant
  label: DisplayableAttribute['label']
  value?: DisplayableAttribute['label']
}

export const AttributeGroup: FC<AttributeGroupProps> = ({
  Icon,
  attributes,
  displayInline,
  iconBackgroundVariant = 'no-bg',
  label,
  value,
}) => {
  const { spacing, colors } = useTheme()

  /** It gets all the labels to facilitate when it needs to be displayed inline instead of in a column */
  const attributeLabels = attributes?.map(({ label }) => label)

  return (
    <AttributeWithIconWrapper alignItems="flex-start" gap={spacing.defaultSmall}>
      <AttributeIconContainer iconBackgroundVariant={iconBackgroundVariant}>
        <Icon aria-hidden />
      </AttributeIconContainer>

      <Flex flexDirection="column" gap={spacing.mini}>
        <HeadlineText as="p" size="medium" color={colors.grey.primary}>
          {label}
        </HeadlineText>

        {displayInline ? (
          <BodyText size="medium" color={colors.grey.light1}>
            {attributeLabels?.join(' ')}
          </BodyText>
        ) : (
          attributeLabels?.map((label, index) => (
            <BodyText size="medium" color={colors.grey.light1} key={`group-attributes-${index}`}>
              {label}
            </BodyText>
          ))
        )}

        {value ? (
          <BodyText size="medium" color={colors.grey.light1}>
            {value}
          </BodyText>
        ) : null}
      </Flex>
    </AttributeWithIconWrapper>
  )
}
