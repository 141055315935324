import { type GetListingQuery, type GetProfileQuery } from '@kijiji/generated/graphql-types'

import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { type DataLayerListing } from '@/lib/ga/types/dataLayer'
import { type Listing as SearchListing } from '@/types/search'

type Listing = GetListingQuery['listing'] | SearchListing

export const getDatalayerListing = (
  listing: GetListingQuery['listing'] | Listing,
  sellerProfile?: GetProfileQuery['profile']
): DataLayerListing | undefined => {
  if (!listing) return

  return {
    a: {
      dl: listing?.description?.length,
      ic: listing.imageUrls.length,
      id: listing.id,
      t: 'type' in listing ? listing.type : undefined,
      prc: {
        amt: isAmountPrice(listing.price) ? listing.price?.amount : undefined,
        t: listing.price?.type,
      },
      u: { at: sellerProfile?.userType ?? undefined },
    },
  }
}
