import {
  type CommercialProfileV2,
  type GetProfileQuery,
  type StandardProfileV2,
} from '@kijiji/generated/graphql-types'

export type ProfileQuery = NonNullable<GetProfileQuery['profile']>

export const isCommercialProfile = (
  profile: GetProfileQuery['profile']
): profile is CommercialProfileV2 => {
  return profile.__typename === 'CommercialProfileV2'
}

export const isStandardProfile = (profile?: unknown): profile is StandardProfileV2 => {
  return (profile as StandardProfileV2)?.__typename === 'StandardProfileV2'
}
