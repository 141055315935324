/**
 * Calculates the price of an item (i.e. car) relative to the market average in percent.
 * The progress is calculated assuming that the average vehicle price equates to 50%.
 * For example, if the market value of a car is $100,000, and it's price is $125,000
 * then the progress should be 63 (62.5% rounded up).
 *
 * @param price - The price of the vehicle.
 * @param averagePrice - The average vehicle price.
 * @returns The progress percentage (0-100).
 */
export const getPriceRelativeToMarketValuePercentage = (
  price: number,
  averagePrice: number
): number => {
  /* Prevent division by zero */
  if (averagePrice === 0) return 0

  const progress = Math.round((price / (averagePrice * 2)) * 100)

  /* Cap the result to 100 */
  return Math.min(progress, 100)
}
