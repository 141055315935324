import styled from 'styled-components'

import { Spacing } from '@/ui/atoms/spacing'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const MAIN_IMAGE_MAX_HIGHT_DESKTOP = '46rem'
const MAIN_IMAGE_MAX_HIGHT_TABLET = '38rem'
const MAIN_IMAGE_MAX_HIGHT_MEDIUM_MOBILE = '30rem'
const MAIN_IMAGE_MAX_HIGHT_MOBILE = '25.7rem'

export const VipGalleryWrapper = styled(Spacing)<{ hasThumbnails?: boolean }>(
  ({ theme, hasThumbnails }) => `
  display: grid;
  margin-bottom: ${theme.spacing.default};
  grid-template-columns: 1fr;

  ${MEDIA_QUERIES(theme).medium}{
    gap: ${theme.spacing.defaultSmall};
    grid-template-columns: ${hasThumbnails ? `1fr 0.32fr` : '1fr'};
    height: ${MAIN_IMAGE_MAX_HIGHT_DESKTOP};
    margin-bottom: ${theme.spacing.large};
  }

  ${MEDIA_QUERIES(theme).large}{
    height: ${MAIN_IMAGE_MAX_HIGHT_TABLET};
  }
    
  // This media query is for gallery-only it doesn't spill over or have any conflicts outside of this context
  @media screen and (min-width: 1180px) {
    height: ${MAIN_IMAGE_MAX_HIGHT_DESKTOP};
  }
`
)

export const VipGalleryMainWrapper = styled.div<{ isCarousel?: boolean }>(
  ({ theme, isCarousel }) => `
  ${isCarousel ? 'overflow: hidden;' : ''}
  display: ${isCarousel ? 'block' : 'none'};
  height: ${MAIN_IMAGE_MAX_HIGHT_MOBILE};

  // This media query is for gallery-only it doesn't spill over or have any conflicts outside of this context
  @media screen and (min-width: 540px) {
    height: ${MAIN_IMAGE_MAX_HIGHT_MEDIUM_MOBILE};
  }
    
  ${MEDIA_QUERIES(theme).medium} {
    display: ${isCarousel ? 'none' : 'block'};
    height: inherit;
  }

  ${MEDIA_QUERIES(theme).large}{
    height: ${MAIN_IMAGE_MAX_HIGHT_TABLET};
  }
    
  // This media query is for gallery-only it doesn't spill over or have any conflicts outside of this context
  @media screen and (min-width: 1180px) {
    height: ${MAIN_IMAGE_MAX_HIGHT_DESKTOP};
  }
`
)

const thumbnailGridColumnStyle = `
  grid-template-columns: 1fr;
  height: inherit;
`

export const VipGalleryThumbnailGrid = styled.div(
  ({ theme }) => `
  display: none;
  
  ${MEDIA_QUERIES(theme).medium} {
    display: grid;
    gap: ${theme.spacing.defaultSmall};
   ${thumbnailGridColumnStyle}
  }

  ${MEDIA_QUERIES(theme).large} {
    grid-template-rows: 1fr 1fr 1fr;
    ${thumbnailGridColumnStyle}
  }
`
)

export const VipGalleryExpandThumbnailContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.grey.light5};
  border: .1rem solid ${theme.colors.grey.light4};
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  
  & svg {
    ${TRANSITION_STYLE}
    color: ${theme.colors.grey.light1};
  }
`
)

export const ListingVirtualTourThumbnailContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.purple.primary};
  border: .1rem solid ${theme.colors.grey.light4};
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;

    & svg {
    ${TRANSITION_STYLE}
    color: ${theme.colors.white};
  }
`
)

/** GALLERY MOBILE CAROUSEL */
export const GalleryMobileCarouselContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`

export const GalleryMobileCarouselViewport = styled.div`
  overflow: hidden;
  height: 100%;
`

export const GalleryMobileCarouselList = styled.ul(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  height: 100%;

  ${MEDIA_QUERIES(theme).medium}{
    padding: 0;
  }
`
)

export const GalleryMobileCarouselSlide = styled.li(
  ({ theme }) => `
    background-color: ${theme.colors.white};
    display: flex;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;

    & > div {
      width: 100%;
    }
  `
)
