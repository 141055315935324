import { SellerType } from '@kijiji/generated/graphql-types'

/**
 * Generates the generic profile URL for the given user.
 *
 * NOTE: This is to be used as a fallback, there are SEO URLs that are generated for specific user
 * types by the backend. This should only be used if those fail for some reason.
 */
export const getGenericProfilePath = (userId: string): string => {
  const commercialPrefix = SellerType.Commercial + '_'
  if (userId.startsWith(commercialPrefix)) {
    return `/o-profile/${userId.replace(commercialPrefix, '')}/listings/1?profileType=commercial&referral=organic`
  } else {
    return `/o-profile/${userId}/listings/1`
  }
}
