import { type TFunction } from 'next-i18next'

const loanTerms: number[] = [36, 48, 60, 72, 84, 96]

/**
 * Generates an array of loan term options for use in the payment estimator loan term select.
 *
 * @param t - Translation function from `next-i18next` for localizing the label.
 * @returns An array of objects, each containing:
 *  - `label`: A string representing the loan term with a localized "months" suffix.
 *  - `value`: The numerical value of the loan term.
 */
export const getLoanTermOptions = (t: TFunction) =>
  loanTerms.map((term) => ({
    label: `${term} ${t('payment_estimator:modal.months')}`,
    value: term,
  }))
