import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'

export const ScoresContainer = styled(Flex)(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  border: 0.1rem solid ${theme.colors.grey.light4};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing.large};
`
)

export const ScoreItemContainer = styled(Flex)(
  ({ theme }) => `
  &:not(:last-child) {
    border-bottom: 0.1rem solid ${theme.colors.grey.light4};
  }

  &:first-child:not(:only-child) {
    padding-bottom: ${theme.spacing.large};
  }

  &:last-child:not(:only-child) {
    padding-top: ${theme.spacing.large};
  }

  &:not(:first-child):not(:last-child) {
    padding-top: ${theme.spacing.large};
    padding-bottom: ${theme.spacing.large};
  }
`
)

export const ScoreText = styled(HeadlineText)(
  ({ theme }) => `
  
  &:before {
    content: '\u2022'; /* This is a bullet point in Unicode */
    margin: 0 ${theme.spacing.defaultSmall} 0;
    color: ${theme.colors.grey.light3};
  }

  &:after {
    content: '/10';
    font-size: ${theme.typography.body.small.fontSize};
    font-weight: ${theme.fontWeight.regular};
  }
`
)
