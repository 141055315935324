import { AUTOS_NON_VEHICLES_CATEGORIES, isAnyCarsVehiclesCategory } from '@kijiji/category'
import VehiclesIcon from '@kijiji/icons/src/icons/AllVehicles'
import ListingIcon from '@kijiji/icons/src/icons/Listings'
import { type TFunction } from 'next-i18next'
import { type ComponentType, type SVGProps } from 'react'

import { type ProfileQuery, isStandardProfile } from '@/features/profile/types/profile'
import { isAutosDealer } from '@/features/profile/utils/isAutosDealer'

/**
 * This function returns a standardized label with the number of listings per seller
 * This function will return customized label for different types of sellers.
 *
 * This function uses the "TRANSLATION_KEYS.PROFILE" translations
 */
export const getSellerListingNumberLabel = (
  profile: ProfileQuery,
  t: TFunction,
  categoryId?: number
): { label: string; icon: ComponentType<SVGProps<SVGSVGElement>> } | undefined => {
  const numberOfListings = profile.numberOfListings ?? 1
  const isSalesforceEnabled = isStandardProfile(profile) && profile.isSalesforceEnabled

  const isDealer = isAutosDealer(profile.userType)
  const icon = isDealer ? VehiclesIcon : ListingIcon

  /**
   * We don't know how many listings the seller has when they have a salesforce ID
   * We will return a different label in that case
   */
  if (isSalesforceEnabled) {
    return { label: t('profile:profile.metrics.listing_count.salesforce'), icon }
  }

  /**
   * It should only show vehicles label if the listing is from a dealer AND
   * on a vehicle category
   */
  const isVehicleCategory = categoryId && isAnyCarsVehiclesCategory(categoryId)
  const isNonVehicleListing = categoryId && AUTOS_NON_VEHICLES_CATEGORIES.includes(categoryId)

  if (isDealer && isVehicleCategory && !isNonVehicleListing) {
    return {
      icon,
      label: t('profile:profile.metrics.listing_count.autos', { listingCount: numberOfListings }),
    }
  }

  return {
    icon,
    label: t('profile:profile.metrics.listing_count.label', { listingCount: numberOfListings }),
  }
}
