import { getUserDevice } from '@/utils/getUserDevice'

export const CAMPAIGN_PLATFORM = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MWEB: 'mweb',
}
export const getPlatformForCampaign = () => {
  const { isDesktop, isTablet } = getUserDevice()

  if (isDesktop) return CAMPAIGN_PLATFORM.DESKTOP
  if (isTablet) return CAMPAIGN_PLATFORM.TABLET

  return CAMPAIGN_PLATFORM.MWEB
}
