import {
  type GetListingQuery,
  type GetProfileQuery,
  UserType,
} from '@kijiji/generated/graphql-types'

import { isPaidListing } from '@/features/listing/utils/isPaidListing'
import { isCommercialProfile } from '@/features/profile/types/profile'

/**
 * The profile data returns a standard profile or a commercial one
 * This function is getting the data returned and standardizing the return
 *
 * If this data is on the context of a listing, then we can also return the websiteUrl if it is a paid listing.
 * If it is used only on a profile context, then websiteUrl will only be returned if the UserType is professional
 */
export const getCommercialProfileFromData = (
  profile?: GetProfileQuery['profile'],
  listing?: GetListingQuery['listing']
) => {
  const isProfessionalUser =
    profile?.userType === UserType.Professional || (listing && isPaidListing(listing))

  return profile && isCommercialProfile(profile)
    ? {
        phoneNumber: profile.phoneNumber,
        websiteUrl: isProfessionalUser ? profile.websiteUrl : undefined,
        logoUrl: profile.logoUrl,
        staticMapUrl: profile.staticMapUrl,
      }
    : undefined
}
