import { ListingPriceRating } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { PriceInsightsBadge } from '@/features/listing/components/price-insights/PriceInsightsBadge'
import { PriceInsightCarfaxLogo } from '@/features/listing/components/price-insights/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { Divider } from '@/ui/atoms/divider'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal } from '@/ui/molecules/modal/Modal'

export type PriceInsightsModalProps = {
  handleModalClose: () => void
  isOpen: boolean
}

const CARFAX_LOGO = '/next-assets/images/carfax_logo.svg'

export const PriceInsightsModal: FC<PriceInsightsModalProps> = ({ handleModalClose, isOpen }) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING, TRANSLATION_KEYS.COMMON])

  const orderedInsightBadges = [
    ListingPriceRating.Great,
    ListingPriceRating.Good,
    ListingPriceRating.Fair,
    ListingPriceRating.Poor,
  ]

  return (
    <Modal
      closeButtonLabel={t('common:modals.close.button.label')}
      data-testid="price-insight-modal"
      hasCloseButton
      id="price-insight-modal"
      isOpen={isOpen}
      label={t('listing:price_insights.lean_more.title')}
      onCancel={handleModalClose}
      width={{ small: '100vw', medium: '68rem' }}
    >
      <Spacing
        as="header"
        pTop={spacing.large}
        medium={{ pTop: spacing.husky }}
        pBottom={spacing.xLarge}
      >
        <HeadlineText as="h3" weight="bold" size="large">
          {t('listing:price_insights.lean_more.title')}
        </HeadlineText>
      </Spacing>

      <Flex flexDirection="column" gap={spacing.default}>
        <Divider />

        <BodyText size="medium" color={colors.grey.light1}>
          {t('listing:price_insights.lean_more.carfax_description')}
        </BodyText>

        <PriceInsightCarfaxLogo src={CARFAX_LOGO} alt="Carfax" skipNextImage />

        <Divider />
      </Flex>

      <Spacing pTop={spacing.xLarge} pBottom={spacing.xLarge}>
        <Flex flexDirection="column" gap={spacing.default}>
          <BodyText size="medium" color={colors.grey.primary}>
            {t('listing:price_insights.lean_more.paragraph1')}
          </BodyText>

          <BodyText size="medium" color={colors.grey.primary}>
            {t('listing:price_insights.lean_more.paragraph2')}
          </BodyText>
        </Flex>
      </Spacing>

      <Spacing pBottom={spacing.default}>
        <HeadlineText as="h4" size="medium" weight="bold" color={colors.grey.primary}>
          {t('listing:price_insights.lean_more.badge_meaning.question')}
        </HeadlineText>
      </Spacing>

      <Flex flexDirection="column" gap={spacing.large}>
        {orderedInsightBadges.map((badge) => {
          return (
            <Flex
              flexDirection="column"
              gap={spacing.mini}
              key={`price-insight-learn-more-${badge}`}
            >
              <PriceInsightsBadge insightType={badge} />
              <BodyText>
                {t(`listing:price_insights.badge.description.${badge.toLowerCase()}`)}
              </BodyText>
            </Flex>
          )
        })}
      </Flex>
    </Modal>
  )
}
