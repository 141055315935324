export const EDIT_LISTING_URL = '/p-edit-ad.html'
export const MY_ACCOUNT_LISTINGS = '/m-my-ads/active/1'

export const LISTING_ACTIONS_QUERY_PARAMETERS = {
  LISTING_ID: 'adId',
  SUBMIT_TYPE: 'submitType',
}

export enum SUBMIT_AD_TYPE {
  REPOST = 'repostAd',
}
