import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { AttributeGroup } from '@/features/attributes/components/AttributeGroup'
import { type DisplayableAttribute } from '@/features/attributes/types/listingAttributes'
import { Flex } from '@/ui/atoms/flex'

type VipGenericAttributesProps = {
  hasCoreAttributes?: boolean
  attributes: { firstColumn: DisplayableAttribute[]; secondColumn: DisplayableAttribute[] } | null
}

export const VipGenericAttributes: FC<VipGenericAttributesProps> = ({
  attributes,
  hasCoreAttributes,
}) => {
  const { spacing } = useTheme()

  if (!attributes) return null

  const { firstColumn, secondColumn } = attributes

  return (
    <>
      <Flex flexDirection="column" gap={spacing.large} data-testid="vip-attributes-generic">
        {firstColumn.map(({ Icon, label, name }, index) => {
          return (
            <AttributeGroup
              Icon={Icon}
              iconBackgroundVariant={hasCoreAttributes ? 'no-bg' : 'secondary-bg'}
              key={`generic-attr-col-1-${index}`}
              label={name}
              value={label}
            />
          )
        })}
      </Flex>

      {secondColumn.length > 0 && (
        <Flex flexDirection="column" gap={spacing.large} data-testid="vip-attributes-generic2">
          {secondColumn.map(({ Icon, label, name }, index) => {
            return (
              <AttributeGroup
                Icon={Icon}
                iconBackgroundVariant={hasCoreAttributes ? 'no-bg' : 'secondary-bg'}
                key={`generic-attr-col-2-${index}`}
                label={name}
                value={label}
              />
            )
          })}
        </Flex>
      )}
    </>
  )
}
