import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import {
  LearnMoreCarfaxIconContainer,
  LearnMoreCarfaxLogo,
} from '@/components/vip/vip-vehicle-history/styled'
import { getVehicleReportBadges } from '@/components/vip/vip-vehicle-history/utils'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal } from '@/ui/molecules/modal/Modal'

export type VehicleHistoryLearnMoreModalProps = {
  isVisible: boolean
  handleCloseModal: () => void
  carfaxLogoSrc: string
}

export const VehicleHistoryLearnMoreModal: FC<VehicleHistoryLearnMoreModalProps> = ({
  carfaxLogoSrc,
  handleCloseModal,
  isVisible,
}) => {
  const { routeLocale } = useLocale()
  const { spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.VIP, TRANSLATION_KEYS.COMMON])

  const { lowKilometresIcon, noAccidentsIcon, oneOwnerIcon, topConditionIcon } =
    getVehicleReportBadges(t, routeLocale)

  return (
    <Modal
      closeButtonLabel={t('common:modals.close.button.label')}
      data-testid="vehicle-history-learn-more-modal"
      hasCloseButton
      id="vehicle-history-learn-more-modal"
      isOpen={isVisible}
      label={t('vip:vehicle_history.learn_more')}
      maxWidth={{ small: '100vw', medium: '68rem' }}
      onCancel={handleCloseModal}
      width={{ small: '100vw', medium: 'fit-content' }}
    >
      <Spacing mBottom={spacing.xLarge}>
        <LearnMoreCarfaxLogo src={carfaxLogoSrc} alt="" />
      </Spacing>

      <Flex flexDirection="column" gap={spacing.large}>
        <HeadlineText as="h2" size="large">
          {t('vip:vehicle_history.learn_more.modal.title')}
        </HeadlineText>

        <BodyText size="medium">{t('vip:vehicle_history.learn_more.modal.description_1')}</BodyText>
        <BodyText size="medium">{t('vip:vehicle_history.learn_more.modal.description_2')}</BodyText>

        <HeadlineText size="medium">
          {t('vip:vehicle_history.learn_more.modal.subtitle')}
        </HeadlineText>

        <LearnMoreCarfaxIconContainer>
          {noAccidentsIcon}
          <BodyText size="medium">
            {t('vip:vehicle_history.learn_more.modal.no_accidents.description')}
          </BodyText>
        </LearnMoreCarfaxIconContainer>

        <LearnMoreCarfaxIconContainer>
          {oneOwnerIcon}
          <BodyText size="medium">
            {t('vip:vehicle_history.learn_more.modal.one_owner.description')}
          </BodyText>
        </LearnMoreCarfaxIconContainer>

        <LearnMoreCarfaxIconContainer>
          {lowKilometresIcon}
          <BodyText size="medium">
            {t('vip:vehicle_history.learn_more.modal.low_km.description')}
          </BodyText>
        </LearnMoreCarfaxIconContainer>

        <LearnMoreCarfaxIconContainer>
          {topConditionIcon}
          <BodyText size="medium">
            {t('vip:vehicle_history.learn_more.modal.top_condition.description')}
          </BodyText>
        </LearnMoreCarfaxIconContainer>
      </Flex>
    </Modal>
  )
}
