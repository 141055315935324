import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'

import { TextLink } from '@/ui/atoms/text-link'

export const getUrlAttributeLink = (attribute: ListingAttributeV2) => {
  const value = attribute.values?.[0]

  try {
    const url = value ? new URL(value) : null
    if (!url) return ''

    return (
      <TextLink
        hasUnderline
        href={url.href}
        key={`${attribute.canonicalName}-link`}
        size="medium"
        target="_blank"
        variant="secondary"
      >
        {attribute.name}
      </TextLink>
    )
  } catch {
    return ''
  }
}
