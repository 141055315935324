import { useTheme } from 'styled-components'

import { RelativeTime } from '@/components/shared/relative-time/RelativeTime'
import { ProfileAvatar } from '@/features/profile/components/ProfileAvatar'
import { ReviewDate } from '@/features/profile/components/review/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { StarMeter } from '@/ui/atoms/star-meter'
import { ReadMoreText } from '@/ui/molecules/read-more-text'

type ReviewProps = {
  avatarUrl?: string
  collapsedText: string
  date?: string
  expandedText?: string
  rating: number
  text?: string
  userId: string
  userName: string
}

export const Review = ({
  avatarUrl,
  collapsedText,
  date,
  expandedText,
  rating,
  text,
  userId,
  userName,
}: ReviewProps) => {
  const { spacing, colors } = useTheme()

  return (
    <Flex flexDirection="column" data-testid="review">
      <Flex gap={spacing.default} alignItems="center">
        <ProfileAvatar
          imageSrc={avatarUrl}
          variation="HEADER_AND_USER_REVIEWS"
          userId={userId}
          userName={userName}
        />
        <Flex flexDirection="column">
          <BodyText weight="medium">{userName}</BodyText>
          <Flex alignItems="center">
            <StarMeter score={rating} size="small" />
            {date ? (
              <ReviewDate mLeft={spacing.defaultSmall}>
                <RelativeTime color={colors.grey.light1} size="small" date={date} />
              </ReviewDate>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
      {text ? (
        <Spacing mTop={spacing.default}>
          <ReadMoreText
            buttonCollapsedText={collapsedText}
            buttonExpandedText={expandedText}
            isButtonHiddenOnExpand={false}
          >
            {text}
          </ReadMoreText>
        </Spacing>
      ) : null}
    </Flex>
  )
}
