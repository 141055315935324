import styled from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'
import { Spacing } from '@/ui/atoms/spacing'

export const VipPriceContainer = styled(Spacing)<{ hasPriceRatingBadge?: boolean }>(
  ({ theme, hasPriceRatingBadge }) => `
  align-items: ${hasPriceRatingBadge ? 'flex-start' : 'center'};
  display: flex;
  flex-direction: ${hasPriceRatingBadge ? 'column' : 'row'};
  gap: ${theme.spacing.mini};
`
)

export const VipPriceBadge = styled(Spacing)(
  ({ theme }) => `
  background: ${theme.colors.red.primary};
  border-radius: ${theme.borderRadius.small};
  color: ${theme.colors.white};
  display: flex;
  margin-top: ${theme.spacing.mini};
  padding: 0.2rem ${theme.spacing.defaultSmall};
  width: fit-content;
`
)

export const VipPriceDropLabel = styled(BodyText)(
  ({ theme }) => `
  text-decoration: line-through;
  margin-right: ${theme.spacing.mini};
`
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const VipPriceTooltipButton = styled.button`
  display: flex;
  align-items: center;

  & svg {
    height: 2.4rem;
    width: 2.4rem;
  }
`
