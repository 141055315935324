import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { CoreListingDetails } from '@/components/shared/core-listing-card/card-details/CoreListingDetails'
import { CoreListingPrice } from '@/components/shared/core-listing-card/card-details/CoreListingPrice'
import { ListingImageWithFallback } from '@/components/shared/core-listing-card/card-details/ListingImageWithFallback'
import {
  CoreListingCardFeedImageContainer,
  CoreListingCardHeading,
  CoreListingCardLink,
  CoreListingFeedCardContainer,
} from '@/components/shared/core-listing-card/styled'
import { FavouriteButton } from '@/components/shared/listing/FavouriteButton'
import { type GenericListing } from '@/features/listing/types/listings'
import { shouldHidePriceByCategory } from '@/features/listing/utils/shouldHidePriceByCategory'
import { Flex } from '@/ui/atoms/flex'

export type CoreListingFeedCardProps = {
  /**
   * Listing index
   */
  index: number
  /** Covers listings coming from search or generic ones */
  listing: GenericListing
  /**
   * Action to add Listing click to GA
   */
  handleTrackListingClick: (position: number, listingId: string) => void
}

export const CoreListingFeedCard: FC<CoreListingFeedCardProps> = ({
  index,
  listing,
  handleTrackListingClick,
}) => {
  const { colors, spacing } = useTheme()

  const shouldHideListingPrice = shouldHidePriceByCategory(listing.categoryId)
  const flags = 'flags' in listing ? listing.flags : undefined

  const handleListingClick = () => {
    handleTrackListingClick(index + 1, listing.id)
  }

  return (
    <CoreListingFeedCardContainer data-testid="listing-card">
      <Flex gap={spacing.default} justifyContent="space-between" alignItems="flex-start">
        <Flex gap={spacing.default}>
          <CoreListingCardFeedImageContainer>
            <ListingImageWithFallback variant="feed" src={listing.imageUrls[0]} alt="" />
          </CoreListingCardFeedImageContainer>

          <Flex
            justifyContent="space-between"
            media={{ medium: { gap: spacing.default }, large: { gap: '5.6rem' } }}
          >
            <Flex
              flexDirection="column"
              gap={spacing.mini}
              media={{ medium: { gap: spacing.defaultSmall } }}
            >
              {shouldHideListingPrice ? null : (
                <CoreListingPrice
                  categoryId={listing.categoryId}
                  flags={flags}
                  price={listing.price}
                />
              )}

              <CoreListingCardHeading
                color={colors.grey.primary}
                data-testid="listing-title"
                forwardedAs="h3"
                size={{ small: 'medium', medium: 'large' }}
              >
                <CoreListingCardLink
                  data-testid="listing-link"
                  href={listing.url}
                  onClick={handleListingClick}
                >
                  {listing.title}
                </CoreListingCardLink>
              </CoreListingCardHeading>

              <CoreListingDetails listing={listing} />
            </Flex>
          </Flex>
        </Flex>

        <FavouriteButton listingId={listing.id} />
      </Flex>
    </CoreListingFeedCardContainer>
  )
}
