import { type AdType } from '@kijiji/generated/graphql-types'
import { type UserStatus } from 'next-auth'

import { PTG } from '@/components-page/vip/advertisement/vipAdSlots'
import { type RouteLocale } from '@/domain/locale'
import {
  createCategoryIdLabel,
  createFeaturesLabel,
  createForSaleByLabel,
  createPriceLabel,
} from '@/features/advertisement/components/gpt/searchGpt'
import { GPT_KEYS } from '@/features/advertisement/constants/gpt'
import { type GptTargeting } from '@/features/advertisement/types/adConfig'
import { removeEmptyValues } from '@/features/advertisement/utils/removeEmptyValues'
import { getGenericTargetingKeys } from '@/features/advertisement/utils/targeting/getGenericTargetingKeys'
import { getLocationTargetingKeys } from '@/features/advertisement/utils/targeting/getLocationTargetingKeys'
import { getUserTargetingKeys } from '@/features/advertisement/utils/targeting/getUserTargetingKeys'
import { getVipAutosTargetingValues } from '@/features/advertisement/utils/targeting/getVipAutosTargetingValues'
import { filterNullAttributes } from '@/features/attributes/utils/filterNullAttributes'
import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import {
  type VipQueryAttributeAll,
  type VipQueryListing,
  type VipQueryPrice,
} from '@/types/listings'

export type GenerateVipTargetingValuesInput = {
  adType: AdType
  categoryPathIds: string[]
  categoryId: number
  hasUserOptedOutTargeting: boolean
  isMlsAd: boolean
  listingAttributes: VipQueryAttributeAll
  listingFlags: VipQueryListing['flags']
  listingId: string
  listingPrice?: VipQueryPrice | null
  locationId: number
  routeLocale: RouteLocale
  userStatus: UserStatus
  companyName?: string
}

export const generateVipTargetingValues = ({
  adType,
  categoryId,
  categoryPathIds,
  companyName,
  hasUserOptedOutTargeting,
  isMlsAd,
  listingAttributes,
  listingFlags,
  listingId,
  listingPrice,
  locationId,
  routeLocale,
  userStatus,
}: GenerateVipTargetingValuesInput): GptTargeting[] => {
  const genericKeys = getGenericTargetingKeys({
    hasUserOptedOutTargeting,
    pageType: GA_PAGE_TYPE.VIEW_AD,
    ptgValues: PTG,
  })

  const locationKeys = getLocationTargetingKeys({ locationId })

  const categoryKeys = [createCategoryIdLabel(categoryPathIds)]

  const userKeys = getUserTargetingKeys({ userStatus, routeLocale })

  const listingKeys: GptTargeting[] = [
    { key: GPT_KEYS.LISTING_TYPE_LABEL, value: isMlsAd ? 'mls' : 'c' },
    { key: GPT_KEYS.AD_TYPE_LABEL, value: adType },
    { key: GPT_KEYS.AD_ID_LABEL, value: `${listingId}` },
  ]

  const cleanedAttributes = filterNullAttributes(listingAttributes)
  const autosAttributes = getVipAutosTargetingValues(cleanedAttributes)

  const forSaleByKey = createForSaleByLabel(cleanedAttributes)
  if (forSaleByKey) listingKeys.push(forSaleByKey)

  const priceKey = listingPrice ? createPriceLabel(listingPrice, categoryId) : null
  if (priceKey) listingKeys.push(...priceKey)

  const featureKey = createFeaturesLabel(listingFlags, categoryId)
  if (featureKey) listingKeys.push(featureKey)

  if (companyName) {
    listingKeys.push({ key: GPT_KEYS.DEALER_NAME_LABEL, value: companyName })
  }

  const targetingValues: GptTargeting[] = removeEmptyValues([
    ...genericKeys,
    ...locationKeys,
    ...categoryKeys,
    ...userKeys,
    ...listingKeys,
    ...autosAttributes,
  ])

  return targetingValues
}
