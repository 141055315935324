import {
  AuthModalVariant,
  GetRequestViewingModalStateDocument,
  RequestViewingMode,
  useGetRequestViewingModalStateQuery,
} from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useCallback } from 'react'

import { isUserAuthenticated } from '@/features/auth/constants/user'
import { useSignInModal } from '@/features/auth/hooks/useSignInModal'

export const useRequestViewingState = () => {
  /** Read current value from the cache */
  const { data, client } = useGetRequestViewingModalStateQuery()
  const { openSignInModal } = useSignInModal()
  const { asPath } = useRouter()
  const { status } = useSession()

  const {
    requestViewingState: {
      isModalOpen = false,
      notification: prevNotification = null,
      mode: prevMode = RequestViewingMode.Fsbo,
    } = {},
  } = data ?? {}

  const setRequestViewingState = useCallback(
    ({
      isOpen,
      notification,
      mode,
    }: {
      isOpen?: boolean
      notification?: 'success' | 'error'
      mode?: RequestViewingMode
    }) => {
      if (isOpen && !isUserAuthenticated(status)) {
        openSignInModal({
          modalVariant: AuthModalVariant.LoginToRequestViewing,
          callbackUrl: asPath,
        })
        return
      }

      client.cache.writeQuery({
        query: GetRequestViewingModalStateDocument,
        data: {
          requestViewingState: {
            ...data?.requestViewingState,
            isModalOpen: isOpen ?? isModalOpen,
            notification: notification ?? prevNotification,
            mode: mode ?? prevMode,
          },
        },
      })
    },
    [
      status,
      client.cache,
      data?.requestViewingState,
      isModalOpen,
      prevNotification,
      prevMode,
      openSignInModal,
      asPath,
    ]
  )

  return {
    setRequestViewingState,
    isRequestViewingModalOpen: isModalOpen,
    requestViewingNotification: prevNotification,
    requestViewingMode: prevMode,
  }
}
