import { CATEGORIES, isAnyRealEstateCategory, isAnyVacationRentalsCategory } from '@kijiji/category'

/**
 * It should only return this section in the categories:
 * - Real Estate
 * - Vacation Rentals
 * - Events
 */
export const shouldRenderLocationSection = (categoryId: number): boolean => {
  return (
    isAnyRealEstateCategory(categoryId) ||
    isAnyVacationRentalsCategory(categoryId) ||
    CATEGORIES.EVENTS_CATEGORY_ID === categoryId
  )
}
