import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const VipAdditionalOptionsContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  display: grid;
  gap: ${theme.spacing.default};
  row-gap: ${theme.spacing.defaultSmall};
  
  ${MEDIA_QUERIES(theme).medium}{
    grid-template-columns: 1fr 1fr;
  }
`
)
