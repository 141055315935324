import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { ListingReportForm } from '@/features/listing/components/report/ListingReportForm'
import { Flex } from '@/ui/atoms/flex/Flex'
import { HeadlineText } from '@/ui/atoms/headline-text/HeadlineText'
import { Modal } from '@/ui/molecules/modal/Modal'

type ListingReportModalProps = {
  /**
   * The ID of the listing to report.
   */
  listingId: string

  /**
   * Controlled state for the modal.
   */
  isOpen: boolean

  /**
   * Callback to close the modal.
   */
  onClose: () => void
}

/**
 * Modal to report a listing. It contains a form to report the listing.
 */
export const ListingReportModal: FC<ListingReportModalProps> = ({ listingId, isOpen, onClose }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])
  const { spacing } = useTheme()

  return (
    <Modal
      isOpen={isOpen}
      id="listing-report-modal"
      label={t('listing:report.modal.title')}
      hasCloseButton
      onCancel={onClose}
      padding={spacing.xLarge}
    >
      <Flex flexDirection="column" gap={spacing.large}>
        <HeadlineText as="h2" size="large" textAlign="center">
          {t('listing:report.modal.title')}
        </HeadlineText>
        <ListingReportForm listingId={listingId} onClose={onClose} />
      </Flex>
    </Modal>
  )
}
