import styled from 'styled-components'

import { Spacing } from '@/ui/atoms/spacing'

//The line-height must be equivalent to the font-size, or the date will not align with the rating
export const ReviewDate = styled(Spacing)`
  & p {
    line-height: 1;
  }
`
