import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { VipSectionContainer } from '@/components/vip/shared/VipSectionContainer'
import { AutocanFeaturesContainer } from '@/components/vip/vip-autocan-features/styled'
import { AutocanBanner } from '@/features/autocanada/components/AutocanBanner'
import { AutocanFeatures } from '@/features/autocanada/utils/getAutocanFeatures'
import { type VipQueryListing } from '@/types/listings'

type VipAutoCanSectionProps = {
  allowedFeatures: AutocanFeatures[]
  listing: VipQueryListing
  userAgent: string
}

export const VipAutoCanSection: FC<VipAutoCanSectionProps> = ({
  allowedFeatures,
  listing,
  userAgent,
}) => {
  const { spacing } = useTheme()

  /**
   * We will account how many live features autocan has
   * It will switch between showing the badge as a inline tile with another autocan feature AND
   * taking the full row for a single available feature
   */
  const autocanLiveFeatures = allowedFeatures.length
  const hasMultipleLiveFeatures = autocanLiveFeatures > 1

  if (autocanLiveFeatures === 0) return null

  return (
    <VipSectionContainer pTop={spacing.large} pBottom={spacing.large}>
      <AutocanFeaturesContainer useInlineVariant={hasMultipleLiveFeatures}>
        {allowedFeatures.includes(AutocanFeatures.WARRANTY) && (
          <AutocanBanner
            interactWithWholeBanner={hasMultipleLiveFeatures}
            listing={listing}
            userAgent={userAgent}
            variant={AutocanFeatures.WARRANTY}
          />
        )}

        {allowedFeatures.includes(AutocanFeatures.VALUE_ESTIMATE) && (
          <AutocanBanner
            interactWithWholeBanner={hasMultipleLiveFeatures}
            userAgent={userAgent}
            listing={listing}
            variant={AutocanFeatures.VALUE_ESTIMATE}
          />
        )}
      </AutocanFeaturesContainer>
    </VipSectionContainer>
  )
}
