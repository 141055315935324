import { isAnyJobsCategory, isAnyServicesCategory } from '@kijiji/category'

/**
 * There are a few categories that shouldn't display price.
 *
 * This function centralized the logic for those price exceptions
 * */
export const shouldHidePriceByCategory = (categoryId: number) => {
  return isAnyServicesCategory(categoryId) || isAnyJobsCategory(categoryId)
}
