import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { Flex } from '@/ui/atoms/flex'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const R2SStructuredMessagesBox = styled(Flex)<{ isVisible: boolean }>(
  ({ isVisible }) => `
  ${TRANSITION_STYLE}

  overflow:hidden;
  // Set a height high enough that the dynamic content will fit 
  max-height: ${isVisible ? '25rem' : '0'};
  opacity: ${isVisible ? '1' : '0'};;
`
)

export const R2SEnhanceLeadsContainer = styled.div(
  ({ theme }) => `
  display: grid;
  gap: ${theme.spacing.defaultSmall};
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: ${theme.spacing.defaultSmall};
  overflow-x: auto;

  & > span {
    width: 100%;
    
    & > label {
      justify-content: center;
      white-space: nowrap;
      width: 100%;
    }
  }
`
)

export const R2SFormSubmitContainer = styled(Flex)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing.large};

  ${MEDIA_QUERIES(theme).medium}{
    background-color: ${theme.colors.white};
    bottom: 0;
    position: sticky;
    z-index: ${zIndexRegistry.FLOATING_CTA};
  }
`
)

export const R2SFileSelectionContainer = styled(Flex)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.2rem;
  border-radius: ${theme.borderRadius.small};
  border: 0.1rem solid ${theme.colors.purple.light4};
  background-color: ${theme.colors.green.light5};
  gap: ${theme.spacing.defaultSmall};

  /* Icon sizing */
  & svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  & > :nth-child(2) {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
)
