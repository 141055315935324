import { getYoutubeThumbnail } from '@/features/listing-gallery/utils/getYoutubeThumbnail'
import VirtualTourThumbnail from '~/public/next-assets/images/virtual-tour-thumbnail.png'

/**
 * The content of the gallery.
 */
export type GalleryContent =
  | {
      type: 'picture'
      source: string
    }
  | {
      type: 'youtube'
      videoId: string
      thumbnailUrl: string
    }
  | {
      type: 'virtual-tour'
      thumbnailUrl: string
      url: string
    }

export const getGalleryContent = (
  imageUrls: string[],
  youtubeVideoId?: string | null,
  virtualTourUrl?: string | null
) => {
  const content: GalleryContent[] = []

  // The video should appear as the first item in the gallery unless there are no images
  if (youtubeVideoId) {
    content.push({
      type: 'youtube',
      videoId: youtubeVideoId,
      thumbnailUrl: getYoutubeThumbnail(youtubeVideoId),
    })
  }

  const images: GalleryContent[] = imageUrls.map((image) => ({
    source: image,
    type: 'picture',
  }))
  content.push(...images)

  // The virtual tour should appear as the last item in the gallery unless there are no images
  if (virtualTourUrl) {
    content.push({
      type: 'virtual-tour',
      thumbnailUrl: VirtualTourThumbnail.src,
      url: virtualTourUrl,
    })
  }

  return { content }
}
