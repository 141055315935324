import AttachmentIcon from '@kijiji/icons/src/icons/Attach'
import CloseIcon from '@kijiji/icons/src/icons/Close'
import UploadIcon from '@kijiji/icons/src/icons/Upload'
import { useTranslation } from 'next-i18next'
import { type ChangeEvent, type FC, useRef } from 'react'
import { useTheme } from 'styled-components'

import { ChipActionButton } from '@/components/shared/chip-action-button/ChipActionButton'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { R2SFileSelectionContainer } from '@/features/listing/components/reply-to-seller/conversations/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

export type R2SJobAttachmentProps = {
  onFileChange: (file: File | null) => void
  selectedFile: File | null
  supportedFileTypes: string[]
}

export const R2SJobAttachment: FC<R2SJobAttachmentProps> = ({
  onFileChange,
  selectedFile,
  supportedFileTypes,
}) => {
  const { spacing, colors } = useTheme()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation([TRANSLATION_KEYS.R2S])

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null

    if (file) {
      onFileChange(file)
    }
  }

  const handleRemoveFile = () => {
    onFileChange(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <div data-testid="job-attachment">
      {/* This is hidden, actual input and label is styled outside ourselves */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        data-testid="resume-file-input"
        accept={supportedFileTypes.join(',')}
      />

      {selectedFile ? (
        <R2SFileSelectionContainer>
          <AttachmentIcon aria-hidden="true" />
          <BodyText color={colors.purple.primary} size="medium">
            {selectedFile.name}
          </BodyText>
          <button
            onClick={handleRemoveFile}
            type="button"
            aria-label={t('r2s:resume.remove.label')}
          >
            <CloseIcon aria-hidden />
          </button>
        </R2SFileSelectionContainer>
      ) : (
        <ChipActionButton type="button" onClick={handleButtonClick}>
          <Flex alignItems="center" justifyContent="flex-start" gap={spacing.defaultSmall}>
            <UploadIcon aria-hidden size="default" />
            <BodyText color={colors.purple.primary} size="medium">
              {t('r2s:resume.button.label')}
            </BodyText>
          </Flex>
        </ChipActionButton>
      )}
    </div>
  )
}
