import { type Listing } from '@kijiji/generated/graphql-types'
import { type FC } from 'react'

import { ListingImageType } from '@/features/listing/types/listings'
import {
  ListingGalleryImage,
  ListingGalleryMainImageButton,
  ListingGalleryMainImageContainer,
  StyledPlayIcon,
} from '@/features/listing-gallery/components/styled'
import { VirtualTour } from '@/features/listing-gallery/components/VirtualTour'
import { getGalleryImages } from '@/features/listing-gallery/utils/getGalleryImages'

export type ListingGalleryMainImageProps = {
  handleClick?: (index: number, type: ListingImageType) => void
  imageUrls?: Listing['imageUrls']
  imageAlt?: string
  /**
   * The ID of a youtube video. The parameters after ?v= in the youtube url.
   */
  youtubeVideoId?: string | null
  /**
   * The URL of a 360 virtual tour.
   */
  virtualTourUrl?: string | null
  /** Use the gallery image UI as a div instead of a interactive button */
  notInteractive?: boolean

  /**
   * Defines if this component is being used as a carousel
   */
  isCarousel?: boolean
}

export const ListingGalleryMainImage: FC<ListingGalleryMainImageProps> = ({
  handleClick,
  imageAlt,
  imageUrls = [],
  youtubeVideoId,
  virtualTourUrl,
  notInteractive,
  isCarousel,
}) => {
  const { main } = getGalleryImages(imageUrls, youtubeVideoId, virtualTourUrl, 0)

  if (!main.imageSrc) return null

  const handleMainImageClick = () => {
    let mainImageType: ListingImageType = ListingImageType.IMAGE

    if (main.isVideo) {
      mainImageType = ListingImageType.VIDEO
    } else if (main.isVirtualTour) {
      mainImageType = ListingImageType.TOUR
    }

    /** Main image index will always be 0 */
    handleClick?.(0, mainImageType)
  }

  return (
    <ListingGalleryMainImageContainer>
      <ListingGalleryMainImageButton
        onClick={handleMainImageClick}
        imageSrc={main.imageSrc}
        data-testid={isCarousel ? 'gallery-carousel-button' : 'listing-gallery-main'}
        as={notInteractive ? 'div' : 'button'}
        notInteractive={notInteractive}
      >
        {main.isVideo ? <StyledPlayIcon variant="md" data-testid="gallery-main-play-icon" /> : null}
        {main.isVirtualTour ? (
          <VirtualTour url={main.imageSrc} />
        ) : (
          <ListingGalleryImage
            skipNextImage
            src={main.imageSrc}
            alt={imageAlt ?? ''}
            data-testid={isCarousel ? 'gallery-slide-image' : 'gallery-main-image'}
          />
        )}
      </ListingGalleryMainImageButton>
    </ListingGalleryMainImageContainer>
  )
}
