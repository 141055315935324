import ShareListingsIcon from '@kijiji/icons/src/icons/ShareListings'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { type FC, useCallback, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { type SocialShareDirection } from '@/features/social-sharing/components/SocialShareMenu'
import { SocialShareButtonWrapper } from '@/features/social-sharing/components/styled'
import { useClickOutside } from '@/hooks/useClickOutside'
import { type VipQueryListing } from '@/types/listings'
import { Button } from '@/ui/atoms/button'
import { type ResponsiveProp } from '@/ui/typings/helpers'

const SocialShareMenu = dynamic(
  () =>
    import('@/features/social-sharing/components/SocialShareMenu').then(
      (module) => module.SocialShareMenu
    ),
  {
    ssr: false,
  }
)

export type SocialShareMenuButtonProps = {
  isSimplified?: boolean
  listing: VipQueryListing
  /**
   * Direction the menu will open
   */
  direction?: ResponsiveProp<SocialShareDirection>
  page: 'vip'
}

export const SocialShareMenuButton: FC<SocialShareMenuButtonProps> = ({
  direction = 'right',
  isSimplified,
  listing,
  page,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.COMMON])
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const socialMenuContainer = useRef<HTMLDivElement>(null)
  const { spacing } = useTheme()

  const toggleMenu = () => setIsMenuOpen((prev) => !prev)
  const dismissMenu = useCallback(() => setIsMenuOpen(false), [])

  useClickOutside(socialMenuContainer, dismissMenu)

  return (
    <SocialShareButtonWrapper ref={socialMenuContainer} isSimplified={isSimplified}>
      <Button
        data-testid="social-share-button"
        variant={isSimplified ? 'primaryNoBorder' : 'secondary'}
        size="small"
        gap={spacing.mini}
        onClick={toggleMenu}
      >
        <ShareListingsIcon size="xsm" /> <span>{t('social_share.button.label')}</span>
      </Button>

      {isMenuOpen ? (
        <SocialShareMenu
          dismissMenu={dismissMenu}
          direction={direction}
          listing={listing}
          page={page}
        />
      ) : null}
    </SocialShareButtonWrapper>
  )
}
