export const CPO_PARTNERS = {
  ACURA: 'acura',
  FORD: 'ford',
  GM: 'gm',
  HONDA: 'honda',
  VOLKSWAGEN: 'volkswagen',
} as const

export const CPO_VALUES: Record<string, { link: string }> = {
  [CPO_PARTNERS.ACURA]: { link: 'https://cuv.acura.ca/inventory/search?L=E' },
  [CPO_PARTNERS.FORD]: {
    link: 'https://pubads.g.doubleclick.net/gampad/clk?id=449726319&iu=/5138',
  },
  [CPO_PARTNERS.GM]: { link: 'https://www.gmcpo.ca/Benefits' },
  [CPO_PARTNERS.HONDA]: { link: 'https://cuv.honda.ca/inventory/search?L=E' },
  [CPO_PARTNERS.VOLKSWAGEN]: { link: 'https://www.vw.ca/en/certified-preowned.html' },
}
