import { isAutocanValueEstimateAvailable } from '@/domain/autocanada/isAutocanValueEstimateAvailable'
import { isAutocanWarrantyAvailable } from '@/domain/autocanada/isAutocanWarrantyAvailable'
import { getLocationPath } from '@/domain/location/getLocationPath'
import { type VipQueryListing } from '@/types/listings'

export enum AutocanFeatures {
  VALUE_ESTIMATE = 'value-estimate',
  WARRANTY = 'warranty',
}

export const getAutocanFeatures = (
  listing: VipQueryListing,
  isDealerListing: boolean
): AutocanFeatures[] => {
  const locationPath = getLocationPath(listing.location.id)

  const allowWarranty = isAutocanWarrantyAvailable({
    categoryId: listing.categoryId,
    isDealerListing,
    locationPath,
    sellerType: listing.posterInfo.sellerType,
  })

  const allowValueEstimate = isAutocanValueEstimateAvailable({
    categoryId: listing.categoryId,
    isDealerListing,
    locationPath,
    sellerType: listing.posterInfo.sellerType,
  })

  const allowedFeatures: AutocanFeatures[] = []

  if (allowWarranty) allowedFeatures.push(AutocanFeatures.WARRANTY)
  if (allowValueEstimate) allowedFeatures.push(AutocanFeatures.VALUE_ESTIMATE)

  return allowedFeatures
}
