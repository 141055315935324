import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { getMyConversationUrl } from '@/features/listing/utils/reply-to-seller/getMyConversationUrl'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { SystemMessage } from '@/ui/molecules/system-message'

export type R2SExistingConversationProps = { conversationId?: string | null }

export const R2SExistingConversation: FC<R2SExistingConversationProps> = ({ conversationId }) => {
  const { t } = useTranslation(TRANSLATION_KEYS.R2S)
  const { colors, spacing } = useTheme()

  const chatUrl = getMyConversationUrl(conversationId)

  return (
    <Flex gap={spacing.default} flexDirection="column">
      <SystemMessage
        description={
          <BodyText color={colors.grey.primary} size="large" textAlign="left">
            {t('r2s:existing_conversation.message')}
          </BodyText>
        }
        hideCloseButton
        variation="tip"
      />

      <Spacing pBottom={spacing.default}>
        <Button as="a" variant="primary" href={chatUrl} target="_blank" isFullWidth>
          {t('r2s:existing_conversation.open_chat')}
        </Button>
      </Spacing>
    </Flex>
  )
}
