import { useGetListingsSimilarQuery } from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode, useEffect } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { ListingsSimilarModalBody } from '@/features/listing/components/similar/ListingsSimilarModalBody'
import { MobileFooterCloseButtonContainer } from '@/features/listing/components/similar/styled'
import { LISTING_SIMILAR_MODAL_ITEM_COUNT } from '@/features/listing/constants/listingSimilar'
import { isExternalListingId } from '@/features/listing/utils/isExternalListingId'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { getSearchImpressions } from '@/lib/ga/utils/getSearchImpressions'
import { Button } from '@/ui/atoms/button'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal } from '@/ui/molecules/modal/Modal'
import { sendToLogger } from '@/utils/sendToLogger'

export type ListingsSimilarModalProps = {
  /**
   * Controlled open state of the modal.
   */
  isOpen: boolean

  /**
   * Callback to close the modal.
   */
  handleClose: () => void

  /**
   * ListingID
   */
  listingId: string

  successMessage?: {
    title: string
    description: string | ReactNode
  }
}

/**
 * Modal wrapper to show similar listings
 */
export const ListingsSimilarModal: FC<ListingsSimilarModalProps> = ({
  handleClose,
  isOpen,
  listingId,
  successMessage,
}) => {
  const { query } = useRouter()
  const { spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.COMMON, TRANSLATION_KEYS.LISTING])

  const isExternalId = isExternalListingId(query)

  const { data, error } = useGetListingsSimilarQuery({
    fetchPolicy: 'cache-first',
    variables: { listingId, limit: LISTING_SIMILAR_MODAL_ITEM_COUNT, isExternalId },
    onError: (err) =>
      sendToLogger(err, { fingerprint: ['listings-similar-modal'], extra: { listingId } }),
  })

  const areListingsAvailable =
    (!error && data?.listingsSimilar && data.listingsSimilar.length > 0) ?? false

  useEffect(() => {
    if (!areListingsAvailable) return
    if (isOpen) {
      const impressions = getSearchImpressions({ listings: data?.listingsSimilar ?? [] })
      trackEvent({
        action: GA_EVENT.ViewPromotion,
        name: GA_EVENT.ViewPromotion,
        customParameters: { ecommerce: { items: impressions } },
      })
    }
  }, [isOpen, areListingsAvailable, data])

  const handleCloseClick = (btnName: string) => {
    trackEvent({
      action: GA_EVENT.ModalClose,
      label: `partner=recommendations_on_reply_listing;btn=${btnName}`,
    })
    handleClose()
  }

  if (!areListingsAvailable) return null

  return (
    <Modal
      closeButtonLabel={t('common:modals.close.button.label')}
      hasCloseButton
      id="listing-similar-modal"
      isOpen={isOpen}
      label={t('listing:listings_similar.title')}
      width={{ large: '103rem', small: '100%' }}
      title={t('listing:listings_similar.title')}
      margin={{
        small: '0',
        large: '10vh auto',
      }}
      onCancel={() => {
        handleCloseClick('x_btn')
      }}
    >
      {data?.listingsSimilar ? (
        <Spacing
          mBottom={spacing.large}
          mTop={spacing.large}
          mLeft={spacing.large}
          mRight={spacing.large}
        >
          <ListingsSimilarModalBody
            successMessage={successMessage}
            listings={data.listingsSimilar}
          />
        </Spacing>
      ) : null}

      <MobileFooterCloseButtonContainer>
        <Button onClick={() => handleCloseClick('close_btn')} variant="secondary" isFullWidth>
          {t('forms.buttons.close')}
        </Button>
      </MobileFooterCloseButtonContainer>
    </Modal>
  )
}
