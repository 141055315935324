import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const ExpiredVipBanner = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.purple.light5};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.default}; 
  padding: ${theme.spacing.default} ${theme.spacing.large};
  align-items: center;

  & > a {
    white-space: nowrap;
  }

  ${MEDIA_QUERIES(theme).medium} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`
)

export const ExpiredVipImageContainer = styled.div`
  flex-shrink: 0;
  max-width: 8rem;
  height: 8rem;
`
