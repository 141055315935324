import styled from 'styled-components'

/**
 * Only apply a margin-bottom if AdSense content gets injected
 * A successful AdSense request applies an inline style to the ins element
 */
export const VipAdSenseWrapper = styled.div(
  ({ theme }) => `
  ins[style*="height"] {
    margin-bottom: ${theme.spacing.husky};
    display: inline-block;
  }
`
)
