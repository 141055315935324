import { ONTARIO_LOCATION_ID } from '@/constants/location'
import { getLocationPath } from '@/domain/location/getLocationPath'

/**
 * Verify if a location ID is in Ontario
 */
export const isOntarioLocation = (locationId: number) => {
  const locationPath = getLocationPath(locationId)
  const isInOntario = locationPath.some((location) => ONTARIO_LOCATION_ID === location.id)

  return isInOntario
}
