import { StyledGptR2S } from '@/components-page/vip/advertisement/styled'
import { VIP_GPT_ID } from '@/components-page/vip/advertisement/vipAdSlots'
import { LEGACY_MIN_DESKTOP, LEGACY_MIN_TABLET } from '@/constants/pageSettings'
import { triggerManualAdRefresh } from '@/features/advertisement/utils/triggerManualAdRefresh'
import { useBreakpointChange } from '@/hooks/useBreakpointChange'

export const GptR2S = () => {
  useBreakpointChange([LEGACY_MIN_DESKTOP, LEGACY_MIN_TABLET], () => {
    triggerManualAdRefresh(VIP_GPT_ID.R2S)
  })

  return <StyledGptR2S id={VIP_GPT_ID.R2S} data-testid={VIP_GPT_ID.R2S} />
}
