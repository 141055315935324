import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ListingDetailsDivider } from '@/components/shared/core-listing-card/card-details/ListingDetailsDivider'
import { CoreListingShippingBadge } from '@/components/shared/core-listing-card/card-details/styled'
import { formatListingDetails } from '@/components/shared/core-listing-card/utils/formatListingDetails'
import { RelativeTime } from '@/components/shared/relative-time/RelativeTime'
import { ListingDetailsContainer } from '@/components/srp/listing-card/listing-details/styled'
import { ListingBadgeIcons } from '@/components/srp/listing-card/ListingCard'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { getListingProximityLabel } from '@/domain/srp/getListingProximityLabel'
import { type GenericListing } from '@/features/listing/types/listings'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

export type CoreListingDetailsProps = {
  listing: GenericListing
}

export const CoreListingDetails: FC<CoreListingDetailsProps> = ({ listing }) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.LISTING])

  const { location } = listing
  const sortingDate = 'sortingDate' in listing ? listing.sortingDate : undefined

  // Should not return the  component if listing doesn't have any details defined
  if (!location.name && !sortingDate) return null

  const proximity =
    'distance' in listing.location ? getListingProximityLabel(listing.location) : undefined
  const shippedBySeller =
    'flags' in listing && 'shippedBySeller' in listing.flags ? listing.flags.shippedBySeller : false

  const listingDetails = formatListingDetails(listing, t, true)

  return (
    <ListingDetailsContainer data-testid="listing-details">
      {!!location.name && (
        <BodyText color={colors.grey.light1} data-testid="listing-location" size="xSmall">
          {location.name}
        </BodyText>
      )}

      <ListingDetailsDivider visible={!!location.name && !!proximity} />

      {!!proximity && (
        <BodyText color={colors.grey.light1} data-testid="listing-proximity" size="xSmall">
          {proximity}
        </BodyText>
      )}

      <ListingDetailsDivider visible={(!!location.name || !!proximity) && !!sortingDate} />

      <Flex gap={spacing.defaultSmall}>
        {/* Dates cause hydration errors since timezone is different between client and server */}
        {!!sortingDate && <RelativeTime date={sortingDate} />}
      </Flex>

      <ListingDetailsDivider
        visible={(!!location.name || !!proximity || !!sortingDate) && !!shippedBySeller}
      />

      {shippedBySeller ? (
        <CoreListingShippingBadge
          size={{ small: 'xSmall', medium: 'small' }}
          color={colors.grey.light1}
        >
          {ListingBadgeIcons.shippedBySeller}
          <span>{t('listing:flag.shipping')}</span>
        </CoreListingShippingBadge>
      ) : null}

      {!!listingDetails && (
        <BodyText color={colors.grey.light1} data-testid="listing-proximity" size="xSmall">
          {listingDetails}
        </BodyText>
      )}
    </ListingDetailsContainer>
  )
}
