import {
  type GetListingQuery,
  type LogoType,
  SellerType,
  useGetProfileMetricsQuery,
  useGetProfileQuery,
  UserType,
} from '@kijiji/generated/graphql-types'
import { format } from 'date-fns/format'
import { useTranslation } from 'next-i18next'
import { type FC, type HTMLAttributes, type PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { filterNullAttributes } from '@/features/attributes/utils/filterNullAttributes'
import { getForSaleByLabel } from '@/features/attributes/utils/getForSaleByLabel'
import { ProfileAvatar } from '@/features/profile/components/ProfileAvatar'
import { SellerNameLink } from '@/features/profile/components/styled'
import { UserRating } from '@/features/profile/components/UserRating'
import { isStandardProfile } from '@/features/profile/types/profile'
import { getGenericProfilePath } from '@/features/profile/utils/getGenericProfilePath'
import { trackEvent } from '@/lib/ga'
import { type GaEvent, GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Skeleton } from '@/ui/atoms/skeleton'

type AvatarWrapperProps = PropsWithChildren &
  HTMLAttributes<HTMLAnchorElement> & { avatarHref?: string }
const AvatarWrapper: FC<AvatarWrapperProps> = ({ children, avatarHref, ...props }) => {
  return avatarHref ? (
    <LinkCustom href={avatarHref} noStyle {...props}>
      {children}
    </LinkCustom>
  ) : (
    <>{children}</>
  )
}

type SellerOverviewCardProps = {
  /**
   * GA event to be triggered on rating click
   */
  googleReviewsGaEvent?: GaEvent

  /**
   * Specifies if avatar is hidden
   */
  hideAvatar?: boolean

  /**
   * Specifies if rating is hidden
   */
  hideRating?: boolean

  /**
   * Specifies if for sale by field is hidden
   */
  hideForSaleBy?: boolean

  /**
   * The listing field will be used to populate fields like "forSaleBy" and decide which link the avatar should be wrapped with
   * If we need to show the general profile information without a listing context, don't pass this field.
   */
  listing?: GetListingQuery['listing']

  /**
   * Specifies the logo type to be fetched from the API
   */
  logoType: LogoType

  /**
   * Seller Id
   */
  userId: string

  /**
   * Specifies if we should show how long the user has been on Kijiji
   */
  showJoinedSinceMetric?: boolean
}

/**
 * This component combines the user's Avatar, title, rating and their profile type.
 */
export const SellerOverviewCard: FC<SellerOverviewCardProps> = ({
  googleReviewsGaEvent,
  hideAvatar,
  hideForSaleBy,
  hideRating = false,
  listing,
  logoType,
  userId,
  showJoinedSinceMetric,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.PROFILE, TRANSLATION_KEYS.LISTING])

  const { data: profileData, loading: loadingProfile } = useGetProfileQuery({
    fetchPolicy: 'cache-first',
    variables: { profileId: userId, size: logoType },
  })
  const profile = profileData?.profile

  /** TODO: Document these definitions and extract them into generic utils functions */
  const isFSBOProfile = listing?.posterInfo.sellerType === SellerType.Fsbo
  const isFSBBProfile =
    profile?.userType === UserType.Professional ||
    profile?.userType === UserType.ReProfessional ||
    profile?.userType === UserType.RetailProfessional ||
    profile?.userType === UserType.JobsProfessional

  const { data: profileMetrics } = useGetProfileMetricsQuery({
    skip: !showJoinedSinceMetric && !isFSBBProfile && !isFSBOProfile,
    fetchPolicy: 'cache-first',
    variables: { profileId: userId },
  })

  const { memberSince } = profileMetrics?.profile ?? {}
  const formattedMemberSinceLabel = memberSince ? format(memberSince, 'MMMM yyyy') : undefined

  const name = profile?.name ?? t(`${TRANSLATION_KEYS.PROFILE}:profile.name.placeholder`)
  const photoSrc = isStandardProfile(profile) ? profile.imageUrl : null

  const listingAttributes = filterNullAttributes(listing?.attributes?.all)
  const forSaleByLabel = hideForSaleBy
    ? null
    : getForSaleByLabel({
        attributes: listingAttributes,
        sellerType: listing?.posterInfo.sellerType,
        t,
      })

  const profilePath = profileData?.profile.profilePath ?? getGenericProfilePath(userId)

  const handleProfileLinkClick = () => {
    trackEvent({ action: GA_EVENT.ViewUsersOtherAds, label: 'btn=sellerInfoBox' })
  }

  return (
    <Flex gap={spacing.default} alignItems="center">
      {hideAvatar ? null : (
        <AvatarWrapper
          aria-label={t('profile:avatar.aria_label', { name })}
          avatarHref={profilePath}
          onClick={handleProfileLinkClick}
        >
          <ProfileAvatar
            loading={loadingProfile}
            imageSrc={photoSrc}
            userId={userId}
            userName={name}
            userType={profile?.userType}
            variation="PROFILE_OVERVIEW"
          />
        </AvatarWrapper>
      )}

      <div>
        {loadingProfile ? (
          <Skeleton height="1rem" width="10rem" />
        ) : (
          <HeadlineText as="h3" size="medium">
            <SellerNameLink href={profilePath} onClick={handleProfileLinkClick}>
              {name}
            </SellerNameLink>
          </HeadlineText>
        )}

        {!hideRating && <UserRating googleReviewsGaEvent={googleReviewsGaEvent} userId={userId} />}

        {showJoinedSinceMetric && formattedMemberSinceLabel ? (
          <BodyText size="medium" color={colors.grey.light1}>
            {t('profile:metrics.joined_since', { formattedDate: formattedMemberSinceLabel })}
          </BodyText>
        ) : null}

        {forSaleByLabel ? (
          <BodyText size="medium" color={colors.grey.light1}>
            {forSaleByLabel}
          </BodyText>
        ) : null}
      </div>
    </Flex>
  )
}
