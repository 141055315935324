import {
  EDIT_LISTING_URL,
  LISTING_ACTIONS_QUERY_PARAMETERS,
  MY_ACCOUNT_LISTINGS,
  SUBMIT_AD_TYPE,
} from '@/features/listing/constants/listingUrls'

type GetListingActionUrlProps = {
  action: 'delete' | 'promote' | 'edit' | 'repost'
  listingId: string
}
export const getListingActionUrl = ({ action, listingId }: GetListingActionUrlProps): string => {
  const { LISTING_ID, SUBMIT_TYPE } = LISTING_ACTIONS_QUERY_PARAMETERS

  if (action === 'edit') {
    return `${EDIT_LISTING_URL}?${LISTING_ID}=${listingId}`
  }

  if (action === 'repost') {
    return `${EDIT_LISTING_URL}?${LISTING_ID}=${listingId}&${SUBMIT_TYPE}=${SUBMIT_AD_TYPE.REPOST}`
  }

  /** Everything else */
  return `${MY_ACCOUNT_LISTINGS}?focus=${listingId}&action=${action}`
}
