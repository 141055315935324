import { type GetInitialStructuredMessageQuery } from '@kijiji/generated/graphql-types'
import ChatOutlineIcon from '@kijiji/icons/src/icons/ChatOutline'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ChipActionButton } from '@/components/shared/chip-action-button/ChipActionButton'
import { R2SStructuredMessagesBox } from '@/features/listing/components/reply-to-seller/conversations/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

export type R2SStructuredMessagesProps = {
  structuredMessages: GetInitialStructuredMessageQuery['initialStructuredMessage']
  handleStructureMessageClick: (message: string) => void
  /**
   * Defines if element is visible in the form
   * It should be hidden after user focus on message text area
   */
  isVisible: boolean
}

export const R2SStructuredMessages: FC<R2SStructuredMessagesProps> = ({
  structuredMessages = [],
  handleStructureMessageClick,
  isVisible,
}) => {
  const { spacing, colors } = useTheme()

  if (!structuredMessages?.length) return null

  return (
    <R2SStructuredMessagesBox
      data-testid="structured-messages"
      flexDirection="column"
      gap={spacing.default}
      isVisible={!isVisible}
    >
      {structuredMessages?.map((item) => {
        const { message, id } = item ?? {}
        if (!message || !id) return null

        const onClick = () => handleStructureMessageClick(message)

        return (
          <ChipActionButton key={id} onClick={onClick} type="button">
            <Flex alignItems="center" justifyContent="flex-start" gap={spacing.defaultSmall}>
              <ChatOutlineIcon aria-hidden size="default" />

              <BodyText color={colors.purple.primary} size="medium">
                {message}
              </BodyText>
            </Flex>
          </ChipActionButton>
        )
      })}
    </R2SStructuredMessagesBox>
  )
}
