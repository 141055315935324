import { useListingRequestCarfaxMutation } from '@kijiji/generated/graphql-types'
import { Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { LearnMoreCarfaxLogo } from '@/components/vip/vip-vehicle-history/styled'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { useFormValidation } from '@/hooks/useFormValidation'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal } from '@/ui/molecules/modal/Modal'
import { SystemMessage } from '@/ui/molecules/system-message'
import { TextField } from '@/ui/molecules/text-field'
import { sendToLogger } from '@/utils/sendToLogger'
import { validationFnPerField } from '@/utils/validation'

export type VehicleHistoryRequestModalProps = {
  handleCloseModal: () => void
  isVisible: boolean
  carfaxLogoSrc: string
  listingId: string
}

const validationObj = {
  email: validationFnPerField.email,
}

export const VehicleHistoryRequestModal: FC<VehicleHistoryRequestModalProps> = ({
  handleCloseModal,
  isVisible,
  carfaxLogoSrc,
  listingId,
}) => {
  const { t } = useTranslation([TRANSLATION_KEYS.VIP, TRANSLATION_KEYS.COMMON])
  const { validateForm } = useFormValidation()
  const { colors, spacing } = useTheme()

  const [requestCarfaxReport, { loading, error, data }] = useListingRequestCarfaxMutation()
  const formSubmitted = data?.listingRequestCarfax !== undefined

  const handleValidation = (values: { email: string }) => {
    const isValid = validateForm(validationObj, values)

    if (Object.keys(isValid).length) {
      trackEvent({ action: GA_EVENT.CarfaxCheckFail, label: 'err=validation' })
    }

    return isValid
  }

  const handleRequestSubmit = (values: { email: string }) => {
    trackEvent({ action: GA_EVENT.CarfaxRequestAttempt })

    requestCarfaxReport({
      variables: {
        listingId,
        email: values.email,
      },
      onError: (err) => {
        sendToLogger(err, {
          fingerprint: ['VehicleHistoryRequestModal'],
        })
      },
    })
  }

  return (
    <Modal
      closeButtonLabel={t('common:modals.close.button.label')}
      data-testid="vehicle-history-request-modal"
      hasCloseButton
      height={{ small: '100vh', medium: 'fit-content' }}
      id="vehicle-history-request-modal"
      isOpen={isVisible}
      label={t('vip:vehicle_history.cta.request')}
      maxWidth={{ small: '100vw', medium: '55rem' }}
      onCancel={handleCloseModal}
      width={{ small: '100vw', medium: 'fit-content' }}
    >
      <Spacing mBottom={spacing.large}>
        <LearnMoreCarfaxLogo src={carfaxLogoSrc} alt="" />
      </Spacing>

      {!formSubmitted ? (
        <Flex flexDirection="column" gap={spacing.default}>
          {error && (
            <SystemMessage
              hideCloseButton
              variation="error"
              title={t('common:forms.errors.generic.title')}
              description={t('common:forms.errors.generic.description')}
            />
          )}

          <BodyText size="medium">{t('vip:vehicle_history.request.modal.description')}</BodyText>

          <Formik
            initialValues={{ email: '' }}
            onSubmit={handleRequestSubmit}
            validate={handleValidation}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({ handleChange, handleSubmit, values, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Flex flexDirection="column" gap={spacing.default}>
                    <TextField
                      autoComplete="email"
                      bottom="0"
                      error={errors.email && t('common:forms.inputs.email.errors.invalid')}
                      id="email"
                      label={t('common:forms.inputs.email.label')}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                    />

                    <Button
                      isFullWidth
                      isLoading={loading}
                      loadingLabel={t('common:forms.buttons.loading')}
                      type="submit"
                    >
                      {t('vip:vehicle_history.cta.request')}
                    </Button>

                    <BodyText size="xSmall" color={colors.grey.light1}>
                      {t('vip:vehicle_history.request.modal.disclaimer')}
                    </BodyText>
                  </Flex>
                </form>
              )
            }}
          </Formik>
        </Flex>
      ) : (
        <Flex flexDirection="column" gap={spacing.default}>
          <SystemMessage
            hideCloseButton
            variation="success"
            title={t('vip:vehicle_history.request.modal.success.title')}
            description={t('vip:vehicle_history.request.modal.success.description')}
          />
          <Button isFullWidth onClick={handleCloseModal}>
            {t('common:forms.buttons.close')}
          </Button>
        </Flex>
      )}
    </Modal>
  )
}
