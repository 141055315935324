import { RequestViewingMode } from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { RequestViewingButton } from '@/features/listing/components/request-viewing/RequestViewingButton'
import { useRequestViewingState } from '@/features/listing/hooks/request-viewing/useRequestViewingState'
import { isPintListing } from '@/features/listing/utils/isPintListing'
import { isListingEligibleForRequestViewing } from '@/features/listing/utils/request-viewing/isListingEligibleForRequestViewing'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type VipQueryListing, isRealEstateListingType } from '@/types/listings'
import { Button } from '@/ui/atoms/button'
import { SystemMessage } from '@/ui/molecules/system-message'

export enum RequestViewingType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}
type RequestViewingProps = {
  listing: VipQueryListing
  type?: RequestViewingType
  source: string
}

type RequestViewingButtonProps =
  | { onClick: () => void; href?: never }
  | { onClick?: never; href: string }

export const MAX_AVAILABLE_DATES = 3

/**
 * Component that renders a request viewing button. It also manages the display of success/error notifications after request viewing attempts
 * @component
 * @param props - Component props
 * @param props.listing - The listing object containing information about the property
 * @param props.type - The type of button to render. It can be either 'default' or 'custom'. Default renders a button, custom renders a styled button with a notification.
 * @param props.source - The source of the request viewing action for tracking purposes
 * @returns Returns either a button, notification, or null depending on the RequestViewingState
 */
export const RequestViewing = ({
  listing,
  type = RequestViewingType.CUSTOM,
  source,
}: RequestViewingProps) => {
  const { setRequestViewingState, requestViewingNotification, requestViewingMode } =
    useRequestViewingState()
  const { data: session } = useSession()
  const { t } = useTranslation([TRANSLATION_KEYS.REQUEST_VIEWING, TRANSLATION_KEYS.COMMON])

  const isPintAd = isPintListing(listing)
  const requestViewingUrl = isRealEstateListingType(listing) ? listing.requestViewingUrl : undefined

  const handleClickFSBORequestViewing = () => {
    setRequestViewingState({ isOpen: true, mode: RequestViewingMode.Fsbo })
    trackEvent({
      action: GA_EVENT.RequestViewingBegin,
      label: `cta=request_a_viewing;ad_id=${listing.id}`,
      customParameters: { eventCategory: source },
    })
  }

  const handleClickSubscriptionRequestViewing = () => {
    setRequestViewingState({ isOpen: true, mode: RequestViewingMode.SubscriptionSeller })
    trackEvent({
      action: GA_EVENT.RequestViewingBegin,
      label: `cta=request_a_viewing;ad_id=${listing.id};seller_type=subscription`,
      customParameters: { eventCategory: source },
    })
  }

  const getRequestViewingButton = ({ onClick, href }: RequestViewingButtonProps) => {
    if (type === RequestViewingType.DEFAULT) {
      return href ? (
        <Button variant="primary" isFullWidth as="a" target="_blank" href={href}>
          {t('request_viewing:title_short')}
        </Button>
      ) : (
        <Button variant="primary" isFullWidth onClick={onClick}>
          {t('request_viewing:title_short')}
        </Button>
      )
    } else {
      return <RequestViewingButton {...(href ? { href } : { onClick })} showNewBadge />
    }
  }

  const renderRequestViewingButton = () => {
    // if the listing is a Subscription Seller and has a external request viewing url, render a link
    if (isPintAd && requestViewingUrl) {
      return getRequestViewingButton({ href: requestViewingUrl })
    }
    // if the listing is a Subscription Seller and doesn't have that url, render a button to open Subscription Seller Request Viewing
    if (isPintAd) {
      return getRequestViewingButton({ onClick: handleClickSubscriptionRequestViewing })
    }
    // finally if it's a FSBO listing, render a button to open FSBO Request Viewing
    return getRequestViewingButton({ onClick: handleClickFSBORequestViewing })
  }

  const renderNotification = () => {
    if (!requestViewingNotification) {
      return null
    }

    const mode =
      requestViewingMode === RequestViewingMode.SubscriptionSeller ? 'subscription' : 'fsbo'
    const email = session?.user?.email

    const isSuccessNotification = requestViewingNotification === 'success'
    const systemMessageTitle = isSuccessNotification
      ? `request_viewing:notification.success.${mode}.title`
      : 'common:system_messages.generic_error.title'

    const systemMessageDescription = isSuccessNotification
      ? `request_viewing:notification.success.${mode}.description`
      : 'request_viewing:notification.error.description'

    const systemMessageDescriptionParams = mode === 'subscription' ? { email } : {}

    return (
      <SystemMessage
        hideCloseButton
        variation={isSuccessNotification ? 'success' : 'error'}
        title={t(systemMessageTitle)}
        description={t(systemMessageDescription, systemMessageDescriptionParams)}
      />
    )
  }

  if (!isListingEligibleForRequestViewing(listing)) return null
  if (type === 'default') return renderRequestViewingButton()
  return requestViewingNotification ? renderNotification() : renderRequestViewingButton()
}
