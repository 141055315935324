import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const VipAttributesWrapper = styled.div<{
  hasCoreAttributes: boolean
  hasGenericAttributes: boolean
}>(
  ({ theme, hasCoreAttributes, hasGenericAttributes }) => `
  display: grid;
  gap: ${theme.spacing.large};
  grid-template-columns: 1fr;
  padding-bottom: ${hasGenericAttributes ? theme.spacing.husky : '0'};
  
  ${MEDIA_QUERIES(theme).medium} {
    grid-template-columns: ${hasCoreAttributes ? 'minmax(22rem, auto) 1fr' : '1fr'}; 
  }
`
)

export const VipAttributeCoreWrapper = styled.div(
  ({ theme }) => `
  border-bottom: .1rem solid ${theme.colors.grey.light4};
  display: grid;
  gap: ${theme.spacing.large};
  grid-template-columns: 1fr 1fr;
  padding-bottom: ${theme.spacing.default};
  
  ${MEDIA_QUERIES(theme).medium} {
    border-bottom: none;
    border-right: .1rem solid ${theme.colors.grey.light4};
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    padding-right: ${theme.spacing.default};
  }
`
)

export const VipGroupAttributesWrapper = styled.div(
  ({ theme }) => `
  display: grid;
  gap: ${theme.spacing.large};
  grid-template-columns: 1fr;
  
  ${MEDIA_QUERIES(theme).medium} {
    grid-template-columns: 1fr 1fr;
  }
`
)

export const VipAttributesFeaturedWrapper = styled.div(
  ({ theme }) => `
  width: 100%;
  border-top: 0.1rem solid ${theme.colors.grey.light4};
  padding-top: ${theme.spacing.large};

  ${MEDIA_QUERIES(theme).medium}{
    border-top: none;
    padding-top: 0;
  }
`
)
