import { getImageBySize } from '@kijiji/image'
import { type FC, type ImgHTMLAttributes, useEffect, useState } from 'react'

import { ImageGalleryPictureSlideStyled } from '@/components/shared/image-gallery-modal/styled'

type ImageGalleryPictureSlideProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  /**
   * The source URL of the image
   */
  src: string

  /**
   * Whether the image should show show it's full size version.
   * Note: This will be overridden if the image is in view to true
   */
  shouldShowFullSize?: boolean

  /**
   * Whether the image of the slide is in view or not
   */
  inView: boolean

  /**
   * Callback to called when the main image is loaded and in view
   */
  onLoadAndInView?: () => void
}

export const ImageGalleryPictureSlide: FC<ImageGalleryPictureSlideProps> = ({
  src,
  inView,
  alt,
  onLoad,
  onLoadAndInView,
  shouldShowFullSize,
  ...imgProps
}) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const showLargeImage = inView || shouldShowFullSize

  const handleMainLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    setIsLoaded(true)
    return onLoad && onLoad(event)
  }

  useEffect(() => {
    if (inView && isLoaded && onLoadAndInView) {
      onLoadAndInView()
    }
  }, [inView, isLoaded, onLoadAndInView])

  if (!showLargeImage && !isLoaded) {
    // Use the same quality as the thumbnail so it's not reloaded
    return (
      <ImageGalleryPictureSlideStyled
        src={getImageBySize(src, 140, 'WEBP', true)}
        alt={alt}
        onLoad={onLoad}
        {...imgProps}
      />
    )
  }

  return (
    <ImageGalleryPictureSlideStyled
      src={getImageBySize(src, 1600, 'WEBP', true)}
      alt={alt}
      onLoad={handleMainLoad}
      {...imgProps}
    />
  )
}
