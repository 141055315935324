import { type ListingPrice } from '@kijiji/generated/graphql-types'
import SvgIconCalculator from '@kijiji/icons/src/icons/IconCalculator'
import InfoToolTip from '@kijiji/icons/src/icons/InfoToolTip'
import { useTranslation } from 'next-i18next'
import { type FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { PaymentEstimatorModal } from '@/features/payment-estimator/components/PaymentEstimatorModal'
import { DEFAULT_FORM_VALUES } from '@/features/payment-estimator/constants/paymentEstimatorForm'
import { type PaymentEstimatorFormValues } from '@/features/payment-estimator/types/paymentEstimator'
import { calculateMonthlyPayment } from '@/features/payment-estimator/utils/calculateMonthlyPayment'
import { convertFormValuesToCents } from '@/features/payment-estimator/utils/convertFormValuesToCents'
import { getSavedFormValues } from '@/features/payment-estimator/utils/getSavedFormValues'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { isAutosDealerAmountPrice } from '@/types/search'
import { Flex } from '@/ui/atoms/flex'
import { Skeleton } from '@/ui/atoms/skeleton'
import { Spacing } from '@/ui/atoms/spacing'
import { TextLink } from '@/ui/atoms/text-link'
import { Tooltip } from '@/ui/atoms/tool-tip'

export type PaymentEstimatorProps = {
  /**
   * Listing price
   */
  price: ListingPrice
}

/**
 * Estimates monthly vehicle payments based on the interest rate, down payment and trade-in value.
 */
export const PaymentEstimator: FC<PaymentEstimatorProps> = ({ price }) => {
  const { spacing, colors } = useTheme()
  const { t } = useTranslation('payment_estimator')

  const [showModal, setShowModal] = useState(false)
  const [savedFormValues, setSavedFormValues] = useState<PaymentEstimatorFormValues | null>(null)
  const [loading, setLoading] = useState(true)
  const { routeLocale } = useLocale()

  useEffect(() => {
    const localStorageValues = getSavedFormValues()
    if (localStorageValues) {
      setSavedFormValues(localStorageValues)
    }

    /**
     * It will save user preferences on local storage
     * And set default values for their next session
     */
    setLoading(false)
  }, [])

  const monthlyPayment = useMemo(
    () =>
      savedFormValues && isAutosDealerAmountPrice(price)
        ? calculateMonthlyPayment({
            ...savedFormValues,
            locale: routeLocale,
            price: price.amount,
          })
        : null,
    [price, routeLocale, savedFormValues]
  )

  if (!isAmountPrice(price)) {
    return null
  }

  const handleOnClick = () => {
    trackEvent({ action: GA_EVENT.PaymentEstimatorBegin })
    setShowModal(true)
  }

  const handleModalClose = () => {
    // Update the monthly payment calculation
    const localStorageFormValues = getSavedFormValues()
    if (localStorageFormValues) {
      setSavedFormValues(localStorageFormValues)
    }
    setShowModal(false)
  }

  if (loading) {
    return (
      <Flex alignItems="center" gap={spacing.defaultSmall}>
        <SvgIconCalculator size="xxsm" color={colors.grey.primary} />
        <Skeleton height="2.2rem" width="13.1rem" bottom="0rem" id="payment-estimator" />
      </Flex>
    )
  }

  return (
    <Flex alignItems="center" gap={spacing.defaultSmall}>
      <SvgIconCalculator size="xxsm" color={colors.grey.primary} />

      {savedFormValues && monthlyPayment ? (
        <>
          <TextLink
            as="button"
            variant="secondary"
            hasUnderline
            onClick={handleOnClick}
            aria-label={t('payment_estimator:button_text')}
          >
            {monthlyPayment}
          </TextLink>
          <Tooltip
            orientation="top-left"
            tooltipId="payment-estimator"
            tooltipText={t('payment_estimator:tooltip_text')}
            variant="dark"
          >
            <Spacing mTop={spacing.mini}>
              <InfoToolTip size="xsm" aria-hidden />
            </Spacing>
          </Tooltip>
        </>
      ) : (
        <TextLink
          as="button"
          variant="secondary"
          hasUnderline
          onClick={handleOnClick}
          aria-label={t('payment_estimator:button_text')}
        >
          {t('payment_estimator:button_text')}
        </TextLink>
      )}

      {showModal && (
        <PaymentEstimatorModal
          handleModalClose={handleModalClose}
          initialFormValues={savedFormValues ?? convertFormValuesToCents(DEFAULT_FORM_VALUES)}
          isOpen={showModal}
          price={price.amount}
        />
      )}
    </Flex>
  )
}
