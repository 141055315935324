import styled from 'styled-components'

import { VipSectionTitleImage } from '@/components/vip/shared/vip-section-title/styled'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const VehicleSectionContent = styled.div(
  ({ theme }) => `
  display: grid;
  gap: ${theme.spacing.defaultSmall};
  grid-template-columns: 1fr;
  
  ${MEDIA_QUERIES(theme).medium} {
    gap: ${theme.spacing.default};
    grid-template-columns: repeat(2, minmax(auto, 37rem));
  }

`
)

export const VehicleHistoryViewReportContent = styled.div(
  ({ theme }) => `
  display: grid;
  gap: ${theme.spacing.default};
  grid-template-columns: repeat(2, 9.2rem);
  
  & svg {
    width: 9.2rem;
    height: 4rem;
    border-radius: ${theme.borderRadius.small};
  }
    
  ${MEDIA_QUERIES(theme).medium}{
    grid-template-columns: repeat(4, 9.2rem);
  }
`
)

/** LEARN MORE - MODAL */
// eslint-disable-next-line @kijiji/prefer-image-custom
export const LearnMoreCarfaxLogo = styled.img(
  ({ theme }) => `
  align-self: center;
  height: 4rem;
  width: min-content;

  ${MEDIA_QUERIES(theme).medium}{
    align-self: flex-start;
  }
`
)

export const LearnMoreCarfaxIconContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.default};

  & svg {
    flex-shrink: 0;
    height: 4rem;
    width: 9.2rem;
  }

  ${MEDIA_QUERIES(theme).medium} {
    flex-direction: row;
  }
`
)

export const CarfaxLogoImage = styled(VipSectionTitleImage)(
  ({ theme }) => `
  height: 1.5rem; /* Default mobile height */
  ${MEDIA_QUERIES(theme).medium} {
    height: 2rem; /* Desktop height */
  }
`
)
