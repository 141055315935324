import {
  LogoType,
  useGetGoogleReviewsQuery,
  useGetProfileQuery,
  useGetReviewSummaryQuery,
} from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { getDealerRatingProps } from '@/features/profile/utils/getDealerRatingProps'
import { getNumericUserId } from '@/features/profile/utils/getNumericUserId'
import { getUserRatingProps } from '@/features/profile/utils/getUserRatingProps'
import { isCommercialUserId } from '@/features/profile/utils/isCommercialUserId'
import { type GaEvent } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { type RatingProps, Rating } from '@/ui/molecules/rating'
import { isDealerAccount } from '@/utils/user'

export type UserRatingProps = {
  googleReviewsGaEvent?: GaEvent
  userId: string
  size?: RatingProps['size']
}

/**
 * This component combines the user's Avatar, title, rating and their profile type.
 */
export const UserRating: FC<UserRatingProps> = ({ googleReviewsGaEvent, userId, size }) => {
  const { data } = useSession()
  const { spacing, colors } = useTheme()
  const { t } = useTranslation([TRANSLATION_KEYS.PROFILE])

  const isOwnRating = userId === data?.user.sub
  const isCommercialUser = isCommercialUserId(userId)
  const numericUserId = getNumericUserId(userId)

  const { data: profileData } = useGetProfileQuery({
    fetchPolicy: 'cache-first',
    variables: {
      profileId: userId,
      size: LogoType.ViewAd,
    },
  })

  const isDealer = isDealerAccount(profileData?.profile.userType)

  const { data: reviewData, loading: loadingUserReviews } = useGetReviewSummaryQuery({
    skip: isDealer || isCommercialUser,
    fetchPolicy: 'cache-first',
    variables: { userId: numericUserId },
  })

  const { data: googleReviewData, loading: loadingDealerReviews } = useGetGoogleReviewsQuery({
    skip: !isDealer,
    fetchPolicy: 'cache-first',
    variables: { dealerId: numericUserId.toString() },
  })

  const { shouldShowRating, ...props } = isDealer
    ? getDealerRatingProps({
        accountType: profileData?.profile.userType,
        googleReviewData,
        googleReviewsGaEvent,
        loadingDealerReviews,
      })
    : getUserRatingProps({
        isOwnRating,
        loadingUserReviews,
        numericUserId,
        reviewData,
      })

  if (!shouldShowRating) {
    return null
  }

  const ratingAccessibilityLabel = isDealer
    ? t('profile:rating.google_rating')
    : t('profile:rating.accessibility.label')

  return (
    <Flex alignItems="center" gap={spacing.defaultSmall}>
      <Rating {...props} size={size} accessibilityLabel={ratingAccessibilityLabel} />

      {isDealer && (
        <BodyText as="span" size="small" color={colors.grey.light1}>
          {t('profile:rating.google_rating')}
        </BodyText>
      )}
    </Flex>
  )
}
