import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { RelativeTime } from '@/components/shared/relative-time/RelativeTime'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { type VipQueryListing } from '@/types/listings'
import { Spacing } from '@/ui/atoms/spacing'

export type VipPostedDateProps = {
  sortingDate?: VipQueryListing['sortingDate']
}

export const VipPostedDate: FC<VipPostedDateProps> = ({ sortingDate }) => {
  const { t } = useTranslation([TRANSLATION_KEYS.VIP])
  const { colors, spacing } = useTheme()

  if (!sortingDate) return null

  return (
    <Spacing mBottom={spacing.default}>
      <RelativeTime color={colors.grey.primary} size="small" as="span" date={sortingDate}>
        {t('vip:posted')}
      </RelativeTime>
    </Spacing>
  )
}
