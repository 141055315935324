import {
  type GetAutosWarrantyQuery,
  type GetListingQuery,
  useGetAutosWarrantyQuery,
} from '@kijiji/generated/graphql-types'
import ChevronRight from '@kijiji/icons/src/icons/ChevronRight'
import { formatPrice } from '@kijiji/money'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type FC, useState } from 'react'
import { useTheme } from 'styled-components'

import { HideAtOrLarger, ShowAtOrLarger } from '@/components/shared/breakpoint'
import { ImageCustom } from '@/components/shared/image'
import {
  AC_URL,
  AUTOCAN_CTA_IDS,
  ICO_UTM_PARAMS,
  ICO_UTM_PARAMS_PAGE_SPECIFIC,
} from '@/constants/ico'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { getAutocanWarrantyUrl } from '@/domain/autocanada/getAutocanWarrantyUrl'
import { openAutocanValueEstimateModal } from '@/domain/autocanada/openAutocanValueEstimateModal'
import { ROUTE_LOCALE } from '@/domain/locale'
import { getLocationPath } from '@/domain/location/getLocationPath'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import {
  AutocanBannerIconWrapper,
  AutocanBannerImage,
  AutocanBannerImageContainer,
  AutocanBannerWrapper,
  IconContainer,
} from '@/features/autocanada/components/styled'
import { AutocanFeatures } from '@/features/autocanada/utils/getAutocanFeatures'
import { isWarrantyQuoteExpired } from '@/features/autocanada/utils/isWarrantyQuoteExpired'
import { useLocale } from '@/hooks/useLocale'
import { RemoteConfigs } from '@/lib/firebase/config/configs'
import { useConfig } from '@/lib/firebase/hooks'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { getIcoScriptURL } from '@/utils/autoCanadaHelper'
import { getUserDevice } from '@/utils/getUserDevice'
import { sendToLogger } from '@/utils/sendToLogger'

const WARRANTY_SHIELD_IMAGE = '/next-assets/images/autocanada/img-autocan-warranty-shield.png'
const VALUE_ESTIMATE_COIN_IMAGE = '/next-assets/images/autocanada/il-dollar.png'
const VALUE_ESTIMATE_IMAGE = '/next-assets/images/autocanada/car-gold-bars.png'

export type AutocanBannerProps = {
  /**
   * Specifies if the entire banner should be interactable on desktop
   * A button will appear on the right side when it isn't
   *
   * i.e. when the feature appears by itself on the view the desktop version would take the non-interactable version
   */
  interactWithWholeBanner?: boolean
  /**
   * Specifies the listing data coming back from the API
   */
  listing: NonNullable<GetListingQuery['listing']>
  /**
   * Specifies the browser's user agent
   */
  userAgent: string
  /**
   * Autocan feature variant
   */
  variant: AutocanFeatures
}

export const AutocanBanner: FC<AutocanBannerProps> = ({
  interactWithWholeBanner,
  listing,
  userAgent,
  variant,
}) => {
  const { data: autocanData } = useConfig(RemoteConfigs.AUTOCAN.key) ?? {}

  const [warrantyQuote, setWarrantyQuote] = useState<GetAutosWarrantyQuery['autosWarranty']>()
  const { t } = useTranslation([TRANSLATION_KEYS.AUTOCANADA, TRANSLATION_KEYS.LISTING])
  const { colors, spacing } = useTheme()
  const { data: userData } = useSession()
  const { routeLocale } = useLocale()
  const { push } = useRouter()

  useGetAutosWarrantyQuery({
    skip: variant !== AutocanFeatures.WARRANTY,
    variables: { id: listing.id },
    onCompleted: ({ autosWarranty }) => {
      if (!autosWarranty) return

      if (!isWarrantyQuoteExpired(autosWarranty.offerExpiry)) {
        setWarrantyQuote(autosWarranty)
      }
    },
  })

  const isMobile = getUserDevice(userAgent).isPhone
  const listingMainImage = listing?.imageUrls[0]
  const provinceId = getLocationPath(listing.location.id)[1].id

  const warrantyExternalLink =
    variant === AutocanFeatures.WARRANTY
      ? getAutocanWarrantyUrl({
          baseUrl:
            typeof autocanData?.warrantyUrl === 'string' ? autocanData.warrantyUrl : undefined,
          isMobile,
          hasQuote: !!warrantyQuote,
          listing,
          pageType: 'VIP',
          provinceId,
          routeLocale,
          userId: userData?.user.sub,
        })
      : undefined

  if (variant === AutocanFeatures.WARRANTY && !warrantyExternalLink) return null

  const config = {
    [AutocanFeatures.WARRANTY]: {
      buttonLabel: t('autocanada:warranty.link.label'),
      badge: WARRANTY_SHIELD_IMAGE,
      badgeHeight: '4.7rem',
      imageSrc: listingMainImage,
      subtitle: warrantyQuote
        ? t('autocanada:warranty.quote.subtitle', {
            asLowAsPerMonth: formatPrice(warrantyQuote.asLowAsPerMonth, {
              isFrench: routeLocale !== ROUTE_LOCALE.en,
            }),
          })
        : t('autocanada:warranty.subtitle_short'),
      title: warrantyQuote ? t('autocanada:warranty.quote.title') : t('autocanada:warranty.title'),
      usesMainImage: true,
      externalLink: warrantyExternalLink,
    },
    [AutocanFeatures.VALUE_ESTIMATE]: {
      badge: VALUE_ESTIMATE_COIN_IMAGE,
      badgeHeight: '4rem',
      buttonLabel: t('autocanada:value_estimate.link.label'),
      externalLink: undefined,
      imageSrc: VALUE_ESTIMATE_IMAGE,
      subtitle: t('autocanada:value_estimate.link.subtitle'),
      title: t('autocanada:value_estimate.title'),
      usesMainImage: false,
    },
  }

  const {
    badge,
    badgeHeight,
    imageSrc,
    usesMainImage,
    externalLink,
    title,
    subtitle,
    buttonLabel,
  } = config[variant]

  const bannerTagType = interactWithWholeBanner ? (externalLink ? 'a' : 'button') : 'div'
  const isLink = bannerTagType === 'a'

  const shouldShowLongSubtitle =
    variant === AutocanFeatures.WARRANTY && !interactWithWholeBanner && !warrantyQuote

  const getCarDetailsTranslationKeys = () => {
    const { carmake, carmodel, caryear } =
      listing.attributes?.all?.reduce((acc: Record<string, string>, curr) => {
        const name = curr?.canonicalName
        const value = curr?.values[0]

        if (!value) return acc

        if (
          name === ATTRIBUTES.CAR_MAKE ||
          name === ATTRIBUTES.CAR_MODEL ||
          name === ATTRIBUTES.CAR_YEAR
        ) {
          return { ...acc, [name]: value }
        }

        return acc
      }, {}) ?? {}

    return { carmake, carmodel, caryear }
  }

  const handleClick = () => {
    if (variant === AutocanFeatures.WARRANTY) {
      const label = warrantyQuote ? 'btn=quote' : 'btn=FnWURL'

      trackEvent({
        action: GA_EVENT.R2SExternalBegin,
        label,
        customParameters: { eventCategory: 'VIP' },
      })
      return
    }

    if (variant === AutocanFeatures.VALUE_ESTIMATE) {
      openAutocanValueEstimateModal({
        attributes: listing.attributes?.all,
        ctaId: AUTOCAN_CTA_IDS.VALUE_ESTIMATE_VIP,
        customGAParameters: { eventCategory: 'VIP' },
        isMobile,
        provinceId,
        routeLocale,
        user: userData?.user,
        utmCampaign: ICO_UTM_PARAMS_PAGE_SPECIFIC.VIP.UTM_CAMPAIGN,
        utmContent: ICO_UTM_PARAMS.UTM_CONTENT_ESTIMATE,
        push,
      })

      trackEvent({
        action: GA_EVENT.ValueEstimateBegin,
        customParameters: { eventCategory: 'VIP' },
      })

      return
    }

    /**
     * TODO: It will be implemented on a later ticket
     *
     * if (variant === 'financing') {
     *  trackEvent({
     *    action: GA_EVENT.R2SExternalBegin,
     *    label: 'btn=FnIURL',
     *    customParameters: { eventCategory: 'VIP' },
     *  })
     */
  }

  /**
   * props to be passed to the component handling the click of the feature
   * It will be the banner when it is interactable, and the button when the banner is not
   * */
  const clickProps = {
    href: isLink ? externalLink : undefined,
    onClick: handleClick,
    target: isLink ? '_blank' : undefined,
  }

  const warrantyQuoteDescription = () => {
    if (!warrantyQuote) return null

    return (
      <BodyText size="xSmall" color={colors.grey.light1}>
        {t('autocanada:warranty.quote.description', {
          paymentTermInMonths: warrantyQuote.paymentTermInMonths,
        })}
      </BodyText>
    )
  }

  const autocanBannerContent = () => {
    const translationKeys = shouldShowLongSubtitle ? getCarDetailsTranslationKeys() : {}

    return (
      <>
        {variant === AutocanFeatures.VALUE_ESTIMATE ? (
          // Only initialize the script if the value-estimate banner is showing
          <Script
            id={AC_URL.ICO_SCRIPT_ID}
            onError={(err) => sendToLogger(err, { fingerprint: ['ValueEstimate'] })}
            src={getIcoScriptURL()}
            strategy="lazyOnload"
          />
        ) : null}

        <Flex alignItems="center">
          <AutocanBannerImageContainer>
            <AutocanBannerImage
              $isIllustration={!usesMainImage}
              alt=""
              data-testid="autocan-image"
              skipNextImage
              src={imageSrc}
            />

            <AutocanBannerIconWrapper iconHeight={badgeHeight}>
              <ImageCustom src={badge} alt="" skipNextImage />
            </AutocanBannerIconWrapper>
          </AutocanBannerImageContainer>

          <Flex flexDirection="column" gap={spacing.mini}>
            <HeadlineText as="h3" size="small" weight="medium">
              {title}
            </HeadlineText>

            {shouldShowLongSubtitle ? (
              <BodyText size="small">
                <ShowAtOrLarger as="span" breakpoint="medium">
                  {t('autocanada:warranty.subtitle_long', translationKeys)}
                </ShowAtOrLarger>

                <HideAtOrLarger as="span" breakpoint="medium">
                  {subtitle}
                </HideAtOrLarger>
              </BodyText>
            ) : (
              <BodyText size="small">{subtitle}</BodyText>
            )}
          </Flex>
        </Flex>
      </>
    )
  }

  if (interactWithWholeBanner) {
    return (
      <Flex flexDirection="column" gap={spacing.defaultSmall}>
        <AutocanBannerWrapper
          {...clickProps}
          forwardedAs={bannerTagType}
          alignItems="center"
          aria-label={buttonLabel}
          justifyContent="space-between"
        >
          {autocanBannerContent()}

          <IconContainer>
            <ChevronRight aria-hidden color={colors.grey.light1} />
          </IconContainer>
        </AutocanBannerWrapper>

        {warrantyQuoteDescription()}
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" gap={spacing.defaultSmall}>
      <AutocanBannerWrapper
        alignItems="center"
        aria-label={buttonLabel}
        justifyContent="space-between"
      >
        {autocanBannerContent()}

        <HideAtOrLarger breakpoint="medium">
          <IconContainer>
            <ChevronRight aria-hidden color={colors.grey.light1} />
          </IconContainer>
        </HideAtOrLarger>

        <ShowAtOrLarger breakpoint="medium">
          <Button {...clickProps} as={bannerTagType} variant="secondary" size="small">
            {buttonLabel}
          </Button>
        </ShowAtOrLarger>
      </AutocanBannerWrapper>

      {warrantyQuoteDescription()}
    </Flex>
  )
}
