import { type CoreListing, type GetListingQuery } from '@kijiji/generated/graphql-types'

import { type Listing as SearchListing } from '@/types/search'

type Listing = NonNullable<GetListingQuery['listing']>

/**
 * Accounts for any type of listing including from a search, or just CoreListings (carousels)
 */
export type GenericListing = NonNullable<Listing | SearchListing | CoreListing>

export enum ListingImageType {
  IMAGE = 'Image',
  VIDEO = 'Video',
  TOUR = 'VirtualTour',
  SHOWCASE = 'Shwcse',
}
